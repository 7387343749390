import './SliderArrowStyles.css';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const SliderArrow = ({ direction, handleClick, width, height, top, left, arrowMargin, paddingLeft, borderRadius, border, additionalStyles, disableHover, invisible, iconSize }) => {

    const defaultSliderArrowStyles = {
        width: width,
        height: height,
        borderRadius: borderRadius,
        border: border,
        top: top,
        left: left,
    };

    const sliderArrowStyles = {
        ...defaultSliderArrowStyles,
        ...additionalStyles,
    };

    const sliderLeftArrowStyles = {
        ...sliderArrowStyles,
        paddingLeft: paddingLeft,
        left: arrowMargin,
        transform: "translateY(-50%)",
    };

    const sliderRightArrowStyles = {
        ...sliderArrowStyles,
        paddingLeft: paddingLeft,
        right: arrowMargin,
        transform: "translateY(-50%)",
    };

    let arrowClasses = direction === 'left' ? 'sliderArrow sliderArrowLeft' : 'sliderArrow sliderArrowRight';
    if (disableHover) {
        arrowClasses += ' no-hover';
    }

    if (invisible) {
        arrowClasses += ' invisible';
    }

    const arrow =
        <div
            className={arrowClasses}
            onClick={handleClick}
            style={{
                ...direction === 'left' ? sliderLeftArrowStyles : sliderRightArrowStyles,
            }}
        >
            {direction === 'left' ? <FaAngleLeft size={iconSize}/> : <FaAngleRight size={iconSize}/>}
        </div>;

    return arrow;
};

export default SliderArrow;
