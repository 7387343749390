import React, { useState, useEffect } from 'react';
import './ListingsOverviewBoxStyles.css';
import { commonFont, alternateColorScheme } from '../commonStyles';
import WatchListing from './WatchListing';
import ListingStatistics from './ListingStatistics';
import SmallWatchListing from './SmallWatchListing';
import boughtWatchImage1 from '../assets/fakeListingImages/watchListing1.png';
import boughtWatchImage2 from '../assets/fakeListingImages/watchListing4.png';
import boughtWatchImage3 from '../assets/fakeListingImages/watchListing5.png';
import boughtWatchImage4 from '../assets/fakeListingImages/watchListing6.png';
import boughtWatchImage5 from '../assets/fakeListingImages/watchListing7.png';
import boughtWatchImage6 from '../assets/fakeListingImages/watchListing8.png';



 function ListingsOverviewBox({ }) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    // Size på en listing kan vara 1 eller 2 använder denna för att beräkna hur många listings som ryms på varje rad.
    const [listingSizes, setListingSizes] = useState(Array(12).fill(1));
    const [expandedCountByRow, setExpandedCountByRow] = useState(Array(12).fill(0));

    const [expandedoverViewBox, setExpandedoverViewBox] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);

    const saveScrollPosition = () => {
        console.log("Saving scroll position ", window.scrollY);
        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
            console.log("Scrolling to ", lastScrollY);
            if (expandedoverViewBox) {
            window.scrollTo(0, lastScrollY - 0);
            }
; // Introducing a 100ms delay
    }, [expandedoverViewBox]);

    const handleExpandButtonClick = () => {
        saveScrollPosition();
        setExpandedoverViewBox(!expandedoverViewBox);
    };

    const watchInfo = {brand: "Rolex", modelName: "Day-Date 36", dateAdded: new Date('2023-06-01'), popularity: 450,
    sellerComment: "Ref 118388 Men’s 18K Yellow Gold Black MOP Dia Dial , Bezel & Case 2006",
     price: 42995, shippingCost: "$99", sellerCountry: "US",  sellerCity: "New York", 
     images: [boughtWatchImage1, boughtWatchImage2, boughtWatchImage3]}

    const numOfListings = 12;

    const recalculateExpandedCountByRow = (newListingSizes) => {
        const newExpandedCountByRow = Array(12).fill(0);
        let sizeSum = 0;
        let rowIndex = 0;
      
        for (let i = 0; i < newListingSizes.length; i++) {
          sizeSum += newListingSizes[i];
          const sizeThreshold = newExpandedCountByRow[rowIndex] > 0 ? 5 : 4;
      
          if (sizeSum > sizeThreshold) {
            rowIndex++;
            sizeSum = newListingSizes[i];
          }
      
          if (newListingSizes[i] === 2) {
            newExpandedCountByRow[rowIndex]++;
          }
        }
        return newExpandedCountByRow;
      };

    const handleListingSizeChange = (index, size, rowIndex) => {
        // Update listingSizes
        setListingSizes(prevSizes => {
          const newSizes = [...prevSizes];
          newSizes[index] = (size === 'large') ? 2 : 1;
          const newExpandedCount = recalculateExpandedCountByRow(newSizes);
          setExpandedCountByRow(newExpandedCount);


          return newSizes;
        });
      

      }

      const renderRows = () => {
        let rows = [];
        let sizeSum = 0;
        let rowIndex = 0;
        let renderedListings = [];
        let expandedCount = 0;  // Count of expanded items in current row
        
        const addRow = () => {
            rows.push(
                <div className="listingsOverviewRow" key={rowIndex}>
                    {renderedListings}
                </div>
            );
        }
    
        for (let i = 0; i < listingSizes.length; i++) {
            sizeSum += listingSizes[i];
            if (listingSizes[i] === 2 || listingSizes[i]=== 3) expandedCount++;
    
            const sizeThreshold = (windowWidth > 1340) ? (expandedCount > 0 ? 5 : 4) : windowWidth > 945 ? 4 : windowWidth > 660 ? 3 : 2;
            
            if (sizeSum >= sizeThreshold) {
                if (rowIndex === 0 || expandedoverViewBox) addRow();
                rowIndex++;
                renderedListings = [];
                sizeSum = listingSizes[i];
                expandedCount = (listingSizes[i] === 2) ? 1 : windowWidth > 840 ? 0 : 0;
            }
    
            if (rowIndex === 0 || expandedoverViewBox) {
                renderedListings.push(
                    <ListingStatistics
                        key={i}
                        watchListingInfo={watchInfo}
                        index={i}
                        rowIndex={rowIndex}
                        isExpanded={listingSizes[i] === 2}
                        handleListingSizeChange={handleListingSizeChange}
                        width={windowWidth}
                    />
                );
    
                // Handle the last row
                if (i === listingSizes.length - 1) {
                    addRow();
                }
            }
        }
        return rows;
    };
    

    const calculateDynamicMinWidth = (listingSizes, expandedCountByRow) => {
        const smallWidth = 270;  // Replace with the actual small listing width
        const largeWidth = 500;  // Replace with the actual large listing width
        const gap = 25;          // The gap you've set between listings in your CSS
        
        let dynamicMinWidth = 0;
        
        if(expandedCountByRow[0] > 0) {
          // At least one item is large in the first row
          dynamicMinWidth = (smallWidth + gap) * 2 + largeWidth - gap;
        } else {
          // All items are small in the first row
          dynamicMinWidth = (smallWidth + gap) * 3 - gap;
        }
      
        // Add extra padding or margins if needed
        dynamicMinWidth += 0;
        
        return dynamicMinWidth;
      }
      
    
      const dynamicMinWidth = calculateDynamicMinWidth(listingSizes, expandedCountByRow);



    return (
        <div
        style={{
            display: "flex"
        }}
        >
        <div className={`listingsOverViewParentDiv ${expandedoverViewBox ? 'expanded' : 'none'}` }
        >
            <button className='listingsOverviewExpandButton'
            onClick={handleExpandButtonClick}
            >
                <p className='ListingsExpansionButtonText' 
                style={{
                    color: 'white',
                    marginTop: "22px"
                }}>
                    {expandedoverViewBox ? "Minimize" : "Expand"}
                    
                </p>

            </button>
            <p className='listingsOverviewHeader'
            style={{
                fontFamily: commonFont.fontFamily
            }}
            >
                {`Your Listings`}
            </p>
        <div className="listingsOverviewBoxDiv"
        
        >
            {renderRows()}
        </div>
        </div>
        </div>
      );
    
}

export default ListingsOverviewBox;