import React, { useState, useEffect, useContext } from 'react';
import { WatchFilterContext } from '../WatchFilterContext';
import './WatchListingsStyles.css';
import './commonStyles.css';
import listingImage1 from '../assets/fakeListingImages/watchListing1.png';
import listingImage2 from '../Klockbilder/Rolex-Sea-Dweller-44.png';
import listingImage3 from '../Klockbilder/Rolex-Yacht-Master-40.png';
import listingImage4 from '../Klockbilder/Rolex-Daytona.jpeg';
import listingImage5 from '../Klockbilder/Rolex-Air-King.jpeg';
import listingImage6 from '../Klockbilder/Rolex-Datejust-36.png';
import listingImage7 from '../Klockbilder/Rolex-Datejust-41.png';
import listingImage8 from '../Klockbilder/Rolex-GMT-Master-II.jpeg';
import listingImage9 from '../Klockbilder/Rolex-Oyster-Perpetual-36.png';
import listingImage10 from '../Klockbilder/Rolex-Oyster-Perpetual-41.png';
import TestImage from '../assets/fakeListingImages/watchListing6.png';
import SimplifiedWatchListing from './SimplifiedWatchListing';
import SmallWatchListing from './SmallWatchListing';
import { Link } from 'react-router-dom';
import FilterDropDown from './FilterDropDown';

function WatchListings({ marginTop }) {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
}, []);


    const watchListingParentDivJSStyles = {
        marginTop: marginTop
    }

    const watches = [
        {brand: "Rolex", modelName: "Day-Date 36", dateAdded: new Date('2023-06-01'), popularity: 450,
    sellerComment: "Ref 118388 Men’s 18K Yellow Gold Black MOP Dia Dial , Bezel & Case 2006",
     price: 42995, shippingCost: "$99", sellerCountry: "US",  sellerCity: "New York", 
     images: [listingImage5, listingImage2, listingImage4]},
     {brand: "Rolex", modelName: "Oyster Perpetual 26", dateAdded: new Date('2023-06-02'), popularity: 300,
     sellerComment: "Lady's Oyster Perpetual Ref 76094 Steel Silver Dial", dateAdded: new Date('2023-06-03'),
      price: 3900, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Wheaton",
      images: [listingImage1, listingImage2, listingImage3]},
      {brand: "Rolex", modelName: "GMT-Master II", dateAdded: new Date('2023-06-04'), popularity: 120,
      sellerComment: "REAL in STOCK Unworn 2023 New Release Two Tone Grey Black Ceramic Bezel 40mm GMT-Master II",
       price: 23395, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Miami",
       images: [listingImage3, listingImage2, listingImage7]},
       {brand: "Rolex", modelName: "Day-Date II", dateAdded: new Date('2023-06-05'),popularity: 150,
       sellerComment: "President 41mm 218239 18k White Gold Roman Dial Watch B/PAPERS",
        price: 43900, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Boca Raton",
        images: [listingImage10, listingImage8, listingImage9]},
        {brand: "Rolex", modelName: "Sky-Dweller", dateAdded: new Date('2023-06-06'), popularity: 500,
        sellerComment: "326934 COMPLETE SET",
         price: 100000, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Carmel",
         images: [listingImage2, listingImage9, listingImage1]},
         {brand: "Rolex", modelName: "Datejust 31", dateAdded: new Date('2023-06-07'), popularity: 200,
         sellerComment: "Pre-Owned Datejust 31 Yellow Gold White Roman Dial on Presidential Bracelet [WITH BOX]",
          price: 19995, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Los Angeles",
          images: [listingImage4, listingImage6, listingImage5]},
          {brand: "Rolex", modelName: "Submariner Date", dateAdded: new Date('2023-06-08'),popularity: 220,
          sellerComment: "126610LN NEVER WORN 5-2022 CARD / COMPLETE",
           price: 16990, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Dallas",
           images: [listingImage5, listingImage8, listingImage2]},
           {brand: "Rolex", modelName: "Yacht-Master II", dateAdded: new Date('2023-06-09'), popularity: 20,
           sellerComment: "44mm NEW/UNWORN",
            price: 22200, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Beverly Hills",
            images: [listingImage2, listingImage8, listingImage3]},
            {brand: "Rolex", modelName: "Explorer II", dateAdded: new Date('2023-06-10'), popularity: 200,
            sellerComment: "Polar White 42mm 216570",
             price: 11950, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Oradell",
             images: [listingImage6, listingImage5, listingImage10]},
             {brand: "Rolex", modelName: "Sea-Dweller 4000", dateAdded: new Date('2023-06-11'),popularity: 50,
             sellerComment: "40mm F Serial 16600",
              price: 10495, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Oradell",
              images: [listingImage9, listingImage2, listingImage3]},
              {brand: "Rolex", modelName: "Sky-Dweller", dateAdded: new Date('2023-06-12'), popularity: 90,
              sellerComment: "CHOCOLATE DIAL - NEW 2023 - FRESH - AVAILABLE NOW - 326935",
               price: 59888, shippingCost: "$99", sellerCountry: "US",  sellerCity: "New York",
               images: [listingImage4, listingImage2, listingImage1]},
               {brand: "Rolex", modelName: "Cellini Dual Time", dateAdded: new Date('2023-06-13'), popularity: 400,
               sellerComment: "18K Rose Gold Black Dial on Black Leather Strap [COMPLETE SET 2021] 39mm",
                price: 16450, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Los Angeles",
                images: [listingImage1, listingImage8, listingImage3]},
    ]

    const [orderedWatches, setOrderedWatches] = useState(watches);


    const handleChange = (selectedOption) => {
        switch (selectedOption.value) {
          case 'New Listings':
            // Sort by a 'dateAdded' property of each watch, for example
            setOrderedWatches([...watches].sort((a, b) => b.dateAdded - a.dateAdded));
            break;
          case 'Popular':
            // Sort by a 'popularity' property of each watch, for example
            setOrderedWatches([...watches].sort((a, b) => b.popularity - a.popularity));
            break;
          case 'Rising Price':
            // Sort by price in ascending order
            setOrderedWatches([...watches].sort((a, b) => a.price - b.price));
            break;
          case 'Falling Price':
            // Sort by price in descending order
            setOrderedWatches([...watches].sort((a, b) => b.price - a.price));
            break;
          default:
            setOrderedWatches(watches);
        }
      };
  
      const sortBy = [
          { value: 'New Listings', label: 'New Listings' },
          { value: 'Popular', label: 'Popular' },
          { value: 'Rising Price', label: 'Rising Price' },
          { value: 'Falling Price', label: 'Falling Price' },
      
          // Add more options as needed
        ];







    


    return (
        <div className='dropDownAndListingParentDiv'>
          <span className='header listingsHeader'
          >Watch Listings</span>
            <div className='dropDownParentDiv'>
            <FilterDropDown options={sortBy} handleChange={handleChange} borderRadius={"5px"} width={"160px"} />
            </div>
        <div className='watchListingsParentDiv' style={watchListingParentDivJSStyles}>
            {orderedWatches.map((watch, index) => (
                (screenWidth > 1100 || (screenWidth <= 860 && screenWidth >= 721) || (screenWidth <= 570 && screenWidth >= 360)) ?
                  <SimplifiedWatchListing key={index} watchListingInfo={watch} images={watch.images} to={`/watchlisting3`}/>
                  :
                  <SmallWatchListing key={index} watchListingInfo={watch} images={watch.images} to={`/watchlisting3`}/>
                
            ))}
        </div>
        </div>
    );

}

export default WatchListings;