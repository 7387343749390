import React, { useState, useEffect } from 'react';
import ToggleSwitch from './ToggleSwitch';
import InputField from './InputField';
import TwoSmallInputFields from './TwoSmallInputFields';
import Button from './MediumBlackButton';
import ResetPasswordLink from './ResetPasswordLink';
import { useNavigate } from 'react-router-dom';
import { signInUser, signUpUser } from '../api/userService';
import { useAuth } from '../contexts/AuthContext'; // Adjust the path as necessary


const LoginForm = () => {

  const { setIsAuthenticated } = useAuth();

  const [ipError, setIpError] = useState(null);


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formContainer = {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '800px',
    width: windowWidth > 520 ? '470px' : "350px", 
}

  const navigate = useNavigate();

  const [mode, setMode] = useState('left');



  const [username, setUsername] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);


  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);

  const [passwordcopy, setPasswordcopy] = useState('');

  const [passwordMatchError, setPasswordMatchError] = useState(null);

  const [showPassword, setShowPassword] = useState(false);

  const [submitted, setSubmitted] = useState(false);


  function validateEmail(email) {
    // Regular expression for basic email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  
    if (!emailRegex.test(email)) {
      return 'Invalid email address';
    } else {
      return null;
    }
  }

  function validatePassword(password) {
    // Check that the password is at least 10 characters long:
    if (password.length < 10) {
      return 'Password must be at least 10 characters long';
    } else {
      return null;
    }
  }

  function validatePasswordMatch(password, passwordcopy) {
    // Check that the passwords match when a user creates an account:
    if (password !== passwordcopy) {
        return 'Passwords must match';
    } else {
        return null;
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
}



  const handleFirstnameChange = (event) => {
    setFirstname(event.target.value);
  };

  const handleLastnameChange = (event) => {
    setLastname(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);

  };

  const handlePasswordcopyChange = (event) => {
    setPasswordcopy(event.target.value);
  }; 

  const handleSubmit = (event) => {
    console.log("handleSubmit called");

    setIpError(null); // Reset IP error state

    // Prevent the form from being submitted by default
    event.preventDefault();

    setSubmitted(true);

    // Validate the email:
    const errorMessage = validateEmail(email);
    setEmailError(errorMessage);

    // Validate Password:
    const passwordErrorMessage = validatePassword(password);
    setPasswordError(passwordErrorMessage);
  
    const passwordMatchErrorMessage = validatePasswordMatch(password, passwordcopy);
    setPasswordMatchError(passwordMatchErrorMessage);

    // If there are no errors, you can continue with submitting the form
    if (!errorMessage && !passwordErrorMessage && (!passwordMatchErrorMessage || mode === 'left')) {
        console.log("No input errors.");

        // Depending on the mode, either sign in or sign up
        if (mode === 'right') {
            signUpUser(firstname, lastname, username, email, password)
            .then(() => {
                console.log('User signed up successfully');
                setIsAuthenticated(true);  // Update authentication state
                localStorage.setItem('isLoggedIn', 'true'); // Persist login state
                navigate('/profileHome/explore-primetick');
            })
            .catch((error) => {
                if (error.response && error.response.status === 400 && error.response.data === 'An account has already been registered from this IP address.') {
                  setIpError(error.response.data);
              } else {
                  console.error('Error signing up:', error);
                  // Handle other types of errors here
              }
            });

        } else {
// Inside the handleSubmit function

            signInUser(email, password)
                .then(() => {
                  console.log('User signed in successfully');
                  setIsAuthenticated(true);  // Update authentication state
                  localStorage.setItem('isLoggedIn', 'true'); // Persist login state
                  navigate('/profileHome/explore-primetick');
              })
                .catch((error) => {
                    console.error('Error signing in:', error);
                    // Handle additional unexpected errors here, if needed
                });

        }
    }
};



  



  return (
    <div style={parentContainer}>

    <form style={formContainer} onSubmit={handleSubmit}>
    <ToggleSwitch width={windowWidth} leftLabel={"Sign In"} rightLabel={"Register Account"} onToggle={setMode} marginTop={'60px'} marginBottom={'40px'}/>

      {mode === 'right' && (
        <>
        <TwoSmallInputFields width={windowWidth} type="text" placeholder1={windowWidth > 520 ? "Enter Your First Name:" : "First Name:"} value1={firstname} onChange1={handleFirstnameChange} placeholder2={windowWidth > 520 ? "Enter Your Last Name:" : "Last Name:"} value2={lastname} onChange2={handleLastnameChange} marginTop={'30px'} marginBottom={'50px'} />
        <label>
            Username:
        <InputField type="text" placeholder="Enter Your Username:" value={username} onChange={handleUsernameChange} marginTop={'6px'} marginBottom={'0px'} />
        </label>
        </>
      )}
      <label style={{marginTop: '30px',}}>
        Email:
        <InputField type="text" placeholder="Enter Your Email:" value={email} onChange={handleEmailChange} marginTop={'6px'} marginBottom={'30px'} />
      </label>        
      {submitted && emailError && <p style={{margin: '10px 0'}}>{emailError}</p>}

        <label>
         Password:
        <InputField type={showPassword ? 'text' : 'password'} placeholder="Enter Your Password:" value={password} onChange={handlePasswordChange} marginTop={'6px'} marginBottom={'10px'}/>
        <input type="checkbox" onClick={togglePasswordVisibility} style={{marginBottom: '35px'}}/> Show Password

        </label>

        {submitted && passwordError && <p style={{ margin: '10px 0' }}>{passwordError}</p>}

        {mode === 'right' && (
            <>
                <label>
                Confirm Password:
                <InputField type="password" placeholder="Confirm Your Password:" value={passwordcopy} onChange={handlePasswordcopyChange} marginTop={'6px'} marginBottom={'20px'}/>
                </label>        

                {submitted && passwordMatchError && <p style={{ margin: '10px 0' }}>{passwordMatchError}</p>}
                {ipError && <p style={{ color: 'red', margin: '10px 0' }}>{ipError}</p>}

            </>
        )}
        {mode === 'left' && (
            <>
             <ResetPasswordLink />
             <Button text="Sign In"/>
             </>

        )}
        {mode === 'right' && (
            
             <Button text="Create Account"/>
             

        )}


      </form>
    </div>
  );
}

//

const parentContainer = {
    display: 'flex',
    justifyContent: 'center',
}





export default LoginForm;
