const LightBoldTextBox = ({ lightText, lightTextFontWeight, boldText, boldTextFontWeight, marginLeft, color }) => {


const lightTextStyle = {
  fontWeight: lightTextFontWeight, // Adjust as necessary
  whiteSpace: 'pre-wrap', // Preserve spaces
  marginLeft: marginLeft,
  color: color,
}

const boldTextStyle = {
    fontWeight: boldTextFontWeight, // Adjust as necessary
    whiteSpace: 'pre-wrap', // Preserve spaces
    marginLeft: marginLeft,
    color: color,
  }
 return (
  <>
    <span style={boldTextStyle}>{boldText}</span>
    <span style={lightTextStyle}>{lightText}</span>
  </>
);

}

export default LightBoldTextBox;