import RecommendedSellerListings from './RecommendedSellerListings';
import './SellerDashBoardKeyStatisticsStyles.css';
import { LiaMoneyBillWaveSolid } from 'react-icons/lia';
import { TfiWallet } from 'react-icons/tfi';
import { IoTrendingUpSharp } from 'react-icons/io5';
import { TbDeviceWatchUp } from 'react-icons/tb';



const revenueAmount = 5070212;

const incomingPayments = 46023;

const salesTrend = 37;

function formatNumberWithCommas(number) {
    const str = number.toString();
    let result = '';
    let count = 0;
    
    for (let i = str.length - 1; i >= 0; i--) {
      count++;
      result = str[i] + result;
      
      if (count % 3 === 0 && i !== 0) {
        result = ',' + result;
      }
    }
    
    return result + ".00";
  }
  
  console.log(formatNumberWithCommas(32453223531)); // Output: "32,453,223,531"
  

  function SellerDashBoardKeyStatistics({ keyStatistics }) {
    function formatNumberWithCommas(number) {
      const str = number.toString();
      let result = '';
      let count = 0;
      
      for (let i = str.length - 1; i >= 0; i--) {
        count++;
        result = str[i] + result;
        
        if (count % 3 === 0 && i !== 0) {
          result = ',' + result;
        }
      }
      
      return result + ".00";
    }
  
    return (
      <div className='SellerDashBoardKeyStatisticsParent'>
        {keyStatistics.map((statistic, index) => (
          <div className='SellerDashBoardKeyStatistic' key={index}>
            <div className='SellerDashBoardKeyStatisticIconParent'>
              {statistic.icon}
            </div>
            <div className='SellerDashBoardKeyStatisticTextsParent'>
              <p className='SellerDashBoardKeyStatisticHeader'>
                {statistic.header}
              </p>
              <p className='SellerDashBoardKeyStatisticText'
                style={{
                  color: statistic.smallTextColor
                }}
              >
                {statistic.smallText}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  }
  
  export default SellerDashBoardKeyStatistics;