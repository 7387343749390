import React, {lazy, useState, useEffect} from 'react';
import './WatchDetailsPageStyles.css'
import { Link } from 'react-router-dom';

import wristWatchImage from '../assets/SellWatchPageImages/wristWatchImage1.png';
import pocketWatchImage from '../assets/SellWatchPageImages/pocketWatchImage1.png';
import otherOptionImage from '../assets/SellWatchPageImages/otherOptionImage1.png';
import maleSymbolImage2 from '../assets/SellWatchPageImages/maleSymbol2.png';

import femaleSymbolImage2 from '../assets/SellWatchPageImages/femaleSymbol2.png';
import SimpleButton from '../components/SimpleButton';
import BasicButton from '../components/BasicButton';
import listingPreviewImage1 from '../assets/SellWatchPageImages/previewListingImage1.png';

import SellWatchInputComponent from '../components/SellWatchInputComponent';
import SimpleDynamicTextBox from '../components/DynamicTextBox';
import LightBoldTextBox from '../components/LightBoldTextBox';
import FilterDropDown from '../components/FilterDropDown';
import InputField from '../components/InputField';
import SimpleTextInput from '../components/SimpleTextInput'; 
import ListingPreviewComponent from '../components/ListingPreviewComponent';
import { HiOutlineGift, HiX } from 'react-icons/hi';

import CustomDropdown from '../components/CustomDropDown';
import DropDownWatchDetailsContent from '../components/DropDownWatchDetailsContent';
import WatchFunctionsCheckBoxes from '../components/WatchFunctionsCheckboxes';
import { GiWaterDivinerStick } from 'react-icons/gi';

const WatchDetailsPage = ({ formData, setFormData, functionsCheckboxData, setFunctionsCheckboxData }) => {



    const [width, setWidth] = useState(window.innerWidth);



    useEffect(() => {
        window.scrollTo(0, 0);

        const handleResize = () => setWidth(window.innerWidth);
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    const handleCheckboxDataChange = (field, value) => {
        setFunctionsCheckboxData(prevFormData => ({
            ...prevFormData,
            [field]: value
        }));
    }

    const handleChange = (field, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [field]: value
        }));
    };



    function calculateWidthBasedOnScreenWidth(width) {
        let sizeCategory;
    
        // First switch statement to categorize the screen width
        switch (true) {
            case (width < 1160):
                sizeCategory = 'small';
                break;
            default:
                sizeCategory = 'unknown';
        }
    }


    const previewListingInfo = {brand: "Rolex", modelName: "Day-Date 36", dateAdded: new Date('2023-06-01'), popularity: 450,
    sellerComment: "Ref 118388 Men’s 18K Yellow Gold Black MOP Dia Dial , Bezel & Case 2006",
     price: 42995, shippingCost: "$99", sellerCountry: "US",  sellerCity: "New York", 
     images: [listingPreviewImage1]}
  
    

    let obligatoryWatchDetailCategories = [

        {
            category: "Type Of Watch",
            dropdownOptions: null,

            clickableOptions: [
                { value: 'Wristwatch', label: 'Wristwatch' },  
                { value: 'Pocket watch', label: 'Pocketwatch' },
                { value: 'Other', label: 'Other' },
            ],
            clickableOptionWidth: width > 570 ? "90%" : "95%",
            clickableOptionHeight: width > 1160 || (width < 1033 && width > 828) ? "170px" : width > 690 ? "150px" : width > 570 ? "135px" : "115px",
            fontSize: width > 570 ? "0.9rem" : "0.8rem",
            categoryHeaderMarginTop: width > 690 ? "30px": width > 570 ? "40px" : "20px",
            width: "100%",
            height: width > 690 ? "210px" : width > 570 ? "190px" : "180px",
            images: [wristWatchImage, pocketWatchImage, otherOptionImage],
            imageWidth: width > 1160 || (width < 1033 && width > 828) ? "90px" : width > 690 ? "70px" : "60px",
            imageHeight: width > 1160 || (width < 1033 && width > 828) ? "90px" : width > 690 ? "70px" : "60px",
            imageMarginTop: "10px"
        },

        {
            category: "Brand",
            dropdownOptions: [
                { value: 'Rolex', label: 'Rolex' },
                { value: 'Omega', label: 'Omega' },
                { value: 'Cartier', label: 'Cartier' },  
                { value: 'Patek Philippe', label: 'Patek Philippe' },
                { value: 'Audemars Piguet', label: 'Audemars Piguet' },
                { value: 'Hublot', label: 'Hublot' },  
                { value: 'TAG Heuer', label: 'TAG Heuer' },
                { value: 'IWC Schaffhausen', label: 'IWC Schaffhausen' },
                { value: 'Longines', label: 'Longines' },  
                { value: 'Zenith', label: 'Zenith' },
                { value: 'Breitling', label: 'Breitling' },
                { value: 'Hamilton', label: 'Hamilton' },  
                { value: 'Panerai', label: 'Panerai' },
                { value: 'Baume & Mercier', label: 'Baume & Mercier' },  
                { value: 'Blancpain', label: 'Blancpain' },
                { value: 'A. Lange & Söhne', label: 'A. Lange & Söhne' },
                { value: 'Glashütte Original', label: 'Glashütte Original' },  
                { value: 'Tudor', label: 'Tudor' },
                { value: 'Franck Muller', label: 'Franck Muller' },
            ],
            clickableOptions: null,
            categoryHeaderMarginTop: "10px",
            marginTop: width > 570 ? "0px" : "20px",
            width: "100%",
            height: width > 570 ? "63px" : "75px",
        },

        {
            category: "Model",
            dropdownOptions: null,
            clickableOptions: null,
            textInput: true,
            placeholderText: "",
            categoryHeaderMarginTop: "10px",
            marginTop: width > 570 ? "0px" : "20px",

            width: "100%",
            height: width > 570 ? "63px" : "75px",
        },
        {
            category: "Case Diameter (mm)",
            dropdownOptions: null,
            clickableOptions: null,
            categoryHeaderMarginTop: "10px",
            placeholderText: "",
            marginTop: width > 570 ? "0px" : "20px",

            width: "100%",
            height: width > 570 ? "63px" : "75px",

        },
        {
            category: "Movement",
            dropdownOptions: [
                { value: 'Automatic', label: 'Automatic' },
                { value: 'Manual winding', label: 'Manual winding' },  
                { value: 'Quartz', label: 'Quartz' },
            ],
            clickableOptions: null,
            categoryHeaderMarginTop: "10px",
            marginTop: width > 570 ? "0px" : "20px",
            height: width > 570 ? "63px" : "75px",

            width: "100%",
        },
        {
            category: "Gender",
            dropdownOptions: null,
            clickableOptions: [
                { value: "Men's Watch/Unisex", label: "Men's Watch/Unisex" },
                { value: "Women's Watch", label: "Women's Watch" },  
            ],
            clickableOptionWidth: "90%",
            clickableOptionHeight: "150px",
            marginTop: width > 570 ? "0px" : "20px",
            fontSize: width > 570 ? "0.9rem" : "0.8rem",
            categoryHeaderMarginTop: "15px",
            width: "100%",
            height: "170px",
            images: [maleSymbolImage2, femaleSymbolImage2],
            imageWidth: "70px",
            imageHeight: "70px",
            imageMarginTop: "10px"

        },
        {
            category: "Reference Number",
            dropdownOptions: null,
            clickableOptions: null,
            textInput: true,
            placeholderText: "Usually found on the case back",
            width: "100%",
            categoryHeaderMarginTop: "10px",
            marginTop: width > 570 ? "0px" : "20px",
            height: width > 570 ? "63px" : "75px",

        },
        {
            category: "Condition",
            dropdownOptions: [
                { value: 'Unworn', label: 'Unworn (Mint Condition, without signs of wear)' },
                { value: 'Very Good', label: 'Very Good (Worn with little to no signs of wear)' },  
                { value: 'Good', label: 'Good (Light signs of wear or scratches)' },
                { value: 'Fair', label: 'Fair (Obvious signs of wear or scratches)' },
                { value: 'Poor', label: 'Poor (Heavy signs of wear or scratches)' },  
                { value: 'Incomplete', label: 'Incomplete (Components missing, non-functional)' },
            ],
            clickableOptions: null,
            categoryHeaderMarginTop: "10px",

            width: "100%",
            marginTop: width > 570 ? "0px" : "20px",
            height: width > 570 ? "63px" : "75px",        
        },
        {
            category: "Scope Of Delivery",
            dropdownOptions: [
                { value: 'Watch Only', label: 'Watch Only' },
                { value: 'Watch with original box', label: 'Watch with original box' },
                { value: 'Watch with original papers', label: 'Watch with original papers' },  
                { value: 'Watch with original box and original papers', label: 'Watch with original box and original papers' },  
            ],
            clickableOptions: null,
            categoryHeaderMarginTop: "10px",

            width: "100%",
            marginTop: width > 570 ? "0px" : "20px",
            height: width > 570 ? "63px" : "75px",
        },
        {
            category: "Serial Number",
            dropdownOptions: null,
            clickableOptions: null,
            textInput: true,
            placeholderText: "Usually found on the back or side of the case",
            categoryHeaderMarginTop: "10px",

            width: "100%",
            marginTop: width > 570 ? "0px" : "20px",
            height: width > 570 ? "63px" : "75px",

        },

        {
            category: "Year Of Production",
            dropdownOptions: null,
            clickableOptions: null,
            textInput: true,
            categoryHeaderMarginTop: "10px",

            width: "100%",
            marginTop: width > 570 ? "0px" : "20px",
            height: width > 570 ? "63px" : "75px",    

        },

        {
            category: "Short Description",
            dropdownOptions: null,
            clickableOptions: null,
            textInput: false,
            placeholderText: "Very Short Description of the watch. Maximum 200 letters.",
            description: true,
            short: true,
            categoryHeaderMarginTop: "10px",
            
            width: "100%",
            marginTop: "10px",
            height: "220px",
            descriptionWidth: width > 1160 || (width < 1033 && width > 828) ? 466 : width > 690 ? 382 : width > 570 ? 346 : 290,
            descriptionHeight: 100,
            minConstraints: width > 570 ? [220, 100] : [250, 100],
            maxConstraints: width > 1160 || (width < 1033 && width > 828) ? [466, 160] : width > 690 ? [382, 160] : width > 570 ? [346, 160] : [290, 200],
        },

        {
            category: "Description",
            dropdownOptions: null,
            clickableOptions: null,
            categoryHeaderMarginTop: "10px",
            description: true,
            textInput: false,
            placeholderText: "For Example, where did you buy the watch? Does it still have a warranty? Has it been repaired? Is the watch damaged?",
            width: "100%",
            marginTop: width > 570 ? "10px" : "20px",

            descriptionWidth: width > 1160 || (width < 1033 && width > 828) ? 466 : width > 690 ? 382 : width > 570 ? 346 : 290,
            descriptionHeight: 150,
            minConstraints: [200, 100],
            maxConstraints:  width > 1160 || (width < 1033 && width > 828) ? [466, 320] : width > 690 ? [383, 320] : width > 570 ? [347, 320] : [290, 320],

        },

        {
            category: "Price",
            currencyOptions: [
                {value: "$", label: "USD"},
                {value: "SEK", label: "SEK"},
                {value: "£", label: "GBP"},
                {value: "€", label: "EUR"},
        
                 ],
            clickableOptions: null,
            categoryHeaderMarginTop: width > 570 ? "10px" : "0px",
            textInput: false,
            width: "100%",
            height: width > 570 ? "53px" : "65px", 
             marginTop: width > 570 ? "10px" : "0px"   

        },



        {
            category: "Comission Fee",
            dropdownOptions: null, 
            clickableOptions: null,
            categoryHeaderMarginTop: "10px",

            width: "100%",
            height: width > 570 ? "63px" : "75px",  
            marginTop: width > 570 ? "10px" : "2px"   
          
        },

        {
            category: "Earnings",
            dropdownOptions: null,
            clickableOptions: null,
            categoryHeaderMarginTop: "10px",

            width: "100%",
            height: width > 570 ? "63px" : "75px",  
            marginTop: width > 570 ? "10px" : "2px"   
  
        }
    ]

    let caliberCategories = [

        {
            category: "Movement/Caliber",
            dropDownOptions: null,
            checkboxes: null,
            textInput: true,
            width: "100px",
            height: width > 570 ? "63px" : "90px",

        },
        {
            category: "Base Caliber",
            dropDownOptions: null,
            checkboxes: null,
            textInput: true,
            width: "100px",
            height: width > 570 ? "63px" : "90px",
        },
        {
            category: "Power Reserve (h)",
            dropDownOptions: null,
            checkboxes: null,
            textInput: true,
            width: "100px",
            height: width > 570 ? "63px" : "90px",
        },
        {
            category: "Number Of Jewels",
            dropDownOptions: null,
            checkboxes: null,
            textInput: true,
            width: "100px",
            height: width > 570 ? "63px" : "90px",
        },
        {
            category: "Frequency",
            dropDownOptions: [
                { value: 'A/h', label: 'A/h' },  
                { value: 'Hz', label: 'Hz' },
            ],
            checkboxes: null,
            textInput: false,
            width: "100px",
            height: width > 570 ? "63px" : "90px",
        },
        {
            category: "Other Details",
            dropDownOptions: null,
            checkboxes: [
                { value: 'Genevian Seal', label: 'Genevian Seal' },
                { value: 'Chronometer', label: 'Chronometer' },
                { value: 'Master Chronometer', label: 'Master Chronometer' },
            ],
            textInput: false,
            width: "100px",
            height: width > 570 ? "63px" : "125px",
        },

        
    ]

    let caseCategories = [
        {
            category: "Case Material",
            dropDownOptions: [
                { value: 'Aliminium', label: 'Aliminium' },  
                { value: 'Bronze', label: 'Bronze' },
                { value: 'Carbon', label: 'Carbon' },
                { value: 'Ceramic', label: 'Ceramic' },
                { value: 'Gold/Steel', label: 'Gold/Steel' },
                { value: 'Palladium', label: 'Palladium' },
                { value: 'Plastic', label: 'Plastic' },
                { value: 'Platinum', label: 'Platinum' },
                { value: 'Red Gold', label: 'Red Gold' },
                { value: 'Rose Gold', label: 'Rose Gold' },
                { value: 'Silver', label: 'Silver' },
                { value: 'Steel', label: 'Steel' },
                { value: 'Tantalum', label: 'Tantalum' },
                { value: 'Titanium', label: 'Titanium' },
                { value: 'Tungsten', label: 'Tungsten' },
                { value: 'White Gold', label: 'White Gold' },
                { value: 'Yellow Gold', label: 'Yellow Gold' },
            ],
            checkboxes: null,
            textInput: false,
            width: "100px",
            height: width > 570 ? "63px" : "90px",

        },
        {
            category: "Bezel Material",
            dropDownOptions: [
                { value: 'Aliminium', label: 'Aliminium' },  
                { value: 'Bronze', label: 'Bronze' },
                { value: 'Carbon', label: 'Carbon' },
                { value: 'Ceramic', label: 'Ceramic' },
                { value: 'Gold/Steel', label: 'Gold/Steel' },
                { value: 'Palladium', label: 'Palladium' },
                { value: 'Plastic', label: 'Plastic' },
                { value: 'Platinum', label: 'Platinum' },
                { value: 'Red Gold', label: 'Red Gold' },
                { value: 'Rose Gold', label: 'Rose Gold' },
                { value: 'Silver', label: 'Silver' },
                { value: 'Steel', label: 'Steel' },
                { value: 'Tantalum', label: 'Tantalum' },
                { value: 'Titanium', label: 'Titanium' },
                { value: 'Tungsten', label: 'Tungsten' },
                { value: 'White Gold', label: 'White Gold' },
                { value: 'Yellow Gold', label: 'Yellow Gold' },
            ],
            checkboxes: null,
            textInput: false,
            width: "100px",
            height: width > 570 ? "63px" : "90px",

        },
        {
            category: "Water Resistance",
            dropDownOptions: [
                { value: 'Not Water Resistant', label: 'Not Water Resistant' },  
                { value: '1', label: '1 ATM' },
                { value: '2', label: '2 ATM' },
                { value: '2.5', label: '2.5 ATM' },
                { value: '3', label: '3 ATM' },
                { value: '4', label: '4 ATM' },
                { value: '5', label: '5 ATM' },
                { value: '6', label: '6 ATM' },
                { value: '7', label: '7 ATM' },
                { value: '8', label: '8 ATM' },
                { value: '9', label: '9 ATM' },
                { value: '10', label: '10 ATM' },
                { value: '15', label: '15 ATM' },
                { value: '20', label: '20 ATM' },
                { value: '30', label: '30 ATM' },
                { value: '40', label: '40 ATM' },
                { value: '50', label: '50 ATM' },
                { value: '60', label: '60 ATM' },
                { value: '70', label: '70 ATM' },
                { value: '80', label: '80 ATM' },
                { value: '90', label: '90 ATM' },
                { value: '100', label: '100 ATM' },
                { value: '110', label: '110 ATM' },
                { value: '120', label: '120 ATM' },
                { value: 'Over 120', label: 'Over 120 ATM' },
    
            ],
            dropdownHeight: "50px",
            checkboxes: null,
            textInput: false,
            width: "100px",
            height: width > 570 ? "63px" : "90px",
    
        },
        {
        category: "Thickness (mm)",
        dropDownOptions: null,
        checkboxes: null,
        textInput: true,
        width: "100px",
        height: width > 570 ? "63px" : "90px",

    },
    {
        category: "Crystal",
        dropDownOptions: [
            { value: 'Glass', label: 'Glass' },  
            { value: 'Mineral Glass', label: 'Mineral Glass' },
            { value: 'Plastic', label: 'Plastic' },
            { value: 'Plexiglass', label: 'Plexiglass' },
            { value: 'Sapphire Crystal', label: 'Sapphire Crystal' },
        ],
        checkboxes: null,
        textInput: false,
        width: "100px",
        height: width > 570 ? "63px" : "105px",

    },

    {
        category: "Other Details",
        dropDownOptions: null,
        checkboxes: [
            { value: 'Display Back', label: 'Display Back' },
            { value: 'Gemstones and/or Diamonds', label: 'Gemstones and/or Diamonds' },
            { value: 'PVD/DLC Coating', label: 'PVD/DLC Coating' },
        ],
        textInput: false,
        width: "100%",
        height: width > 570 ? "63px" : "125px",
    },


        
    ]

    let dialAndHandsCategories = [
        {
            category: "Dial",
            dropDownOptions: [
                { value: 'Black', label: 'Black' },  
                { value: 'Blue', label: 'Blue' },
                { value: 'Bordeaux', label: 'Bordeaux' },
                { value: 'Bronze', label: 'Bronze' },
                { value: 'Brown', label: 'Brown' },
                { value: 'Champagne', label: 'Champagne' },
                { value: 'Gold', label: 'Gold' },
                { value: 'Gold (solid)', label: 'Gold (solid)' },
                { value: 'Green', label: 'Green' },
                { value: 'Grey', label: 'Grey' },
                { value: 'Mother Of Pearl', label: 'Mother Of Pearl' },
                { value: 'Orange', label: 'Orange' },
                { value: 'Pink', label: 'Pink' },
                { value: 'Purple', label: 'Purple' },
                { value: 'Red', label: 'Red' },
                { value: 'Silver', label: 'Silver' },
                { value: 'Silver (solid)', label: 'Silver (solid)' },
                { value: 'Transparent', label: 'Transparent' },
                { value: 'White', label: 'White' },
                { value: 'Yellow', label: 'Yellow' },
            ],
            checkboxes: null,
            textInput: false,
            width: "100px",
            height: width > 570 ? "63px" : "90px",

        },
        {
            category: "Dial Numerals",
            dropDownOptions: [
                { value: 'Arabic Numerals', label: 'Arabic Numerals' },  
                { value: 'Roman Numerals', label: 'Roman Numerals' },
                { value: 'No Numerals', label: 'No Numerals' },
            ],
            checkboxes: null,
            textInput: false,
            width: "100px",
            height: width > 570 ? "63px" : "90px",

        },
    {
        category: "Dial Features",
        dropDownOptions: null,
        checkboxes: [
            { value: 'Guilloché Dial', label: 'Guilloché Dial' },
            { value: 'Guilloché Dial (handwork)', label: 'Guilloché Dial (handwork)' },
            { value: 'Luminous Numerals', label: 'Luminous Numerals' },
            { value: 'Luminous Indices', label: 'Luminous Indices' },

        ],
        textInput: false,
        width: "100px",
        marginTop: width > 570 ? "0px" : "0px",
        height: width > 690 ? "100px" : width > 570 ? "100px" : "180px",
    },
    {
        category: "Hand Details",
        dropDownOptions: null,
        checkboxes: [
            { value: 'Central Seconds', label: 'Central Seconds' },
            { value: 'Small Seconds', label: 'Small Seconds' },
            { value: 'Luminous Hands', label: 'Luminous Hands' },
            { value: 'Tempered Blue Hands', label: 'Tempered Blue Hands' },

        ],
        textInput: false,
        width: "100px",
        marginTop: width > 570 ? "0px" : "0px",
        height: width > 570 ? "100px" : "180px",
    },
    
    ]


    let braceletOrStrap = [
        {
            category: "Bracelet Material",
            dropDownOptions: [
                { value: 'Aliminium', label: 'Aliminium' },  
                { value: 'Calf Skin', label: 'Calf Skin' },
                { value: 'Ceramic', label: 'Ceramic' },
                { value: 'Crocodile Skin', label: 'Crocodile Skin' },
                { value: 'Gold/Steel', label: 'Gold/Steel' },  
                { value: 'Leather', label: 'Leather' },
                { value: 'Lizard Skin', label: 'Lizard Skin' },
                { value: 'Ostrich Skin', label: 'Ostrich Skin' },
                { value: 'Plastic', label: 'Plastic' },  
                { value: 'Platinum', label: 'Platinum' },
                { value: 'Red Gold', label: 'Red Gold' },
                { value: 'Rose Gold', label: 'Rose Gold' },
                { value: 'Rubber', label: 'Rubber' },  
                { value: 'Satin', label: 'Satin' },
                { value: 'Shark Skin', label: 'Shark Skin' },
                { value: 'Silicon', label: 'Silicon' },
                { value: 'Silver', label: 'Silver' },  
                { value: 'Snake Skin', label: 'Snake Skin' },
                { value: 'Steel', label: 'Steel' },
                { value: 'Textile', label: 'Textile' },
                { value: 'Titanium', label: 'Titanium' },
                { value: 'White Gold', label: 'White Gold' },
                { value: 'Yellow Gold', label: 'Yellow Gold' },
            ],
            checkboxes: null,
            textInput: false,
            width: "100px",
            height: width > 570 ? "63px" : "90px",

        },
        {
            category: "Bracelet Color",
            dropDownOptions: [
                { value: 'Beige', label: 'Beige' },  
                { value: 'Black', label: 'Black' },
                { value: 'Blue', label: 'Blue' },
                { value: 'Bordeaux', label: 'Bordeaux' },  
                { value: 'Bronze', label: 'Bronze' },
                { value: 'Brown', label: 'Brown' },
                { value: 'Gold', label: 'Gold' },  
                { value: 'Gold/Steel', label: 'Gold/Steel' },
                { value: 'Green', label: 'Green' },
                { value: 'Grey', label: 'Grey' },  
                { value: 'Orange', label: 'Orange' },
                { value: 'Pink', label: 'Pink' },
                { value: 'Purple', label: 'Purple' },  
                { value: 'Red', label: 'Red' },
                { value: 'Silver', label: 'Silver' },
                { value: 'Steel', label: 'Steel' },  
                { value: 'White', label: 'White' },
                { value: 'Yellow', label: 'Yellow' },
            ],
            
            checkboxes: null,
            textInput: false,
            width: "100px",
            height: width > 570 ? "63px" : "90px",

        },

    {
        category: "Clasp Material",
        dropDownOptions: null,
        dropDownOptions: [
            { value: 'Aliminium', label: 'Aliminium' },  
            { value: 'Bronze', label: 'Bronze' },
            { value: 'Gold/Steel', label: 'Gold/Steel' },  
            { value: 'Plastic', label: 'Plastic' },  
            { value: 'Platinum', label: 'Platinum' },
            { value: 'Red Gold', label: 'Red Gold' },
            { value: 'Rose Gold', label: 'Rose Gold' },
            { value: 'Silver', label: 'Silver' },  
            { value: 'Steel', label: 'Steel' },
            { value: 'Titanium', label: 'Titanium' },
            { value: 'White Gold', label: 'White Gold' },
            { value: 'Yellow Gold', label: 'Yellow Gold' },
        ],
        textInput: false,
        width: "100px",
        height: "83px",
    },
    {
        category: "Type Of Clasp",
        checkboxes: null,
        dropDownOptions: [
            { value: 'Buckle', label: 'Buckle' },
            { value: 'Double-fold Clasp', label: 'Double-fold Clasp' },
            { value: 'Fold Clasp', label: 'Fold Clasp'},
            { value: 'Fold Clasp, hidden', label: 'Fold Clasp, hidden' },
            { value: 'Jewelry Clasp', label: 'Jewelry Clasp' },

        ],
        textInput: false,
        width: "100px",
        height: width > 570 ? "63px" : "90px",
    },
    


        
    ]

    let functions = [
        {
            category: "Bracelet Material",
            checkboxes: [
                { value: 'Moon Phase', label: 'Moon Phase' },  
                { value: 'Minute Repeater', label: 'Minute Repeater' },  

                { value: 'Chronograph', label: 'Chronograph' },
                { value: 'Double Chronograph', label: 'Double Chronograph' },
                { value: 'Flyback', label: 'Flyback' },
                { value: 'Panorama Date', label: 'Panorama Date' },  
                { value: 'Chiming Clock', label: 'Chiming Clock' },
                { value: 'Repeater', label: 'Repeater' },
                { value: 'Tourbillon', label: 'Tourbillon' },
                { value: 'Date', label: 'Date' },  
                { value: 'Weekday', label: 'Weekday' },
                { value: 'Month', label: 'Month' },
                { value: 'Year', label: 'Year' },
                { value: 'Annual Calendar', label: 'Annual Calendar' },  
                { value: '4-year Calendar', label: '4-year Calendar' },
                { value: 'Perpetual Calendar', label: 'Perpetual Calendar' },
                { value: 'Alarm', label: 'Alarm' },
                { value: 'GMT', label: 'GMT' },  
                { value: 'Equation Of Time', label: 'Equation Of Time' },
                { value: 'Jumping Hour', label: 'Jumping Hour' },
                { value: 'Tachymeter', label: 'Tachymeter' },

            ],
            dropDownOptions: null,
            textInput: false,
            width: "100px",
            height: width > 570 ? "63px" : "90px",

        },
    ]

    let watchModelCategories = obligatoryWatchDetailCategories.slice(0, 6);
    let individualWatchCategories = obligatoryWatchDetailCategories.slice(6, 13);
    let priceCategories = obligatoryWatchDetailCategories.slice(13);

    /*
    `typeofwatch
    brand
    model 
    reference number 
    

    condition 
    scope of delivery 
    serial number 
    year of production 
    case diameter
    movement
    gender

    description`
    */

    return (
        <div className='sellYourWatchPageParentDiv2'>
            <div className='sellYourWatchInputParentComponent2'>
                <SimpleDynamicTextBox text={"Watch Details"} fontSize={"2rem"} fontWeight={500} color={"#003251"}/>
                <SimpleDynamicTextBox text={"Watch Model Details"} fontSize={"1.5rem"} fontWeight={450} color={"#003251"} marginTop={"30px"}/>
                    <SellWatchInputComponent categoryList={watchModelCategories} updateParentFormData={handleChange} 
                    formData={formData}

                    height={width > 570 ? "660px" : "800px"} marginTop={"10px"} paddingTop={"0px"}/>

                <SimpleDynamicTextBox text={"Individual Watch Details"} fontSize={"1.5rem"} fontWeight={450} color={"#003251"} marginTop={"60px"}/>
                    <SellWatchInputComponent categoryList={individualWatchCategories} 
                    updateParentFormData={handleChange} 
                    height={width > 570 ? "860px" : "1200px"} marginTop={"15px"} paddingTop={"20px"}/>

            <SimpleDynamicTextBox text={"Price"} fontSize={"1.5rem"} fontWeight={450} color={"#003251"} marginTop={width > 570 ? "60px" : "30px"}/>
            <SellWatchInputComponent categoryList={priceCategories} 
                    updateParentFormData={handleChange} currency={formData["Currency"]} currencyValue={formData["Price"]}
                    height={width > 570 ? "220px" : "270px"} marginTop={"15px"} paddingTop={"20px"}/>
            <div style={{display: 'flex', marginTop: "60px", alignItems: 'center'}}>
            <SimpleDynamicTextBox text={"Additional Information"} fontSize={"1.5rem"} fontWeight={450} color={"#003251"} marginTop={"6px"}/>
            <SimpleDynamicTextBox text={"(Optional)"} marginLeft={"10px"} fontSize={"1rem"} fontWeight={450} color={"#003251"} marginTop={"6px"}/>
            </div>

            <CustomDropdown header={"Caliber"} width={width > 1160 || (width < 1033 && width > 828) ? "710px" : width > 690 ? "590px" : width > 570 ? "530px" : "315px"} height={width > 570 ? "460px" : "600px"} marginLeft={"0px"} arrowMarginLeft={width > 1160 || (width < 1033 && width > 828) ? "356px" : width > 690 ? "236px" : width > 570 ? "176px" : "-32px"} marginTop={"15px"}>
                <DropDownWatchDetailsContent categoryList={caliberCategories} height={width > 570 ? "440px" : "500px"} parentFormData={formData} updateParentFormData={handleChange} />
            </CustomDropdown>
            <CustomDropdown header={"Case"} width={width > 1160 || (width < 1033 && width > 828) ? "710px" : width > 690 ? "590px" : width > 570 ? "530px" : "315px"} height={width > 570 ? "550px" : "610px"} marginLeft={"0px"} arrowMarginLeft={width > 1160 || (width < 1033 && width > 828) ? "356px" : width > 690 ? "236px" : width > 570 ? "176px" : "-32px"} marginTop={"10px"}>
                <DropDownWatchDetailsContent categoryList={caseCategories} height={"440px"} parentFormData={formData} updateParentFormData={handleChange} />
            </CustomDropdown>
            <CustomDropdown header={"Dial And Hands"} width={width > 1160 || (width < 1033 && width > 828) ? "710px" : width > 690 ? "590px" : width > 570 ? "530px" : "315px"} height={width > 570 ? "500px" : "570px"} marginLeft={"0px"} arrowMarginLeft={width > 1160 || (width < 1033 && width > 828) ? "356px" : width > 690 ? "236px" : width > 570 ? "176px" : "-32px"} marginTop={"10px"}>
                <DropDownWatchDetailsContent categoryList={dialAndHandsCategories} height={width > 690 ? "320px" : width > 570 ? "360px" : "500px"} parentFormData={formData} updateParentFormData={handleChange} />
            </CustomDropdown>
            <CustomDropdown header={"Bracelet/Strap"} width={width > 1160 || (width < 1033 && width > 828) ? "710px" : width > 690 ? "590px" : width > 570 ? "530px" : "315px"} height={"520px"} marginLeft={"0px"} arrowMarginLeft={width > 1160 || (width < 1033 && width > 828) ? "356px" : width > 690 ? "236px" : width > 570 ? "176px" : "-32px"} marginTop={"10px"}>
                <DropDownWatchDetailsContent categoryList={braceletOrStrap} height={"300px"} parentFormData={formData} updateParentFormData={handleChange} />
            </CustomDropdown>
            <CustomDropdown header={"Functions"} width={width > 1160 || (width < 1033 && width > 828) ? "710px" : width > 690 ? "590px" : width > 570 ? "530px" : "315px"} height={width > 570 ? "400px" : "745px"} marginLeft={"0px"} arrowMarginLeft={width > 1160 || (width < 1033 && width > 828) ? "356px" : width > 690 ? "236px" : width > 570 ? "176px" : "-32px"} marginTop={"10px"}>
                <WatchFunctionsCheckBoxes checkboxList={functions[0].checkboxes} checkboxStatuses={functionsCheckboxData} updateParentData={handleCheckboxDataChange}/>
            </CustomDropdown>


            <div style={{width: "100%", height: "1px", backgroundColor: "black", color: "black", marginTop: "20px"}}>
            </div>
            <div style={{display: 'flex',width: "100%", height: "80px", justifyContent: 'flex-start', marginTop: "35px"}}>
            <Link to="/sell/watchpictures">
            <BasicButton text={"Accept Details And Continue"} width={width > 570 ? "400px" : "315px"} height={"50px"} backgroundColor={"white"} 
            hoveredBackgroundColor={"black"} 
            color={"black"} hoveredColor={"white"}
            border={"3px solid black"} borderRadius={"3px"}
            fontSize={"1rem"} fontWeight={"600"}
            />
            </Link>

            </div>

            </div>
            {/*
            <div className='sellYourWatchStickyPreviewParentComponent2' style={sellYourWatchPreviewParentComponentStyles}>
            <div className='sellYourWatchPreviewParentComponent2'>
                <SimpleDynamicTextBox text={"Listing Preview"} fontSize={"2rem"} fontWeight={500} color={"#003251"} marginLeft={"10px"}/>
                <SimpleDynamicTextBox text={"This is What Your Listing Will Look Like: "} fontSize={"1rem"} fontWeight={500} color={"#003251"} marginLeft={"10px"} marginTop={"20px"}/>

                <div className='centerContainer'>
                <div className='listingPreviewParentDiv2'>
                <ListingPreviewComponent title={formData["Brand"] ? `${formData["Brand"].label} ${formData["Model"] || ''}` : ""} shortDescription={formData["Short Description"] ? formData["Short Description"] : ""} price={formData["Price"]} currency={formData["Currency"]} watchListingInfo={previewListingInfo} images={previewListingInfo.images} to={"/testpage"}/>                        

                </div>
                </div>
            </div>
            </div>
    */}
        </div>
    );
    }


export default WatchDetailsPage;