import React, { useState } from 'react';
import './AddImagesComponentStyles.css';
import SimpleDynamicTextBox from './SimpleDynamicTextBox';
import { MdOutlineAddAPhoto, MdAddAPhoto } from 'react-icons/md';

const AddImagesComponent = ( { onAddImage, marginTop, text1, text1Margin, text2, width  } ) => {

  // Handle file selection through file dialog
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
        onAddImage(reader.result); // Pass the image data to the parent
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Handle drag and drop
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
        onAddImage(reader.result); // Pass the image data to the parent
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      className="addImageThumbNailDiv"
      style={{marginTop: marginTop}}
      onDrop={handleDrop}
      onDragOver={(event) => event.preventDefault()}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        className="addImageFileInput"
      />
      
        <>
        <MdOutlineAddAPhoto color='white' size={35}/>
        <SimpleDynamicTextBox text={text1} 
        fontSize={width > 1240 ? "1rem" : "0.9rem"} 
        fontWeight={400} color={"white"} marginLeft={"0px"}
        marginTop={text1Margin}
        />
        {width > 1240 &&
                <SimpleDynamicTextBox text={text2} 
        fontSize={"1rem"} 
        fontWeight={400} color={"white"} marginLeft={"0px"}
        marginTop={"10px"}
        />  
        }      
        </>
    </div>
  );
};

export default AddImagesComponent;
