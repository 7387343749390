import React, { useEffect, useState } from 'react';
import './BuyWatchPageStyles.css';
import '../components/commonStyles.css';
import BuyWatchActualForm from '../components/BuyWatchPageComponents/BuyWatchActualForm';
import BuyWatchSummary from '../components/BuyWatchPageComponents/BuyWatchSummary';
import BuyWatchPreview from '../components/BuyWatchPageComponents/BuyWatchPreview';
function BuyWatchPage({  }) {

    const [sameshippingAddress, setSameshippingAddress] = useState(true);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className='buyWatchPageParent'>
            <div className='buyWatchPageFormSide'>
                <div className='buyWatchFormParent'>
                <div className='buyWatchFormStandardDiv'>
                    <p 
                    className='buypageHeader'
                    style={{

                    }}
                    >
                        Your Order
                    </p>
                </div>
                <form className='buyWatchFormActualForm'>
                    
                    <BuyWatchActualForm type={"billing"}/>

                    <BuyWatchActualForm type={"shipping"} sameshippingAddress={sameshippingAddress} setSameshippingAddress={setSameshippingAddress}/>

                </form>

                <BuyWatchSummary
                width={windowWidth}
                />

            </div>

            </div>
            {windowWidth > 1080 && 
            <div className='buyWatchPageInfoSide'
            style={{
                height: sameshippingAddress ? "1930px" : "2560px",
            }}
            >
                <div className='stickyDiv'>
                <BuyWatchPreview/>
                </div>
            </div>
            }
        </div>
    );
}

export default BuyWatchPage;