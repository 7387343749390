import { Link } from 'react-router-dom';
import '../components/IconLinkStyles.css'; // Import the CSS file


const IconLink = ({ className, to, icon, selectedIcon, text, marginTop, marginBottom, leftPadding,
                    height, width, onClick, currentPathName}) => {
    const divStyle = {
        marginBottom: marginBottom,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: leftPadding,
    };





    return (
        <Link to={to} className={className}
        onClick={onClick}
        style={{
            height: height,
            width: width,
            marginTop: marginTop,

        }}
        >

        <div style={divStyle} className="icon-link">
            <span className="icon">{icon}</span>
            <span className="selected-icon">{selectedIcon}</span>
            <span className="text">{text}</span>
        </div>
        </Link>
    );
};

export default IconLink;