import React, { useEffect, useState } from 'react';
import './SellerProfileReviewWatchBoughtStyles.css';
import { commonFont, alternateColorScheme } from '../commonStyles';
import { FiTruck, FiWatch } from 'react-icons/fi';
import { TbTruckDelivery } from 'react-icons/tb';



const SellerProfileReviewWatchBought = ( {
    borderTopRight, boughtWatchImage, boughtWatchName, boughtWatchKeyStats,
    shipmentInfo} ) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    function isWatchNameLongerThan51(str) {
        return str.length > 51;
      }

    useEffect(() => {
        // Update the state when the window is resized
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        
        // Attach resize listener
        window.addEventListener('resize', handleResize);

        // Clean up: remove resize listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
        
    

    const watchPillValues = {
        "Year Of Production": { value: "Year Of Production: 2018", width: "220px" },
        "Box": { value: "Original Box", width: "120px" },
        "Condition": { value: "Pre-owned (Very Good)", width: "210px" },
        "Papers": { value: "Original Papers", width: "140px" },
    };


    const shipmentDetails1 = <> <i><b>Sent on</b> {shipmentInfo.dateSent} <b>Delivered on</b> {shipmentInfo.dateDelivered}.<br /></i> </>;
    const shipmentDetails2 = <> <i><b>Shipped from:</b> {shipmentInfo.shippedFrom}, <b>Shipped to:</b> {shipmentInfo.shippedTo}.</i> </>;


    return (
        <div className='singleTextReviewBoughtWatchDiv'
        style={{
            borderTopRightRadius: borderTopRight,
        }}
        >
            {screenWidth > 1100 ? (
            <>
            {screenWidth > 1400 && (
                <img className='reviewBoughtWatchImage' src={boughtWatchImage}
                    style={{borderRadius: "10px"}}
                ></img>
            )}
            <div className='singleTextReviewBoughtWatchModelAndStatsDiv'>
                <div className='boughtWatchDivImageAndHeaderAndKeyStatsDiv'>

                {screenWidth < 1400 && (
                <img className='reviewBoughtWatchImage' src={boughtWatchImage}
                    style={{borderRadius: "10px"}}
                ></img>
                )}

                <div className='boughtWatchDivHeaderAndKeyStatsDiv'>

                <p
                    style={{
                        color: "black",
                        fontWeight: 500,
                        fontFamily: commonFont.fontFamily,
                        fontSize: (screenWidth > 1200) ?  "1rem" : "0.9rem",
                        marginTop: "0px",
                        marginLeft: "5px",
                        marginBottom: "0px"
                    }}
                >
                    {boughtWatchName}
                    
                </p>
                    <div className='singleTextReviewBoughtWatchKeyStatsDiv'>
                        <div className="group1PillsDiv">
                            {Object.keys(boughtWatchKeyStats).slice(0, 2).map((key, index) => {
                                const { value, width } = boughtWatchKeyStats[key];

                                return (
                                    <div 
                                        className='singleTextReviewWatchStatPill' 
                                        style={{
                                            width: width,
                                            marginLeft: index !== 0 ? "15px" : "0px"
                                        }}
                                        key={index}
                                    >
                                        <p
                                            style={{
                                                color: "black",
                                                fontWeight: 400,
                                                fontFamily: commonFont.fontFamily,
                                                fontSize: "1rem",
                                                marginTop: "0px",
                                                marginLeft: "0px",
                                                marginBottom: "0px"
                                            }}
                                        >
                                            {value}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="group2PillsDiv">
                            {Object.keys(boughtWatchKeyStats).slice(2, 4).map((key, index) => {
                                const { value, width } = boughtWatchKeyStats[key];

                                return (
                                    <div 
                                        className='singleTextReviewWatchStatPill' 
                                        style={{
                                            width: width,
                                            marginLeft: index !== 0 ? "15px" : "0px"
                                        }}
                                        key={index}
                                    >
                                        <p
                                            style={{
                                                color: "black",
                                                fontWeight: 400,
                                                fontFamily: commonFont.fontFamily,
                                                fontSize: "1rem",
                                                marginTop: "0px",
                                                marginLeft: "0px",
                                                marginBottom: "0px"
                                            }}
                                        >
                                            {value}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                </div>
                {screenWidth > 1200 ? (
    <div 
        style={{
            width: "80%",
            marginTop: (screenWidth < 1400 && isWatchNameLongerThan51(boughtWatchName)) ? "20px" : "5px",

        }}
    >
        <p
            className='boughtWatchParagraph-break-line '
            style={{
                color: "black",
                fontWeight: 400,
                fontFamily: commonFont.fontFamily,
                fontSize: "1rem",
                marginTop: "0px",
                marginLeft: "5px",
                marginBottom: "0px"
            }}
        >
            {shipmentDetails1}
        </p>
        <p
            className='boughtWatchParagraph-break-line'
            style={{
                color: "black",
                fontWeight: 400,
                fontFamily: commonFont.fontFamily,
                fontSize: "1rem",
                marginTop: "0px",
                marginLeft: "5px",
                marginBottom: "0px"
            }}
        >
            {shipmentDetails2}
        </p>
    </div>
) : (
        <div className='boughtWatchHoverDiv'
        style={{marginTop: isWatchNameLongerThan51(boughtWatchName) ? "20px" : "10px"}}
        >
        <p
            style={{
                color: "black",
                fontWeight: 500,
                fontFamily: commonFont.fontFamily,
                fontSize: "1rem",
                marginTop: "0px",
                marginLeft: "0px",
                marginBottom: "0px"
            }}
        >
            Shipment Details
        </p>
        <div className="boughtWatchPopupDiv">
        <p
            style={{
                color: "black",
                fontWeight: 400,
                fontFamily: commonFont.fontFamily,
                fontSize: "1rem",
                marginTop: "0px",
                marginLeft: "0px",
                marginBottom: "0px"
            }}
        >
            {shipmentDetails1} {shipmentDetails2}
        </p>
        </div>
            </div>
)}
            </div>
            </>
            ): (
                <>
                {screenWidth > 650 &&  

                <img className='reviewBoughtWatchImage' src={boughtWatchImage}
                    style={{borderRadius: "10px"}}
                ></img>
                }
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: "space-evenly"}}>
                <div className='boughtWatchDetailsHoverDiv'>
                {screenWidth < 780 ? 
                    <FiWatch size={24} /> : 
                    <p
                        style={{
                            maxWidth: "100%",
                            color: "black",
                            fontWeight: 500,
                            fontFamily: commonFont.fontFamily,
                            fontSize: "0.9rem",
                            margin: "0px",
                        }}
                    >
                        Watch Details
                    </p>
                    }
                    <div className='boughtWatchPopupDivAllInfo'>
                        <img className='reviewBoughtWatchImage' src={boughtWatchImage}
                        style={{borderRadius: "10px"}}
                        ></img>
                        <div style={{display: 'flex', flexDirection: 'column', height: "100%",
                            marginLeft: "5px"
                            }}>
                            {Object.keys(watchPillValues).map((key, index) => {
                                const { value, width } = watchPillValues[key];
                            return (
                                <>
                                <div className='watchDetailsPopUpDivKeyStat'>
                                <p
                                    style={{
                                        maxWidth: "100%",
                                        color: "black",
                                        fontWeight: 400,
                                        fontFamily: commonFont.fontFamily,
                                        fontSize: "0.8rem",
                                        margin: "0px",
                                    }}
                                >
                                    {value}
                                </p>
                                </div>

                                </>
                            );
                        })}
                        </div>
                    </div>


                    
                </div>

                <div className='boughtWatchDetailsHoverDiv'>
                    {screenWidth < 780 ? 
                    <TbTruckDelivery size={24}/> : 
                    
                    <p
                        style={{
                            maxWidth: "100%",
                            color: "black",
                            fontWeight: 500,
                            fontFamily: commonFont.fontFamily,
                            fontSize: "0.9rem",
                            margin: "0px",
                        }}
                    >
                        Shipment Details
                    </p>
                    }
                    <div className='boughtWatchPopupDivAllInfo'
                    style={{top: "0%", height: "110px", paddingLeft: "10px", boxSizing: 'border-box'}}
                    >
                    <p
                        style={{
                            maxWidth: "100%",
                            color: "black",
                            fontWeight: 400,
                            fontFamily: commonFont.fontFamily,
                            fontSize: "0.9rem",
                            margin: "0px",
                            lineHeight: "1.6",

                        }}
                    >
                        {shipmentDetails1} {shipmentDetails2}
                    </p>

                    </div>

                </div>
                </div>

                </>
            )

            }
        </div>
    );
}

export default SellerProfileReviewWatchBought;
