import React, { useState } from 'react';
import './CustomSliderArrowStyles.css';
import { commonFont, alternateColorScheme } from '../commonStyles';
import { HiOutlineArrowSmallLeft, HiOutlineArrowSmallRight } from 'react-icons/hi2';



const CustomSliderArrow = ({ onClick, direction, showHoverArrows, size, top, sidePosition }) => {

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  return (
    <div
      className='customPrevArrowStyles'
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        right: direction == "right" ? sidePosition : null,
        left: direction == "left" ? sidePosition : null,
        opacity: showHoverArrows == true ? 1.0 : 0,
        width: size,
        height: size,
        top: top,
      }}
    >
      {direction == "right" ? <HiOutlineArrowSmallRight size={24}
      color={isHovered ? alternateColorScheme.purple2 : "black"}
      /> 
      : <HiOutlineArrowSmallLeft size={24}  color={isHovered ? alternateColorScheme.purple2 : "black"}/>}
    </div>
  );
};

export default CustomSliderArrow;
