import React, { useState, useEffect, useRef } from 'react';
import './ConversationStyles.css';
import ConversationsChatBubble from './ConversationsChatBubble';
import ImageBubble from './ImageBubble';
import ConversationInputArea from './ConversationInputArea';
import UserConversationThumbnail from '../UserConversationThumbnail';
import ConversationSearch from './ConversationSearch';
import ConversationSearchAndThumbnailsParent from './ConversationSearchAndThumbnailsParent';

function getInitials(name) {
    const nameArray = name.split(' ');
    let initials = '';
  
    for (let i = 0; i < nameArray.length; i++) {
      if (nameArray[i].length > 0) {
        initials += nameArray[i][0].toUpperCase();
      }
    }
  
    return initials;
  }

function Conversation({ }) {
    const conversationRef = useRef(null);

    const [convoSearch, setConvoSearch] = useState('');
    const [filteredConversations, setFilteredConversations] = useState({});

    const handleSearchChange = (e) => {
      setConvoSearch(e.target.value)
    }




    const [activeConversation, setActiveConversation] = useState('conv1');  // defaults to 'conv1'

    const [conversations, setConversations] = useState({
        'conv1': {
           user1: "Filip Larsson",
           user2: "Stella Larsson",
           messages: [
          { user: "Filip Larsson", message: "Hello", images: null, leftBubble: true },
          { user: "Stella Larsson", message: "How are you?", images: null, leftBubble: false },
          { user: "Filip Larsson", message: "I'm good, thanks!", images: null, leftBubble: true },
          { user: "Stella Larsson", message: "How about you?", images: null, leftBubble: false }
        ]
        },
        'conv2': {
        user1: "Jacob Schweizer",
        user2: "Alexandre Moch",
        messages: [
          { user: "Jacob Schweizer", message: "Hi there npccc", images: null, leftBubble: true },
          { user: "Alexandre Moch", message: "The Rest är vaken?!", images: null, leftBubble: false },
          { user: "Jacob Schweizer", message: "Ja har sovit 56h i sträck", images: null, leftBubble: true },
          { user: "Alexandre Moch", message: "Same old, same old.", images: null, leftBubble: false }
        ],
        },
        'conv3': {
            user1: "Axel Johansson",
            user2: "Samuel Larsson",
            messages: [
          { user: "Axel Johansson", message: "Hey!", images: null, leftBubble: true },
          { user: "Samuel Larsson", message: "Hey, long time!", images: null, leftBubble: false },
          { user: "Axel Johansson", message: "Yeah, how have you been?", images: null, leftBubble: true },
          { user: "Samuel Larsson", message: "I've been good, busy with work.", images: null, leftBubble: false }
        ],
        },
        'conv4': {
            user1: "Emily Clark",
            user2: "Mark Johnson",
            messages: [
              { user: "Emily Clark", message: "Hey, are you coming to the party?", images: null, leftBubble: true },
              { user: "Mark Johnson", message: "Of course!", images: null, leftBubble: false },
              { user: "Emily Clark", message: "Awesome!", images: null, leftBubble: true }
            ]
          },
          'conv5': {
            user1: "Samantha Smith",
            user2: "Daniel Lee",
            messages: [
              { user: "Samantha Smith", message: "Have you finished the project?", images: null, leftBubble: true },
              { user: "Daniel Lee", message: "I'm almost done.", images: null, leftBubble: false }
            ]
          },
          'conv6': {
            user1: "Paul Anderson",
            user2: "Nina Williams",
            messages: [
              { user: "Paul Anderson", message: "Are you watching the game tonight?", images: null, leftBubble: true },
              { user: "Nina Williams", message: "I can't, got a dinner.", images: null, leftBubble: false }
            ]
          },
          'conv7': {
            user1: "Oliver Brown",
            user2: "Sophia Davis",
            messages: [
              { user: "Oliver Brown", message: "How's the new job?", images: null, leftBubble: true },
              { user: "Sophia Davis", message: "It's fantastic!", images: null, leftBubble: false }
            ]
          },
          'conv8': {
            user1: "Ella Adams",
            user2: "Lucas Wilson",
            messages: [
              { user: "Ella Adams", message: "Have you seen the new movie?", images: null, leftBubble: true },
              { user: "Lucas Wilson", message: "Not yet.", images: null, leftBubble: false }
            ]
          },
          'conv9': {
            user1: "Michael Thomas",
            user2: "Sarah Taylor",
            messages: [
              { user: "Michael Thomas", message: "How are the wedding plans?", images: null, leftBubble: true },
              { user: "Sarah Taylor", message: "Everything is on track.", images: null, leftBubble: false }
            ]
          },
          'conv10': {
            user1: "Grace Jackson",
            user2: "Matthew Lewis",
            messages: [
              { user: "Grace Jackson", message: "How's the new puppy?", images: null, leftBubble: true },
              { user: "Matthew Lewis", message: "He's adorable!", images: null, leftBubble: false }
            ]
          }
      }
      );

      useEffect(() => {
        const filtered = Object.keys(conversations).reduce((acc, convKey) => {
            const user2 = conversations[convKey].user2;
            if (user2.toLowerCase().includes(convoSearch.toLowerCase())) {
                acc[convKey] = conversations[convKey];
            }
            return acc;
        }, {});
    
        setFilteredConversations(filtered);
    }, [convoSearch, conversations]);
      

      const handleNewMessage = (newMessage) => {
        setConversations(prevConversations => {
            const prevMessages = prevConversations[activeConversation].messages;
            const updatedMessages = [...prevMessages, newMessage];
            return { 
                ...prevConversations, 
                [activeConversation]: { 
                    ...prevConversations[activeConversation], 
                    messages: updatedMessages 
                }
            };
        });
      };
    


      useEffect(() => {
        const container = conversationRef.current;
        
        // Function to scroll to the bottom
        const scrollToBottom = () => {
          container.scrollTop = container.scrollHeight;
        };
      
        // Scroll to the bottom when conversations change
        scrollToBottom();
      
      }, [conversations]);

    // Denna useEffect har ingen som helst påverkan får inte touchend eller dragend att fungera
    useEffect(() => {
        const container = conversationRef.current;
        let isScrolling = false;
    
        console.log("Effect invoked.");
    
        const handleScroll = () => {
            console.log("Scroll event triggered.");
            const atTop = container.scrollTop === 0;
            const atBottom = container.offsetHeight + container.scrollTop >= container.scrollHeight;
            
            if (atTop || atBottom) {
                console.log("Scrolling reached either top or bottom.");
                isScrolling = true;
            }
        };
    
        const applyBounce = () => {
            console.log("Checking if should apply bounce.");
            if (isScrolling) {
                console.log("Applying bounce.");
                container.classList.add('bounce-effect');
                const atTop = container.scrollTop === 0;
                container.style.transform = `translateY(${atTop ? '40px' : '-40px'})`;
    
                setTimeout(() => {
                    console.log("Resetting bounce effect.");
                    container.style.transform = 'translateY(0)';
                    container.classList.remove('bounce-effect');
                }, 300);
                isScrolling = false;
            }
        };
    
        const handleEndOfInteraction = () => {
            console.log("End of interaction (mouseup/touchend).");
            applyBounce();
        };
    
        console.log("Adding event listeners.");
        container.addEventListener('scroll', handleScroll);
        container.addEventListener('mouseup', handleEndOfInteraction);
        container.addEventListener('touchend', handleEndOfInteraction);
    
        return () => {
            console.log("Removing event listeners.");
            container.removeEventListener('scroll', handleScroll);
            container.removeEventListener('mouseup', handleEndOfInteraction);
            container.removeEventListener('touchend', handleEndOfInteraction);
        };
    }, []);
    


    return (
        <div className='thumbnailsAndConversationAndInputAreaParent'>
          <div className='convoActionsPanel'>
            <ConversationSearchAndThumbnailsParent 
            conversations={filteredConversations} 
            changeConversation={(convKey) => setActiveConversation(convKey)}
            activeConversation={activeConversation}
            searchValue={convoSearch}
            handleSearchValueChange={handleSearchChange}
            />
        </div>
        <div className='conversationAndInputAreaParent'>
          <div className='conversationParent' ref={conversationRef}>
          {conversations[activeConversation].messages.map((message, index) => (
                <>
                    {message.message && (
                    <ConversationsChatBubble
                        text={message.message}
                        initials={message.initials}
                        key={`${index}-text`}
                        leftBubble={message.leftBubble}
                    />
                    )}
                    {message.images && (
                    message.images.map((image, imageIndex) => (
                        <ImageBubble
                        image={image}
                        initials={message.initials}
                        key={`${index}-image-${imageIndex}`}
                        leftBubble={message.leftBubble}
                        />
                    ))
                    )}
                </>
                ))}
          </div>
          <ConversationInputArea handleNewMessage={handleNewMessage} />
        </div>
        </div>
      );
}

export default Conversation;