import React, { useState } from 'react';
import './InformationWithLockDivStyles.css';
import { commonFont, alternateColorScheme } from '../commonStyles';
import PersonalInformationField from './PersonalInformationField';
import FilterDropDown from './FilterDropDown';
import BirthInputComponent from './DateInputComponent';
import PersonalInformationUnlockButton from './PersonalInformationUnlockButton';
import { AiOutlineLock } from 'react-icons/ai';
import PersonalInformationContentComponent from './PersonalInformationContentComponent';


const InformationWithLockDiv = ( { header, content, backgroundcolor, width, height, type, windowWidth, headerAndUnlockButtonWidth } ) => {

    const [isUnlockButtonActive, setIsUnlockButtonActive] = useState(false);

    const [isParentHovered, setIsParentHovered] = useState(false);

    const toggleUnlockActive = () => {
        setIsUnlockButtonActive(!isUnlockButtonActive);
    };





      

    return (
        <div className="personalInformationComponentParentDiv"
        style={{
            width: width,
            height: height,
            backgroundColor: backgroundcolor,
        }}
        onMouseEnter={() => setIsParentHovered(true)}
        onMouseLeave={() => setIsParentHovered(false)}
        >
            { !isUnlockButtonActive &&
            <div className='personalInformationComponentLockCoverDiv'>
                <AiOutlineLock size={150} color={alternateColorScheme.silver}/>
            </div>
            }
            <div className='personalInformationHeaderAndUnlockButtonDiv'
            style={{
                width: headerAndUnlockButtonWidth,
            }}
            >
            <p 
            className='personalInformationHeaderDiv'
            style={{
                fontFamily: commonFont.fontFamily,
                }}>
                {header}
            </p> 
            <PersonalInformationUnlockButton
            onClick={toggleUnlockActive}
            isActive={isUnlockButtonActive}
            isParentHovered={isParentHovered}
            />
            </div>
            {content}
    </div>


    );



}


export default InformationWithLockDiv;