import React from 'react';
import './SellerProfileReviewUserComponentStyles.css';
import userProfileImage1 from '../assets/userImages/filipProfilePicture.png';
import { commonFont, alternateColorScheme } from '../commonStyles';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

const SellerProfileReviewUserComponent = ( {
    borderTopLeft, userName, userScore, publishedReview} ) => {

    return (
        <div className='SellerProfileReviewUserComponentParent'
        style={{borderTopLeftRadius: borderTopLeft}}
        >
        <div className='singleTextReviewProfilePicture'>
            <img src={userProfileImage1} width={"100%"} 
            height={"100%"} style={{borderRadius: "45px"}}
            >
            </img>
        </div>
        <div className='singleTextReviewProfileText'>
        <div style={{
                display: "flex",
                marginTop: "10px"
            }}>
                <AiFillStar size={23} color={alternateColorScheme.gold3}/>
                <p
                style={{

                    color: "#003251",
                    fontWeight: 500,
                    fontFamily: commonFont.fontFamily,
                    fontSize: "1.2rem",
                    marginTop: "0px",
                    marginLeft: "4px",
                    marginBottom: "0px"

                }}
                >
                    {userScore}
                </p>

            </div>
            <p
            className='sellerUserReviewText'

            style={{
                color: "#003251",
                fontWeight: 400,
                fontFamily: commonFont.fontFamily,
                marginTop: "3px",
                marginBottom: "0px"

            }}
            >
                {userName}
            </p>

            <p
            className='sellerUserReviewText2'


            style={{
                color: "#003251",
                fontWeight: 400,
                fontFamily: commonFont.fontFamily,
                opacity: 0.8,
                marginTop: "3px",
                marginBottom: "0px"

            }}
            >
                {publishedReview}
            </p>

        </div>

        

    </div>


    );



}


export default SellerProfileReviewUserComponent;