import React, { useState, useRef } from 'react';
import './TinyConversationsSliderStyles.css';
import TinyConversationOverview from './TinyConversationOverview';
import Slider from "react-slick";
import CustomSliderArrow from './CustomSliderArrow';

function TinyConversationsSlider({ }) {

    const [showHoverArrows, setShowHoverArrows] = useState(false);

    const dateSent = "2023-08-07";

    const lastMessage = "Could you please tell me more about the condition of the item? Could you please tell me more about the condition of the item?";
    const lastMessage2 = "The item wasn't as described; how do we go about proces do we go about proces do we go about proces do we go about proces do we go about processing a refund?";


    const dateSent2 = "2023-09-02";
    const conversations = [

        {lastMessage: "Could you please tell me more about the condition of the item? Could you please tell me more about the condition of the item?",
        dateSent: "2023-08-07",
        name: "Martin Scorseze",
        initials: "MS"
        },
        {lastMessage: "The item wasn't as described; how do we go about proces do we go about proces do we go about proces do we go about proces do we go about processing a refund?",
        dateSent: "2023-09-02",
        name: "Donald Norman",
        initials: "DN"


        },
        {lastMessage: "Could you send me more photos, especially of [specific part/angle]?",
        dateSent: "2023-09-12",
        name: "Saul Goodman",
        initials: "SG",


        },

        {lastMessage: "Could you please tell me more about the condition of the item?",
        dateSent: "2023-05-14",
        name: "Mike O'Neil",
        initials: "MO"


        },
        {lastMessage: "I've received the item and I'm satisfied with the purchase. I'll leave a positive review.",
        dateSent: "2023-07-07",
        name: "Margot Robbie",
        initials: "MR"


        },
        {lastMessage: "I've noticed an issue with the item I received. How can we resolve this?",
        dateSent: "2023-09-29",
        name: "Elsa Robson",
        initials: "ER"

        },
    ]

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,  // Change this to the number of slides you want to show
        slidesToScroll: 1,
        arrows: false,
    };

    const sliderRef = useRef();


    const handleArrowClick = (direction) => {
      if (direction === 'left') {
          sliderRef.current.slickPrev(); // this changes the slide to the previous one
      } else if (direction === 'right') {
          sliderRef.current.slickNext(); // this changes the slide to the next one
      }
  };

    return (
        <div className='tinyConversationsSliderParent'
        onMouseEnter={() => setShowHoverArrows(true)} 
        onMouseLeave={() => setShowHoverArrows(false)}
        >
            <p
            style={{
                fontSize: "1rem",
                fontWeight: 500,
                color: "white",
                marginTop: "8px",
                marginBottom: "8px"


            }}
            >
                Conversations
            </p>
            <CustomSliderArrow direction={"left"} onClick={() => handleArrowClick('left')}
                    showHoverArrows={showHoverArrows} size={"35px"} top={"45%"} sidePosition={"5px"} />
            <Slider {...settings}                     ref={sliderRef}
>
                {
                    // Group conversations into sets of 2
                    Array.from({ length: Math.ceil(conversations.length / 2) }, (_, index) => (
                        <div key={index} className='tinyConversationsSlide'>
                            {conversations.slice(index * 2, index * 2 + 2).map((conversation, i) => (
                                <TinyConversationOverview 
                                    key={i}
                                    lastMessage={conversation.lastMessage}
                                    dateSent={conversation.dateSent}
                                    name={conversation.name}
                                    initials={conversation.initials}
                                    marginTop={i === 0 ? "0px" : "10px"}
                                />
                            ))}
                        </div>
                    ))
                }
            </Slider>
            <CustomSliderArrow direction={"right"} onClick={() => handleArrowClick('right')}
                    showHoverArrows={showHoverArrows} size={"35px"} top={"45%"} sidePosition={"5px"} />
        </div>
    );
}

export default TinyConversationsSlider;