import React, { useEffect, useState } from 'react';
import './WatchPicturesPageStyles.css';
import SimpleDynamicTextBox from '../components/SimpleDynamicTextBox';
import { BiBorderRadius, BiEdit } from 'react-icons/bi';
import { PiWarningDiamondBold } from 'react-icons/pi';
import { RxCross2 } from 'react-icons/rx';
import AddImagesComponent from '../components/AddImagesComponent';
import NumberOfImagesComponent from '../components/NumberOfImagesComponent';
import ImageAdderComponent from '../components/ImageAdderComponent';
import SecurityPhotoComponent from '../components/SecurityPhotoComponent';
import BasicButton from '../components/BasicButton';
import { Link } from 'react-router-dom';
import SecurityImage1 from '../assets/SellWatchPageImages/securityImage1.png';
import AnimatedButton from '../components/AnimatedButton';

const WatchPicturesPage = ( {images, setImages, securityImage1, securityImage2, setSecurityImage1, setSecurityImage2} ) => {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const computeGridHeight = () => {
        if (width > 1300) {
            if (images.length <= 2) {
            return 190;
            } else if (images.length <= 6) {
            return 405;
            } else {
            return 620;
            }
        } else if (width > 1240) {
            if (images.length <= 2) {
                return 170;
                } else if (images.length <= 6) {
                return 360;
                } else {
                return 550;
                }
        } else if (width > 1032) {
            if (images.length <= 2) {
                return 140;
                } else if (images.length <= 6) {
                return 306;
                } else {
                return 480;
                }
        }
        else if (width > 900) {
            if (images.length <= 2) {
                return 190;
                } else if (images.length <= 6) {
                return 405;
                } else {
                return 620;
                }
        } else if (width > 820) {
            if (images.length <= 2) {
                return 170;
                } else if (images.length <= 6) {
                return 360;
                } else {
                return 550;
                }
        } else if (width > 700) {
            if (images.length <= 2) {
                return 140;
                } else if (images.length <= 6) {
                return 306;
                } else {
                return 480;
                }
        } else if (width > 570) {
            if (images.length <= 1) {
                return 140;
                } else if (images.length <= 4) {
                return 306;
                } else if (images.length <= 7) {
                return 480;
                } else {
                    return 640;
                }
        } else if (width > 100) {
            if (images.length <= 0) {
                return 142;
                } else if (images.length <= 2) {
                return 312;
                } else if (images.length <= 4) {
                    return 475;
                } else if (images.length <= 6) {
                    return 640;
                } else if (images.length <= 8) {
                    return 810;
                } else {
                return 980;
                }
        }
      };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);



    return (
        <div className="watchPicturesPageParentDiv">


            <div className='watchPicturesPageInputParentDiv'>
                <div className='watchPicturesPageAddPicturesSectionParentDiv'
                style={{height: (width > 700 && width < 820) || (width > 1032 && width < 1120)? computeGridHeight(width)+470 : computeGridHeight(width)+450}}
                >
                    <div style={{display: 'flex', width: "100%", height: "50px"}}>
                        <SimpleDynamicTextBox text={"Watch Pictures"} fontSize={"2rem"} 
                        fontWeight={500} color={"white"} marginLeft={"0px"}
                        marginTop={"20px"}
                        />

                    </div>
                    {width > 570 &&
                    <SimpleDynamicTextBox text={"This is where you upload the pictures of your watch"} 
                        fontSize={width > 700 ? "1.4rem" : width > 570 ? "1.2rem" : "1rem"} 
                        fontWeight={400} color={"white"} marginLeft={"0px"}
                        marginTop={"40px"}
                        />
                    }
                    <div style={{display: 'flex', width: width > 1120 ? "660px" : width > 1032 ? "590px" : width > 820 ? "660px" : width > 700 ? "590px" : width > 570 ? "455px" : "312px", height: width > 1120 ? "140px" : width > 1032 ? "160px" : width > 820 ? "140px" : width > 570 ?  "160px" : "228px",
                    border: "3px solid white", marginLeft: "0px",
                    borderRadius: "10px", marginTop: "20px",
                    backgroundColor: "black",
                    boxSizing: "border-box", padding: "15px",
                    
                    }}>
                    <SimpleDynamicTextBox text={"High-quality images act as a quality seal for your listing. Choose a neutral background for the main photo that won't distract from the watch. Photograph your watch from the front, making sure it's in the center of the frame. Include photos of the watch from all sides to attract as many buyers as possible. You can find more tips for creating high-quality images here."} 
                            fontSize={width > 700 ? "1.05rem" : "0.9rem"} 
                            width={"580px"}
                            fontWeight={400} color={"#e9ecef"} marginLeft={"0px"}
                            marginTop={"0px"}
                            />
                    <div style={{display: 'flex', width: "50", height: "100px",
                    
                    marginLeft: "25px"
                    }}>
                        <PiWarningDiamondBold color='orange' size={25}/>

                    </div>
                    </div>
                    <ImageAdderComponent
                        images={images}
                        setImages={setImages}
                        computeGridHeight={computeGridHeight}
                        marginTop={"45px"}
                        width={width}
                    />

                </div>
                    <div style={{width: width > 1300 ? "700px" : width > 1240 ? "650px" : width > 1120 ? "600px" : width > 1032 ? "570px" : width > 900 ? "700px" : width > 820 ? "600px" : width > 700 ? "550px" : width > 570 ? "425px" : "260px", height: "2px", backgroundColor: "black", marginTop: "50px", marginLeft: "15px"}}>
                        </div>
                <div className='watchPicturesSecuritySectionParentDiv'>
                    <SimpleDynamicTextBox text={"Proof Of Ownership"} 
                        fontSize={"1.8rem"} 
                        fontWeight={500} color={"white"} marginLeft={"0px"}
                        marginTop={"20px"}
                        />
                    <SimpleDynamicTextBox text={"Please upload photos showing your watch set to the following times. This shows us that the watch is actually in your possession."} 
                        fontSize={"1.1rem"} maxWidth={"550px"}
                        fontWeight={400} color={"white"} marginLeft={"0px"}
                        marginTop={"30px"}
                        />
                    <div className='watchSecurityPicturesParentDiv'>
                        <div className='watchSecurityPicturesChildDiv'
                        >
                        <SimpleDynamicTextBox text={"Security Photo 1"} 
                        fontSize={"1.1rem"} maxWidth={"550px"}
                        fontWeight={500} color={"black"} marginLeft={"5px"}
                        marginTop={"20px"}
                        />
                        <div className='centerContainer'
                        style={{marginTop: "5px"}}
                        >
                        <SecurityPhotoComponent 
                        securityImage={securityImage1}
                        setSecurityImage={setSecurityImage1}
                        displayPhoto={SecurityImage1}
                        />
                        </div>
                        </div>
                        <div className='watchSecurityPicturesChildDiv'
                        >
                        <SimpleDynamicTextBox text={"Security Photo 2"} 
                        fontSize={"1.1rem"} maxWidth={"550px"}
                        fontWeight={500} color={"black"} marginLeft={"5px"}
                        marginTop={"20px"}
                        />
                        <div className='centerContainer'
                        style={{marginTop: "5px"}}
                        >
                        <SecurityPhotoComponent 
                        securityImage={securityImage2}
                        setSecurityImage={setSecurityImage2}
                        displayPhoto={SecurityImage1}
                        />
                        </div>
                        </div>
                    </div>
                </div>
                <div style={{width: width > 1240 ? "700px" : width > 1120 ? "600px" : width > 1032 ? "520px" : width > 820 ? "680px" : width > 700 ? "560px" : width > 570 ? "440px" : "280px", height: "1px", backgroundColor: "black", color: "black", marginTop: "40px", 
                marginLeft: "10px"
                }}>
            </div>
            <div>
            <div style={{display: 'flex',width: "92%", height: "80px", 
            alignItems: 'center', borderRadius: "2px",
            justifyContent: 'flex-start', marginTop: "35px",
            backgroundColor: "white",
            marginLeft: width > 820 ? "10px" : "2px"
            }}>
            <Link to="/sell/watchdetails" className="no-underline">
            <AnimatedButton text={width > 700 ? "Go Back To Step 1" : "Go back"} direction={"left"} width={width > 1120 ? "220px" : width > 1032 ? "180px" : width > 820 ? "220px" : width > 700 ? "195px" : "125px"} height={"50px"}
            hoverColor={"black"} color={"white"} textcolor={"black"} arrowColor={"black"} arrowAnimatedColor={"white"}
            borderRadius={"3px"} border={"3px solid black"} 
            fontSize={width > 1240 ? "1rem" : width > 1120 ? "0.9rem" : width > 1032 ? "0.8rem" : width > 820 ? "1rem" : "0.9rem"} fontWeight={"600"} 
            paddingLeft={"20px"} marginLeft={"10px"}
            arrowMarginLeft={"0px"}
            paragraphMarginLeft={"5px"}
            /> 
            </Link>

            <Link to="/sell/personalinformation" className="no-underline">
            <AnimatedButton text={width > 700 ? "Continue To Personal Information" : "Continue"} direction={"right"} width={width > 1240 ? "350px" : width > 1120 ? "316px" : width > 1032 ? "290px" : width > 820 ? "350px" : width > 700 ? "316px" : "145px"} height={"50px"}
            hoverColor={"black"} color={"white"} textcolor={"black"} arrowColor={"black"} arrowAnimatedColor={"white"}
            borderRadius={"3px"} border={"3px solid black"} 
            fontSize={width > 1240 ? "1rem" : width > 1120 ? "0.9rem" : width > 1032 ? "0.8rem" : width > 820 ? "1rem" : "0.9rem"} fontWeight={"600"} 
            paddingLeft={"20px"} marginLeft={width > 1300 ? "210px" : width > 1240 ? "150px" : width > 1120 ? "120px" : width > 1032 ? "90px" : width > 900 ? "210px" : width > 820 ? "120px" : width > 700 ? "100px" : width > 570 ? "175px" : "40px"}
            paragraphMarginLeft={"15px"}
            arrowMarginLeft={"11px"}
            /> 
            </Link>

            </div>
            </div>

            </div>



        </div>


    );



}


export default WatchPicturesPage;