import React, { useState, useEffect } from 'react';
import './WatchFunctionsCheckboxesStyles.css';
import CheckBox from './CheckBox';
import { FaWindowMinimize } from 'react-icons/fa';

const WatchFunctionsCheckBoxes = ({ checkboxList, checkboxStatuses ,updateParentData }) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCheckBoxChange = (text, isChecked) => {

    updateParentData(text, isChecked);
  };

  return (
    <div className="watchFunctionsCheckBoxesParentDiv">
      {
        Array.from({ length: Math.ceil(checkboxList.length / 2) }, (_, i) => i).map(chunkIndex => (
          <div key={chunkIndex} style={{ display: 'flex', flexDirection: windowWidth > 570 ? "row" : "column", width: '100%', marginTop: windowWidth > 570 ? "12px" : "0px", height: windowWidth <= 570 && "80px", }}>
            <div style={{ width: windowWidth > 570 ? '45%' : "100%", height: windowWidth <= 570 && "34px" }}>
              <CheckBox
                onCheckBoxChange={(text, isChecked) => handleCheckBoxChange(text, isChecked)}
                size={1.5}
                marginLeft={windowWidth > 570 ? "0px" : "10px"}
                labelMarginLeft={"10px"}
                fontSize={"1.05rem"}
                fontWeight={400}
                color={"white"}
                text={checkboxList[chunkIndex * 2]?.value}
                isChecked={checkboxStatuses[checkboxList[chunkIndex * 2]?.value]}
              />
            </div>
            {
              // Render the second checkbox in the chunk if it exists
              checkboxList[chunkIndex * 2 + 1] && (
                <div style={{ width: windowWidth > 570 ? '55%' : "100%", height: windowWidth <= 570 && "34px" }}>
                  <CheckBox
                    onCheckBoxChange={(text, isChecked) => handleCheckBoxChange(text, isChecked)}
                    size={1.5}
                    marginLeft={windowWidth > 570 ? "0px" : "10px"}
                    labelMarginLeft={"10px"}
                    fontSize={"1.05rem"}
                    fontWeight={400}
                    color={"white"}
                    text={checkboxList[chunkIndex * 2 + 1].value}
                    isChecked={checkboxStatuses[checkboxList[chunkIndex * 2 + 1]?.value]}
                  />
                </div>
              )
            }
          </div>
        ))
      }
    </div>
  );
}

export default WatchFunctionsCheckBoxes;
