
const SimpleTextInput = ({ width, height, fontSize, fontWeight, border, placeholder, value, onChange, borderRadius, paddingLeft, marginLeft}) => {

    const SimpleTextInputStyles = {
        width: width,
        height: height,
        fontSize: fontSize,
        fontWeight: fontWeight,
        border: border,
        borderRadius: borderRadius,
        paddingLeft: paddingLeft,
        boxSizing: 'border-box',
        marginLeft: marginLeft,
    }

    return (
        <input type={"text"} placeholder={placeholder} value={value} onChange={onChange} style={SimpleTextInputStyles}/>
    );

}

export default SimpleTextInput;