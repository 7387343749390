import React, { useState, useEffect } from 'react';
import './SellWatchInputComponentStyles.css';
import SimpleDynamicTextBox from './SimpleDynamicTextBox';
import InputClickableBox from './InputClickableBox';
import FilterDropDown from './FilterDropDown';
import SimpleTextInput from './SimpleTextInput';
import ResizableBox from './ResizableBox';
import ResizableInput from './ResizableInput';
import { RxCross1, RxCross2 } from 'react-icons/rx';

const SellWatchInputComponent = ({ categoryList, updateParentFormData, currency, currencyValue, height, marginTop, paddingTop }) => {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const [selectedWatchTypeOptionIndex, setSelectedWatchTypeOptionIndex] = useState(null);
    const [selectedGenderOptionIndex, setSelectedGenderOptionIndex] = useState(null);

    let currentCurrency = currency ? currency : "$";

    function getComissionAndReturn(categoryName) {
        if (categoryName === "Comission Fee") {
            return currencyValue * 0.1;
        } else if (categoryName === "Earnings") {
            return currencyValue * 0.9;
        } else {
            return currencyValue;  // Default return if needed
        }
    }
   

    return (
        <div className='SellWatchInputParentDiv' style={{ height: height, marginTop: marginTop, paddingTop: paddingTop, borderRadius: "10px", border: "4px solid #023e8a" }}>
            {categoryList.map((category, index) => {
                const numGridColumns = category.clickableOptions ? category.clickableOptions.length : 1;
                let selectedOptionIndex = (category.category === "Type Of Watch") ? selectedWatchTypeOptionIndex : selectedGenderOptionIndex;
                let setSelectedOptionIndex = (category.category === "Type Of Watch") ? setSelectedWatchTypeOptionIndex : setSelectedGenderOptionIndex;
                const clickableOptionHeight = category.clickableOptionHeight;
                const clickableOptionWidth = category.clickableOptionWidth;


                const InputClickableOptionsGridDiv = {
                    display: 'grid',
                    gridTemplateColumns: `repeat(${numGridColumns}, 1fr)`, // this sets the number of columns based on props
                    gap: '10px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    paddingBottom: '10px',
                    placeItems: 'center', // Add this line to center the children
                    height: category.height,
                };

                return (
                    <div className='sellYourWatchInputComponent' style={{ height: category.height, width: category.width, marginTop: category.marginTop}} key={index}>
                        <div className='sellYourWatchInputComponentFirstItem sellYourWatchInputComponentFirstItem2ndClass'
                        style={{
                            height: (width < 570) ? "35px" : "40px"
                        }}>
                            <SimpleDynamicTextBox text={category.category} fontSize={width > 1160 || (width < 1033 && width > 828) ? "1.2rem" : "1.05rem"} fontWeight={450} color={"white"} marginTop={category.categoryHeaderMarginTop} />
                        </div>
                        <div style={{width: width > 570 ? "70%" : "100%",}}>
                            {category.clickableOptions &&
                                 
                                <div style={InputClickableOptionsGridDiv}>
                                    {category.clickableOptions.map((option, optionIndex) => (
                                        <InputClickableBox 
                                        width={clickableOptionWidth} 
                                        height={clickableOptionHeight} 
                                        text={option.value} 
                                        fontSize={category.fontSize}
                                        image={category.images[optionIndex]}
                                        imageWidth={category.imageWidth}
                                        imageHeight={category.imageHeight} 
                                        imageMarginTop={category.imageMarginTop}
                                        isSelected={selectedOptionIndex === optionIndex} // <-- Pass down isSelected prop
                                        onSelect={() => {
                                            setSelectedOptionIndex(optionIndex); // <-- Existing code to set selected option index
                                            updateParentFormData(category.category, option.value); // <-- Additional code to update parent form data
                                          }}
                                        key={optionIndex} />
                                    ))}
                                </div>
                            }
                            {category.dropdownOptions && 
                            <div className='sellWatchDropdownOrTextParentDiv'>
                            <FilterDropDown options={category.dropdownOptions} 
                            handleChange={(selectedOption) => updateParentFormData(category.category, selectedOption)}
                            marginLeft={"0px"} 
                            width={width > 1160 || (width < 1033 && width > 828) ? "470px" : width > 690 ? "386px" : width > 570 ? "352px" : "293px"} height={"40px"} borderRadius={"6px"} border={"1px solid black"}/>
                            </div>
                            }
                            {category.textInput && 
                            <div className='sellWatchDropdownOrTextParentDiv'>
                        
                            <SimpleTextInput width={"470px"} height={"38px"} fontSize={"1rem"}
                            border={"1px solid black"} 
                            onChange={(e) => updateParentFormData(category.category, e.target.value)}
                            placeholder={category.placeholderText} 
                            borderRadius={"6px"} paddingLeft={"10px"}/>

                            </div>
                            } 
                            {category.shortDescription &&
                                <div className='sellWatchDropdownOrTextParentDiv'>

                                <SimpleTextInput width={"470px"} height={"100px"} fontSize={"1rem"}
                                onChange={(e) => {
                                    console.log("Child Input:", e.target.value);
                                    updateParentFormData(category.category, e.target.value);
                                }}
                                border={"1px solid black"} placeholder={category.placeholderText} 
                                borderRadius={"6px"} paddingLeft={"10px"}/>
    
                                </div>

                            }
                            {category.description &&
                               <ResizableInput 
                               initialWidth={category.descriptionWidth} 
                               initialHeight={category.descriptionHeight}
                               minConstraints={category.minConstraints} 
                               maxConstraints={category.maxConstraints}
                               borderRadius={"6px"} 
                               marginTop={category.marginTop}
                               fontSize={"1rem"} 
                               marginLeft={"0px"} 
                               placeholderText={category.placeholderText}
                               onInputChange={(value) => updateParentFormData(category.category, value)}
                               minLength={10} 
                               maxLength={category.short ? 150 : 1000}
                               />


                            }
                            {category.category == "Case Diameter (mm)" && 
                            <div style={{display: "flex", width: "100%",paddingLeft: "0px", 
                            alignItems: 'center', justifyContent: width > 1160 || width < 1033 ? 'space-evenly' : 'space-between', boxSizing: 'border-box',
                            }  }>
                            <SimpleTextInput width={width > 1160 || (width < 1033 && width > 828) ? "216px" : width > 690 ? "180px" : width > 570 ? "164px" : "133px"} height={"38px"} fontSize={"1rem"}
                            border={"1px solid black"} placeholder={category.placeholderText} 
                            borderRadius={"6px"} paddingLeft={"10px"}/>
                            <RxCross2 size={22} color='white'/>
                            <SimpleTextInput width={width > 1160 || (width < 1033 && width > 828) ? "216px" : width > 690 ? "180px" : width > 570 ? "164px" : "133px"} height={"38px"} fontSize={"1rem"}
                            border={"1px solid black"} placeholder={category.placeholderText} 
                            borderRadius={"6px"} paddingLeft={"10px"}/>
                            </div>
                            }
                             {category.category == "Price" && 
                            <div style={{display: "flex", width: "100%",paddingLeft: "0px", 
                            alignItems: 'center', boxSizing: 'border-box',
                            }  }>
                            <SimpleTextInput width={width > 570 ? "216px" : "160px"} height={"38px"} fontSize={"1rem"}
                            border={"1px solid black"} placeholder={category.placeholderText} marginTop={category.marginTop}
                            onChange={(e) => updateParentFormData(category.category, e.target.value)}

                            borderRadius={"6px"} paddingLeft={"10px"} />
                            <FilterDropDown options={category.currencyOptions}  
                            handleChange={(selectedOption) => updateParentFormData("Currency", selectedOption.value)}
                            marginLeft={"20px"} 
                            width={"100px"} height={"40px"} borderRadius={"6px"} border={"1px solid black"}/>
                            </div>
                            }
                            {(category.category == "Comission Fee" || category.category == "Earnings") &&
                            <SimpleDynamicTextBox text={`${currentCurrency} ${getComissionAndReturn(category.category)}`} fontSize={"1rem"} 
                            fontWeight={400} color={"black"} 
                            width={width > 570 ? "216px" : "149px"}
                            marginTop={category.marginTop} backgroundColor={"white"} 
                            borderRadius={"6px"} paddingLeft={"10px"}
                            maxWidth={"205px"} height={"36px"} spanHeight={"38px"}
                            />

                            }
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default SellWatchInputComponent;
