import React, { useState } from 'react';
import './ToggleSwitchStyles.css';

const ToggleSwitch = ({ width, leftLabel, rightLabel, onToggle, marginTop, marginBottom }) => {
    const [mode, setMode] = useState('left');

    const toggleToLeft = () => {
        if(mode === 'right') {
            setMode('left');
            onToggle('left');
        }
    };

    const toggleToRight = () => {
        if(mode === 'left') {
            setMode('right');
            onToggle('right');
        }
    };

    const outerDivStyle = {
        marginTop: marginTop,
        marginBottom: marginBottom,
        display: 'flex', 
        justifyContent: 'center', 
        width: width > 520 ? '450px' : "350px", 
        margin: '0 auto'
    }

    return(
        <div style={outerDivStyle}>
            <div 
              onClick={toggleToLeft} 
              className={`toggleOption ${mode === 'left' ? 'leftActive' : ''}`}
              >
              {leftLabel}
            </div>
            <div 
              onClick={toggleToRight} 
              className={`toggleOption ${mode === 'right' ? 'rightActive' : ''}`}
              >
              {rightLabel}
            </div>
        </div>
    );
}



export default ToggleSwitch;
