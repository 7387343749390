import React, { useState } from 'react';
import './AnimatedArrowStyles.css';
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi';

const AnimatedArrow = ({ arrows, animate, direction, animatedColor, regularColor, arrowMarginLeft }) => {


  return (
      <div className="threeArrowParentDiv"
      style={{marginLeft: arrowMarginLeft}}
      >
        {arrows.map((arrow, index) => (
        direction === "left" ? 
          <FiArrowLeft key={index} className={animate ? '' : 'no-transition'} style={{ left: arrow.position }} size={18} 
          color={animate ? animatedColor : regularColor}
          />
        : <FiArrowRight key={index} className={animate ? '' : 'no-transition'} style={{ left: arrow.position }} size={18} 
        color={animate ? animatedColor : regularColor}
        />

      ))}
      </div>
  );
};

export default AnimatedArrow;
