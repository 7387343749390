import React, { useState } from 'react';
import './LargeListingStatisticsBigButtonStyles.css';
import { BsArrowUpSquare } from 'react-icons/bs';
import { IoIosArrowDropup } from 'react-icons/io';
import { alternateColorScheme } from '../commonStyles';
import LargeListingStatisticsButtonContent from './LargeListingStatisticsButtonContent';

const LargeListingStatisticsBigButton = ( {buttonContent, marginTop, startPaddingTop, 
    hoverPaddingTop, hoverBoxText, hoverBoxHeight, hoverBoxTopValue} ) => {

    const [showListingPlacement, setShowListingPlacement] = useState(false);

    return (
        <button className='visitorsStatButton'
        style={{
            marginTop: marginTop,
            paddingTop: showListingPlacement ? hoverPaddingTop : startPaddingTop
        }}
        onMouseEnter={() => setShowListingPlacement(true)} 
        onMouseLeave={() => setShowListingPlacement(false)}
    >
        {buttonContent}

        {showListingPlacement &&
    <div className='largeListingkeyStatPopupDiv'
    style={{
        height: hoverBoxHeight,
        top: hoverBoxTopValue
    }}
    >
        <p style={{
            margin: "0px"
        }}>
            {hoverBoxText}
        </p>
        
    </div>
    }
    </button>


    );



}


export default LargeListingStatisticsBigButton;