import './LargeListingStatisticsButtonContentStyles.css';
import React, { useState } from 'react';
import './LargeListingStatisticsBigButtonStyles.css';
import { BsArrowUpSquare } from 'react-icons/bs';
import { IoIosArrowDropup } from 'react-icons/io';
import { alternateColorScheme } from '../commonStyles';


function LargeListingStatisticsButtonContent({}) {


    return (
        <>
        <IoIosArrowDropup color="black" size={17} style={{marginRight: "32px"}}/>
        <div 
        style={{
            display: 'flex',
            width: "90%",
            height: "25px",
            }}      
        >
        <p className='listingPlacementText'>
            17
        </p>
        <p className='listingPlacementTextSmall'>
            th
        </p>
        </div>
        </>
    );
}

export default LargeListingStatisticsButtonContent;