import React from 'react';
import './PersonalInformationFieldStyles.css';
import { commonFont, alternateColorScheme } from '../commonStyles';
import InputField from './InputField';

const PersonalInformationField = ( {value, onChange} ) => {




    const handleChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <div className="personalInformationFieldParentDiv">
            <input 
            className='personalInformationInputField'
            type='text'
            value={value}
            onChange={handleChange}
            >
            </input>
        </div>


    );



}


export default PersonalInformationField;