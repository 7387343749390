import React, {useState} from 'react';
import './TestPageStyles.css';
import boughtWatchImage1 from '../assets/fakeListingImages/watchListing1.png';
import boughtWatchImage2 from '../assets/fakeListingImages/watchListing2.png';
import boughtWatchImage3 from '../assets/fakeListingImages/watchListing3.png';
import boughtWatchImage4 from '../assets/fakeListingImages/watchListing4.png';

import { alternateColorScheme } from '../commonStyles';
import BuyWatchSummary from '../components/BuyWatchPageComponents/BuyWatchPreview';
import DynamicDropDown from '../components/DynamicDropDown';
import WatchListingSpecificationsDiv from '../components/WatchListingSpecificationsDivCopy';
import WatchListing from '../components/WatchListing';
import SimplifiedWatchListing from '../components/SimplifiedWatchListing';
import SimplifiedWatchListingPreview from '../components/SimplifiedWatchListingPreview';

const FAQ = () => {

    const WatchListingData = {brand: "Rolex", modelName: "Day-Date 36", dateAdded: new Date('2023-06-01'), popularity: 450,
    sellerComment: "Ref 118388 Men’s 18K Yellow Gold Black MOP Dia Dial , Bezel & Case 2006",
     price: 42995, shippingCost: "$99", sellerCountry: "US",  sellerCity: "New York", 
     images: [boughtWatchImage1, boughtWatchImage2, boughtWatchImage3, boughtWatchImage4]}

    return (
        <div className='testPageParentDiv'>
                <WatchListing watchListingInfo={WatchListingData} images={WatchListingData.images} to={`/watchlisting3`}/>

                <SimplifiedWatchListing watchListingInfo={WatchListingData} images={WatchListingData.images} to={`/watchlisting3`}/>

                <SimplifiedWatchListingPreview title={"Hej"} shortDescription={"Hej"} watchListingInfo={WatchListingData} images={[]} to={''}/>
        </div>
    );
}

export default FAQ;