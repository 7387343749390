import React, { useState, useEffect } from 'react';
import './CardStack.css';
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineFlipToFront } from "react-icons/md";

const CardStack = ({ numCards, cardContent, handleDeleteClick, width }) => {
    const initialCardStack = [...Array(numCards).keys()];
    const [cardStack, setCardStack] = useState(initialCardStack);



    const handleClick = () => {
        setCardStack([cardStack[numCards - 1], ...cardStack.slice(0, numCards - 1)]);
    };

    useEffect(() => {
        console.log("Hello", cardStack);
        

    
        // If the length has changed, it likely means a card was removed
        if (cardStack.length > cardContent.length) {
            // Find the maximum value in the existing cardStack
            const maxValue = Math.max(...cardStack);
    
            // Remove this maximum value from cardStack
            setCardStack(prevStack => prevStack.filter(value => value !== maxValue));
        } else if (cardStack.length < cardContent.length) {
            // If a card was added, add a new index
            const maxValue = Math.max(...cardStack);
            const maxIndex = cardStack.indexOf(maxValue);
          
            setCardStack(prevStack => {
              const newStack = [...prevStack]; // Create a copy of the array
              const newIndex = maxValue + 1;
              newStack.splice(maxIndex + 1, 0, newIndex); // Insert newIndex at the position right after maxValue
              return newStack;
            });
          }
          
    
    }, [cardContent.length]);

    return (
        <div className="cardContainer">
            {console.log(cardStack)}
            {cardStack.map((position, index) => (
                <div
                    className={`card card${position}`}
                    key={index}
                >
                    {cardContent[index]}
                </div>
            ))}
            {cardContent.length > 1 &&
                <>
                    <button className='cardSwitchButton' onClick={handleClick}>
                        
                        {width > 640 ?
                        "Flip Next"
                        :
                        <MdOutlineFlipToFront color='black' size={22}/>
                    }
                        
                        </button>

                    <button className='deleteCardButton' onClick={() => {
                        const maxIndex = cardStack.indexOf(Math.min(...cardStack));
                        console.log("maxindex", maxIndex);

                        handleDeleteClick(maxIndex);
                    }}>
                        {width > 640 ?
                        "Delete Card"
                        :
                        <RiDeleteBin6Line color='black' size={22}/>
                    }
                    </button>

                </>
            }
        </div>
    );
};

export default CardStack;
