import './LargeListingStatisticsButtonContent2Styles.css';
import React, { useState } from 'react';
import { BsArrowUpSquare } from 'react-icons/bs';
import { IoIosArrowDropup } from 'react-icons/io';
import { FaEye } from 'react-icons/fa';


import { alternateColorScheme } from '../commonStyles';

const totalVisitors = 10244;

function LargeListingStatisticsButtonContent2({}) {


    return (
        <>
        <FaEye color="black" size={17} style={{marginRight: "28px"}}/>
        <div className='centerContainer'
        style={{
            height: "20px"
        }}
        >
        <div 
        style={{
            display: 'flex',
            flexDirection: 'column',
            width: "100%",
            height: "25px",
            }}      
        >
        <p className='listingPlacementText2'>
            {totalVisitors}
        </p>

        </div>
        </div>
        </>
    );
}

export default LargeListingStatisticsButtonContent2;