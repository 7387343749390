import React, { useState } from 'react';
import './UserReviewStyles.css';
import SellerProfileReviewUserComponent from './SellerProfileReviewUserComponent';
import SellerProfileReviewWatchBought from './SellerProfileReviewWatchBought';
import { commonFont, alternateColorScheme } from '../commonStyles';
import { MdThumbUpOffAlt, MdThumbUpAlt } from 'react-icons/md';

const UserReview = ( { marginTop, borderTopLeftRadius, borderTopRightRadius, 
    boughtWatchImage, boughtWatchName, boughtWatchKeyStats,
    userComment, userName, userScore, shipmentInfo, publishedReview } ) => {

    const [helpfulButtonHover, setHelpfulButtonHover] = useState(false);
    const [helpfulButtonIsActive, setHelpfulButtonIsActive] = useState(false);
    
    const [helpfulButtonCounter, setHelpfulButtonCounter] = useState(0);
    
    const handleClick = () => {
      if (helpfulButtonCounter) {
          setHelpfulButtonCounter(helpfulButtonCounter - 1);
      } else {
          setHelpfulButtonCounter(helpfulButtonCounter + 1);
      }
      setHelpfulButtonIsActive(!helpfulButtonIsActive);

    };

    const [showFullUserComment, setShowFullUserComment] = useState(!(isUserCommentLongerThan120(userComment)));



    function isUserCommentLongerThan120(str) {
        return str.length > 120;
      }

      function getUserCommentFirst120Chars(str) {
        return str.substring(0, 120);
      }

    return (
        <div className='sellerSingleTextReviewParentDiv'
        style={{marginTop: marginTop}}
        >
        <div className='sellerSingleTextReviewTopRowDiv'>

            <SellerProfileReviewUserComponent 
            borderTopLeft={borderTopLeftRadius} userName={userName}
            userScore={userScore} publishedReview={publishedReview}
            />

            <SellerProfileReviewWatchBought borderTopRight={borderTopRightRadius}
            boughtWatchImage={boughtWatchImage} boughtWatchName={boughtWatchName}
            boughtWatchKeyStats={boughtWatchKeyStats} shipmentInfo={shipmentInfo}
            />

        </div>



        <div className='singleTextReviewUserComment'>

        <p
            style={{
                color: "white",
                fontWeight: 500,
                fontFamily: commonFont.fontFamily,
                opacity: 1,
                marginTop: "0px",
                marginBottom: "0px"

            }}
            >
                Comment:
        </p>

        <p
                style={{
                    color: "white",
                    fontWeight: 400,
                    fontFamily: commonFont.fontFamily,
                    opacity: 1,
                    marginTop: "5px",
                    marginBottom: "0px"

                }}
                >   
                {
                showFullUserComment ? (
                    <>
                    {userComment}
                    {isUserCommentLongerThan120(userComment) && 
                    <span onClick={() => setShowFullUserComment(!showFullUserComment)} style={{ 
                        cursor: 'pointer', textDecoration: 'underline',
                        marginLeft: "0px", fontSize: '1rem',
                        display: 'block',
                        color: alternateColorScheme.purple
                        }}>
                        Show Less
                    </span>
                    }
                    </>
                ) : (
                    <>
                    {getUserCommentFirst120Chars(userComment) + '...'}
                    <span onClick={() => setShowFullUserComment(!showFullUserComment)} style={{ 
                        cursor: 'pointer', textDecoration: 'underline',
                        marginLeft: "0px", fontSize: '1rem',
                        display: 'block',
                        color: alternateColorScheme.purple
                        }}>
                        Show More
                    </span>
                    </>
                )
                }

                </p>
        </div>

        <div className='singleTextReviewSmallButtonDiv'>
            <button className='sellerProfileUsefulReviewButton'
                onClick={handleClick}
                onMouseEnter={() => setHelpfulButtonHover(true)}
                onMouseLeave={() => setHelpfulButtonHover(false)}
                style={{
                    backgroundColor: (helpfulButtonHover | helpfulButtonIsActive) ? alternateColorScheme.gold2 : "white"
                }}
            >
                <p
                    style={{
                        color: (helpfulButtonHover | helpfulButtonIsActive) ?  "white" : "black",
                        fontWeight: 400,
                        fontFamily: commonFont.fontFamily,
                        fontSize: "1.1rem",
                        opacity: 1,
                        marginTop: "0px",
                        marginLeft: "50px",
                        marginRight: "5px",
                        marginBottom: "0px"

                    }}
                    >
                        Helpful
                    </p>
                    {helpfulButtonHover && !helpfulButtonIsActive && 
                    <MdThumbUpOffAlt size={24} color='white'
                    className='fade-in'
                    />}
                    {helpfulButtonIsActive && 
                    <MdThumbUpAlt size={24} color='white'
                    className='fade-in'
                    />}
                    <p
                        style={{
                        color: (helpfulButtonHover | helpfulButtonIsActive) ?  "white" : "black",
                        fontWeight: 500,
                        fontFamily: commonFont.fontFamily,
                        fontSize: "1.1rem",
                        opacity: 1,
                        marginTop: "0px",
                        marginLeft: (helpfulButtonHover | helpfulButtonIsActive) ? "5px" : "29px",
                        marginRight: "0px",
                        marginBottom: "0px"

                    }}
                    >
                    {helpfulButtonCounter}

                    </p>

            </button>
        </div>

    </div>


    );



}


export default UserReview;