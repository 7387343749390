import React, { useState, useEffect } from 'react';
import ThumbWidget from '../ThumbWidget';
import './ExploreSectionStyles.css';
import exploreWatchesImage from '../../assets/exploreImages/exploreprimetick6.png';
import sellYourWatchImage from '../../assets/exploreImages/sellyourwatch3.png';
import conversationsImage from '../../assets/exploreImages/conversations2.png';
import monitorWatchesImage from '../../assets/exploreImages/monitorwatches1.png';


const ExploreSection = () => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        // Activate the animation when the component mounts
        setActive(true);
    }, []);

    return (
        <div className={`exploreParentDiv slide-up-fade-in ${active ? 'active' : ''}`}>
            <div className='exploreGridDiv'>
                <div className="exploreChildDiv">
                    <ThumbWidget text={"Explore Watches"} imgSrc={exploreWatchesImage} to={'/exploreWatches'}/>
                </div>
                <div className="exploreChildDiv">
                    <ThumbWidget text={"Sell Your Watch"} imgSrc={sellYourWatchImage} to={'/sell/watchdetails'}/>
                </div>
                <div className="exploreChildDiv">
                    <ThumbWidget text={"View Conversations"} imgSrc={conversationsImage} to={'/profileHome/conversations'}/>
                </div>
                <div className="exploreChildDiv">
                    <ThumbWidget text={"Monitor Watches"} imgSrc={monitorWatchesImage}/>
                </div>
            </div>
        </div>

    );
};

export default ExploreSection;