import './ImageInputAreaStyles.css';
import { RxCross2 } from 'react-icons/rx';

function ImageInputArea({ pendingImages, handleRemoveButtonClick }) {


    return (
        <div className='imageInputAreaParent'>
        {pendingImages.map((imageData, index) => (
        <div className='pendingImageContainer'>
        <img 
          key={index} 
          src={imageData} 
          alt={`Pending Image ${index}`} 
          className="pendingImage"

        />
          <button className='pendingImageRemoveButton'
                onClick={() => handleRemoveButtonClick(index)}
                >
                <RxCross2 size={14}/>
        </button>
        </div>
      ))}
        </div>
    );
}

export default ImageInputArea;