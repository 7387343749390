import { alternateColorScheme } from '../../commonStyles';
import './ConversationsChatBubbleStyles.css';

  // Function to calculate the width
  

  const calculateWidth = (str) => {
    const baseWidth = 40;
    const charCount = str.length;
    const extraWidthPerChar = 7.5;



    if (charCount > 70) return 550;

    return (extraWidthPerChar * charCount + baseWidth);
  };

function ConversationsChatBubble({ text, initials, leftBubble }) {

    const width = calculateWidth(text);


    return (
        <div className='chatBubbleAndInitialsParent'
        style={{
            alignSelf: leftBubble ? "flex-start" : "flex-end",
        }}
        >
        {leftBubble &&
        <div className='profileInitialsDiv'>
            <p 
            style={{
                fontSize: "0.9rem",
                color: "white"
            }}
            >
                {initials}
            </p>
        </div>
        }
        <div className='chatBubbleParent'
        style={{
            width: width,
            borderBottomLeftRadius: leftBubble ? "1px" : "12px",
            borderBottomRightRadius: leftBubble ? "12px" : "1px",
            backgroundColor: leftBubble ? alternateColorScheme.silver2 : '#3797f0',

        }}
        >
            <p className='chatBubbleText'
            style={{
                color: leftBubble ? "black" : "white",
            }}
            >
                {text}
            </p>
        </div>
        </div>
    );
}

export default ConversationsChatBubble;