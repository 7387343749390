import React, {useState} from 'react';
import './DetailedWatchInfoComponentStyles.css';
import DynamicTextBox from './DynamicTextBox';
import SimpleDynamicTextBox from './SimpleDynamicTextBox';
import LightBoldTextBox from './LightBoldTextBox';
import { HiCheck, HiX, HiOutlineInformationCircle, HiInformationCircle, HiMiniQuestionMarkCircle } from 'react-icons/hi';
import { BiSolidPaperPlane } from 'react-icons/bi';
import { TbTruckDelivery } from 'react-icons/tb';
import { FaDhl, FaFedex, FaUps } from 'react-icons/fa';

import { color, motion } from "framer-motion";
import { Link } from 'react-router-dom';
import ShippingInfoComponent from './ShippingInfoComponent';
import BasicWatchInfoComponent from './BasicWatchInfoComponent';
import DescriptionComponent from './DescriptionComponent';
import SellerInfoComponent from './SellerInfoComponent';


const DetailedWatchInfoComponent = ( {} ) => {

    const [hoveringDeliveryButton, setHoveringDeliveryButton] = useState(false);


    let deliveryDate = new Date();
    deliveryDate.setDate(deliveryDate.getDate() + 3);
    let deliveryDateString = deliveryDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD

    let deliveryInformation = 
    <>
    <LightBoldTextBox lightText={"1-2 Days"} lightTextFontWeight={"500"} boldText={"This Item Is Ready To Ship Within "} boldTextFontWeight={"400"}/>
    <LightBoldTextBox lightText={"3-6 Days."} lightTextFontWeight={"500"} boldText={". Expect Delivery Between "} boldTextFontWeight={"400"}/>

    </>

        const WatchListingData = {
            brand: "Rolex",
            modelName: "Day-Date 36",
            dateAdded: new Date('2023-06-01'),
            popularity: 450,
            sellerComment: "Ref 118388 Men’s 18K Yellow Gold Black MOP Dia Dial, Bezel & Case 2006",
            price: 42995,
            shippingCost: "$99",
            sellerCountry: "US",
            sellerCity: "New York",
            condition: "New (Unworn)",
            productionYear: 2012,
            box: "Original",
            papers: "Original",
            seller: "Professional Dealer",
            inStock: true,
            deliveryTime: 4,
            description: `2021 Rolex Day-Date 36mm 128238 Mother of Pearl Diamond Dial

Condition: Watch is in mint condition, please see photos for details.
            
Includes the following:
            
            ️Double Rolex boxed
            ️Box and Papers - Yes
            ️Rolex Warranty card dated 2021
            ️Manual & Warranty booklets
            ️Green Hang Tag included
            ️Bezel Protector
            ️Size: 7.5inch wrist (Full Links)
            ️Watch is in mint condition. Please see photos for details.
            ️Watch keeping accurate time within +/- 2 seconds
            ️Appraisal included

🔵Discounts available! Contact us for details.
            
🔵Shipping: Shipping via FedEx Fully Insured overnight shipping to CONUS. (Packages will be sent to Fedex Station closest to you for pickup, present your ID to the FedEx representative and they will release package to you.) This is required so we can insure the shipment.
            
🔵International: FedEx Priority shipping available. Buyer responsible for import/duty fees. Please message me your country before buying if you are not in the US to confirm we can ship to you.`
        }


    return (
        <div className='listingInfoHeaderAndContentParentDiv'>
            <DynamicTextBox text={`Listing Information`} color="white" fontSize="2rem" fontWeight="600" divHeight="50px"marginLeft="30px" marginTop="30px"  />

            <div className="detailedWatchInfoParentDiv">
                <div className='detailedWatchInfoBasicInfoParentDiv'>

                    <DynamicTextBox text={`Watch Info`} color="white" fontSize="1.5rem" fontWeight="600" divHeight="40px"marginLeft="10px" marginTop="14px" />
                    <div style={{width: '140px', height: '2px', backgroundColor: 'white', marginTop: '-7px', marginLeft: '10px', marginBottom: "15px"}}></div>
                    <div className='centerContainer'>
                    <BasicWatchInfoComponent/>
                    </div>
                </div>
                <div className='detailedWatchInfoSellerAndShippingAndDescriptionParentDiv'>
                        <ShippingInfoComponent marginTop={"16px"}/>
                    <DynamicTextBox text={`Description`} color="white" fontSize="1.5rem" fontWeight="600" divHeight="40px"marginLeft="10px" marginTop="54px" underline={true} />
                    <div style={{width: '160px', height: '2px', backgroundColor: 'white', marginTop: '-7px', marginLeft: '10px', marginBottom: "15px"}}></div>

                    <div className='centerContainer descriptionComponent'>
                        <DescriptionComponent description={WatchListingData.description}/>
                    </div>
                </div>
            </div>
        </div>


    );



}


export default DetailedWatchInfoComponent;