import React, { useEffect, useState } from 'react';
import './LargeListingStatisticsStyles.css';
import { Link } from 'react-router-dom';
import SmallWatchListing from './SmallWatchListing';
import boughtWatchImage1 from '../assets/fakeListingImages/watchListing1.png';
import boughtWatchImage2 from '../assets/fakeListingImages/watchListing4.png';
import boughtWatchImage3 from '../assets/fakeListingImages/watchListing5.png';
import boughtWatchImage4 from '../assets/fakeListingImages/watchListing6.png';
import boughtWatchImage5 from '../assets/fakeListingImages/watchListing7.png';
import boughtWatchImage6 from '../assets/fakeListingImages/watchListing8.png';
import ChartTestComponent from './VisitorChart';
import VisitorChart from './VisitorChart';

import LargeListingStatisticsBigButton from './LargeListingStatisticsBigButton';
import { IoIosArrowDropup } from 'react-icons/io';
import { FaRegEye } from 'react-icons/fa';
import LargeListingStatisticsButtonContent from './LargeListingStatisticsButtonContent';
import LargeListingStatisticsButtonContent2 from './LargeListingStatisticsButtonContent2';
import LargeListingStatisticsButtonContent3 from './LargeListingStatisticsButtonContent3';
import TinyConversationOverview from './TinyConversationOverview';
import TinyConversationsSlider from './TinyConversationsSlider';
import { alternateColorScheme } from '../commonStyles';
import BasicButton from './BasicButton';

const LargeListingStatistics = ( {} ) => {


    const watchInfo = {brand: "Rolex", modelName: "Day-Date 36", dateAdded: new Date('2023-06-01'), popularity: 450,
    sellerComment: "Ref 118388 Men’s 18K Yellow Gold Black MOP Dia Dial , Bezel & Case 2006",
     price: 42995, shippingCost: "$99", sellerCountry: "US",  sellerCity: "New York", 
     images: [boughtWatchImage1, boughtWatchImage2, boughtWatchImage3]}

     const date = new Date(2023, 5, 24);

     const totalVisitors = 10244;

    // 200 hours in seconds
    const totalSeconds = 200 * 60 * 60;
    
    // Setting the initial time remaining to 20 hours in seconds
    const initialSecondsRemaining = 20 * 60 * 60;

    const [secondsRemaining, setSecondsRemaining] = useState(initialSecondsRemaining);

    useEffect(() => {
        // Set an interval to decrease the time remaining by 1 second every second
        const interval = setInterval(() => {
          if (secondsRemaining > 0) {
            setSecondsRemaining(secondsRemaining => secondsRemaining - 1);
          } else {
            clearInterval(interval);
          }
        }, 1000);
        
        // Clear interval on component unmount
        return () => clearInterval(interval);
      }, [secondsRemaining]);

            // Calculate days, hours, minutes from the seconds remaining
    const days = Math.floor(secondsRemaining / (3600 * 24));
    const hours = Math.floor((secondsRemaining % (3600 * 24)) / 3600);
    const minutes = Math.floor((secondsRemaining % 3600) / 60);
    const seconds = secondsRemaining % 60;

    const visitorChartInData = [30, 12, 45, 27, 68, 42, 22, 18, 40, 74, 33, 22, 55, 72, 99, 52, 43, 65, 34, 76, 66, 92]

    return (
        <div className="LargeListingStatisticsParentDiv">
            <div className='smallListingAndKeyStatsParentDiv'>
            <SmallWatchListing watchListingInfo={watchInfo}/>
            <TinyConversationsSlider/>
            </div>
            <div className='centerContainer'>
            <div className='lisingVisitorsChartParentDiv'>
            <VisitorChart inputDate={date} inData={visitorChartInData}/>
            <div className='visitorChartKeyStatsDiv'>
            <LargeListingStatisticsBigButton buttonContent={<LargeListingStatisticsButtonContent/>}
            marginTop={"0px"} startPaddingTop={"4px"} hoverPaddingTop={"1px"}
            hoverBoxText={"This listing is currently your 17th most viewed listing since you joined PrimeTick."}
            />

            <LargeListingStatisticsBigButton
            
            marginTop={"0px"}
            buttonContent={<LargeListingStatisticsButtonContent2/>}
            startPaddingTop={"4px"} hoverPaddingTop={"1px"}
            hoverBoxText={`${totalVisitors} people have visited this listing.`}
            hoverBoxHeight={"20px"} hoverBoxTopValue={"-50px"}
            />

            <LargeListingStatisticsBigButton buttonContent={<LargeListingStatisticsButtonContent3/>}
            marginTop={"0px"} startPaddingTop={"4px"} hoverPaddingTop={"1px"}
            hoverBoxText={"Potential customers looks at this listing for 32 Seconds on average."}
            />

            </div>
            </div>
            </div>

            <div className='largeListingStatisticsActionsSectionsParent'>
            <div className='largeListingStatisticsActionsSection'>
                <p style={{
                    fontSize: "1.1rem",
                    fontWeight: 500,
                    margin: "2px"
                }}>
                    Upgrade Your Listing
                </p>
                <p style={{
                    fontSize: "0.9rem",
                    fontWeight: 500,
                    margin: "0px",
                    marginLeft: "2px",
                    color: alternateColorScheme.purple2
                }}>
                    Get up to 20% more visitors <br></br> with priority listing
                </p>
                <Link to="/upgradeListing">

                <BasicButton text={"Upgrade Now"}  width={"200px"} height={"40px"} border={"2px solid black"} backgroundColor={"white"} hoveredBackgroundColor={alternateColorScheme.gold2} color={"black"} 
                borderRadius={"3px"} marginLeft={"0px"} marginTop={"10px"}
                fontSize={"1.1rem"} fontWeight={"400"}
                />
                </Link>
            </div>

            <div className='largeListingStatisticsActionsSection'
            style={{
                marginLeft: "15px"
            }}
            >
                                <p style={{
                    fontSize: "1.1rem",
                    fontWeight: 500,
                    margin: "2px"
                }}>
                    Extend Your Listing
                </p>
                <p style={{
                    fontSize: "0.9rem",
                    fontWeight: 500,
                    margin: "0px",
                    marginLeft: "2px",
                    color: alternateColorScheme.purple2
                }}>
                    Countdown: {days}d {hours}h {minutes}m {seconds}s <br></br>
                    Extend your listing period here
                </p>
                <Link to="/upgradeListing">

                <BasicButton text={"Extend Now"}  width={"200px"} height={"40px"} border={"2px solid black"} backgroundColor={"white"} hoveredBackgroundColor={alternateColorScheme.gold2} color={"black"} 
                borderRadius={"3px"} marginLeft={"0px"} marginTop={"10px"}
                fontSize={"1.1rem"} fontWeight={"400"}
                />
                </Link>
            </div>


            </div>

        </div>


    );



}


export default LargeListingStatistics;