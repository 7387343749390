import axios from 'axios';


export const signUpUser = (firstname, lastname, username, email, password) => {
    return axios.post('http://localhost:5001/api/users/register', {
        firstName: firstname,
        lastName: lastname,
        userName: username,
        email: email,
        password: password
    }).then(response => {
        // Save the token to local storage or in-memory storage
        localStorage.setItem('token', response.data.token);
        return response;
    });
    
};

export const signInUser = (email, password) => {
    return axios.post('http://localhost:5001/api/users/login', {
        email: email,
        password: password
    }).then(response => {
        // Save the token to local storage or in-memory storage
        localStorage.setItem('token', response.data.token);
        return response;
    });
};



export const getUserData = async (uid) => {

};