import React, { useState } from 'react';
import './BasicButtonStyles.css';

const BasicButton = ( {text, width, height, backgroundColor, hoveredBackgroundColor, color, hoveredColor, border, borderRadius, fontSize, fontWeight, marginLeft, marginTop, } ) => {

    const [isHovered, setHovered] = useState(false);



    const handleClick = () => {
        console.log('Button clicked');
    };

    const BasicButtonStyles = {
        cursor: 'pointer', // Add this line

        width: width,
        height: height,
        backgroundColor,
        border: border,
        borderRadius: borderRadius,
        color: color,
        fontSize: fontSize,
        fontWeight: fontWeight,
        marginLeft: marginLeft,
        marginTop: marginTop,
    }
    const hoveredBasicButtonStyles = {
        cursor: 'pointer', // Add this line

        width: width,
        height: height,
        backgroundColor: hoveredBackgroundColor,
        color: hoveredColor,
        border: border,
        borderRadius: borderRadius,
        fontSize: fontSize,
        fontWeight: fontWeight,
        textDecoration: 'underline',
        marginLeft: marginLeft,
        marginTop: marginTop,

    }

    return (
        <button 
        type="submit" 
        className='basicButtonStyles'
        style={isHovered ? hoveredBasicButtonStyles : BasicButtonStyles} 
        onClick={handleClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        >
            {text}
        </button>
    );



}


export default BasicButton;