import { useState, useEffect, useRef } from 'react';
import './ConversationSearchStyles.css';
import { BiSearch } from 'react-icons/bi';
function ConversationSearch({ searchClick, handleSearchChange, currentSearchValue }) {

    const inputRef = useRef(null);

    const [isActive, setIsActive] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const parentRef = useRef(null);


    useEffect(() => {
        function handleDocumentClick(event) {
          if (parentRef.current && parentRef.current.contains(event.target)) {
            setIsActive(true);
          } else {
            setIsActive(false);
          }
        }
    
        // Attach the click event listener
        document.addEventListener('click', handleDocumentClick);
    
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleDocumentClick);
        };
      }, []); // Empty dependency array means this effect runs once when the component mounts

    const handleClick = () => {

        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      };

    return (
        <div className='conversationSearchParent'
        onClick={handleClick}
        ref={parentRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}

        >   
            <div className='conversationSearchTextInputParent'>
                <input ref={inputRef} className='conversationSearchTextInput'
                onChange={handleSearchChange} value={currentSearchValue}
                />
            <div className={isHovered ? 'conversationSearchNormalUnderline conversationSearchDynamicUnderlineHover' : 'conversationSearchNormalUnderline'}></div>
            <div className={isActive ? 'conversationSearchDynamicUnderline conversationSearchDynamicUnderlineActive' : (isHovered ? 'conversationSearchDynamicUnderline conversationSearchDynamicUnderlineHover' : 'conversationSearchDynamicUnderline')}></div>
            </div>
            <div className='conversationSearchLogoParent'
            onClick={searchClick}
            >
                <BiSearch className='searchIconTransition' size={30} color={(isActive || isHovered) ? "black" : "gray"}/>
            </div>
        </div>
    );
}

export default ConversationSearch;