import React, {useState} from 'react';
import './BuyWatchSummaryStyles.css';
import '../commonStyles.css';
import { Link } from 'react-router-dom';
import { alternateColorScheme } from '../../commonStyles';
import { HiCheck, HiX, HiOutlineInformationCircle, HiInformationCircle, HiMiniQuestionMarkCircle } from 'react-icons/hi';
import { FaMoneyBillTransfer } from 'react-icons/fa6';

function BuyWatchSummary({ width }) {

    const [acceptedGeneralTerms, setAcceptedGeneralTerms] = useState(false);
    const [acceptedDutiesAndTaxes, setAcceptedDutiesAndTaxes] = useState(false);
    const [buyButtonIsHovered, setBuyButtonIsHovered] = useState(false);


    const underlineStyle = {
        '--underline-width': width > 570 ? `300px` : "90%",
        alignSelf: width > 570 ? "flex-start" : "center",
        marginTop: "10px"

      };

    return (
        <div className='buyWatchSummaryParent'>
            <p 
                className='text buyWatchFormHeader'

                >
                   Summary 
            </p>
            <div className='underLineDiv animatedUnderlineExpand'
            style={underlineStyle}
            > 
            </div>

            <div className='buyWatchFormStandardDiv buyWatchSummaryPaymentInfoDiv'
            style={{
                marginTop: "50px"
            }}
            >
                <div
                style={{
                    width: width > 570 ? "37%" : "100%",
                    marginTop: "0px"
                }}
                >
                <p className='text buyWatchSummarySmallHeader'>
                    PrimeTick Buyer Protection
                </p>
                </div>

                <div
                style={{
                    display: "flex",
                    flexDirection: "column"
                }}
                >
                    <div
                    style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginTop: width > 570 ? "0px" : "10px"
                    }}
                    >
                        <div
                        style={{
                            width: width > 570 ? "6%" : "10%"
                        }}
                        >
                        <HiCheck size={24} color='#4ad66d'/>
                        </div>
                        <p className='text buyWatchSummarySmallText'>
                            Payment via PrimeTick's Escrow Service
                        </p>
                    </div>

                    <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px"
                    }}
                    >
                        <div
                        style={{
                            width: width > 570 ? "6%" : "10%"
                        }}
                        >
                        <HiCheck size={24} color='#4ad66d'/>
                        </div>
                        <p className='text buyWatchSummarySmallText'>  
                            Authenticity Guarantee
                        </p>
                        
                    </div>

                    <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px"
                    }}
                    >
                        <div
                        style={{
                            width: width > 570 ? "6%" : "10%"
                        }}
                        >
                        <HiCheck size={24} color='#4ad66d'/>
                        </div>
                        <p className='text buyWatchSummarySmallText'>  
                            14-day money-back guarantee
                        </p>
                    </div>

                </div>
                
            </div>

            <div className='buyWatchFormStandardDiv buyWatchSummaryPaymentInfoDiv'
                style={{
                    height: "60px",
            }}  
            >
                <div
                    style={{
                        width: width > 570 ? "37%" : "100%",
                        marginTop: width > 570 ? "0px" : "50px"
                    }}
                >
                <p className='text buyWatchSummarySmallHeader'>
                    Available Payment Methods
                </p>
                </div>
                <div className='buyWatchSummaryWireTransferDiv'>
                    <FaMoneyBillTransfer size={26} color={alternateColorScheme.gold}/>
                    <p className='text buyWatchSummarySmallText'
                    style={{color: "white"}}
                    >  
                            Wire transfer 
                        </p>
                </div>

            </div>

            <div className='buyWatchFormStandardDiv buyWatchSummaryPaymentInfoDiv'
                    style={{
                    height: width > 570 ? "300px" : "428px",
                    flexDirection: width > 1200 ? "row" : width > 1080 ? "column" : width > 780 ? "row" : "column"
            }}  
            >
                <div
                    style={{
                        width: width > 1300 ? "37%" : width > 1080 ? "100%" : width > 570 ? "37%" : "100%"
                }}
                >
                <p className='text buyWatchSummarySmallHeader'
                    style={{
                        marginTop: width > 570 ? "0px" : "80px"
                    }}
                >
                    Offer Summary
                </p>
                </div>

                <div className='buyWatchSummaryOffer'>
                    <div className='buyWatchFormStandardDiv buyWatchSummaryOfferRow'>
                        <p className='text buyWatchSummarySmallText buyWatchSummarySmallHeaderThickness'
                            style={{
                                fontSize: "1.2rem",
                            }}
                        >  
                                Item price
                        </p>
                        <p className='text buyWatchSummarySmallText'
                            style={{
                                fontSize: "1.2rem"
                            }}
                        >  
                                $153,000

                        </p>
                    </div>

                    <div className='buyWatchFormStandardDiv buyWatchSummaryOfferRow'>
                        <p className='text buyWatchSummarySmallText buyWatchSummarySmallHeaderThickness'
                            style={{
                                fontSize: "1.2rem"
                            }}
                        >  
                                Shipping Cost
                        </p>
                        <p className='text buyWatchSummarySmallText'
                            style={{
                                fontSize: "1.2rem"
                            }}
                        >  
                                $100

                        </p>

                    </div>

                    <div 
                        className='underLineDiv'

                        style={{
                            width: "92%",
                            alignSelf: "center",
                            marginTop: "10px"
                        }}
                        >

                        </div>


                        <div className='buyWatchFormStandardDiv buyWatchSummaryOfferRow'
                        style={{
                            marginTop: "20px"
                        }}
                        >
                        <p className='text buyWatchSummarySmallText'
                            style={{
                                fontSize: "1.2rem",
                                fontWeight: 500,

                            }}
                        >  
                                Total price
                        </p>
                        <p className='text buyWatchSummarySmallText'
                            style={{
                                fontSize: "1.2rem",
                                fontWeight: 500,

                            }}
                        >  
                                $153,100

                        </p>

                    </div>
                    <div className='buyWatchFormStandardDiv buyWatchSummaryOfferRow'
                        style={{
                            flexDirection: width > 570 ? "row" : "column",
                            marginTop:  width > 570 ? "0px" : "10px"
                        }}
                        >
                        <p className='text buyWatchSummarySmallText'
                            style={{
                                fontSize: "1.2rem",
                                fontWeight: 500,

                            }}
                        >  
                                In the payment currency
                        </p>
                        <p className='text buyWatchSummarySmallText'
                            style={{
                                fontSize: "1.2rem",
                                fontWeight: 500,
                                marginTop: width > 570 ? "0px" : "2px"

                            }}
                        >  
                                ≈ SEK 1,668,790

                        </p>

                    </div>

                    <div className='buyWatchFormStandardDiv buyWatchSummaryOfferRow'>
                        <p className='text buyWatchSummarySmallText'
                            style={{
                                fontSize: "1.1rem",
                                marginTop: width > 570 ? "20px" : "40px",
                            }}
                        >  
                                Customs duties and import taxes may be incurred in addition to the price listed above.
                        </p>


                    </div>

                </div>

            </div>

            <div
            className='underLineDiv'
            style={{
                width: "98%",
                alignSelf: "center",
                marginTop: "30px"
            }}
            >

            </div>

            <div className='buyWatchFormStandardDiv'
                    style={{
                    flexDirection: "column",
                    marginTop: "30px",
                    height: width > 570 ? "200px" : "260px",
                    backgroundColor: "black",
                    borderRadius: "8px",
                    padding: "15px",
                    boxSizing: "border-box"
            }}  
            >

            <div className='buyWatchFormStandardDiv'
            
            style={{

                alignItems: "flex-start"
            }}>
            <div
            style={{
                width: width > 570 ? "5%" : "10%"
            }}
            >
            <input
                className='standardCheckBox'
                id='generalTerms'
                type="checkbox"
                checked={acceptedGeneralTerms}
                onChange={() => setAcceptedGeneralTerms(!acceptedGeneralTerms)}
            />
            </div>
            <label className='buyWatchSummarySmallText'
                htmlFor='generalTerms'
                style={{
                fontSize: "1.05rem",
                color: "white",
                cursor: "pointer",

                }}
            >
                I hereby acknowledge and accept the General Terms and Conditions of Sale for Trusted Checkout.
            </label>


            </div>

            <div className='buyWatchFormStandardDiv'
            
            style={{
                height: "30px",
                alignItems: "flex-start",
                marginTop: width > 570 ? "10px" : "20px"
            }}>
            <div
            style={{
                width: width > 570 ? "5%" : "10%"
            }}
            >
            <input
                className='standardCheckBox'
                id='dutiesAndTaxes'
                type="checkbox"
                checked={acceptedDutiesAndTaxes}
                onChange={() => setAcceptedDutiesAndTaxes(!acceptedDutiesAndTaxes)}
                required
            />
            </div>
            <label className='buyWatchSummarySmallText'
                htmlFor='dutiesAndTaxes'
                style={{
                fontSize: "1.05rem",
                color: "white",
                cursor: "pointer",
                }}
            >
                I am aware that this purchase may incur additional customs duties and import taxes.
            </label>


            </div>

            <div className='buyWatchFormStandardDiv'
            style={{
                height: "80px",
                marginTop: "40px",
                justifyContent: width > 570 ? "flex-end" : "center",
                alignItems: "flex-end"
            }}
            >
                <Link
                className='resetLinkStyle'
                to="/thanksForPurchase" // Relative path
                >
                <button className='buyWatchSummaryBuyButton'
                onMouseEnter={() => setBuyButtonIsHovered(true)}
                onMouseLeave={() => setBuyButtonIsHovered(false)}
                >
                    <p style={{
                        fontFamily: 'Roboto',
                        fontSize: "1.25rem",
                        fontWeight: 500,
                        color: buyButtonIsHovered ? "white" : "black"
                    }}>
                        Submit order
                    </p>
                </button>
                </Link>
            </div>


            </div>



        </div>
    );
}

export default BuyWatchSummary;