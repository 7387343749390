import React, { useState } from 'react';
import './FooterComponentStyles.css';
import { alternateColorScheme } from '../commonStyles';
import { Link } from 'react-router-dom';
import { BsTelephone } from 'react-icons/bs';
import { SiVisa, SiMastercard } from 'react-icons/si';
import { GrAmex } from 'react-icons/gr';
import { FaMoneyBillTransfer } from 'react-icons/fa6';
import { AiOutlineInstagram, AiFillInstagram, AiOutlineFacebook, AiFillFacebook, AiOutlineYoutube, AiFillYoutube } from 'react-icons/ai';
import { RiTwitterXLine } from 'react-icons/ri';
function FooterComponent({  }) {

    const [whichSocialLinkActive, setWhichSocialLinkActive] = useState('');

    return (
        <div className='footerComponentParent'>

            <div className='footerComponentFirstRow'>
                <div className='firstRowTwoItems'>
                <div className='footerComponentFirstRowItem'>
                    <p className='text footerHeader'>
                        Buy On PrimeTick
                    </p>
                    <Link
                    className='resetLinkStyle'
                    style={{
                        marginTop: "35px"
                    }}
                    >
                    <p className='text footerLinkText'>
                        PrimeTick Buyer Protection
                    </p>
                    </Link>
                    <Link
                    className='resetLinkStyle footerLinkStyle'
                    >
                    <p className='text footerLinkText'>
                        Payment via an escrow account
                    </p>
                    </Link>
                    <Link
                    className='resetLinkStyle footerLinkStyle'
                    >
                    <p className='text footerLinkText'>
                        Authenticity Guarantee
                    </p>
                    </Link>
                    <Link
                    className='resetLinkStyle footerLinkStyle'
                    >
                    <p className='text footerLinkText'>
                        Easy Returns
                    </p>
                    </Link>
                </div>
                <div className='footerComponentFirstRowItem'>
                <p className='text footerHeader'>
                        Sell On PrimeTick
                    </p>
                    <Link
                    className='resetLinkStyle'
                    style={{
                        marginTop: "35px"
                    }}
                    >
                    <p className='text footerLinkText'>
                        Selling Watches as a Private Seller
                    </p>
                    </Link>
                    <Link
                    className='resetLinkStyle footerLinkStyle'
                    >
                    <p className='text footerLinkText'>
                        Selling Watches Commercially
                    </p>
                    </Link>
                    <Link
                    className='resetLinkStyle footerLinkStyle'
                    >
                    <p className='text footerLinkText'>
                        Free Appraisal
                    </p>
                    </Link>
                    <Link
                    className='resetLinkStyle footerLinkStyle'
                    >
                    <p className='text footerLinkText'>
                        Advice for private sellers
                    </p>
                    </Link>
                </div>
                </div>
                <div className='firstRowTwoItems'>
                <div className='footerComponentFirstRowItem'>
                <p className='text footerHeader'>
                        About PrimeTick
                    </p>
                    <Link
                    className='resetLinkStyle'
                    style={{
                        marginTop: "35px"
                    }}
                    >
                    <p className='text footerLinkText'>
                        About us
                    </p>
                    </Link>
                    <Link
                    className='resetLinkStyle footerLinkStyle'
                    >
                    <p className='text footerLinkText'>
                        Jobs
                    </p>
                    </Link>
                    <Link
                    className='resetLinkStyle footerLinkStyle'
                    >
                    <p className='text footerLinkText'>
                        Press
                    </p>
                    </Link>
                    <Link
                    className='resetLinkStyle footerLinkStyle'
                    >
                    <p className='text footerLinkText'>
                        Legal Details
                    </p>
                    </Link>
                </div>
                <div className='footerComponentFirstRowItem'>
                <p className='text footerHeader'>
                     Personalized support
                    </p>
                    <Link
                    className='resetLinkStyle'
                    style={{
                        marginTop: "35px"
                    }}
                    >
                    <p className='text footerLinkText'>
                        Frequently Asked Questions
                    </p>
                    </Link>
                    <Link
                    className='resetLinkStyle footerLinkStyle'
                    >
                    <p className='text footerLinkText'>
                        Contact
                    </p>
                    </Link>
                    <Link
                    className='resetLinkStyle footerLinkStyle'
                    >
                    <p className='text footerLinkText'>
                    Accessibility
                    </p>
                    </Link>
                    <div 
                    className='footerTelephoneDiv'
                    >
                        <BsTelephone color='white' size={28}/>
                        <p className='text footerPhoneNumberStyles'
                        >
                        +46 5 434 325 12
                        </p>
                    </div>
                </div>
                </div>

            </div>

            <div className='footerComponentSecondRow'>
                <div className='secondRowPaymentMethodsDiv'>

                    <p className='text footerHeader'>
                        Payment Methods
                    </p>
                    <div className='standardFlexDiv paymentMethodsParent'
                    style={{
                        marginTop: "20px"
                    }}
                    >
                        <div className='twoPaymentThumbNailsParent'>
                        <div className='paymentCompanyThumbNail'>
                            <SiVisa size={36}/>
                            
                        </div>

                        <div className='paymentCompanyThumbNail'
                        style={{
                            marginLeft: "20px"
                        }}
                        >
                            <SiMastercard size={32}/>
                            
                        </div>
                        </div>
                        <div className='twoPaymentThumbNailsParent'>
                        
                        <div className='paymentCompanyThumbNail amexCardClass'
                        >
                            <GrAmex size={38}/>
                            
                        </div>

                        <div className='footerWireTransferDiv'>
                            <FaMoneyBillTransfer size={26} color={alternateColorScheme.gold4}/>
                            <p className='text'
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: "1rem",
                                fontWeight: 400,
                                
                            }}
                            >  
                                    Wire transfer 
                            </p>
                        </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className='footerComponentLastRow'>
            <div className='lastRowLinksDiv'>
                <div className='lastRow2LinkChunk'>
            <Link
                    className='resetLinkStyle footerLinkStyle'
                    >
                    <p className='text footerLinkText2'
                    >
                        Data Privacy Policy
                    </p>
                    </Link>
                    <Link
                    className='resetLinkStyle footerLinkStyle'
                    style={{
                        marginLeft: "20px"
                    }}
                    >
                    <p className='text footerLinkText2'>
                        Manage Cookies
                    </p>
                    </Link>
                    </div>
                    <div className='lastRow2LinkChunk'>
                    <Link
                    className='resetLinkStyle footerLinkStyle marginToTAndC'

                    >
                    <p className='text footerLinkText2'>
                    Terms & Conditions
                    </p>
                    </Link>
                    <Link
                    className='resetLinkStyle footerLinkStyle'
                    style={{
                        marginLeft: "20px"
                    }}
                    >
                    <p className='text footerLinkText2'>
                    Sitemap
                    </p>
                    </Link>
                    </div>
                    </div>
                    <div className='footerSocialMediaLinksDiv'>

                        <Link
                        onMouseEnter={() => setWhichSocialLinkActive('instagram')}
                        onMouseLeave={() => setWhichSocialLinkActive('')}

                        className='footerSocialMediaLink'
                        >
                            {whichSocialLinkActive === 'instagram' ? <AiFillInstagram size={38}/> : <AiOutlineInstagram  size={38}/>}
                        </Link>
                        <Link
                        onMouseEnter={() => setWhichSocialLinkActive('facebook')}
                        onMouseLeave={() => setWhichSocialLinkActive('')}
                        className='footerSocialMediaLink'
                        >
                            {whichSocialLinkActive === 'facebook' ? <AiFillFacebook size={36}/> : <AiOutlineFacebook  size={36}/>}
                        </Link>
                        <Link
                        onMouseEnter={() => setWhichSocialLinkActive('youtube')}
                        onMouseLeave={() => setWhichSocialLinkActive('')}
                        className='footerSocialMediaLink'
                        >
                            {whichSocialLinkActive === 'youtube' ? <AiFillYoutube size={38}/> : <AiOutlineYoutube  size={38}/>}
                        </Link>
                        <Link
                        className='footerSocialMediaLink'
                        >
                            <RiTwitterXLine size={32}/>
                        </Link>

                    </div>
                    </div>
            </div>

    );
}

export default FooterComponent;