import React, { useState } from 'react';
import './NewSearchBarStyles.css';
import { BiSearch } from 'react-icons/bi';
import { alternateColorScheme } from '../commonStyles';


const NewSearchBar = ( { onSearch, width } ) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [isHovered, setIsHovered] = useState(false); // New state variable

    const handleInputChange = (event) => {
      setSearchTerm(event.target.value);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      onSearch(searchTerm);
    };




    return (
      <form className="searchBarParentDiv"
      style={{width: width, borderRadius: "10px", marginTop: "10px", marginLeft: "4%"}}

      >
        <input
          className='newSearchBarInput'
            style={{
                width: "100%",
                height: "100%",
                boxSizing: 'border-box',
                borderRadius: "0px",
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
                fontSize: "1rem",
                border: 'none',
                color: 'white',
                backgroundColor: 'black',

            }}
            type="text"
            placeholder="Search Watchmodel or brand"
            value={searchTerm}
            onChange={handleInputChange}
        />
        <button type="submit" 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
                width: "50px",
                height: "100%",
                background: isHovered ?  alternateColorScheme.silver2 : '#000',
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px", 
                border: isHovered ? 'none' : '3px solid #000',
                cursor: 'pointer', // Changes the cursor to a hand when hovering over the button
                transition: 'all 0.3s ease',
            }}
        
        >
        <div className='searchBarButtonIconWrapper' style={{paddingLeft: isHovered ? "5px" : "0px"}}>
            <BiSearch color={isHovered ? 'black' : 'white'} size={isHovered ? 22 : 20}/>
        </div>        
    </button>

      </form>
    );
  }
  
  export default NewSearchBar;
  