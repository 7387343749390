import React from 'react';
import './SecurityPhotoComponentStyles.css';
import AddImagesComponent from './AddImagesComponent';
import ImageWithHoveringRemoveIcon from './ImageWithHoveringRemoveIcon';

const SecurityPhotoComponent = ( { securityImage, setSecurityImage, displayPhoto } ) => {

    const handleAddImage = (image) => {
            setSecurityImage(image)
        }

    const handleRemoveImage = () => {
        setSecurityImage(null);
    }

    return (
        <div className="securityPhotoComponentParentDiv">
            <img src={displayPhoto}
            className='securityComponentImageStyling'
            >
            </img>
            {securityImage &&
            <ImageWithHoveringRemoveIcon
            image={securityImage}
            handleRemoveImage={handleRemoveImage}

            />
            }
            {!securityImage && 
            <AddImagesComponent onAddImage={handleAddImage} marginTop={"0px"}
            text1={"Select files or Drag and drop"}
            text1Margin={"30px"}
            />

            }
        </div>


    );



}


export default SecurityPhotoComponent;