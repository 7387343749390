import React from 'react';
import './ExploreWatchesPageStyles.css';
import '../components/commonStyles.css';
import { alternateColorScheme } from '../commonStyles';
import PopularWatchBrands from '../components/PopularWatchBrands';

const ExploreWatches = () => {
    return (
        
        <div className='exploreWatchesParentDiv'>
            <p 
            className='header '
            style={{
                margin: "30px",
            }}
            >
                Welcome to PrimeTick</p>
            <PopularWatchBrands/>
        </div>
    );
}

export default ExploreWatches;