import React, { useState, useEffect } from 'react';
import './CustomDropDownStyles.css';

import DropDownWatchDetailsContent from './DropDownWatchDetailsContent';
import SimpleDynamicTextBox from './SimpleDynamicTextBox';
import { IoIosArrowDown } from 'react-icons/io';

const CustomDropdown = ({ header, children, width, height, marginLeft, arrowMarginLeft, marginTop }) => {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div 
      className='dropDownParentDiv'
      style={{
        marginLeft: marginLeft,
        marginTop: marginTop,
        width: width,
      }}
    >
      <div
        className={`dropdown ${isOpen ? 'dropdown-open' : 'dropdown-closed'}`}
        onClick={toggleDropdown}
      >
        <SimpleDynamicTextBox className={`${isOpen ? 'dropdownTextOpen' : 'dropdownTextClosed'}`} text={header} fontSize={"1.2rem"} fontWeight={500} marginLeft={"10px"} width={"300px"}/>
        <IoIosArrowDown className={`arrowIcon ${isOpen ? "rotateArrowIcon" : ""}`} size={"30px"} style={{marginLeft: arrowMarginLeft}}/>
      </div>

      <div
        className={`dropdownContent ${isOpen ? 'dropdownContentOpen' : 'dropdownContentClosed'}`}
        style={{ 
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          height: isOpen ? height : '0px',
          width: width,
          backgroundColor: '#003251',
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          alignItems: 'center',
        }}
      >
        {isOpen && children}
      </div>
    </div>
  );
};

export default CustomDropdown;
