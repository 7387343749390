import React, { useState, useEffect } from 'react';
import './DropDownWatchDetailsContentStyles.css'
import SimpleDynamicTextBox from './SimpleDynamicTextBox';
import SimpleTextInput from './SimpleTextInput';
import FilterDropDown from './FilterDropDown';
import CheckBox from './CheckBox';
import { GiWaterDivinerStick } from 'react-icons/gi';


const DropDownWatchDetailsContent = ( { categoryList, height, updateParentFormData, parentFormData } ) => {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    function printHi() {
        console.log("hi");
    }

    const watchDetailsDropDownContentParentDivStyles = {
        minHeight: height,
    }


    return (
    <div className={`watchDetailsDropDownContentParentDiv`} style={watchDetailsDropDownContentParentDivStyles}>
        {categoryList.map((category, index) => (
            <div className='dropDownInputRow' style={{
                height: width <= 570 && category.height
            }} key={index}>
                <div className='dropDownInputRowFirstItem'
                >
                <SimpleDynamicTextBox text={category.category} color={"white"} fontSize={width > 1160 || (width < 1033 && width > 828) ? "1.2rem" : width > 690 ? "1.1rem" : "0.95rem"} fontWeight={450}/>
                </div>
                {category.textInput && 
                <SimpleTextInput width={width > 1160 || (width < 1033 && width > 828) ?  "460px" : width > 570 ? "400px" : "96%"} height={"38px"} fontSize={"1rem"} 
                value={parentFormData[category.category]}
                onChange={(e) => updateParentFormData(category.category, e.target.value)}
                borderRadius={"6px"} paddingLeft={"10px"} border={"1px solid black"} />
                }
                {category.dropDownOptions && category.category != "Frequency" &&
                    <FilterDropDown options={category.dropDownOptions}           
                    defaultOption={parentFormData[category.category]}
                    marginLeft={"0px"} width={width > 1160 || (width < 1033 && width > 828) ? "460px" : width > 690 ? "400px" : width > 570 ? "370px" : "290px"} height={"39px"} 
                    menuHeight={category.dropdownHeight ? category.dropdownHeight : "20px"} borderRadius={"6px"}
                    handleChange={(selectedOption) => updateParentFormData(category.category, selectedOption)}
                    />
                    
                }
                {category.category == "Frequency" &&
                    <>
                    <div className='frequencyFlexDiv'>
                    <SimpleTextInput width={width > 1160 || (width < 1033 && width > 828) ? "335px" : width > 570 ? "275px" : "186px"} height={"38px"} fontSize={"1rem"} 
                     borderRadius={"6px"} paddingLeft={"10px"} value={parentFormData[category.category]}
                     onChange={(e) => updateParentFormData(category.category, e.target.value)}

                     />
                    <FilterDropDown options={category.dropDownOptions}  
                    defaultOption={parentFormData[category.category]}

                     marginLeft={width > 570 ? "30px" : "15px" } width={"90px"}
                     height={"39px"} borderRadius={"6px"} 
                     handleChange={(selectedOption) => updateParentFormData(category.category, selectedOption)}
                     />
                     </div>
                    </>
                }
                {category.checkboxes &&
                    <>
                    <div style={{display: 'flex', width: width > 570 ? '70%' : "95%", flexDirection: 'column', marginTop: category.marginTop ? category.marginTop : width > 570 ? "0px" : "20px"}}>

                        {category.checkboxes &&
                        // Create chunks of 2 items
                        
                        Array.from({ length: Math.ceil(category.checkboxes.length / 2) }, (_, i) => i).map(chunkIndex => (
                            (width > 570 ?
                            <div key={chunkIndex} style={{display: 'flex', width: '100%', marginTop: "9px"}}>
                            <div style={{width: '50%'}}>
                            <CheckBox 
                                isChecked={parentFormData[category.checkboxes[chunkIndex * 2].value]}
                                onCheckBoxChange={(text, isChecked) => updateParentFormData(text, isChecked)}
                                size={1.5} 
                                labelMarginLeft={"10px"} 
                                fontSize={width > 1160 || (width < 1033 && width > 828) ? "1.05rem" : "1rem"} 
                                fontWeight={400} 
                                color={"white"} 
                                text={category.checkboxes[chunkIndex * 2].value} 
                            />
                            </div>
                            { 
                                // Render the second checkbox in the chunk if it exists
                                category.checkboxes[chunkIndex * 2 + 1] && (
                                <div style={{width: '50%'}}>

                                <CheckBox 
                                    isChecked={parentFormData[category.checkboxes[chunkIndex * 2 + 1].value]}
                                    onCheckBoxChange={(text, isChecked) => updateParentFormData(text, isChecked)}
                                    size={1.5} 
                                    marginLeft={"0px"} 
                                    labelMarginLeft={"10px"} 
                                    fontSize={width > 1160 || (width < 1033 && width > 828) ? "1.05rem" : "1rem"} 
                                    fontWeight={400} 
                                    color={"white"} 
                                    text={category.checkboxes[chunkIndex * 2 + 1].value} 
                                />
                                </div>

                                )
                            }
                            </div>
                            :
                            <React.Fragment key={chunkIndex}>

                            <div style={{display: 'flex', width: "100%", height: "30px"}}
                            
                            >

                                <CheckBox 
                                isChecked={parentFormData[category.checkboxes[chunkIndex * 2].value]}
                                onCheckBoxChange={(text, isChecked) => updateParentFormData(text, isChecked)}
                                size={1.5} 
                                marginLeft={"0px"} 
                                labelMarginLeft={"10px"} 
                                fontSize={width > 1160 || (width < 1033 && width > 828) ? "1.05rem" : "1rem"} 
                                fontWeight={400} 
                                color={"white"} 
                                text={category.checkboxes[chunkIndex * 2].value} 
                                />
                            </div>
                            {category.checkboxes[chunkIndex * 2 + 1] && 
                                <div style={{display: 'flex', width: "100%", height: "30px"}}
                            
                                >
    
                                    <CheckBox 
                                    isChecked={parentFormData[category.checkboxes[chunkIndex * 2 + 1].value]}
                                    onCheckBoxChange={(text, isChecked) => updateParentFormData(text, isChecked)}
                                    size={1.5} 
                                    marginLeft={"0px"} 
                                    labelMarginLeft={"10px"} 
                                    fontSize={width > 1160 || (width < 1033 && width > 828) ? "1.05rem" : "1rem"} 
                                    fontWeight={400} 
                                    color={"white"} 
                                    text={category.checkboxes[chunkIndex * 2 + 1].value} 
                                    />
                                </div>  
                            }
                            </React.Fragment>

                        )
                        ))
                        
                        }

                    </div>
                    </>
                }
                
 
            </div>
           ))
            }
    </div>
    )

    };

export default DropDownWatchDetailsContent;
