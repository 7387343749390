import React, { useRef, useState, useEffect } from 'react';
import Slider from "react-slick";
import InnerImageZoom from 'react-inner-image-zoom';
import './WatchListingPage3Styles.css';
import listingImage1 from '../assets/fakeListingImages/watchListing1.png';
import listingImage2 from '../assets/fakeListingImages/watchListing2.png';
import listingImage3 from '../assets/fakeListingImages/watchListing3.png';
import listingImage4 from '../assets/fakeListingImages/watchListing1.png';
import listingImage5 from '../assets/fakeListingImages/watchListing2.png';
import listingImage6 from '../assets/fakeListingImages/watchListing3.png';
import listingImage7 from '../assets/fakeListingImages/watchListing1.png';
import listingImage8 from '../assets/fakeListingImages/watchListing2.png';
import listingImage9 from '../assets/fakeListingImages/watchListing3.png';
import listingImage10 from '../assets/fakeListingImages/watchListing1.png';
import listingImage11 from '../assets/fakeListingImages/watchListing2.png';
import listingImage12 from '../assets/fakeListingImages/watchListing3.png';
import SliderArrow from '../components/SliderArrow';
import WatchInfoComponent from '../components/WatchInfoComponent';
import DetailedWatchInfoComponent from '../components/DetailedWatchInfoComponent';
import DynamicTextBox from '../components/DynamicTextBox';
import MediumBlackButton from '../components/MediumBlackButton';
import SimpleButton from '../components/SimpleButton';
import LightBoldTextBox from '../components/LightBoldTextBox';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import SimpleDynamicTextBox from '../components/SimpleDynamicTextBox';



const WatchListingPage = () => {


    const listingPageSliderRef = useRef();
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    const handleImageClick = (index) => {
        setSelectedImage(index);
        listingPageSliderRef.current.slickGoTo(index);
    };

    const handleArrowClick = (direction) => {

        if (direction === 'left') {
            listingPageSliderRef.current.slickPrev(); // this changes the slide to the previous one
        } else if (direction === 'right') {
            listingPageSliderRef.current.slickNext(); // this changes the slide to the next one
        }
    };

    const WatchListingData = {
        brand: "Rolex",
        modelName: "Day-Date 36",
        dateAdded: new Date('2023-06-01'),
        popularity: 450,
        sellerComment: "Ref 118388 Men’s 18K Yellow Gold Black MOP Dia Dial, Bezel & Case 2006",
        price: 42995,
        shippingCost: "$99",
        sellerCountry: "US",
        sellerCity: "New York",
        images: [listingImage1, listingImage2, listingImage3, listingImage4, listingImage5, listingImage6, listingImage7, listingImage8, listingImage9, listingImage10, listingImage11, listingImage12],
        condition: "New (Unworn)",
        productionYear: 2012,
        box: "Original",
        papers: "Original",
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 150,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        afterChange: (current) => setSelectedImage(current) // updates selectedImage state whenever the slide changes

    };



    return (
        <div className='watchListingPage3ParentDiv'>
        <div className='watchListingPageImagesAndInfoParentDiv'>
            <div className='watchListingImagesSectionDiv'>
                <div className='watchListingPageImageSliderDiv'>
                    <SliderArrow className={"invisible"} direction={'left'} handleClick={() => handleArrowClick('left')} width={"77px"} 
                    height={"170px"} top={"42%"} arrowMargin={"5px"} paddingLeft={"21px"} borderRadius={"0px"} border={"2px solid black"} iconSize={"27px"}/>
                    <Slider ref={listingPageSliderRef}{...settings} style={{borderRadius: "10px"}}>
                        {WatchListingData.images.map((image, index) => (
                            <div key={index}>
                                <InnerImageZoom 
                                     src={image} 
                                     zoomSrc={image}
                                     zoomScale={1.8}
                                     alt={`WatchImage ${index + 1}`}
                                     className='watchListingSliderImageStyling'
                                />                            
                            </div>
                        ))}
                    </Slider>
                    <SliderArrow className={"invisible"} direction={'right'} handleClick={() => handleArrowClick('right')} width={"77px"} 
                    height={"170px"} top={"42%"} arrowMargin={"5px"} paddingLeft={"25px"} borderRadius={"0px"} border={"2px solid black"} iconSize={"25px"}/>
                <div className='watchListingPageMultipleImagesParentDiv'>
                {WatchListingData.images.map((image, index) => (
                    <img 
                    key={index}
                    className={`watchListingPageSmallImage ${selectedImage === index ? 'active' : ''}`} 
                    src={image}
                    onClick={() => handleImageClick(index)}
                    alt={`WatchImage ${index + 1}`} 
                    />
                ))}
                </div>

                </div>    
            </div>
            <div className='watchListingInfoSectionDiv3'>
                <WatchInfoComponent/>
            </div>
        </div>
            <div className='watchListingPageDetailedDescriptionParentDiv'>
                <div className='centerContainer' style={{marginTop: "0px"}}>
                <DetailedWatchInfoComponent/>
                </div>
            </div>
        </div>
    );
}

export default WatchListingPage;