import { colors, commonFont } from '../commonStyles'

const SmallInputField = ({width, type, placeholder1, value1, onChange1, placeholder2, value2, onChange2, marginTop, marginBottom}) => {

    const containerDivStyle = {
        display: 'flex',
        height: '40px',
        marginTop: marginTop,
        marginBottom: marginBottom,
        width: width > 520 ? '448px' : "352px",
    
    }

    const SmallInputFieldStyle = {
        marginTop: '6px',
        width: width > 520 ? '191px' : "143px",
        height: '32px',
        backgroundColor: colors.accentcolor3,
        border: '3px solid black',
        borderRadius: '2px',
        fontFamily: commonFont.fontFamily,
        fontWeight: '500',
        fontSize: '15px',
        paddingLeft: '10px',
    }
    // Applies to 2st input:
    const firstInputFieldStyle = {
        ...SmallInputFieldStyle,
        marginRight: width > 520 ? '30px' : "30px",
    }

    return (
        <div style={containerDivStyle}>    
        <label>
        Firstname:
        <input type={type} placeholder={placeholder1} value={value1} onChange={onChange1} style={firstInputFieldStyle}/>
        </label>
        <label>
        Lastname:
        <input type={type} placeholder={placeholder2} value={value2} onChange={onChange2} style={SmallInputFieldStyle}/>
        </label>
        </div>
    );

}
// Applies to 2nd input:




export default SmallInputField;