import React from 'react';
import './WatchListingSpecificationsDivStyles.css';
import SimpleDynamicTextBox from './SimpleDynamicTextBox';
import LightBoldTextBox from './LightBoldTextBox';
import { TbDeviceWatchStar, TbDeviceWatchCog } from 'react-icons/tb';
import { RiBox3Line } from 'react-icons/ri';
import { LiaFileContractSolid } from 'react-icons/lia';


const WatchListingSpecificationsDiv = ( {WatchListingData, width, height, marginTop, border, backgroundColor} ) => {

    const watchListingSpecificationsDivStyle = {
        width: width,
        height: height,
        marginTop: marginTop,
        border,
        backgroundColor: backgroundColor,
    }

    return (
        <div className='watchListingSpecificationsDiv' style={watchListingSpecificationsDivStyle}>
            <SimpleDynamicTextBox text={ 
            <>
                <TbDeviceWatchStar color='white' size={"1.3rem"}/>
                <LightBoldTextBox boldText={" Condition: "} boldTextFontWeight={"500"} lightText={WatchListingData.condition} lightTextFontWeight={"350"}/>
                </>
                } 
                color={"white"} fontSize="1.1rem" fontWeight="410" marginLeft="10px" marginTop="0px" maxWidth={"90%"}/>

            <SimpleDynamicTextBox text={ 
                <>
                <TbDeviceWatchCog color='white' size={"1.3rem"}/>
                <LightBoldTextBox boldText={" Production Year: "} boldTextFontWeight={"500"} lightText={WatchListingData.productionYear} lightTextFontWeight={"350"}/>
                </>
                } 
                color={"white"} fontSize="1.1rem" fontWeight="410" marginLeft="10px" marginTop="0px" maxWidth={"90%"}/>
            <SimpleDynamicTextBox text={ 
                <>
                <RiBox3Line color='white' size={"1.3rem"}/>
                <LightBoldTextBox boldText={" Box: "} boldTextFontWeight={"500"} lightText={WatchListingData.box} lightTextFontWeight={"350"}/>
                </>
                } 
                color={"white"} fontSize="1.1rem" fontWeight="410" marginLeft="10px" marginTop="0px" maxWidth={"90%"}/>
            <SimpleDynamicTextBox text={ 
                <>
                <LiaFileContractSolid color='white' size={"1.3rem"}/>
                <LightBoldTextBox boldText={" Papers: "} boldTextFontWeight={"500"} lightText={WatchListingData.papers} lightTextFontWeight={"350"}/>
                </>
                } 
                color={"white"} fontSize="1.1rem" fontWeight="410" marginLeft="10px" marginTop="0px" maxWidth={"90%"}/>
</div>


    );



}


export default WatchListingSpecificationsDiv;