import React, {useState, useRef} from 'react';
import './SmallWatchListingStyles.css';
import './commonStyles.css';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { commonFont, alternateColorScheme } from '../commonStyles';
import SliderArrow from './SliderArrow';
import CustomSliderArrow from './CustomSliderArrow';
import boughtWatchImage1 from '../assets/fakeListingImages/watchListing1.png';
import boughtWatchImage2 from '../assets/fakeListingImages/watchListing4.png';
import boughtWatchImage3 from '../assets/fakeListingImages/watchListing5.png';
import boughtWatchImage4 from '../assets/fakeListingImages/watchListing6.png';
import boughtWatchImage5 from '../assets/fakeListingImages/watchListing7.png';
import boughtWatchImage6 from '../assets/fakeListingImages/watchListing8.png';
import { FaInfo } from 'react-icons/fa6';
import { color, motion } from "framer-motion";
import { HiOutlineLocationMarker, HiLocationMarker } from 'react-icons/hi';


const SmallWatchListing = ( {watchListingInfo, images, to} ) => {

    const [showHoverArrows, setShowHoverArrows] = useState(false);
    const [showFullSellerComment, setShowFullSellerComment] = useState(false);
    const [showSellerLocation, setShowSellerLocation] = useState(false);


    const listingSliderRef = useRef();

    const handleArrowClick = (event, direction) => {
        event.preventDefault(); // Prevent the default anchor link behavior
        event.stopPropagation(); // Prevent the link navigation

        if (direction === 'left') {

            listingSliderRef.current.slickPrev(); // this changes the slide to the previous one
        } else if (direction === 'right') {
            listingSliderRef.current.slickNext(); // this changes the slide to the next one
        }
    };

    const handleMouseEnter = () => {
        setShowHoverArrows(true);
      };
    
      const handleMouseLeave = () => {
        setShowHoverArrows(false);
      };

    const settings = {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // set this to false to remove the default arrows

      };

      const ownImages= [boughtWatchImage1, boughtWatchImage2, boughtWatchImage3]

      const isSellerCommentLong = (sellerComment) => {
        
        return sellerComment.length >= 24;
    }

    const shortenSellerComment = (sellerComment) => {
        
        if (isSellerCommentLong(sellerComment)) {
            return sellerComment.substring(0, 24) + "...";
        } else {
            return sellerComment
        }
    }

    return (
        <Link 
        className='resetLinkStyle' 
        to={'/watchListing3'}
        >
        <div className="smallWatchListingParent"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
        >
        <CustomSliderArrow direction={"left"} onClick={(event) => handleArrowClick(event, 'left')}
                        showHoverArrows={showHoverArrows} 
                        top={"100px"}
                        sidePosition={"6px"} />
            <Slider ref={listingSliderRef} {...settings}>
            {(images ? images : ownImages).map((image, index) => (
                        <img
                        className='smallWatchListingImage'
                        src={image}
                        >
                        </img>
                    ))}
            </Slider>
            <CustomSliderArrow direction={"right"} onClick={(event) => handleArrowClick(event, 'right')}
                        showHoverArrows={showHoverArrows} 
                        top={"100px"}
                        sidePosition={"6px"} />
            <p 
            className='smallWatchListingHeader'
            style={{
                fontFamily: commonFont.fontFamily,
                }}>
                Rolex Day-Date 36
            </p> 
            <div
            className='smallWatchListingCommentDiv'
            >
            <p 
            className='smallWatchListingComment'
            style={{
                fontFamily: commonFont.fontFamily,
                }}>
                {shortenSellerComment(watchListingInfo.sellerComment)}

            </p> 
            {isSellerCommentLong(watchListingInfo.sellerComment) &&
                    <button 
                    className='showFullSellerCommentButton'
                    onMouseEnter={() => setShowFullSellerComment(true)} 
                    onMouseLeave={() => setShowFullSellerComment(false)}
                    >
                        <FaInfo size={10} color='white'/>
                        {showFullSellerComment &&
                    <motion.div className='sellerCommentPopupDiv'
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0, opacity: 0 }}
                    transition={{ duration: 0.1 }}
                    >
                    {watchListingInfo.sellerComment}
                    </motion.div>
                }
                    </button>
                }

            </div>
            <div
            className='smallWatchListingPriceAndLocationDiv'
            >
            <div className='smallWatchListingPriceAndShippingDiv'>
            <p 
            className='smallWatchListingPrice'
            style={{
                fontFamily: commonFont.fontFamily,
                }}>
                {`$${watchListingInfo.price}`}

            </p> 
            <p 
            className='smallWatchListingShipping'
            style={{
                fontFamily: commonFont.fontFamily,
                }}>
                {`+${watchListingInfo.shippingCost} Shipping`}

            </p> 
            </div>

            <button
            className='smallWatchListingLocationButton'
            onMouseEnter={() => setShowSellerLocation(true)} 
            onMouseLeave={() => setShowSellerLocation(false)}
            >
                {showSellerLocation ? <HiLocationMarker size={20}/> : <HiOutlineLocationMarker size={20}/>}
                    <p
                    style={{
                        fontFamily: commonFont.fontFamily,
                        fontSize: "0.6rem",
                        margin: "0px",
                        marginTop: "2px",
                        fontWeight: 500
                    }}
                    >
                    US
                </p> 
            </button>
            {showSellerLocation &&
                    <motion.div className='sellerLocationPopupDiv'
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0, opacity: 0 }}
                    transition={{ duration: 0.1 }}
                    >
                    {`This Seller Is From ${watchListingInfo.sellerCity}, ${watchListingInfo.sellerCountry}`}
                    </motion.div>
                }

            </div>
        </div>
        </Link>


    );



}


export default SmallWatchListing;