import React, {useState, useEffect} from 'react';
import './ProfileHomeSideBarStyles.css';

import IconLink from "../components/IconLink";
import ProfileHomeHamburger from './ProfileHomeHamburger';
import { PiWatch, PiWatchFill } from 'react-icons/pi';
import { AiOutlineCreditCard, AiFillCreditCard, AiOutlineStock, AiOutlineShop, AiFillShop } from 'react-icons/ai';
import { commonFont, alternateColorScheme } from '../commonStyles';

import { BiCog, BiSolidCog, BiConversation } from 'react-icons/bi';
import { MdOutlineLogout } from 'react-icons/md';


const ProfileHomeSideBar = ( {isActive, currentPathName} ) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    return (
        <div className="profileHomeSidebarDiv">

        <>
        <IconLink to="explore-primetick" className={`sidebar-link ${isActive('explore-primetick')}`} icon={<PiWatch size={'23px'} />} selectedIcon={<PiWatchFill size={'23px'} />} text={`Explore PrimeTick`} marginTop={'100px'} marginBottom={'35px'} leftPadding={'30px'}/>
        <IconLink to="seller-dashboard"  className={`sidebar-link ${isActive('seller-dashboard')}`} icon={<AiOutlineStock size={'23px'} />} selectedIcon={<AiOutlineStock size={'23px'} />} text={`Seller Dashboard`} marginTop={'35px'} marginBottom={'35px'} leftPadding={'30px'}/>
        <IconLink to="conversations" currentPathName={currentPathName} className={`sidebar-link ${isActive('conversations')}`} icon={<BiConversation size={'23px'} />} selectedIcon={<AiOutlineStock size={'23px'} />} text={`Conversations`} marginTop={'35px'} marginBottom={'35px'} leftPadding={'30px'}/>

        <IconLink to="payment" className={`sidebar-link ${isActive('payment')}`} icon={<AiOutlineCreditCard size={'23px'} />} selectedIcon={<AiFillCreditCard size={'23px'} />} text={`Payment`} marginTop={'35px'} marginBottom={'35px'} leftPadding={'30px'}/>
        <IconLink to="section-two" className={`sidebar-link ${isActive('section-two')}`} icon={<BiCog size={'23px'} />} selectedIcon={<BiSolidCog size={'23px'} />} text={`Profile Settings`} marginTop={'35px'} marginBottom={'35px'} leftPadding={'30px'}/>
        <IconLink to="sign-out" className={`sidebar-link ${isActive('sign-out')}`} icon={<MdOutlineLogout size={'23px'} />} selectedIcon={<MdOutlineLogout size={'23px'} />} text={`Sign out`} marginTop={'35px'} marginBottom={'35px'} leftPadding={'32px'} />
        </>
        

        
</div>


    );



}


export default ProfileHomeSideBar;