import React from 'react';
import WatchBrandPage from './WatchBrandPage';

const RolexPage = () => {
    return (
        <div>
            <WatchBrandPage brandName={"Rolex"}/>
        </div>
    );
}

export default RolexPage;