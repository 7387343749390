import React from 'react';
import './NumberOfImagesComponentStyles.css';
import SimpleDynamicTextBox from './SimpleDynamicTextBox';
import { AiTwotoneStar } from 'react-icons/ai';
import { BsStars } from 'react-icons/bs';

import { PiShootingStarDuotone, PiShootingStarBold } from 'react-icons/pi';

const NumberOfImagesComponent = ( {currentNumOfPics, width} ) => {

    return (
        <div className="numberOfImagesParentDiv">

            <SimpleDynamicTextBox text={"Pictures Added:"} 
                fontSize={width > 1300 ? "1.1rem" : "0.9rem"} 
                fontWeight={400} color={"white"} marginLeft={"0px"}
                marginTop={width > 1240 ? "20px" : "10px"}
            />

            <SimpleDynamicTextBox text={`${currentNumOfPics} / 10`} 
                fontSize={"1.4rem"} 
                fontWeight={400} color={"white"} marginLeft={"0px"}
                marginTop={"10px"}
            />

            {/*<AiTwotoneStar color='yellow' size={25} style={{marginTop: "10px"}}/>*/}
            {currentNumOfPics == 10 &&
            <BsStars color='yellow' size={22} style={{marginTop: "10px"}}/>
            }

        </div>


    );



}


export default NumberOfImagesComponent;