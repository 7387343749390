//Colours:
export const colors = {
    //primaryColor: '#86bbd8',
    //secondaryColor: '#ffea00',

    primarycolor: '#00296b',
    secondarycolor: '#ced4da',
    accentcolor1: '#60efff',
    accentcolor2: '#d00000',
    accentcolor3: '#cbf3f0',
    accentcolor4: '#00a6fb',
    white: '#fff',
    textcolor: '#000'
  };

export const alternateColorScheme = {
  blue: '#468dcc',
  blue2: '#3682c4',
  blue3: '#034277',
  purple: '#d580af',
  purple2: '#c45196',
  silver: '#faf9fb',
  silver2: '#ddddde',
  silver3: '#b3b2ae',
  silver4: '#d2d2d3',
  gold: '#f4e2ad',
  gold2: '#ebd39b',
  gold3: '#e6ac20',
  gold4: '#e5c384',

}




//Fonts:
export const commonFont = {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 300,
    fontSize: '15px',
  };

  export const latoFont = {
    fontFamily: 'Lato, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
  };

  export const playfairDisplayFont = {
    fontFamily: 'Playfair Display, serif',
    fontWeight: 400,
    fontSize: '20px',
  };

  export const montserratFont = {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 400,
    fontSize: '15px',
  };

  export const ralewayFont = {
    fontFamily: 'Raleway, sans-serif',
    fontWeight: 500,
    fontSize: '15px',
  };

  export const merriweatherFont = {
    fontFamily: 'Merriweather, serif',
    fontWeight: 400,
    fontSize: '16px',
  };

  export const oswaldFont = {
    fontFamily: 'Oswald, sans-serif',
    fontWeight: 400,
    fontSize: '18px',
  };