import { useState } from 'react';
import ReactDOM from 'react-dom';
import { alternateColorScheme } from '../../commonStyles';
import './ImageBubbleStyles.css';

  // Function to calculate the width
  



function ImageBubble({ image, initials, leftBubble }) {

    const [showOverlay, setShowOverlay] = useState(false);

    const imageOverlay = showOverlay && (
        <div className="ImageBubbleOverlay"
        onClick={() => setShowOverlay(false)}
        >
            <img src={image} alt="Popup Image" className="ImageBubbleOverlayImage" />
        </div>
        )


    return (
        <div className='ImageBubbleAndInitialsParent'
        style={{
            alignSelf: leftBubble ? "flex-start" : "flex-end",
        }}
        >
        {leftBubble &&
        <div className='profileInitialsDiv'>
            <p 
            style={{
                fontSize: "0.9rem",
                color: "white"
            }}
            >
                {initials}
            </p>
        </div>
        }
        <div className='ImageBubbleParent'
        style={{
            minWidth: "50px",

        }}
        >
        <img 
            src={image} 
            alt="Image Bubble" 
            className="ImageInBubble" 
            onClick={() => setShowOverlay(true)}

        />

        </div>

        {ReactDOM.createPortal(imageOverlay, document.getElementById('portal-root'))}

        </div>
    );
}

export default ImageBubble;