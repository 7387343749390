import React from 'react';
import './MediumBlackButtonStyles.css';

const Button = ({ text }) => {

    const handleClick = (event) => {
        console.log('Button clicked');
    };



    return (
        <button type="submit"className='mediumBlackButtonStyle' onClick={handleClick}>
            {text}
        </button>
    );
}



export default Button;