import React, { useState, useEffect } from 'react';
import './DateInputComponentStyles.css';
import FilterDropDown from './FilterDropDown';
import { commonFont } from '../commonStyles';

const DateInputComponent = ( {dayValue, monthValue, yearValue, onDayChange, onMonthChange, onYearChange,
header1, header2, header3, list1, list2, type} ) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


      const handleYearChange = (e) => {
        onYearChange(e.target.value);
    };

    return (
        <div className="birthInputComponentDiv">
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <p 
            className='personalInformationSmallText'
            style={{
                fontFamily: commonFont.fontFamily,
                marginTop: "0px",
                marginBottom: "2px"
                }}>
                {header1}
            </p> 
            <FilterDropDown options={list1} defaultOption={dayValue}
            placeHolder={dayValue}
            handleChange={(selectedOption) => onDayChange(selectedOption.value)}
            marginLeft={"0px"} 
            marginTop={"0px"}
            width={windowWidth > 640 ? "165px" : windowWidth > 500 ? "110px" : "75px"} height={"40px"} borderRadius={"6px"} border={"0px solid black"}/>
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <p 
            className='personalInformationSmallText'
            style={{
                fontFamily: commonFont.fontFamily,
                marginTop: "0px",
                marginBottom: "2px",
                marginLeft: "20px"
                }}>
                {header2}
            </p>
            <FilterDropDown options={list2} defaultOption={monthValue}
            placeHolder={monthValue}
            handleChange={(selectedOption) => onMonthChange(selectedOption.value)}
            marginLeft={"20px"} 
            marginTop={"0px"}
            width={windowWidth > 640 ? "190px" : windowWidth > 500 ? "145px" : "98px"} height={"40px"} borderRadius={"6px"} border={"0px solid black"}/>
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <p 
            className='personalInformationSmallText'
            style={{
                fontFamily: commonFont.fontFamily,
                marginTop: "0px",
                marginBottom: "2px",
                marginLeft: "20px"
                }}>
                {header3}
            </p>
            <input
            className='birthInputYearField'
            type='text'
            value={yearValue}
            onChange={handleYearChange}

            >
            </input>
            </div>

        </div>


    );



}


export default DateInputComponent;