import React, { useState, useEffect, useRef } from 'react';
import './BuyWatchActualFormStyles.css';
import '../commonStyles.css';
import { alternateColorScheme } from '../../commonStyles';
import FilterDropDown from '../FilterDropDown';

const countries = [
    { label: "Afghanistan", value: "Afghanistan" },
    { label: "Albania", value: "Albania" },
    { label: "Algeria", value: "Algeria" },
    { label: "Andorra", value: "Andorra" },
    { label: "Angola", value: "Angola" },
    { label: "Argentina", value: "Argentina" },
    { label: "Armenia", value: "Armenia" },
    { label: "Australia", value: "Australia" },
    { label: "Austria", value: "Austria" },
    { label: "Azerbaijan", value: "Azerbaijan" },
    { label: "Bahamas", value: "Bahamas" },
    { label: "Bahrain", value: "Bahrain" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Barbados", value: "Barbados" },
    { label: "Belarus", value: "Belarus" },
    { label: "Belgium", value: "Belgium" },
    { label: "Belize", value: "Belize" },
    { label: "Benin", value: "Benin" },
    { label: "Bhutan", value: "Bhutan" },
    { label: "Bolivia", value: "Bolivia" },
    { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
    { label: "Botswana", value: "Botswana" },
    { label: "Brazil", value: "Brazil" },
    { label: "Brunei", value: "Brunei" },
    { label: "Bulgaria", value: "Bulgaria" },
    { label: "Burkina Faso", value: "Burkina Faso" },
    { label: "Burundi", value: "Burundi" },
    { label: "Cambodia", value: "Cambodia" },
    { label: "Cameroon", value: "Cameroon" },
    { label: "Canada", value: "Canada" },
    { label: "Cape Verde", value: "Cape Verde" },
    { label: "Central African Republic", value: "Central African Republic" },
    { label: "Chad", value: "Chad" },
    { label: "Chile", value: "Chile" },
    { label: "China", value: "China" },
    { label: "Colombia", value: "Colombia" },
    { label: "Comoros", value: "Comoros" },
    { label: "Costa Rica", value: "Costa Rica" },
    { label: "Croatia", value: "Croatia" },
    { label: "Cuba", value: "Cuba" },
    { label: "Cyprus", value: "Cyprus" },
    { label: "Czech Republic", value: "Czech Republic" },
    { label: "Denmark", value: "Denmark" },
    { label: "Djibouti", value: "Djibouti" },
    { label: "Dominica", value: "Dominica" },
    { label: "Dominican Republic", value: "Dominican Republic" },
    { label: "Ecuador", value: "Ecuador" },
    { label: "Egypt", value: "Egypt" },
    { label: "El Salvador", value: "El Salvador" },
    { label: "Equatorial Guinea", value: "Equatorial Guinea" },
    { label: "Eritrea", value: "Eritrea" },
    { label: "Estonia", value: "Estonia" },
    { label: "Eswatini", value: "Eswatini" },
    { label: "Ethiopia", value: "Ethiopia" },
    { label: "Fiji", value: "Fiji" },
    { label: "Finland", value: "Finland" },
    { label: "France", value: "France" },
    { label: "Gabon", value: "Gabon" },
    { label: "Gambia", value: "Gambia" },
    { label: "Georgia", value: "Georgia" },
    { label: "Germany", value: "Germany" },
    { label: "Ghana", value: "Ghana" },
    { label: "Greece", value: "Greece" },
    { label: "Grenada", value: "Grenada" },
    { label: "Guatemala", value: "Guatemala" },
    { label: "Guinea", value: "Guinea" },
    { label: "Guinea-Bissau", value: "Guinea-Bissau" },
    { label: "Guyana", value: "Guyana" },
    { label: "Haiti", value: "Haiti" },
    { label: "Honduras", value: "Honduras" },
    { label: "Hungary", value: "Hungary" },
    { label: "Iceland", value: "Iceland" },
    { label: "India", value: "India" },
    { label: "Indonesia", value: "Indonesia" },
    { label: "Iran", value: "Iran" },
    { label: "Iraq", value: "Iraq" },
    { label: "Ireland", value: "Ireland" },
    { label: "Israel", value: "Israel" },
    { label: "Italy", value: "Italy" },
    { label: "Jamaica", value: "Jamaica" },
    { label: "Japan", value: "Japan" },
    { label: "Jordan", value: "Jordan" },
    { label: "Kazakhstan", value: "Kazakhstan" },
    { label: "Kenya", value: "Kenya" },
    { label: "Kiribati", value: "Kiribati" },
    { label: "Kuwait", value: "Kuwait" },
    { label: "Kyrgyzstan", value: "Kyrgyzstan" },
    { label: "Laos", value: "Laos" },
    { label: "Latvia", value: "Latvia" },
    { label: "Lebanon", value: "Lebanon" },
    { label: "Lesotho", value: "Lesotho" },
    { label: "Liberia", value: "Liberia" },
    { label: "Libya", value: "Libya" },
    { label: "Liechtenstein", value: "Liechtenstein" },
    { label: "Lithuania", value: "Lithuania" },
    { label: "Luxembourg", value: "Luxembourg" },
    { label: "Sweden", value: "Sweden" },
    { label: "United States of America", value: "United States of America" },

  ];

function BuyWatchActualForm({ type, sameshippingAddress, setSameshippingAddress }) {

    const [sectionIsFocused, setSectionIsFocused] = useState(false);
    // Necessary because the underlines retraction animation is unwanted when page renders.
    const [hasBeenFocused, setHasBeenFocused] = useState(false);


    const handleRadioButtonChange = (event) => {
        const value = event.target.value === 'true'; // Convert the string back to a boolean
        setSameshippingAddress(value);
      };

    // State to control bg-color animation for the input fields:
    const [whichInputIsFocused, setWhichInputIsFocused] = useState('');
    console.log(whichInputIsFocused);
    const updateFocusedInput = (inputIdentifier) => {
        setWhichInputIsFocused(inputIdentifier);
      };

    useEffect(() => {
        if (sectionIsFocused) {
            setHasBeenFocused(true);
        }
    }, [sectionIsFocused]);

    const divRef2 = useRef(null);

    let animationClass = '';
    if (sectionIsFocused) {
        animationClass = 'animatedUnderlineExpand';
    } else if (hasBeenFocused) {
        animationClass = 'animatedUnderlineContract';
    }

    const underlineStyle = {
        '--underline-width': `300px`,
        backgroundColor: sectionIsFocused ? 
        "white"
        :
        "black"

      };


      const [windowWidth, setWindowWidth] = useState(window.innerWidth);

      useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup function to remove the event listener
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    const handleClickOutside = (event) => {
        if (divRef2.current && !divRef2.current.contains(event.target)) {
            setSectionIsFocused(false); // Set state to false when clicked outside of div
        }
      };
    
      const handleClick = () => {
        setSectionIsFocused(true); // Set state to true when div is clicked
      };

      useEffect(() => {
        // Attach the event listener to the document
        document.addEventListener('mousedown', handleClickOutside);
    
        // Cleanup the event listener
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);


    const [personalInformation, setPersonalInformation] = useState({
        name: "Filip Larsson",
        address: "Hälsingegatan 51",
        country: "Sweden",
        postalCode: "11363",
        phoneNumber: "070-680 99 21",
    });

    const updateInformation = (field, value) => {
        setPersonalInformation({
            ...personalInformation,
            [field]: value
        });
    };


    return (


        <fieldset className='buyWatchFormSection2'
        ref={divRef2}
        tabIndex={0} // Makes the div focusable
        onClick={handleClick}
        style={{
        height: type !== "shipping" ? windowWidth > 570 ? "655px" : "720px" : (sameshippingAddress ? "170px" : windowWidth > 570 ? "800px" : "880px"),
        marginTop: "30px",
        backgroundColor: sectionIsFocused ? 
        alternateColorScheme.blue2
        :
        "white",
        }} 

        >

            <div className='buyWatchFormSectionAnimatedDiv'

            >
            <div className='buyWatchFormStandardDiv'

            >
            <legend 
                className='text buyWatchFormHeader'
                style={{
                    color: sectionIsFocused ? 
                    "white"
                    :
                    "black"
                }}
                >
                    {type === "shipping" ? 
                    "Shipping Address"
                    :
                    "Billing Address"
                    }
                </legend>
            </div>

            <div 
            className={`underLineDiv ${animationClass}`} 
            style={underlineStyle}
            >

            </div>

            {type === "shipping" &&
                <div className='shippingRadioButtonsParent'>
                    <div className='buyWatchFormStandardDiv'>
                        <input
                        type="radio"
                        id='sameShipping'
                        value="true"
                        checked={sameshippingAddress === true}
                        onChange={handleRadioButtonChange}
                        />
                          <label className='formShippingRadioText'
                          htmlFor='sameShipping'
                          style={{
                            color: sameshippingAddress
                              ? (sectionIsFocused ? "white" : alternateColorScheme.blue2)
                              : "black"
                          }}
                          >
                        Same shipping address

                        </label>
                    </div> 
                    <div className='buyWatchFormStandardDiv'>
                        <input
                        type="radio"
                        id='diffShipping'
                        value="false"
                        checked={sameshippingAddress === false}
                        onChange={handleRadioButtonChange}
                        />
                          <label className='formShippingRadioText'
                          htmlFor='diffShipping'
                          style={{
                            color: !sameshippingAddress
                              ? (sectionIsFocused ? "white" : alternateColorScheme.blue2)
                              : "black"
                          }}
                          >
                        Different shipping address

                        </label>
                    </div> 
                </div>
            }
            
            {type === "shipping" && !sameshippingAddress && 

            <div className='buyWatchFormInputDiv'
            style={{
                marginTop: "20px"
            }}
            >

                <div className='buywatchFormLabelDiv'>
                <label 
                htmlFor='company' 
                className='buyWatchFormInputLabel'
                style={{
                    color: whichInputIsFocused === "company" ? 
                    "white"
                    :
                    "black"
                    }}
                >
                    Company:
                </label>
                </div>
                <input 
                className='buyWatchFormTextInput' 
                type="text" 
                id="company" 
                name="company"
                onFocus={() => updateFocusedInput('company')}
                onBlur={() => updateFocusedInput('')}
                />

            </div>
            }
            
            {(!sameshippingAddress || type !== "shipping") &&
            <>
            <div className='buyWatchFormInputDiv'
            style={{
                marginTop: "20px"
            }}
            >
                <div className='buywatchFormLabelDiv'>
                <label 
                htmlFor={`firstName${type}`}
                className='buyWatchFormInputLabel'
                style={{
                    color: whichInputIsFocused === "firstName" ? 
                    "white"
                    :
                    "black"
                    }}
                >
                    First name:
                </label>
                </div>
                <input 
                className='buyWatchFormTextInput' 
                type="text" 
                id={`firstName${type}`} 
                name={`firstName${type}`}
                onFocus={() => updateFocusedInput('firstName')}
                onBlur={() => updateFocusedInput('')}
                />

            </div>
            <div className='buyWatchFormInputDiv'
            style={{
                marginTop: "20px"
            }}
            >   
                <div className='buywatchFormLabelDiv'>
                <label 
                htmlFor={`lastName${type}`}
                className='buyWatchFormInputLabel'
                style={{
                    color: whichInputIsFocused === "lastName" ? 
                    "white"
                    :
                    "black"
                    }}
                >
                    Last name:
                </label>
                </div>
                <input 
                className='buyWatchFormTextInput' 
                type="text" 
                id={`lastName${type}`}
                name={`lastName${type}`}
                onFocus={() => updateFocusedInput('lastName')}
                onBlur={() => updateFocusedInput('')}
                />

            </div>

            <div className='buyWatchFormInputDiv'
            style={{
                marginTop: "20px"
            }}
            >   
                <div className='buywatchFormLabelDiv'>
                <label 
                htmlFor={`country${type}`} 
                className='buyWatchFormInputLabel'
                style={{
                    marginLeft: windowWidth > 570 ? "5px" : "0px",
                    marginBottom: windowWidth > 570 ? "0px" : "4px",
                    color: whichInputIsFocused === "country" ? 
                    "white"
                    :
                    "black"
                    }}
                >
                    Country:
                </label>
                </div>

                <FilterDropDown options={countries} defaultOption={personalInformation.country}
                placeHolder={personalInformation.country}
                className="buywatchFormDropdown"
                onFocus={() => updateFocusedInput('country')}
                onBlur={() => updateFocusedInput('')}
                handleChange={(selectedOption) => updateInformation('country', selectedOption)}  
                marginLeft={windowWidth > 570 ? "27px" : "0px"} 
                marginTop={"0px"}
                width={windowWidth > 1410 ? "626px" : windowWidth > 1300 ? "566px" : windowWidth > 1200 ? "502px" : windowWidth > 1080 ? "429px" : windowWidth > 960 ? "626px" : windowWidth > 870 ? "566px" : windowWidth > 780 ? "502px" : windowWidth > 700 ? "429px" : windowWidth > 570 ? "333px" : "305px"} height={"46px"} borderRadius={"6px"} border={"1px solid #ced4da"}/>

            </div>

            <div className='buyWatchFormInputDiv'
            style={{
                marginTop: "20px"
            }}
            >   
                <div className='buywatchFormLabelDiv'>
                <label 
                htmlFor={`phoneNumber${type}`} 
                className='buyWatchFormInputLabel'
                style={{
                    color: whichInputIsFocused === "phoneNumber" ? 
                    "white"
                    :
                    "black"
                    }}
                >
                    Phone number:
                </label>
                </div>
                <input className='buyWatchFormTextInput' 
                type="tel" 
                id={`phoneNumber${type}`} 
                name={`phoneNumber${type}`} 
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
                onFocus={() => updateFocusedInput('phoneNumber')}
                onBlur={() => updateFocusedInput('')}
                />

            </div>

            <div className='buyWatchFormInputDiv'
            style={{
                marginTop: "20px"
            }}
            >   
                <div className='buywatchFormLabelDiv'>
                <label 
                htmlFor={`streetLine1${type}`} 
                className='buyWatchFormInputLabel'
                style={{
                    color: whichInputIsFocused === "streetLine1" ? 
                    "white"
                    :
                    "black"
                    }}
                >
                    Street:
                </label>
                </div>
                <input 
                className='buyWatchFormTextInput' 
                type="text" 
                id={`streetLine1${type}`} 
                name={`streetLine1${type}`} 
                onFocus={() => updateFocusedInput('streetLine1')}
                onBlur={() => updateFocusedInput('')}
                />

            </div>

            <div className='buyWatchFormInputDiv'
            style={{
                marginTop: "20px"
            }}
            >   
                <div className='buywatchFormLabelDiv'>
                <label 
                htmlFor={`streetLine2${type}`} 
                className='buyWatchFormInputLabel'
                style={{
                    color: whichInputIsFocused === "streetLine2" ? 
                    "white"
                    :
                    "black"
                    }}
                >
                    Street line 2:
                </label>
                </div>
                <input 
                className='buyWatchFormTextInput' 
                type="text" 
                id={`streetLine2${type}`} 
                name={`streetLine2${type}`} 
                onFocus={() => updateFocusedInput('streetLine2')}
                onBlur={() => updateFocusedInput('')}
                />

            </div>

            <div className='buyWatchFormInputDiv'
            style={{
                marginTop: "20px"
            }}
            >   
                <div className='buywatchFormLabelDiv'>
                <label 
                htmlFor={`zipCode${type}`} 
                className='buyWatchFormInputLabel'
                style={{
                    color: whichInputIsFocused === "zipCode" ? 
                    "white"
                    :
                    "black"
                    }}
                >
                    Zip code, 
                </label>
                <label 
                htmlFor={`city${type}`} 
                className='buyWatchFormInputLabel'
                style={{
                    marginLeft: "5px",
                    color: whichInputIsFocused === "city" ? 
                    "white"
                    :
                    "black"
                    }}
                >
                     City:
                </label>
                </div>
                <div style={{
                    display: "flex",
                    width: "78%",
                    marginLeft: windowWidth > 570 ? "25px" : "0px",
                    boxSizing: "border-box"
                }}>
                <input 
                style={{
                    width: windowWidth > 570 ? "35%" : "100px",
                    marginLeft: "0px"
                }}
                className='buyWatchFormTextInput' 
                type="text" 
                id={`zipCode${type}`} 
                name={`zipCode${type}`} 
                required
                pattern="\d{5}(-\d{4})?"
                onFocus={() => updateFocusedInput('zipCode')}
                onBlur={() => updateFocusedInput('')}
                />

                <input 
                style={{
                    width: windowWidth > 570 ? "60%" : "163px",
                    marginLeft: windowWidth > 570 ? "5%" : "20px"
                }}
                className='buyWatchFormTextInput' 
                type="text"
                id={`city${type}`} 
                name={`city${type}`}  
                required
                onFocus={() => updateFocusedInput('city')}
                onBlur={() => updateFocusedInput('')}
                /> 
            </div>

            </div>

            </>
            }
            </div>

        </fieldset>

    );
}

export default BuyWatchActualForm;