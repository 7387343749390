import React, { useRef, useState } from 'react';
import Slider from "react-slick";
import './WatchListingStyles.css';
import SliderArrow from './SliderArrow';
import DynamicTextBox from './DynamicTextBox';
import { color, motion } from "framer-motion";
import { Link } from 'react-router-dom';
import { HiOutlineLocationMarker, HiLocationMarker } from 'react-icons/hi';

const WatchListing = ( { watchListingInfo, images, to } ) => {


    const [hoveringSellerComment, setHoveringSellerComment] = useState(false);
    const [hoveringLocation, setHoveringLocation] = useState(false);

    const listingSliderRef = useRef();

    const handleArrowClick = (direction) => {

        if (direction === 'left') {
            listingSliderRef.current.slickPrev(); // this changes the slide to the previous one
        } else if (direction === 'right') {
            listingSliderRef.current.slickNext(); // this changes the slide to the next one
        }
    };

    const isSellerCommentLong = (sellerComment) => {
        return sellerComment.length >= 33;
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // set this to false to remove the default arrows

      };



    return (
        <>
        <div className='watchListing'>
        <SliderArrow direction={'left'} handleClick={() => handleArrowClick('left')} width={"40px"} 
            height={"260px"} arrowMargin={"25px"} paddingLeft={"3px"} borderRadius={"10px"} additionalStyles={{backgroundColor: '#ced4da', top: '38%'}} disableHover={true} invisible={true}/>
        <Link to={to} className='listingLinkStyle'>

            <div  className='imageSliderDiv'>


            <Slider ref={listingSliderRef} {...settings}>
                {images.map((image, index) => (
                        <img className='listingImageStyling' key={index} src={image} alt="watch" />
                ))}
            </Slider>

            </div>
            <div className='listingInfoDiv'>
            <DynamicTextBox text={`${watchListingInfo.brand} ${watchListingInfo.modelName}`} color="black" fontSize="1rem" fontWeight="500" marginLeft="6px" marginTop="2px"  />
            <div className='sellerCommentDiv'>
            <DynamicTextBox text={`${watchListingInfo.sellerComment}`} color="black" fontSize="0.9rem" fontWeight="400"  marginLeft="3px" marginTop="0px" paddingLeft={'5px'} paddingTop={'4px'} divHeight={"25px"}maxWidth={'90%'} borderRadius={'5px'} textOverflow={true}
            />
              {isSellerCommentLong(watchListingInfo.sellerComment) && <button className='listingInfoButton'
                    onMouseEnter={() => setHoveringSellerComment(true)} 
                    onMouseLeave={() => setHoveringSellerComment(false)}
                >
                    i
                </button>}
                {hoveringSellerComment &&
                    <motion.div className='sellerInfoHoverDiv'
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0, opacity: 0 }}
                    transition={{ duration: 0.1 }}
                    >
                    {watchListingInfo.sellerComment}
                    </motion.div>}
            </div>
            <div className='sellerPriceAndLocationDiv'>
            <div className='sellerPriceInfoDiv'>
            <DynamicTextBox text={`$${watchListingInfo.price}`} color="black" fontSize="1rem" fontWeight="400" marginLeft="6px" marginTop="2px"  />
            <DynamicTextBox text={`+${watchListingInfo.shippingCost} Shipping`} color="gray" fontSize="0.8rem" fontWeight="400" marginLeft="6px" marginTop="2px"  />
            </div>
            <div className='sellerLocationDiv'>
                <div className='sellerLocationIconDiv'
                onMouseEnter={() => setHoveringLocation(true)} 
                onMouseLeave={() => setHoveringLocation(false)}
                >
                {hoveringLocation ? <HiLocationMarker color='black'/> : <HiOutlineLocationMarker color='black'/>}
                {hoveringLocation &&
                    <motion.div className='locationInfoHoverDiv'
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0, opacity: 0 }}
                    transition={{ duration: 0.1 }}
                    >
                    {`This Seller Is From ${watchListingInfo.sellerCity}, ${watchListingInfo.sellerCountry}.`}
                    </motion.div>}
                </div>
                
            <DynamicTextBox text={watchListingInfo.sellerCountry} color="black" fontSize="0.7rem" fontWeight="500" marginTop="0px"  />

            </div>
            </div>
            </div>
        </Link>

        <SliderArrow direction={'right'} handleClick={() => handleArrowClick('right')} width={"40px"} 
            height={"260px"} arrowMargin={"25px"} paddingLeft={"20px"} borderRadius={"10px"} additionalStyles={{backgroundColor: '#ced4da', top: '38%'}} disableHover={true} invisible={true}/>
        </div>

        </>
    );



}


export default WatchListing;