import React, { useState, useEffect } from 'react';
import './SellerDashBoardSectionStyles.css';
import AnalyticsKeyFiguresOverview from '../AnalyticsKeyFiguresOverview';
import ListingsOverviewBox from '../ListingsOverviewBox';

const SellerDashBoardSection = ( {} ) => {

    const [active, setActive] = useState(false);

    useEffect(() => {
      // Activate the animation when the component mounts
      setActive(true);
    }, []);

    return (
        <div className={`sellerDashboardParentDiv slide-up-fade-in ${active ? 'active' : ''}`}>

            <AnalyticsKeyFiguresOverview/>

            <ListingsOverviewBox/>

        </div>


    );



}


export default SellerDashBoardSection;