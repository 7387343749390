import React from 'react';
import './TodaysNumbersSellerDashboardStyles.css';

function TodaysNumbersSellerDashboard({  }) {


    return (
        <div className='todaysNumbersParent'>
            <div className='todaysNumbersStat'>
                <p className='todaysNumbersStatHeader'>
                    Sales:
                </p>
                <p className='todaysNumbersStatText'>
                    $ 23,144.00
                </p>
            </div>
            <div className='todaysNumbersStat'>
                <p className='todaysNumbersStatHeader'>
                    Listing Visitors:
                </p>
                <p className='todaysNumbersStatText'>
                    82
                </p>
            </div>
        </div>
    );
}

export default TodaysNumbersSellerDashboard;