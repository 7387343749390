import { useState } from 'react';
import ReactDOM from 'react-dom';
import { RxCross2 } from 'react-icons/rx';
import FilterDropDown from '../FilterDropDown';
import './AddPaymentCardComponentStyles.css';

function AddPaymentCardComponent({ handleExitButtonClick, handleNewCard, setAddPaymentCard }) {

    const portalRoot = document.getElementById('portal-root');

    const [exitButtonIsHovered, setExitButtonIsHovered] = useState(false);

    const [newCardPaymentInformation, setNewCardPaymentInformation] = useState({
        cardHeader: "Name Your Card",
        cardName: "",
        cardNumber: "",
        cardIssueMonth: "",
        cardIssueYear: "",
        cvv: "",
        address: "",
        city: "",
        country: "",
        postalCode: "",
    });

    const updateNewCardInformation = (field, value) => {
        setNewCardPaymentInformation({
            ...newCardPaymentInformation,
            [field]: value
        });
    };

    const [isHeaderFocused, setIsHeaderFocused] = useState(false); // new state for focus

    const [isCardNameInitialised, setIsCardNameInitialised] = useState(false);

    const [isCardNumberInitialised, setIsCardNumberInitialised] = useState(false);

    const [isCardCvvInitialised, setIsCardCvvInitialised] = useState(false);

    const [isCardAddressInitialised, setIsCardAddressInitialised] = useState(false);

    const [isCardCityInitialised, setIsCardCityInitialised] = useState(false);

    const [isCardPostalCodeInitialised, setIsCardPostalCodeInitialised] = useState(false);

    
    const countries = [
        { label: "Afghanistan", value: "Afghanistan" },
        { label: "Albania", value: "Albania" },
        { label: "Algeria", value: "Algeria" },
        { label: "Andorra", value: "Andorra" },
        { label: "Angola", value: "Angola" },
        { label: "Argentina", value: "Argentina" },
        { label: "Armenia", value: "Armenia" },
        { label: "Australia", value: "Australia" },
        { label: "Austria", value: "Austria" },
        { label: "Azerbaijan", value: "Azerbaijan" },
        { label: "Bahamas", value: "Bahamas" },
        { label: "Bahrain", value: "Bahrain" },
        { label: "Bangladesh", value: "Bangladesh" },
        { label: "Barbados", value: "Barbados" },
        { label: "Belarus", value: "Belarus" },
        { label: "Belgium", value: "Belgium" },
        { label: "Belize", value: "Belize" },
        { label: "Benin", value: "Benin" },
        { label: "Bhutan", value: "Bhutan" },
        { label: "Bolivia", value: "Bolivia" },
        { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
        { label: "Botswana", value: "Botswana" },
        { label: "Brazil", value: "Brazil" },
        { label: "Brunei", value: "Brunei" },
        { label: "Bulgaria", value: "Bulgaria" },
        { label: "Burkina Faso", value: "Burkina Faso" },
        { label: "Burundi", value: "Burundi" },
        { label: "Cambodia", value: "Cambodia" },
        { label: "Cameroon", value: "Cameroon" },
        { label: "Canada", value: "Canada" },
        { label: "Cape Verde", value: "Cape Verde" },
        { label: "Central African Republic", value: "Central African Republic" },
        { label: "Chad", value: "Chad" },
        { label: "Chile", value: "Chile" },
        { label: "China", value: "China" },
        { label: "Colombia", value: "Colombia" },
        { label: "Comoros", value: "Comoros" },
        { label: "Costa Rica", value: "Costa Rica" },
        { label: "Croatia", value: "Croatia" },
        { label: "Cuba", value: "Cuba" },
        { label: "Cyprus", value: "Cyprus" },
        { label: "Czech Republic", value: "Czech Republic" },
        { label: "Denmark", value: "Denmark" },
        { label: "Djibouti", value: "Djibouti" },
        { label: "Dominica", value: "Dominica" },
        { label: "Dominican Republic", value: "Dominican Republic" },
        { label: "Ecuador", value: "Ecuador" },
        { label: "Egypt", value: "Egypt" },
        { label: "El Salvador", value: "El Salvador" },
        { label: "Equatorial Guinea", value: "Equatorial Guinea" },
        { label: "Eritrea", value: "Eritrea" },
        { label: "Estonia", value: "Estonia" },
        { label: "Eswatini", value: "Eswatini" },
        { label: "Ethiopia", value: "Ethiopia" },
        { label: "Fiji", value: "Fiji" },
        { label: "Finland", value: "Finland" },
        { label: "France", value: "France" },
        { label: "Gabon", value: "Gabon" },
        { label: "Gambia", value: "Gambia" },
        { label: "Georgia", value: "Georgia" },
        { label: "Germany", value: "Germany" },
        { label: "Ghana", value: "Ghana" },
        { label: "Greece", value: "Greece" },
        { label: "Grenada", value: "Grenada" },
        { label: "Guatemala", value: "Guatemala" },
        { label: "Guinea", value: "Guinea" },
        { label: "Guinea-Bissau", value: "Guinea-Bissau" },
        { label: "Guyana", value: "Guyana" },
        { label: "Haiti", value: "Haiti" },
        { label: "Honduras", value: "Honduras" },
        { label: "Hungary", value: "Hungary" },
        { label: "Iceland", value: "Iceland" },
        { label: "India", value: "India" },
        { label: "Indonesia", value: "Indonesia" },
        { label: "Iran", value: "Iran" },
        { label: "Iraq", value: "Iraq" },
        { label: "Ireland", value: "Ireland" },
        { label: "Israel", value: "Israel" },
        { label: "Italy", value: "Italy" },
        { label: "Jamaica", value: "Jamaica" },
        { label: "Japan", value: "Japan" },
        { label: "Jordan", value: "Jordan" },
        { label: "Kazakhstan", value: "Kazakhstan" },
        { label: "Kenya", value: "Kenya" },
        { label: "Kiribati", value: "Kiribati" },
        { label: "Kuwait", value: "Kuwait" },
        { label: "Kyrgyzstan", value: "Kyrgyzstan" },
        { label: "Laos", value: "Laos" },
        { label: "Latvia", value: "Latvia" },
        { label: "Lebanon", value: "Lebanon" },
        { label: "Lesotho", value: "Lesotho" },
        { label: "Liberia", value: "Liberia" },
        { label: "Libya", value: "Libya" },
        { label: "Liechtenstein", value: "Liechtenstein" },
        { label: "Lithuania", value: "Lithuania" },
        { label: "Luxembourg", value: "Luxembourg" },
        { label: "Sweden", value: "Sweden" },
        { label: "United States of America", value: "United States of America" },

      ];

    const updatecvv = (value) => {
        const isNumeric = /^\d+$/.test(value);
        if (value.length < 4 && (isNumeric || value === "")) {
            updateNewCardInformation('cvv', value);
        }
    };

    const updatePostalCode = (value) => {
        const isNumeric = /^\d+$/.test(value);
        if (value.length < 6 && (isNumeric || value === "")) {
            updateNewCardInformation('postalCode', value);
        }
    };

    const cardIssueMonths = Array.from({ length: 12 }, (_, index) => ({
        value: index + 1, // Values should start from 1
        label: index + 1  // Labels should start from 1
    }));

    const cardIssueYears = Array.from({ length: 21 }, (_, index) => {
        const year = 2023 + index;
        return { value: year, label: year };
    });

    const handleCardNumberChange = (value) => {
        const isCardNumber = /^[\d\s]+$/.test(value);

        let formattedValue = value.replace(/\s+/g, ""); // Remove all spaces
        if (formattedValue.length > 0) {
          formattedValue = formattedValue.match(/.{1,4}/g).join(" "); // Insert a space after every 4 characters
        }
        if ((isCardNumber || value === "") && value.length < 20) {
            updateNewCardInformation('cardNumber', formattedValue);
        }
      };

    return ReactDOM.createPortal(
        <div className='addPaymentCardParent'>

            <div className='addCardTemplate'>
                <button className='addPaymentCardExitButton'
                    onClick={handleExitButtonClick}
                    onMouseEnter={() => setExitButtonIsHovered(true)}
                    onMouseLeave={() => setExitButtonIsHovered(false)}
                >
                    <RxCross2 size={40} color={exitButtonIsHovered ? "gray" : "black"}/>
                </button>
                <div
                className='addCardTemplateHeaderBox'
                >
                    <input  
                            className='addCardTemplateHeaderInput'
                            value={newCardPaymentInformation.cardHeader}
                            onChange={(e) => updateNewCardInformation('cardHeader', e.target.value)}
                            onFocus={() => setIsHeaderFocused(true)} // set focus to true
                            onBlur={() => setIsHeaderFocused(false)} // set focus to false
                        />
                        <div 
                        className="cardHeaderAnimatedUnderline"
                        style={{width: isHeaderFocused ? "300px" : "0px"}}
                        >

                        </div>
                        {newCardPaymentInformation.cardHeader.length == 0 &&
                        <div className='invalidInputValuePopupAlert'
                        style={{
                            right: "-70px"
                        }}
                        >
                            <p style={{
                                fontSize: "1rem",
                                color: "black",

                            }}>
                                Invalid Input
                            </p>
                        </div>
                        }


            </div>
            <p className='addCardTemplateSmallHeader'>
                Card Name
            </p>
            <div className='addCardTemplateInputBox'
                style={{
                    width: "400px"
                }}
            >
                <input className='addCardTemplateInput'
                    style={{
                        width: "400px"
                    }}
                    value={newCardPaymentInformation.cardName}
                    onChange={(e) => updateNewCardInformation('cardName', e.target.value)}
                    onFocus={() => setIsCardNameInitialised(true)}
                    />
            {(newCardPaymentInformation.cardName.length == 0 && isCardNameInitialised) &&
            <div className='invalidInputValuePopupAlert'
            style={{
                right: "-160px"
            }}
            >
                <p style={{
                    fontSize: "1rem",
                    color: "black",

                }}>
                    Invalid Input 
                </p>
            </div>
            }
            </div>

            <p className='addCardTemplateSmallHeader'
            style={{
                marginTop: "30px"
            }}
            >
                Card Number
            </p>
            <div className='addCardTemplateInputBox'
                style={{
                    width: "400px",
                }}
            >
                <input className='addCardTemplateInput'
                    style={{
                        width: "400px"
                    }}
                    value={newCardPaymentInformation.cardNumber}
                    onChange={(e) => handleCardNumberChange(e.target.value)}
                    onFocus={() => setIsCardNumberInitialised(true)}

                />
            {(newCardPaymentInformation.cardNumber.length == 0 && isCardNumberInitialised) &&
            <div className='invalidInputValuePopupAlert'
            style={{
                right: "-160px"
            }}
            >
                <p style={{
                    fontSize: "1rem",
                    color: "black",

                }}>
                    Invalid Input 
                </p>
            </div>
            }
            </div>

            <div className='addCardTemplateInputBox'
                style={{
                    width: "400px",
                    height: "70px",
                    marginTop: "30px"

                }}
            >
                <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "140px",
                    height: "70px",

                }}
                >

                <p className='addCardTemplateSmallHeader'
                    style={{
                        margin: "0px",
                        marginBottom: "2px"
                    }}
                    >
                        Issue Month
                </p>
                <FilterDropDown options={cardIssueMonths} defaultOption={1}
                placeHolder={1}
                handleChange={(selectedOption) => updateNewCardInformation('cardIssueMonth', selectedOption)}
                marginLeft={"0px"} 
                marginTop={"0px"}
                width={"110px"} height={"45px"} borderRadius={"6px"} border={"0px solid black"}
                backgroundColor={"#e9ecef"}
                />

                </div>

                <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "150px",
                    height: "70px",

                }}
                >

                <p className='addCardTemplateSmallHeader'
                    style={{
                        margin: "0px",
                        marginBottom: "2px"
                    }}
                    >
                        Issue Year
                </p>
                <FilterDropDown options={cardIssueYears} defaultOption={2023}
                placeHolder={2023}
                handleChange={(selectedOption) => updateNewCardInformation('cardIssueYear', selectedOption)}
                marginLeft={"0px"} 
                marginTop={"0px"}
                width={"120px"} height={"45px"} borderRadius={"6px"} border={"0px solid black"}
                backgroundColor={"#e9ecef"}
                />

                </div>

                <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "110px",
                    height: "70px",

                }}
                >

                <p className='addCardTemplateSmallHeader'
                    style={{
                        margin: "0px",
                        marginBottom: "2px"
                    }}
                    >
                        CVV
                </p>
                <input
                className='addCardTemplateInput'
                style={{
                    height: "45px",
                    width: "110px",
                }}

                value={newCardPaymentInformation.cvv}
                onChange={(e) => updatecvv(e.target.value)}
                onFocus={() => setIsCardCvvInitialised(true)}
                />

                </div>
                        

                    {(newCardPaymentInformation.cvv.length == 0 && isCardCvvInitialised) &&
                    <div className='invalidInputValuePopupAlert'
                    style={{
                        right: "-160px",
                        top: "20px"
                    }}
                    >
                        <p style={{
                            fontSize: "1rem",
                            color: "black",

                        }}>
                            Invalid Input
                        </p>
                    </div>
                    }    
            </div>

            <p className='addCardTemplateSmallHeader'
                style={{
                    marginTop: "20px"
                }}s
            >
                Address
            </p>
            <div className='addCardTemplateInputBox'
                style={{
                    width: "400px"
                    
                }}
            >
                <input className='addCardTemplateInput'
                    style={{
                        width: "400px"
                    }}
                    value={newCardPaymentInformation.address}
                    onChange={(e) => updateNewCardInformation('address', e.target.value)}
                    onFocus={() => setIsCardAddressInitialised(true)}
                    />
            {(newCardPaymentInformation.address.length == 0 && isCardAddressInitialised) &&
            <div className='invalidInputValuePopupAlert'
            style={{
                right: "-160px"
            }}
            >
                <p style={{
                    fontSize: "1rem",
                    color: "black",

                }}>
                    Invalid Input 
                </p>
            </div>
            }
            </div>

            <div className='addCardTemplateInputBox'
                style={{
                    width: "400px",
                    height: "70px",
                    marginTop: "20px"

                }}
            >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "280px",
                    height: "70px",


                }}
                >

                <p className='addCardTemplateSmallHeader'
                    style={{
                        margin: "0px",
                        marginBottom: "2px"
                    }}
                    >
                        Country
                </p>
                <FilterDropDown options={countries} defaultOption={"Sweden"}
                placeHolder={countries[0].label}
                handleChange={(selectedOption) => updateNewCardInformation('country', selectedOption)}
                marginLeft={"0px"} 
                marginTop={"0px"}
                width={"250px"} height={"45px"} borderRadius={"6px"} border={"0px solid black"}
                backgroundColor={"#e9ecef"}
                />

                </div>

                <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "120px",
                    height: "70px",


                }}
                >

                <p className='addCardTemplateSmallHeader'
                    style={{
                        margin: "0px",
                        marginBottom: "2px"
                    }}
                    >
                        Postal Code
                </p>
                <input
                className='addCardTemplateInput'
                style={{
                    height: "45px",
                    width: "120px",
                }}

                value={newCardPaymentInformation.postalCode}
                onChange={(e) => updatePostalCode(e.target.value)}
                onFocus={() => setIsCardPostalCodeInitialised(true)}
                />


                </div>

            {(newCardPaymentInformation.postalCode.length == 0 && isCardPostalCodeInitialised) &&
            <div className='invalidInputValuePopupAlert'
            style={{
                right: "-160px",
                top: "18px",
            }}
            >
                <p style={{
                    fontSize: "1rem",
                    color: "black",

                }}>
                    Invalid Input 
                </p>
            </div>
            }

            </div>

            <p className='addCardTemplateSmallHeader'
                style={{
                    marginTop: "20px"
                }}s
            >
                City
            </p>
            <div className='addCardTemplateInputBox'
                style={{
                    width: "400px"
                    
                }}
            >
                <input className='addCardTemplateInput'
                    style={{
                        width: "400px"
                    }}
                    value={newCardPaymentInformation.city}
                    onChange={(e) => updateNewCardInformation('city', e.target.value)}
                    onFocus={() => setIsCardCityInitialised(true)}
                    />
            {(newCardPaymentInformation.city.length == 0 && isCardCityInitialised) &&
            <div className='invalidInputValuePopupAlert'
            style={{
                right: "-160px"
            }}
            >
                <p style={{
                    fontSize: "1rem",
                    color: "black",

                }}>
                    Invalid Input 
                </p>
            </div>
            }
            </div>

            <button className='addPaymentCardDoneButton'
            onClick={() => {
                handleNewCard(newCardPaymentInformation);
                setAddPaymentCard(false);
            }}            
            >
                <p
                style={{
                    margin: "0px",
                    fontWeight: 500,
                    fontSize: "1rem",
                }}
                >
                    Create Card
                </p>
            </button>


            </div>
        </div>,
        portalRoot
    );
}

export default AddPaymentCardComponent;