import React, { useEffect } from 'react';
import AppRouter from './AppRouter';
import { AuthProvider } from './contexts/AuthContext'; // Import AuthProvider
import { WatchFilterProvider } from './WatchFilterContext';
import { colors, alternateColorScheme } from './commonStyles';

const App = () => {

  const setCSSVariables = () => {
    document.documentElement.style.setProperty('--silver-1', alternateColorScheme.silver);
    document.documentElement.style.setProperty('--silver-2', alternateColorScheme.silver2);
    document.documentElement.style.setProperty('--silver-3', alternateColorScheme.silver3);
    document.documentElement.style.setProperty('--silver-4', alternateColorScheme.silver4);
    document.documentElement.style.setProperty('--gold-1', alternateColorScheme.gold);
    document.documentElement.style.setProperty('--gold-2', alternateColorScheme.gold2);
    document.documentElement.style.setProperty('--gold-3', alternateColorScheme.gold3);
    document.documentElement.style.setProperty('--gold-4', alternateColorScheme.gold4);
    document.documentElement.style.setProperty('--purple-1', alternateColorScheme.purple);
    document.documentElement.style.setProperty('--purple-2', alternateColorScheme.purple2);
    document.documentElement.style.setProperty('--blue-1', alternateColorScheme.blue);
    document.documentElement.style.setProperty('--blue-2', alternateColorScheme.blue2);
    document.documentElement.style.setProperty('--blue-3', alternateColorScheme.blue3);

    
    // set other variables
  };
  

  useEffect(() => {
    setCSSVariables();
  }, []);

  return (
    <div>
      <AuthProvider> {/* Wrap your app with AuthProvider */}
        <WatchFilterProvider>
          <AppRouter />
        </WatchFilterProvider>
      </AuthProvider>

    </div>
  );
};

export default App;