import React, { useState } from 'react';
import './UserConversationThumbnailStyles.css';
import userProfileImage1 from '../assets/userImages/filipProfilePicture.png';
import { FaRegPaperPlane, FaCheck, FaImage } from 'react-icons/fa6';
import { alternateColorScheme } from '../commonStyles';
import { useScroll } from 'framer-motion';

function UserConversationThumbnail({ userName, imageExist, initials, markedAsFinished, changeConversation, lastMessage, isActive, index }) {

    const [isHovered, setIsHovered] = useState(false);

    const messages = [
        { role: 'Them', text: "Hey, I'm interested in the Rolex Oyster Perpetual you have listed. Is it still available?" },
        { role: 'You', text: 'Hello! Yes, the Rolex Oyster Perpetual is still available.' },
        { role: 'Them', text: 'Great! Can you tell me more about its condition? Any scratches or damages?' },
        { role: 'You', text: 'The watch is in excellent condition with no visible scratches or damages. It comes with the original box and certification.' },
        { role: 'Them', text: 'Would you accept $5,500 for it?' }
      ];



    return (
        <div className='userConversationThumbnailParent'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        {...console.log(isActive)}
        style={{

            backgroundColor: isActive ? alternateColorScheme.silver2 : ( isHovered ? alternateColorScheme.silver : "white"),

        }}
        onClick={changeConversation}
        >
            {markedAsFinished &&
            <div className='isConversationMarkedAsFinished'>
                <FaCheck size={12} color='white'/>
            </div>
            }
            <div className='thumbnailProfilePic'>
                {imageExist ? (
                <img src={userProfileImage1}>
                </img>
                ) : (
                <p>
                    {initials}
                </p>
                )}
            </div>
            <div className='conversationThumbnailUsernameAndInfo'>


                <p className='conversationThumbnailUsername'>

                    {userName}
                    
                </p>

                <div className='conversationThumbnailSmallInfoDiv'>
                <div className='conversationThumbnailSenderDiv'>
                    
                    { lastMessage && (

                            <p className='conversationThumbnailSenderText'>
                                { lastMessage.leftBubble ? "Them" : "You" }
                            </p>


                    )}

                </div>
                { lastMessage && (
            <p className='conversationThumbnailText'>

                { lastMessage.message }
            </p>
            )}
            </div>
        </div>
        </div>

    );
}

export default UserConversationThumbnail;