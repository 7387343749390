import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBuPyZTBIxQxV9qVOfCzic0MYZM-V8cGbk",
    authDomain: "primetick-ffb80.firebaseapp.com",
    projectId: "primetick-ffb80",
    storageBucket: "primetick-ffb80.appspot.com",
    messagingSenderId: "881419582144",
    appId: "1:881419582144:web:ed056aba3b1ce6b7248b25",
    measurementId: "G-25SSJ8JZP5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get the Auth instance for the initialized app
export const auth = getAuth(app);
