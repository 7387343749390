import React, { useState, useEffect, useRef } from 'react';
import './ConversationSearchAndThumbnailsParentStyles.css';
import ConversationSearch from './ConversationSearch';
import UserConversationThumbnail from '../UserConversationThumbnail';

function getInitials(name) {
    const nameArray = name.split(' ');
    let initials = '';
  
    for (let i = 0; i < nameArray.length; i++) {
      if (nameArray[i].length > 0) {
        initials += nameArray[i][0].toUpperCase();
      }
    }
  
    return initials;
  }

function ConversationSearchAndThumbnailsParent({ conversations, changeConversation, activeConversation, searchValue, handleSearchValueChange }) {

    return (
        <>
        <ConversationSearch
        handleSearchChange={handleSearchValueChange}
        currentSearchValue={searchValue}
        />
        <div className='convosThumbnailsParent'>

        {conversations && Object.keys(conversations).map((convKey, index) => (


        <UserConversationThumbnail
            userName={conversations[convKey].user2}
            imageExist={index === 0 ? true : false}
            initials={getInitials(conversations[convKey].user2)}  // You might want to replace this with dynamic initials
            changeConversation={() => changeConversation(convKey)}
            markedAsFinished={index === 0}
            lastMessage={conversations[convKey].messages[conversations[convKey].messages.length - 1]}

            isActive={Number(activeConversation[activeConversation.length - 1]) === index+1}

            
            />
        ))}
        </div>
        </>
    );
}

export default ConversationSearchAndThumbnailsParent;