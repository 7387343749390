import React, { useState, useEffect, useRef } from 'react';
import './DynamicDropDownStyles.css';
import boughtWatchImage1 from '../assets/fakeListingImages/watchListing1.png';
import { BsArrowDownCircle, BsArrowDownCircleFill } from 'react-icons/bs';

function DynamicDropDown({ width, height, contentHeight, marginTop,
  headerText, headerPaddingLeft,
  content, 
  iconSize, iconPaddingRight, toggleBackGroundColor, borderRadius }) {
  const [isOpen, setIsOpen] = useState(false);
  const [dropDownHeight, setDropDownHeight] = useState('0px');
  const [isHovered, setIsHovered] = useState(false);
  const [iconRotationDegree, setIconRotationDegree] = useState(0);
  const [animationClass, setAnimationClass] = useState('');


  const contentRef = useRef(null); // Reference to the dropdown content



  const toggleDropdown = (event) => {
    event.preventDefault(); // Prevent the default anchor link behavior
    event.stopPropagation(); // Prevent the link navigation
    
    setIsOpen(!isOpen);
    setIconRotationDegree(prevDegree => prevDegree + 180); // Increment by 180 degrees
  };
  
  useEffect(() => {
    if (isOpen && contentRef.current) {
      setDropDownHeight(contentHeight); // Set height to scrollHeight for opening
      setAnimationClass('dynamicDropdownContentOpen'); // Apply opening animation

    } else {
      setDropDownHeight('0px'); // Set height to 0 for closing
      setAnimationClass('dynamicDropdownContentClosed'); // Apply closing animation if desired

    }
  }, [isOpen, contentHeight]);

  // Inline styles for dynamic height adjustment
  const contentStyle = {
    maxHeight: dropDownHeight,
    width: width,
    overflow: 'hidden',
    transition: 'max-height 0.4s cubic-bezier(0.34, 1.56, 0.64, 1)',
  };

  return (
    <div className="dynamicDropdown" style={{ width: width, minHeight: height, marginTop: marginTop }}>
      <button className="dynamicDropdownToggle" onClick={toggleDropdown} 
      style={{ 
        width: width, 
        height: height, 
        backgroundColor: toggleBackGroundColor,
        borderRadius: borderRadius ? borderRadius : "0px",
        paddingRight: iconPaddingRight ? iconPaddingRight : "5px",
        boxSizing: 'border-box'
      }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}

      >
        <p className='dynamicDropdownToggleText'
        style={{
          paddingLeft: headerPaddingLeft ? headerPaddingLeft : "0px"
        }}
        >
            {headerText}
        </p>
        <div className='dynamicDropdownIconParent'
        style={{
          width: iconSize,
          height: iconSize,

        }}
        >

        <BsArrowDownCircleFill size={iconSize} className={`dynamicDropDownIcon 
        ${isHovered ? "dynamicDropDownShowIcon" : "dynamicDropDownHideIcon"}
        `} 
        style={{
            transform: `rotate(${iconRotationDegree}deg)`,
            transition: 'transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55)' // Adjusted for a snappy effect
        }}        
        />
        <BsArrowDownCircle size={iconSize} className={`dynamicDropDownIcon
         ${isHovered ? "dynamicDropDownHideIcon" : "dynamicDropDownShowIcon"}
         `}
         style={{
            transform: `rotate(${iconRotationDegree}deg)`,
            transition: 'transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55)' // Adjusted for a snappy effect
        }} 
         />



        </div>
      </button>
      <div  
        className={`dynamicDropdownContent ${animationClass}`} // Apply the animation class here
        ref={contentRef} style={contentStyle}>
        {content}
      </div>
    </div>
  );
}

export default DynamicDropDown;
