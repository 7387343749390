import React from 'react';
import './TinyConversationOverviewStyles.css';

function TinyConversationOverview({ lastMessage, dateSent, marginTop, name, initials }) {


    function truncateComment(str) {
        if (!str) {
            console.log('str is undefined or null');
            return "";
        }
        if (str.length > 90) {
          return str.substring(0, 90) + '...';
        }
        return str;
      }
      

    let truncatedMessage = truncateComment(lastMessage);

    return (
        <div className='tinyConversationOverviewParent'
        style={{
            marginTop: marginTop
        }}
        >
            <div className='conversationOverviewHeaderDiv'>
                <p className='conversationOverviewHeaderText'>
                    <b>To:</b> {name}
                </p>
                <div className='conversationOverviewProfileInitialsDiv'>
                    <p 
                    style={{
                        fontSize: "0.65rem",
                        fontWeight: 600
                    }}
                    >
                        {initials}
                    </p>
                </div>

            </div>
            <div className='tinyConversationMainContentDiv'>
            <p 
            style={{
                fontSize: "0.8rem",
                fontWeight: 400,
                margin: "0px",
                marginTop: "3px"
                
            }}
            >
                <b>Last message</b> ({dateSent}): 
            </p>
            <div className='tinyConversationOverviewMessageDiv'>
                <p 
                style={{
                    fontSize: "0.9rem",
                    fontWeight: 400,
                    margin: "0px"
                    
                }}
                >
                    {truncatedMessage}
                </p>
            </div>
            </div>
        </div>
    );
}

export default TinyConversationOverview;