

function DynamicTextBox({ text, color, fontWeight, fontSize, backgroundColor, marginLeft, marginRight, marginTop, paddingLeft, paddingTop, divHeight, maxWidth, borderRadius, textOverflow, onMouseEnter, onMouseLeave }) {

    const divStyle = {

        marginTop: marginTop,
        marginLeft: marginLeft,
        marginRight: marginRight,
        backgroundColor: backgroundColor,
        height: divHeight,
        width: 'fit-content',
        maxWidth: maxWidth,
        paddingLeft: paddingLeft,
        paddingTop: paddingTop,
        boxSizing: 'borderBox',
        borderRadius: borderRadius,
        overflow: 'hidden',
        textOverflow: textOverflow ? 'ellipsis' : 'clip',
        whiteSpace: 'nowrap',


    }

    const spanStyle = {

        color: color,
        fontSize: fontSize,
        fontWeight: fontWeight,
    }

    return (
        <div style={divStyle} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <span style={spanStyle}>{text}</span>
        </div>
    );
}

export default DynamicTextBox;