import React, {useState, useEffect} from 'react';
import './OverviewPageStyles.css';
import SimpleDynamicTextBox from '../components/SimpleDynamicTextBox';
import ListingPreviewComponent from '../components/ListingPreviewComponent';
import SimplifiedWatchListingPreview from '../components/SimplifiedWatchListingPreview';
import CheckBox from '../components/CheckBox';
import BasicButton from '../components/BasicButton';
import AnimatedButton from '../components/AnimatedButton';
import { GiReceiveMoney } from 'react-icons/gi'
import { LuTimer } from 'react-icons/lu'
import { FaStar } from 'react-icons/fa';
import { IoMdCheckmark, IoIosPricetag } from 'react-icons/io';
import { ImCheckmark2 } from 'react-icons/im';
import { AiFillTag } from 'react-icons/ai';
import { Link } from 'react-router-dom';



const OverviewPage = ( {formData, previewListingInfo, images, termsAndConditions, setTermsAndConditions} ) => {
    
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [animatePreview, setAnimatePreview] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const handleCheckboxDataChange1 = (text, checkBoxValue) => {
        setAnimatePreview(!checkBoxValue);
    };

    const handleCheckboxDataChange2 = (text, checkBoxValue) => {
        setTermsAndConditions(checkBoxValue);
    };
    

    return (
        <div className="overviewPageParentDiv">
            <div className='centerContainer'>
                <div style={{width: width > 1100 ? "95%" : "80%"}}>
                    <SimpleDynamicTextBox text={"Summary"} fontSize={"2.3rem"} fontWeight={500} 
                    color={"#003251"} marginTop={"20px"}/>
                    <div style={{
                        width: "60%",
                        height: "1.5px",
                        marginTop: "30px",
                        backgroundColor: "black"
                    }}>
                </div>
                </div>
            </div>
            <div className='centerContainer'>
                <div className='overviewPageListingSummaryParentDiv'>
                    {width > 1200 &&
                    <div className='overviewPageListingSummarySideChildDiv'>
                        <div className='listingInformationMediumDiv'>
                        <SimpleDynamicTextBox text={"Listing Fee"} fontSize={"1.9rem"} fontWeight={500} 
                        color={"#003251"} marginTop={"15px"}/>
                        <div className='listingFeeInformationDiv'>
                            <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                            <GiReceiveMoney color={width > 1300 ? 'white' : "black"} size={22} style={{marginLeft: "5px"}}/>
                            <div style={{display: "flex", justifyContent: "space-between", width: "90%", height: "40px", marginTop: "0px", alignItems: "center"}}>
                            <SimpleDynamicTextBox text={"Listing Fee: "} fontSize={width > 1120 ? "1.4rem" : width > 1050 ? "1.3rem" : "1.1rem"} fontWeight={400} 
                            color={width > 1300 ? 'white' : "black"} marginTop={"3px"} marginLeft={"5px"}/>
                            <SimpleDynamicTextBox text={"€0.00"} fontSize={width > 1120 ? "1.4rem" : width > 1050 ? "1.3rem" : "1.1rem"} fontWeight={300} 
                            color={width > 1300 ? 'white' : "black"} marginTop={"3px"} marginLeft={"0px"}/>
                            </div>
                            </div>

                            <div style={{width: "90%", height: "1px", backgroundColor: width > 1300 ? "white" : "black", marginTop: "20px"}}>
                            </div>
                            <div style={{display: "flex", width: "100%", alignItems: "center", marginTop: "20px"}}>

                            <LuTimer color={width > 1300 ? 'white' : "black"} size={22}  style={{marginLeft: "5px"}}/>
                            <div style={{display: "flex", justifyContent: "space-between", width: "90%", height: "40px", alignItems: "center"}}>

                            <SimpleDynamicTextBox text={"Duration:"} fontSize={width > 1140 ? "1.4rem" : width > 1050 ? "1.2rem" : "1.1rem"} fontWeight={400} 
                            color={width > 1300 ? 'white' : "black"} marginTop={"3px"} marginLeft={"5px"}/>
                            <SimpleDynamicTextBox text={"3 Months"} fontSize={width > 1140 ? "1.4rem" : width > 1050 ? "1.2rem" : "1.1rem"} fontWeight={300} 
                            color={width > 1300 ? 'white' : "black"} marginTop={"3px"} marginLeft={"0px"}/>
                            </div>
                            </div>

                        </div>
                    </div>
                    <div className='listingInformationMediumDiv'
                    style={{marginTop: "40px"}}
                    >
                        <SimpleDynamicTextBox text={"Need To Know"} fontSize={"1.9rem"} fontWeight={500} 
                        color={"#003251"} marginTop={"15px"}/>
                        <div className='listingInformationTextDiv'>
                        <SimpleDynamicTextBox text={"Your listing is valid for 3 months. During this time, the listed item may not be sold elsewhere."} fontSize={"1.1rem"} fontWeight={400} 
                        color={width > 1300 ? 'white' : "black"} marginTop={"15px"}/>
                        <SimpleDynamicTextBox text={"If you sell your watch on PrimeTick, we charge a 5% commission fee."} fontSize={"1.1rem"} fontWeight={400} 
                        color={width > 1300 ? 'white' : "black"} marginTop={"15px"}/>
                        <SimpleDynamicTextBox text={"PrimeTick's Data Privacy Policy applies"} fontSize={"1.1rem"} fontWeight={400} 
                        color={width > 1300 ? 'white' : "black"} marginTop={"15px"}/>
                        <CheckBox size={1.4} text={"I accept PrimeTick's Private Seller General Terms and Conditions"} color={width > 1300 ? 'white' : "black"} 
                        labelMarginLeft={"5px"} fontSize={"1.05rem"}
                        isChecked={termsAndConditions}
                        marginTop={"30px"} onCheckBoxChange={handleCheckboxDataChange2}

                        />
                        </div>
                        
                    </div>
                    </div>
                    }
                    <div className='overviewPageListingSummaryCenterChildDiv'>
                        <div className='centerContainer'>
                            <SimpleDynamicTextBox text={"Congratulations!"} fontSize={"2.3rem"} fontWeight={500} 
                            color={"white"} marginTop={"10px"} marginLeft={"5px"}/>

                        </div>
                        <SimpleDynamicTextBox text={"Here is your complete listing: "} fontSize={"1.5rem"} fontWeight={400} 
                            color={"white"} marginTop={"10px"} marginLeft={"5px"}/>
                        <div className={ animatePreview ? 'overviewListingPreviewParentDiv2' : 'overviewListingPreviewParentDiv2WithoutShake'}>

                        <SimplifiedWatchListingPreview title={formData["Brand"] ? `${formData["Brand"].label} ${formData["Model"] || ''}` : ""} shortDescription={formData["Short Description"] ? formData["Short Description"] : ""} price={formData["Price"]} currency={formData["Currency"]} watchListingInfo={previewListingInfo} images={images} to={"/testpage"}/>       
                            <div className="star" id="star1"><FaStar /></div>
                            <div className="star" id="star2"><FaStar /></div>
                            <div className="star" id="star3"><FaStar /></div>
                        </div>
                        <CheckBox size={1.5} text={"Stop Shaking Effect"} color={"white"} 
                        labelMarginLeft={"5px"} fontSize={"1.2rem"}
                        marginTop={"40px"} onCheckBoxChange={handleCheckboxDataChange1}

                        />
                    </div>
                    {width > 1200 &&
                    <div className='overviewPageListingSummarySideChildDiv'>
                        <div className='listingInformationMediumDiv'>
                            <SimpleDynamicTextBox text={"Save Listing"} fontSize={"1.9rem"} fontWeight={500} 
                            color={"#003251"} marginTop={"15px"}/>
                            <div className='saveListingInformationDiv'>
                                <SimpleDynamicTextBox text={"You can update your listing anytime in your PrimeTick Profile."} fontSize={width > 1200 ? "1.2rem" : "1.05rem"} fontWeight={400} 
                                color={width > 1300 ? 'white' : "black"} marginTop={"15px"}
                                maxWidth={"250px"}
                                />
                                <div style={{width: "90%", height: "1px", backgroundColor: width > 1300 ? 'white' : "black", marginTop: "20px"}}>
                                </div>
                                <BasicButton text={"Save Listing"} width={"90%"} height={"50px"} backgroundColor={"white"} 
                                    hoveredBackgroundColor={"#023e8a"} 
                                    marginTop={"20px"}
                                    color={"black"} hoveredColor={"white"}
                                    border={"3px solid black"} borderRadius={"3px"}
                                    fontSize={"1rem"} fontWeight={"600"}
                                    />
                            </div>
                        </div>
                        <div className='listingInformationMediumDiv'
                        style={{marginTop: "50px"}}
                        >   
                            <SimpleDynamicTextBox text={"Seller Guidelines"} fontSize={"1.9rem"} fontWeight={500} 
                            color={"#003251"} marginTop={"15px"}/>
                            <div className='sellerGuidelinesTextDiv'>
                                <SimpleDynamicTextBox text={"Sellers are required to immediately report any sales to us."} fontSize={"1.1rem"} fontWeight={400} 
                                color={width > 1300 ? 'white' : "black"} marginTop={"15px"}/>
                                <SimpleDynamicTextBox text={"Items may not be sold elsewhere during the 3-month period of validity."} fontSize={"1.1rem"} fontWeight={400} 
                                color={width > 1300 ? 'white' : "black"} marginTop={"15px"}/>
                                <SimpleDynamicTextBox text={"No counterfeit or replica watches may be listed."} fontSize={"1.1rem"} fontWeight={400} 
                                color={width > 1300 ? 'white' : "black"} marginTop={"15px"}/>

                            </div>
                        </div>
                    </div>
                    }
                    {width <= 1200 &&
                    <div className='overviewPageListingSummarySideChildDiv'>
                        <div className='overviewPageSub1200SideDiv'>

                        <div className='listingInformationMediumDiv'
                        style={{
                            width: width > 600 ? "45%" : "100%"
                        }}
                        >
                        <SimpleDynamicTextBox text={"Listing Fee"} fontSize={"1.9rem"} fontWeight={500} 
                        color={"#003251"} marginTop={"15px"} marginLeft={width > 1100 ? "0px" : "4px"}/>
                        <div className='listingFeeInformationDiv'>
                            <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                            <GiReceiveMoney color={width > 1300 ? 'white' : "black"} size={22} style={{marginLeft: "5px"}}/>
                            <div style={{display: "flex", justifyContent: "space-between", width: "90%", height: "40px", marginTop: "0px", alignItems: "center"}}>
                            <SimpleDynamicTextBox text={"Listing Fee: "} fontSize={width > 1120 ? "1.4rem" : width > 1100 ? "1.3rem" : width > 830 ? "1.6rem" : width > 730 ? "1.3rem" : width > 600 ? "1.1rem" : width > 500 ? "1.6rem" : "1.35rem"} fontWeight={400} 
                            color={width > 1300 ? 'white' : "black"} marginTop={"3px"} marginLeft={"5px"}/>
                            <SimpleDynamicTextBox text={"€0.00"} fontSize={width > 1120 ? "1.4rem" : width > 1100 ? "1.3rem" : width > 830 ? "1.6rem" : width > 730 ? "1.3rem" : width > 600 ? "1.1rem" : width > 500 ? "1.6rem" : "1.35rem"} fontWeight={300} 
                            color={width > 1300 ? 'white' : "black"} marginTop={"3px"} marginLeft={"0px"}/>
                            </div>
                            </div>

                            <div style={{width: "90%", height: "1px", backgroundColor: width > 1300 ? "white" : "black", marginTop: "20px"}}>
                            </div>
                            
                            <div style={{display: "flex", width: "100%", alignItems: "center", marginTop: "20px"}}>

                            <LuTimer color={width > 1300 ? 'white' : "black"} size={22}  style={{marginLeft: "5px"}}/>
                            <div style={{display: "flex", justifyContent: "space-between", width: "90%", height: "40px", alignItems: "center"}}>

                            <SimpleDynamicTextBox text={"Duration:"} fontSize={width > 1150 ? "1.4rem" : width > 1100 ? "1.2rem" : width > 830 ? "1.6rem" : width > 730 ? "1.3rem" : width > 600 ? "1.1rem" : width > 500 ? "1.6rem" : "1.35rem"} fontWeight={400} 
                            color={width > 1300 ? 'white' : "black"} marginTop={"3px"} marginLeft={"5px"}/>
                            <SimpleDynamicTextBox text={"3 Months"} fontSize={width > 1150 ? "1.4rem" : width > 1100 ? "1.2rem" : width > 830 ? "1.6rem" : width > 730 ? "1.3rem" : width > 600 ? "1.1rem" : width > 500 ? "1.6rem" : "1.35rem"} fontWeight={300} 
                            color={width > 1300 ? 'white' : "black"} marginTop={"3px"} marginLeft={"0px"}/>
                            </div>
                            </div>

                        </div>
                    </div>   

                    <div className='listingInformationMediumDiv'
                    style={{
                        width: width > 600 ? "45%" : "100%"
                    }}
                    >
                            <SimpleDynamicTextBox text={"Save Listing"} fontSize={"1.9rem"} fontWeight={500} 
                            color={"#003251"} marginTop={"15px"} marginLeft={width > 1100 ? "0px" : "4px"}/>
                            <div className='saveListingInformationDiv'>
                                <SimpleDynamicTextBox text={"You can update your listing anytime in your PrimeTick Profile."} fontSize={width > 1200 ? "1.2rem" : "1.05rem"} fontWeight={400} 
                                color={width > 1300 ? 'white' : "black"} marginTop={"15px"}
                                maxWidth={"320px"}
                                />
                                <div style={{width: "90%", height: "1px", backgroundColor: width > 1300 ? 'white' : "black", marginTop: "20px"}}>
                                </div>
                                <BasicButton text={"Save Listing"} width={"90%"} height={"50px"} backgroundColor={"white"} 
                                    hoveredBackgroundColor={"#023e8a"} 
                                    marginTop={"20px"}
                                    color={"black"} hoveredColor={"white"}
                                    border={"3px solid black"} borderRadius={"3px"}
                                    fontSize={"1rem"} fontWeight={"600"}
                                    />
                            </div>
                        </div>  
    
                        </div>
                        <div className='listingInformationMediumDiv'
                        style={{
                            marginTop: "20px"
                        }}
                        >
                        <SimpleDynamicTextBox text={"Need To Know"} fontSize={"1.9rem"} fontWeight={500} 
                        color={"#003251"} marginTop={"15px"}/>
                        <div className='listingInformationTextDiv'>
                        <SimpleDynamicTextBox text={"Your listing is valid for 3 months. During this time, the listed item may not be sold elsewhere."} fontSize={width > 1200 ? "1.1rem" : "1rem"} fontWeight={400} 
                        color={width > 1300 ? 'white' : "black"} marginTop={"15px"}/>
                        <SimpleDynamicTextBox text={"If you sell your watch on PrimeTick, we charge a 5% commission fee."} fontSize={width > 1200 ? "1.1rem" : "1rem"} fontWeight={400} 
                        color={width > 1300 ? 'white' : "black"} marginTop={"15px"}/>
                        <SimpleDynamicTextBox text={"PrimeTick's Data Privacy Policy applies"} fontSize={width > 1200 ? "1.1rem" : "1rem"} fontWeight={400} 
                        color={width > 1300 ? 'white' : "black"} marginTop={"15px"}/>
                        <CheckBox size={1.4} text={"I accept PrimeTick's Private Seller General Terms and Conditions"} color={width > 1300 ? 'white' : "black"} 
                        labelMarginLeft={"5px"} fontSize={"1.05rem"}
                        isChecked={termsAndConditions}
                        marginTop={"30px"} onCheckBoxChange={handleCheckboxDataChange2}

                        />
                        </div>
                        </div>

                        <div className='listingInformationMediumDiv'
                        style={{
                            marginTop: "20px"
                        }}
                        >
                            <SimpleDynamicTextBox text={"Seller Guidelines"} fontSize={"1.9rem"} fontWeight={500} 
                            color={"#003251"} marginTop={"15px"}/>
                            <div className='sellerGuidelinesTextDiv'>
                                <SimpleDynamicTextBox text={"Sellers are required to immediately report any sales to us."} fontSize={width > 1200 ? "1.1rem" : "1rem"} fontWeight={400} 
                                color={width > 1300 ? 'white' : "black"} marginTop={"15px"}/>
                                <SimpleDynamicTextBox text={"Items may not be sold elsewhere during the 3-month period of validity."} fontSize={width > 1200 ? "1.1rem" : "1rem"} fontWeight={400} 
                                color={width > 1300 ? 'white' : "black"} marginTop={"15px"}/>
                                <SimpleDynamicTextBox text={"No counterfeit or replica watches may be listed."} fontSize={width > 1200 ? "1.1rem" : "1rem"} fontWeight={400} 
                                color={width > 1300 ? 'white' : "black"} marginTop={"15px"}/>

                            </div>
                        </div>

                    </div>
                    }
                </div>

                
            </div>
                <div className='overviewPageUpgradeToTopDiv'
                style={{marginLeft: width > 1100 ? "30px" : "0px", marginTop: "70px"}}
                >
                    <div className='overviewPageUpgradeToTopHeaderDiv'>
                        <SimpleDynamicTextBox text={"Sell faster with a Priority Listing"} fontSize={width > 860 ? "1.5rem" : "1.4rem"} fontWeight={500} 
                        color={"#ffd60a"} marginTop={"15px"} marginLeft={"10px"}/>
                    </div>

                    <div className='overviewPageUpgradeToTopContentDiv'>
                        <div className='overviewPageUpgradeToTopContentDivFirstHalf'>

                        <div className='overviewPageUpgradeToTopContentDivRow'>
                        <div style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between"
                        }}>
                        <ImCheckmark2 size={24} color={"#ffd60a"}/>
                        <span
                        style={{
                            fontSize: "1.2rem",
                            fontWeight: 300,
                            color: "black",
                            marginTop: "0px",
                            marginLeft: "10px",
                            width: "100%"
                        }}
                        >
                            Sell faster with a Priority Listing
                        </span>
                        </div>
                        </div>

                        <div className='overviewPageUpgradeToTopContentDivRow'
                        style={{marginTop: "10px"}}
                        >
                        <div style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            marginTop: "10px"
                        }}>
                        <ImCheckmark2 size={24} color={"#ffd60a"}/>
                        <span
                        style={{
                            fontSize: width > 600 ? "1.2rem" : "1.2rem",
                            fontWeight: 300,
                            color: "black",
                            marginTop: "0px",
                            marginLeft: "10px",
                            width: "100%"

                        }}
                        >
                            Sell 10% faster thanks to better ad placement
                        </span>
                        </div>
                        </div>
                        <BasicButton text={"Activate now for 50€"} width={"315px"} height={"50px"} backgroundColor={"white"} 
                                    hoveredBackgroundColor={"#023e8a"} 
                                    marginTop={width > 860 ? "25px" : "55px"}
                                    color={"black"} hoveredColor={"white"}
                                    border={"3px solid black"} borderRadius={"3px"}
                                    fontSize={"1rem"} fontWeight={"600"}
                                    />
                        
                    </div>
                    <div className='overviewPageUpgradeToTopContentDivSecondHalf'>
                            <div className='upgradeDivBottomImageDiv'>

                                <div className='upgradeDivTopIconHoverDiv'>
                                    <IoIosPricetag size={70} color='#d81939'/>
                                </div>
                                <p className='upgradeDivTopIconParagraph'
                                style=
                                {{fontSize: "1.2rem", color: "white", fontWeight: "400"
                            
                                }}
                                >
                                    Top
                                </p>
                            <img src={images[0]} className='upgradeDivBottomImage'
                            style={{marginTop: "20px"}}
                            >
                            </img>

                            </div>
                    </div>

                    </div>
                </div>
                <div className='centerContainer'>
                    <div className='overviewPageButtonsParentDiv'>
                        <Link to="/sell/personalinformation" className="no-underline">
                        <AnimatedButton text={width > 700 ? "Go Back To Step 3" : "Go Back"} direction={"left"} width={width > 700 ? "220px" : "128px"} height={"50px"}
                        hoverColor={"black"} color={"white"} textcolor={"black"} arrowColor={"black"} arrowAnimatedColor={"white"}
                        borderRadius={"3px"} border={"3px solid black"} 
                        fontSize={"1rem"} fontWeight={"600"} 
                        paddingLeft={"20px"} marginLeft={"0px"}
                        arrowMarginLeft={"0px"}
                        paragraphMarginLeft={"5px"}
                        />
                        </Link>
                        <Link to="/profileHome/explore-primetick" className="no-underline">
                        <BasicButton text={width > 700 ? "Go to your profile" : "Profile"} width={width > 700 ? "300px" : "100px"} height={"50px"} backgroundColor={"white"} 
                                    hoveredBackgroundColor={"black"} 
                                    marginTop={"0px"} marginLeft={"0px"}
                                    color={"black"} hoveredColor={"white"}
                                    border={"3px solid black"} borderRadius={"3px"}
                                    fontSize={"1rem"} fontWeight={"600"}
                                    />
                        </Link>
                    </div>
                </div>
        </div>


    );



}


export default OverviewPage;