import React, { useState, useEffect } from 'react';
import './SellerInfoComponentStyles.css';
import './commonStyles.css';
import SimpleDynamicTextBox from './SimpleDynamicTextBox';
import LightBoldTextBox from './LightBoldTextBox';
import { HiStar } from 'react-icons/hi';
import { BsPersonVcardFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import 'flagpack-dart-sass/dist/flagpack.css';


const SellerInfoComponent = ( {marginLeft, marginTop} ) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const sellerInfoParentDivStyles = {
        marginTop: marginTop,
        marginLeft: marginLeft,
    }

    const WatchListingData = {
        brand: "Rolex",
        modelName: "Day-Date 36",
        dateAdded: new Date('2023-06-01'),
        popularity: 450,
        sellerComment: "Ref 118388 Men’s 18K Yellow Gold Black MOP Dia Dial, Bezel & Case 2006",
        price: 42995,
        shippingCost: "$99",
        sellerCountry: "US",
        sellerCity: "New York",
        condition: "New (Unworn)",
        productionYear: 2012,
        box: "Original",
        papers: "Original",
        seller: "Professional Dealer",
        inStock: true,
        deliveryTime: 4,
        reviewScore: 4.6,
    }

    return (
            <Link to="/sellerProfile" className='resetLinkStyle learnAboutSellerLink'>

            <div className='shortSellerSummaryDiv'>
                <div className='shortSellerSummaryChildDiv'>
                    {(screenWidth > 1280 || (screenWidth < 1180 && screenWidth > 720) || screenWidth < 661) &&
                    <BsPersonVcardFill size={"20px"} color='black' style={{marginLeft: "12px"}}/>
                    }
                    <SimpleDynamicTextBox text={ 
                        <>
                        {screenWidth > 1420 &&
                        <LightBoldTextBox boldText={" Seller:"} boldTextFontWeight={"450"} color={"black"} />
                        }
                        </>
                        } color={"black"} fontSize="1.1rem" fontWeight="410" marginLeft="2px" marginTop="0px" maxWidth={"90%"}/>
                        <span 
                        className='sellerInfoText'
                        >Professional Dealer</span>
                    <span className={`fp ${WatchListingData.sellerCountry.toLowerCase()} fp-${screenWidth > 1280 || (screenWidth < 1180 && screenWidth > 720) || screenWidth < 660 ? 'md' : 'sm'}`} style={{ marginLeft: (screenWidth > 1280) ? "15px" : "9px" }}></span>
                    
                </div>
                <div className='shortSellerSummaryChildDiv'>
                            <div className='reviewScoreDiv'>
                            <HiStar size={"24px"} color='#ffdd00' style={{marginRight: "1px"}}/>
                            <SimpleDynamicTextBox text={WatchListingData.reviewScore} color={"white"} fontWeight={"600"} />
                            {screenWidth > 1280 || (screenWidth < 1180 && screenWidth > 720) ?
                            <SimpleDynamicTextBox text={" Look At Seller Reviews"} color={"white"} fontWeight={"500"} fontSize={"14px"} marginLeft={"7px"}/>
                            :
                            <SimpleDynamicTextBox text={" Reviews"} color={"white"} fontWeight={"500"} fontSize={"14px"} marginLeft={"7px"}/>
                            }
                            </div>
                </div>
                
            </div>
            </Link>



    );



}


export default SellerInfoComponent;