import React, { useState, useEffect } from 'react';
import './PaymentSectionStyles.css';
import { alternateColorScheme } from '../../commonStyles';
import AddPaymentCardComponent from '../PaymentCardsFolder/AddPaymentCardComponent';
import InformationWithLockDiv from '../InformationWithLockDiv';
import PaymentInformationContentComponent from '../PaymentCardsFolder/PaymentInformationContentComponent';
import PaymentCardStack from '../PaymentCardsFolder/PaymentCardStack';
import PurchasedWatches from '../PurchasedWatches';



const PaymentSection = ({}) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    const handleDeleteClick = (index) => {
        setPaymentCardsContent(prevCards => {
            const updatedCards = [...prevCards]; // Create a copy of the array
            updatedCards.splice(index, 1); // Remove the card at the specified index
            return updatedCards;
        });
    };

    const handleNewCard = (newCard) => {
        setPaymentCardsContent(prevCards => {
            const updatedCards = [...prevCards, newCard]; // Add the new card to the end of the array
            return updatedCards;
        });
    };
    
    

    const [paymentCardsContent, setPaymentCardsContent] = useState([
        {
            cardHeader: "Payment Card 1",
            cardName: "Filip Larsson",
            cardNumber: "3433 3243 1239 8639",
            cardIssueMonth: 7,
            cardIssueYear: 2026,
            cvv: 362,
            address: "Hälsingegatan 41",
            city: "Stockholm",
            country: "Sweden",
            postalCode: "11542",

        },
        {
            cardHeader: "Payment Card 2",
            cardName: "John Doe",
            cardNumber: "4520 0023 4939 2810",
            cardIssueMonth: 11,
            cardIssueYear: 2024,
            cvv: 873,
            address: "123 Main St",
            city: "San Francisco",
            country: "USA",
            postalCode: "94101",
          },
          {
            cardHeader: "Business Card 1",
            cardName: "Emily Smith",
            cardNumber: "8921 2233 4455 1199",
            cardIssueMonth: 1,
            cardIssueYear: 2025,
            cvv: 459,
            address: "47 Oxford St",
            city: "London",
            country: "UK",
            postalCode: "W1D 2DT",
          },

    ]);

    function decideColor(num) {
        switch (num) {
          case 0:
            return "#90e0ef"; // Red
          case 1:
            return "#48cae4"; // Green
          case 2:
            return "#00b4d8"; // Blue
          case 3:
            return "#0096c7"; // Yellow
          default:
            return "#0077b6"; // White as default
        }
      }
      

      

    const paymentCards = paymentCardsContent.map((card, index) => (
        <InformationWithLockDiv
          key={index}
          header={card.cardHeader}
          backgroundcolor={decideColor(index)}
          width={windowWidth > 900 ? "710px" : windowWidth > 640 ? "570px" : windowWidth > 500 ? "450px" : "365px"}
          height={"570px"}
          headerAndUnlockButtonWidth={windowWidth > 900 ? "511px" : windowWidth > 500 ? "280px" : "225px"}
          content={
            <PaymentInformationContentComponent
              paymentInformation={card}
              setPaymentInformation={setPaymentCardsContent}
              index={index}
              width={windowWidth}
            />
          }
        />
      ));

    const [active, setActive] = useState(false);

    const [addPaymentCard, setAddPaymentCard] = useState(false);

    useEffect(() => {
        // Activate the animation when the component mounts
        setActive(true);
    }, []);

    const handleExitButtonClick = () => {
        setAddPaymentCard(false);
    };




    return (
        <div className={`slide-up-fade-in ${active ? 'active' : ''}`}>
            <div className='paymentSectionParent'>
                {(addPaymentCard && paymentCardsContent.length < 4) && 
                <AddPaymentCardComponent 
                handleExitButtonClick={handleExitButtonClick}
                handleNewCard={handleNewCard}
                setAddPaymentCard={setAddPaymentCard}
                />
                }

                <PaymentCardStack paymentCards={paymentCards} handleDeleteClick={handleDeleteClick}
                setAddPaymentCard={setAddPaymentCard} width={windowWidth}/>
                <PurchasedWatches/>
                
            </div>
        </div>
    );
};

export default PaymentSection;
