import React from 'react';
import './BasicWatchInfoComponentStyles.css';
import SimpleDynamicTextBox from './SimpleDynamicTextBox';
import LightBoldTextBox from './LightBoldTextBox';

const BasicWatchInfoComponent = ( {} ) => {

    let comprehensiveWatchInformation = {
        BasicInfo: {
            Brand: "Rolex",
            Model: "Day Date 36",
            ReferenceNumber: 128238,
            Movement: "Automatic",
            CaseMaterial: "Yellow gold",
            BraceletMaterial: "Yellow gold",
            YearOfProduction: 2021,
            Condition: "Very good (Worn with little to no signs of wear)",
            Box: "Original",
            Papers: "Original",
            Gender:	"Men's watch/Unisex",
            Location: "United States of America, Michigan, Oak Park, MI",
            Price: 37999,
            InStock: "Yes"
            },
        
        Caliber: {
            Movement: "Automatic",
            Caliber: 3255,
            PowerReserve: 70,
            NumberOfJewels: 31,	
        },

        Case: {
            CaseMaterial: "Yellow Gold",
            CaseDiameter: "36 x 43 mm",
            WaterResistance: 10,
            BezelMaterial: "Yellow Gold",
            Crystal: "Sapphire Crystal",
            Dial: "Mother Of Pearl",
            DialNumerals: "No Numerals",
        },

        Bracelet: {
            BraceletMaterial: "Yellow Gold",
            BraceletColor: "Gold",
            Clasp: "Fold Clasp",
            ClaspMaterial: "Yellow Gold",
        },
    }

    function insertSpacesBeforeCapitals(str) {
        return str.replace(/([A-Z])/g, ' $1').trim();
    }

    return (
        <div className="BasicWatchInfoComponentParentDiv">
            {Object.entries(comprehensiveWatchInformation).map(([key, value], index) => (
            <>
            <SimpleDynamicTextBox text={insertSpacesBeforeCapitals(key)} color={"white"} fontSize={"1.4rem"} fontWeight={500} marginLeft={"0px"} marginTop={"10px"}/>
            <div style={{width: '200px', height: '2px', backgroundColor: 'white', marginTop: '5px', marginLeft: '0px', marginBottom: "15px"}}>
            </div>
            <div className='partialInfoDiv'>
                {Object.entries(comprehensiveWatchInformation[key]).map(([key, value], index) => (
                    <>
                    <div key={index} className='watchInfoRowDiv'>
                        <span className='basicWatchInfoHeader'>{insertSpacesBeforeCapitals(key)}:</span>
                        <span className='basicWatchInfoText'>{value}</span>
                    </div>
                     <div className='infoStatUnderLine'>
                    </div>
                    </>
                ))}
            </div>
            </>
            ))}

        </div>


    );



}


export default BasicWatchInfoComponent;