import React, { useEffect, useState, useRef } from 'react';
import Slider from "react-slick";
import './RecommendedSellerListingsStyles.css';
import './commonStyles.css';
import { Link } from 'react-router-dom';
import { commonFont, alternateColorScheme } from '../commonStyles';
import WatchListing from './WatchListing';
import SimplifiedWatchListing from './SimplifiedWatchListing';
import CustomPrevArrow from './CustomSliderArrow';
import boughtWatchImage1 from '../assets/fakeListingImages/watchListing1.png';
import boughtWatchImage2 from '../assets/fakeListingImages/watchListing4.png';
import boughtWatchImage3 from '../assets/fakeListingImages/watchListing5.png';
import boughtWatchImage4 from '../assets/fakeListingImages/watchListing6.png';
import boughtWatchImage5 from '../assets/fakeListingImages/watchListing7.png';
import boughtWatchImage6 from '../assets/fakeListingImages/watchListing8.png';
import CustomSliderArrow from './CustomSliderArrow';


const RecommendedSellerListings = ( {sellerName} ) => {



    const [slidesToShow, setSlidesToShow] = useState(4);
  
    const [showHoverArrows, setShowHoverArrows] = useState(false);


    const handleMouseEnter = () => {
      setShowHoverArrows(true);
    };
  
    const handleMouseLeave = () => {
      setShowHoverArrows(false);
    };


    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 850) {
            setSlidesToShow(1);
          }
        else if (window.innerWidth <= 1125) {
            setSlidesToShow(2);
          }
        else if (window.innerWidth <= 1425) {
          setSlidesToShow(3);
        } else {
          setSlidesToShow(4);
        }
      }
  
      // Initial setup
      handleResize();
  
      // Listen for resize events
      window.addEventListener('resize', handleResize);
  
      // Clean up
      return () => {
        window.removeEventListener('resize', handleResize);
      }
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 400,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow,
        arrows: false,


      };

      const watches = [
        {brand: "Rolex", modelName: "Day-Date 36", dateAdded: new Date('2023-06-01'), popularity: 450,
    sellerComment: "Ref 118388 Men’s 18K Yellow Gold Black MOP Dia Dial , Bezel & Case 2006",
     price: 42995, shippingCost: "$99", sellerCountry: "US",  sellerCity: "New York", 
     images: [boughtWatchImage1, boughtWatchImage2, boughtWatchImage3]},
     {brand: "Rolex", modelName: "Oyster Perpetual 26", dateAdded: new Date('2023-06-02'), popularity: 300,
     sellerComment: "Lady's Oyster Perpetual Ref 76094 Steel Silver Dial", dateAdded: new Date('2023-06-03'),
      price: 3900, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Wheaton",
      images: [boughtWatchImage3, boughtWatchImage2, boughtWatchImage1]},
      {brand: "Rolex", modelName: "GMT-Master II", dateAdded: new Date('2023-06-04'), popularity: 120,
      sellerComment: "REAL in STOCK Unworn 2023 New Release Two Tone Grey Black Ceramic Bezel 40mm GMT-Master II",
       price: 23395, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Miami",
       images: [boughtWatchImage4, boughtWatchImage5, boughtWatchImage6]},
       {brand: "Rolex", modelName: "Day-Date II", dateAdded: new Date('2023-06-05'),popularity: 150,
       sellerComment: "President 41mm 218239 18k White Gold Roman Dial Watch B/PAPERS",
        price: 43900, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Boca Raton",
        images: [boughtWatchImage6, boughtWatchImage5, boughtWatchImage4]},
        {brand: "Rolex", modelName: "Sky-Dweller", dateAdded: new Date('2023-06-06'), popularity: 500,
        sellerComment: "326934 COMPLETE SET",
         price: 100000, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Carmel",
         images: [boughtWatchImage5, boughtWatchImage1, boughtWatchImage3]},
         {brand: "Rolex", modelName: "Datejust 31", dateAdded: new Date('2023-06-07'), popularity: 200,
         sellerComment: "Pre-Owned Datejust 31 Yellow Gold White Roman Dial on Presidential Bracelet [WITH BOX]",
          price: 19995, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Los Angeles",
          images: [boughtWatchImage2, boughtWatchImage1, boughtWatchImage5]},
          {brand: "Rolex", modelName: "Submariner Date", dateAdded: new Date('2023-06-08'),popularity: 220,
          sellerComment: "126610LN NEVER WORN 5-2022 CARD / COMPLETE",
           price: 16990, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Dallas",
           images: [boughtWatchImage5, boughtWatchImage6, boughtWatchImage1]},
           {brand: "Rolex", modelName: "Yacht-Master II", dateAdded: new Date('2023-06-09'), popularity: 20,
           sellerComment: "44mm NEW/UNWORN",
            price: 22200, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Beverly Hills",
            images: [boughtWatchImage1, boughtWatchImage2, boughtWatchImage4]},
            {brand: "Rolex", modelName: "Explorer II", dateAdded: new Date('2023-06-10'), popularity: 200,
            sellerComment: "Polar White 42mm 216570",
             price: 11950, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Oradell",
             images: [boughtWatchImage4, boughtWatchImage1, boughtWatchImage6]},
             {brand: "Rolex", modelName: "Sea-Dweller 4000", dateAdded: new Date('2023-06-11'),popularity: 50,
             sellerComment: "40mm F Serial 16600",
              price: 10495, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Oradell",
              images: [boughtWatchImage1, boughtWatchImage2, boughtWatchImage3]},
              {brand: "Rolex", modelName: "Sky-Dweller", dateAdded: new Date('2023-06-12'), popularity: 90,
              sellerComment: "CHOCOLATE DIAL - NEW 2023 - FRESH - AVAILABLE NOW - 326935",
               price: 59888, shippingCost: "$99", sellerCountry: "US",  sellerCity: "New York",
               images: [boughtWatchImage6, boughtWatchImage5, boughtWatchImage3]},
               {brand: "Rolex", modelName: "Cellini Dual Time", dateAdded: new Date('2023-06-13'), popularity: 400,
               sellerComment: "18K Rose Gold Black Dial on Black Leather Strap [COMPLETE SET 2021] 39mm",
                price: 16450, shippingCost: "$99", sellerCountry: "US",  sellerCity: "Los Angeles",
                images: [boughtWatchImage3, boughtWatchImage1, boughtWatchImage6]},
    ]

    const sliderRef = useRef();


    const handleArrowClick = (direction) => {
      if (direction === 'left') {
          sliderRef.current.slickPrev(); // this changes the slide to the previous one
      } else if (direction === 'right') {
          sliderRef.current.slickNext(); // this changes the slide to the next one
      }
  };

    return (
        <div className='centerContainer'>
            <div className="recommendedSellerListingsParentDiv"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
            >
                <p 
                className='recommendedListingsHeader'
                style={{
                fontFamily: commonFont.fontFamily,
                color: "black",
                }}>
                Other recommended listings from {sellerName}
                </p> 

                
                <div className='sellerProfileRecommendedListingsDiv'>
                    <CustomSliderArrow direction={"left"} onClick={() => handleArrowClick('left')}
                    showHoverArrows={showHoverArrows} sidePosition={"5px"} />
                    <Slider {...settings}
                    ref={sliderRef}
                    >
                    {watches.map((watchInfo, index) => (


                        

                        <SimplifiedWatchListing 
                            images={watchInfo.images}
                            watchListingInfo={watchInfo}
                            to='/watchListing3'
                        />



                    ))}
                    </Slider>
                    <CustomSliderArrow direction={"right"} onClick={() => handleArrowClick('right')}
                    showHoverArrows={showHoverArrows} sidePosition={"5px"}/>


                </div>
            </div>
        </div>


    );



}


export default RecommendedSellerListings;