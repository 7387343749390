import React, { useState, useEffect } from 'react';
import './WatchInfoComponentStyles.css';
import './commonStyles.css';
import DynamicTextBox from '../components/DynamicTextBox';
import SimpleButton from '../components/SimpleButton';
import LightBoldTextBox from '../components/LightBoldTextBox';
import WatchListingSpecificationsDiv from '../components/WatchListingSpecificationsDiv';
import SimpleDynamicTextBox from '../components/SimpleDynamicTextBox';
import { FaCcVisa, FaCcMastercard, FaCcStripe, FaCcPaypal } from 'react-icons/fa';
import { HiOutlineShieldCheck } from 'react-icons/hi';
import { PiFileTextBold } from 'react-icons/pi';


const WatchInfoComponent = ( {} ) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const WatchListingData = {
        brand: "Rolex",
        modelName: "Day-Date 36",
        dateAdded: new Date('2023-06-01'),
        popularity: 450,
        sellerComment: "Ref 118388 Men’s 18K Yellow Gold Black MOP Dia Dial, Bezel & Case 2006",
        price: 42995,
        shippingCost: "$99",
        sellerCountry: "US",
        sellerCity: "New York",
        condition: "New (Unworn)",
        productionYear: 2012,
        box: "Original",
        papers: "Original",
    }

    return (
        <div className='watchInfoComponentParentDiv'>
            <div className='centerContainer'>
                <div className='InfoComponentHeaderAndDescriptionAndSpecificationsParentDiv'>
                    <DynamicTextBox text={`${WatchListingData.brand} ${WatchListingData.modelName} `} color="white" fontSize="1.8rem" fontWeight="600" marginLeft="22px" marginTop="10px"  />
                    <div className='centerContainer'>
                        <div className='InfoComponentCommentAndSpecificationsParentDiv'>
                            <div className='InfoComponentCommentParentDiv'>
                            <SimpleDynamicTextBox text={
                                <>
                                <PiFileTextBold size={"1.3rem"}/>
                                <LightBoldTextBox boldText={" Short Description:"} boldTextFontWeight={"500"}/>
                                </>
                                } color={"white"} fontSize="1.1rem" fontWeight="500" marginLeft="0px" marginTop="10px" maxWidth={"100%"}/>
                                <span 
                                className='smallText watchListingInfoSmallText'
                                >{WatchListingData.sellerComment}</span>
                            </div>
                            <WatchListingSpecificationsDiv WatchListingData={WatchListingData} width={"93%"} marginTop={"20px"} />
                        </div>
                    </div>

                </div>
                
            </div>
            <div className='centerContainer'>
            <div className='InfoComponentPriceAndLogosAndButtonsParentDiv'>
                <div className='InfoComponentPaymentPriceAndLogosParentDiv'>
                    <div className='InfoComponentPriceParentDiv'>
                    <DynamicTextBox text={`$${WatchListingData.price}`} color="white" fontSize="1.8rem" fontWeight="600" marginRight={"5px"} marginTop="0px"  />
                    <SimpleDynamicTextBox text={`+${WatchListingData.shippingCost} Shipping Cost`} color={"white"} fontSize="0.9rem" fontWeight="300" marginLeft="0px" marginTop="0px" maxWidth={"100%"} height={"6px"}/>

                    </div>
                    <div className='paymentOptionsDiv'>
                    <span className='smallText watchListingInfoSmallText'>
                        We Accept: 
                    </span>
                    <div className='InfoComponentLogosParentDiv'>
                        <FaCcVisa size={"28px"}/>
                        <FaCcMastercard size={"28px"}/>
                        <FaCcPaypal size={"28px"}/>
                        <FaCcStripe size={"28px"}/>
                    </div>
                    </div>

                </div>

                <div className='InfoComponentBuyButtonsParentDiv'>
                    <SimpleButton 
                    to={"/buyWatch"}
                    text={"Buy Watch"} fontSize={"16px"} fontWeight={"600"} 
                    width={screenWidth > 900 ? "47%" : "95%"} height={"42px"} marginLeft={"0px"} color={"black"} 
                    hoverColor={"white"} backgroundColor={"white"} 
                    hoverBackgroundColor={"#4ad66d"} border={"4px solid black"} 
                    borderRadius={"5px"}
                    />
                    <SimpleButton to="/profileHome/conversations" text={"Chat With Seller"} 
                    fontSize={"16px"} fontWeight={"600"} 
                    width={screenWidth > 900 ? "47%" : "95%"}
                    height={"42px"} marginLeft={"0px"} color={"black"} 
                    hoverColor={"white"} backgroundColor={"white"} 
                    hoverBackgroundColor={"black"} border={"4px solid black"} 
                    borderRadius={"5px"}/>

                </div>
                <div className='InfoComponentBuyerProtectionParentDiv'>
                <span className='smallHeader primeTickProtectionHeader'>How PrimeTick Protects Its Buyers</span>
                    <div className='standardFlexDiv'>
                    <HiOutlineShieldCheck size={"1.5rem"}/>
                    <span 
                        className='smallThickText primeTickProtectionText'
                        style={{marginLeft: "5px"}}
                    >14 Day Guaranteed Return Policy</span>
                    </div>
                    <div className='standardFlexDiv'>
                    <HiOutlineShieldCheck size={"1.5rem"}/>
                    <span 
                        className='smallThickText primeTickProtectionText'
                        style={{marginLeft: "5px"}}
                    >Thorough Authenticity Check On Every Watch</span>
                    </div>
                    <div className='standardFlexDiv'>
                    <HiOutlineShieldCheck size={"1.5rem"}/>
                    <span 
                        className='smallThickText primeTickProtectionText'
                        style={{marginLeft: "5px"}}
                    >Payment via PrimeTick's Escrow Service</span>
                    </div>
                
                </div>

            </div>
            </div>

        </div>


    );



}


export default WatchInfoComponent;