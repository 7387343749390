
import React, { useState } from 'react';
import './ImageWithHoveringRemoveIconStyles.css';
import { RxCross2 } from 'react-icons/rx';

const ImageWithHoveringRemoveIcon = ( {image, handleRemoveImage} ) => {

    const [isHovered, setIsHovered] = useState(false);


    return (
        <div style={{marginTop: "5px", position: 'relative'}}>
        <img src={image} alt={`thumbnail`} 
        className='watchImageStyling2'
        />
            <button className='watchImageButtonRotateIcon2' 
            onMouseEnter={() => setIsHovered(true)} // Note the arrow function here
            onMouseLeave={() => setIsHovered(false)} 
            onClick={handleRemoveImage}
            style={{
                transition: 'transform 0.5s ease',
                backgroundColor: isHovered ? '#d81939' : 'white'

            }}
            >
                <div 
                        style={{
                            transform: isHovered ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.5s ease'
                        }}
                className='watchImageButtonIconWrapper2'>
                <RxCross2 
                    size={isHovered ? 16 : 20}
                    color={isHovered ? 'white' : 'black'}
                    />
                </div>
            </button>
        </div>


    );



}


export default ImageWithHoveringRemoveIcon;





