import React, { useEffect, useState } from "react";
import './ProfileHomeStyles.css';
import { useLocation } from 'react-router-dom';
import { useAuth } from "../api/authContext";
import HeaderComponent from "../components/HeaderComponent";
import IconLink from "../components/IconLink";
import { getUserData } from '../api/userService';
import { Routes, Route } from 'react-router-dom';
import { PiWatch, PiWatchFill } from 'react-icons/pi';
import { AiOutlineCreditCard, AiFillCreditCard, AiOutlineStock, AiOutlineShop, AiFillShop } from 'react-icons/ai';
import { commonFont, alternateColorScheme } from '../commonStyles';
import ProfileHomeSideBar from "../components/ProfileHomeSideBar";
import { BiCog, BiSolidCog } from 'react-icons/bi';
import { MdOutlineLogout } from 'react-icons/md';

import ExploreSection from '../components/profileSections/ExploreSection'; // Import different sections
import SellerDashBoardSection from "../components/profileSections/SellerDashBoardSection";
import ConversationsSection from "../components/profileSections/ConversationsSection";
import PaymentSection from '../components/profileSections/PaymentSection';

import SectionTwo from '../components/profileSections/ProfileSectionTwo';
import SignOutSection from '../components/profileSections/SignOutSection';
import ProfileHomeHamburger from "../components/ProfileHomeHamburger";



const ProfileHome = () => {
    const { currentUser } = useAuth();
    const [divWidth, setDivWidth] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const [disableTransition, setDisableTransition] = useState(false);

    useEffect(() => {
        if (currentUser) {
            getUserData(currentUser.uid)

                .then(userData => {
                    console.log('clientside: User Data:', userData); // Add this log statement
                });
        }
    }, [currentUser]);

    const location = useLocation('');
    const [activeLink, setActiveLink] = useState('');

    useEffect(() => {
        const section = location.pathname.split('/')[2];
        console.log("Active link:", section);  // Debugging line
        console.log("current location", location);
        setActiveLink(section); // Defaults to 'explore-primetick' if no section found
    }, [location]);

    useEffect(() => {
        setDisableTransition(true);
        setDivWidth(0);
      
        const timer = setTimeout(() => {
            setDisableTransition(false);
            windowWidth > 700 ? setDivWidth(300) : setDivWidth(250);
        }, 20); // Small delay to trigger the transition
      
        return () => clearTimeout(timer);
      }, [activeLink]);

    const isActive = (section) => {
        return activeLink === section ? 'selected-link' : '';
    };

    const whichHeader = (locationPath) => {
        switch (locationPath) {
          case 'explore-primetick':
            return 'Explore PrimeTick';
        case 'seller-dashboard':
            return 'Seller Dashboard';
        case 'conversations':
          return 'Conversations';
          case 'payment':
            return 'Payment';
          case 'section-two':
            return 'Settings';
          case 'sign-out':
            return 'Sign Out';
          default:
            return 'Unknown Path';
        }
      }

      const [windowWidth, setWindowWidth] = useState(window.innerWidth);

      useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    return (
            <div className="profileHomeContentDiv">
                    {(windowWidth > 1000 && activeLink !== 'conversations') ?
                    <ProfileHomeSideBar isActive={isActive} currentPathName={location.pathname}/>
                    : 
                    (windowWidth < 1000 &&
                    <ProfileHomeHamburger isActive={isActive}/>
                    )}

                <div className="profileHomeMainContentDiv"
                style={{
                  paddingLeft: windowWidth < 716 && activeLink === "payment" && "0px",
                }}
                >
                  <div className="profileHomeMainContentHeaderParent">


                    <p className="profileHomeMainContentHeader" 
                    
                    style={{
                                    fontFamily: commonFont.fontFamily,
                                    fontSize: "1.8rem",
                                    fontWeight: 400,
                                    color: "black",
                                    margin: "0px",
                                    marginBottom: "10px",
                                    marginLeft: "0px"
                                   }}>
                                    {whichHeader(activeLink)}
                            </p> 
                    </div>
                    <div 
                    className={`headerUnderlineDiv ${disableTransition ? 'no-transition' : ''}`}
                    style={{ width: `${divWidth}px` }}
                    >
                    </div>
                    <Routes>
                        <Route path="explore-primetick" element={<ExploreSection />} />
                        <Route path="seller-dashboard" element={<SellerDashBoardSection />} />
                        <Route path="conversations" element={<ConversationsSection />} />

                        <Route path="payment" element={<PaymentSection width={windowWidth} />} />
                        <Route path="section-two" element={<SectionTwo />} />
                        <Route path="sign-out" element={<SignOutSection />} />

                        {/* ...other routes... */}
                    </Routes>
                </div>
            </div>
    );
};




















export default ProfileHome;
