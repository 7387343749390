import React, { useState } from 'react';
import './AnalyticsKeyFiguresOverviewStyles.css';
import { commonFont, alternateColorScheme } from '../commonStyles';

import { LiaMoneyBillWaveSolid, LiaStar } from 'react-icons/lia';
import { MdOutlineAnalytics } from 'react-icons/md';


import { TfiWallet } from 'react-icons/tfi';
import { AiOutlineShoppingCart, AiOutlineEye } from 'react-icons/ai';
import { HiOutlineCursorClick } from 'react-icons/hi';
import { GoTrophy } from 'react-icons/go';



import { IoIosTimer } from 'react-icons/io';
import { IoTrendingUpSharp } from 'react-icons/io5';
import { TbDeviceWatchUp, TbFlagShare } from 'react-icons/tb';
import { VscPersonAdd } from 'react-icons/vsc';

import TodaysNumbersSellerDashboard from './TodaysNumbersSellerDashboard';
import SellerDashBoardKeyStatistics from './SellerDashBoardKeyStatistics';



const revenueAmount = 5070212;

const incomingPayments = 46023;

const salesTrend = 37;

const repeatCustomers = 24;

const avgOrderValue = 16720;



function formatNumberWithCommas(number) {
    const str = number.toString();
    let result = '';
    let count = 0;
    
    for (let i = str.length - 1; i >= 0; i--) {
      count++;
      result = str[i] + result;
      
      if (count % 3 === 0 && i !== 0) {
        result = ',' + result;
      }
    }
    
    return result + ".00";
  }

  const salesKeyStatistics = [
    {
        header: "Total Revenue",
        smallText: `$ ${formatNumberWithCommas(revenueAmount)}`,
        smallTextColor: '#6ede8a',
        icon: <LiaMoneyBillWaveSolid size={50} color='white'/>,
    },
    {
        header: "Sales Trend",
        smallText: `+ ${salesTrend}%`,
        smallTextColor: '#6ede8a',
        icon: <IoTrendingUpSharp size={50} color='white'/>,
    },
    {
        header: "Incoming Payments",
        smallText: `$ ${formatNumberWithCommas(incomingPayments)}`,
        smallTextColor: '#6ede8a',
        icon: <TfiWallet size={50} color='white'/>,
    },
    {
        header: "Best Performing Brand",
        smallText: `TAG Heuer`,
        smallTextColor: '#ffe566',
        icon: <TbDeviceWatchUp size={50} color='white'/>,
    },
 ]

 const customerBaseKeyStatistics = [
    {
        header: "Avg Review Score",
        smallText: `4.6`,
        smallTextColor: '#48cae4',
        icon: <LiaStar size={50} color='white'/>,
    },
    {
        header: "Repeat Customers",
        smallText: `${repeatCustomers}%`,
        smallTextColor: '#48cae4',
        icon: <VscPersonAdd size={50} color='white'/>,
    },
    {
        header: "Avg Order Value",
        smallText: `$ ${formatNumberWithCommas(avgOrderValue)}`,
        smallTextColor: '#6ede8a',
        icon: <AiOutlineShoppingCart size={50} color='white'/>,
    },
    {
        header: "Top Customer Country",
        smallText: `England`,
        smallTextColor: '#ffe566',
        icon: <TbFlagShare size={50} color='white'/>,
    },
 ]

 const listingEngagementKeyStatistics = [
    {
        header: "Total Views (month)",
        smallText: `7046 (+ 14%)`,
        smallTextColor: '#48cae4',
        icon: <AiOutlineEye size={50} color='white'/>,
    },
    {
        header: "Avg Listing Visit Time",
        smallText: `47 Seconds`,
        smallTextColor: '#48cae4',
        icon: <IoIosTimer size={50} color='white'/>,
    },
    {
        header: "Click-Through Rate",
        smallText: `2%`,
        smallTextColor: '#48cae4',
        icon: <HiOutlineCursorClick size={50} color='white'/>,
    },
    {
        header: "Top Traffic Source",
        smallText: `Instagram (35%)`,
        smallTextColor: '#ffe566',
        icon: <GoTrophy size={50} color='white'/>,
    },
 ]


const AnalyticsKeyFiguresOverview = ( {} ) => {





    return (
        <div className='listingsOverviewParentDiv'>
        <div className='sellerDashboardKeyFiguresParent'>

        <p className='sellerDashboardSmallHeader'
        >
            Todays Numbers
        </p>
        <TodaysNumbersSellerDashboard/>
        <p className='sellerDashboardSmallHeader'
        style={{
            marginTop: "25px"
        }}
        >
            Sales Metrics
        </p>
        <SellerDashBoardKeyStatistics
        keyStatistics={salesKeyStatistics}
        />
        <p className='sellerDashboardSmallHeader'
        style={{
            marginTop: "25px"
        }}
        >
            Customer Base
        </p>
        <SellerDashBoardKeyStatistics
        keyStatistics={customerBaseKeyStatistics}
        />
                <p className='sellerDashboardSmallHeader'
        style={{
            marginTop: "25px"
        }}
        >
            Listing Engagement
        </p>
        <SellerDashBoardKeyStatistics
        keyStatistics={listingEngagementKeyStatistics}
        />
                <button 
          className="sellerDashboardKeyFiguresParentButton"

        >
            <p 
            style={{
                fontSize: "1.2rem",
                fontWeight: 500,
                color: "white",
                margin: "0px",
            }}
            >
                Get Detailed Analytics
            </p>
            <MdOutlineAnalytics size={35} color='white'/>
        </button>
        </div>

        </div>
    );



}


export default AnalyticsKeyFiguresOverview;