import './TextBoxStyles.css';

function TextBox({ children, color, fontWeight, fontSize }) {


    return (
        <div className='divStyle'>
        <span style={{fontSize: fontSize}} className='spanStyle'>{children}</span>
        </div>
    );
}

export default TextBox;