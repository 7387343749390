import LoginForm from '../components/LoginForm';
import HeaderComponent from '../components/HeaderComponent';

const LoginPage = () => {
  return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <HeaderComponent text="Sign In Or Create An Account" />
          <LoginForm />
      </div>
  );
}
export default LoginPage;