import React from 'react';
import './DescriptionComponentStyles.css';

const DescriptionComponent = ( {description} ) => {



    return (
        <div className="descriptionComponentParentDiv">
              <p style={{color: 'white', fontSize: '18px', fontWeight: '400', lineHeight: 1.6, marginTop: "0px"}}>
                {description}
                </p>
        </div>


    );



}


export default DescriptionComponent;