import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './SimpleButtonStyles.css';

const SimpleButton = ({ to, text, fontSize, fontWeight, width, height, marginLeft, marginTop, color, hoverColor, backgroundColor, hoverBackgroundColor, border, borderRadius }) => {

    const [isHovered, setHovered] = useState(false);

    const handleClick = (event) => {
        console.log('Button clicked');
    };

    
    const simpleButtonStyles = {
        fontSize: fontSize,
        fontWeight: fontWeight,
        width: "100%",
        height: "100%",
        marginLeft: marginLeft,
        marginTop: marginTop,
        color: color,
        backgroundColor: backgroundColor,
        border: border,
        borderRadius: borderRadius,
        boxSizing: "borderBox",
        transition: "color 0.2s ease" // This is the converted line

    }

    const hoveredSimpleButtonStyles = {
        ...simpleButtonStyles,
        color: hoverColor, 

    };


    return (

        <Link 
        to={to}
        style={{
            width: width,
            height: height,
            textDecoration: "none"
        }}
        >
        <button
        type="submit" 
        className='simpleButtonCssStyles'
        style={isHovered ? hoveredSimpleButtonStyles : simpleButtonStyles} 
        onClick={handleClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        >
            {text}
        </button>
        </Link>
    );
}



export default SimpleButton;