import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import SliderArrow from '../SliderArrow';
import CustomSliderArrow from '../CustomSliderArrow';
import { Link } from 'react-router-dom';
import HoverPopupDiv from '../HoverPopupDiv';
import DynamicDropDown from '../DynamicDropDown';
import WatchKeyStatsSummary from '../WatchKeyStatsSummary';
import { commonFont, latoFont, montserratFont, playfairDisplayFont, ralewayFont, merriweatherFont, oswaldFont } from '../../commonStyles';
import { HiOutlineLocationMarker, HiLocationMarker } from 'react-icons/hi';
import './BuyWatchPreviewStyles.css';
import boughtWatchImage1 from '../../assets/fakeListingImages/watchListing1.png';
import boughtWatchImage2 from '../../assets/fakeListingImages/watchListing2.png';
import boughtWatchImage3 from '../../assets/fakeListingImages/watchListing3.png';
import { RxFontRoman } from 'react-icons/rx';

function BuyWatchPreview({  }) {

    const [showHoverArrows, setShowHoverArrows] = useState(null);
    const [locationIsHovered, setLocationIsHovered] = useState(null);

    const listingSliderRef = useRef();

    const handleArrowClick = (event, direction) => {
        event.preventDefault(); // Prevent the default anchor link behavior
        event.stopPropagation(); // Prevent the link navigation

        if (direction === 'left') {

            listingSliderRef.current.slickPrev(); // this changes the slide to the previous one
        } else if (direction === 'right') {
            listingSliderRef.current.slickNext(); // this changes the slide to the next one
        }
    };

    const handleMouseEnter = () => {
        setShowHoverArrows(true);
      };
    
      const handleMouseLeave = () => {
        setShowHoverArrows(false);
      };
    

    const settings = {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // set this to false to remove the default arrows
      };

      const watchImages= [boughtWatchImage1, boughtWatchImage2, boughtWatchImage3];

      const watchKeyStats = {
        condition: "Pre-Owned (Very Good)",
        productionYear: 2012,
        box: "Original",
        papers: "Original",
    };

      const dropDownContent = 
        <div className='buyWatchSummaryDropdownContent'>
            <WatchKeyStatsSummary width={"100%"} height={"190px"} watchKeyStats={watchKeyStats} />
        </div>;
      
        const sellerComment = "41mm Rose Gold with Black Dial | Mint Condition | Full Set | 15400OR";

        function truncateComment(str, num) {
            if (str.length > num) {
              return str.slice(0, num) + '...';
            } else {
              return str;
            }
          }

    return (

        <div className='buyWatchPreviewParent'>
        <Link 
        className='buyWatchSummaryLink'
        to={'http://localhost:3000/watchlisting3'} style={{textDecoration: 'none', color: 'black'}}>
            <div className='buyWatchPreviewSliderParentDiv'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            >
            <CustomSliderArrow direction={"left"} onClick={(event) => handleArrowClick(event, 'left')}
                    showHoverArrows={showHoverArrows} 
                    top={"41%"}
                    sidePosition={"2px"} />

            <Slider ref={listingSliderRef} {...settings} className='buyWatchsliderStyles'>
            {watchImages.map((image, index) => (
                        <img
                        className='buyWatchPreviewImage'
                        src={image}
                        >
                        </img>
                    ))}
            </Slider>

            <CustomSliderArrow direction={"right"} onClick={(event) => handleArrowClick(event, 'right')}
                    showHoverArrows={showHoverArrows} 
                    top={"41%"}
                    sidePosition={"2px"} />
            </div>
            <p className='buyWatchPreviewSellerHeader'
            style={{
                font: latoFont,

            }}
            >
                Audemars Piguet Royal Oak Selfwinding

            </p> 
            <p className='buyWatchPreviewSellerComment'
            style={{
                font: latoFont,

            }}
            >
                {truncateComment(sellerComment, 42)}
            </p>

            <Link 
        className='buyWatchSummaryLink'
        to={'http://localhost:3000/sellerProfile'} style={{textDecoration: 'none', color: 'black'}}>
            <div className='buyWatchPreviewSellerInfo'

            >
            <p className='buyWatchPreviewSellerComment'
            style={{
                font: latoFont,
                fontWeight: 500,
                margin: "0px",
            }}
            >
                Freddie Keeble Watches & Diamonds
            </p>
            <div className='buyWatchPreviewSellerLocation'
                onMouseEnter={() => setLocationIsHovered(true)}
                onMouseLeave={() => setLocationIsHovered(false)}

            >
                
                {locationIsHovered ? 
                    <>
                    <HiLocationMarker size={20}/>
                    <HoverPopupDiv width={"220px"} height={"40px"} 
                    text={"This seller is from New York, US."}
                    top={"-52px"}
                    left={"-184px"}
                    />
                    </>
                    :
                    <HiOutlineLocationMarker size={20}/>

                }
                <p 
                style={{
                    margin: "0px",
                    fontSize: "0.8rem",
                    fontWeight: 500,
                }}
                >
                    US
                </p>
            
            </div>
            </div>
            </Link> 

            

            <DynamicDropDown width={"370px"} height={"45px"}
            headerText={"Additional Information"}
            contentHeight={"190px"}
            content={dropDownContent}
            iconSize={"24px"}
            />  
             </Link> 
        </div>
    );
}

export default BuyWatchPreview;