import './LargeListingStatisticsButtonContentStyles.css';
import React, { useState } from 'react';
import './LargeListingStatisticsBigButtonStyles.css';
import { IoTimerOutline } from 'react-icons/io5';

import { IoIosArrowDropup } from 'react-icons/io';
import { alternateColorScheme } from '../commonStyles';


function LargeListingStatisticsButtonContent3({}) {


    return (
        <>
        <IoTimerOutline color="black" size={17} style={{marginRight: "32px"}}/>
        <div 
        style={{
            display: 'flex',
            width: "90%",
            height: "25px",
            }}      
        >
        <p className='listingPlacementText'>
            32
        </p>
        <p className='listingPlacementTextSmall'>
            s
        </p>
        </div>
        </>
    );
}

export default LargeListingStatisticsButtonContent3;