import React, { useState, useEffect } from 'react';
import './PaymentCardStackStyles.css';
import CardStack from '../CardStack';
import { RxCross2 } from 'react-icons/rx';

function PaymentCardStack({ paymentCards, handleDeleteClick, setAddPaymentCard, width }) {

    const [addCardButtonIsHovered, setAddCardButtonIsHovered] = useState(false);

      function calculateParentPadding(cardContentLength) {
        switch (true) {
            case cardContentLength === 1:
                return width < 450 ? 0 : 20;
            case cardContentLength === 2:
                return width < 450 ? 0 : 40;
            case cardContentLength === 3:
                return width < 450 ? 0 : 55;
            default:
                return width < 450 ? 0 : 65;
        }
    }
    
    function calculateParentWidthAndHeight(cardContentLength) {
        switch (true) {
            case cardContentLength === 1:
                return [width > 715 ? "90%" : "100%", 595];
            case cardContentLength === 2:
                return [width > 715 ? "90%" : "100%", 595];
            case cardContentLength === 3:
                return [width > 715 ? "90%" : "93%", 595];
            default:
                return [width > 715 ? "90%" : "95%", 595];
        }
    }

    return (
        <div className='paymentCardsParentDiv'
        style={{
            width: calculateParentWidthAndHeight(paymentCards.length)[0],
            height: calculateParentWidthAndHeight(paymentCards.length)[1],
            paddingTop: calculateParentPadding(paymentCards.length) + 45,
            paddingLeft: calculateParentPadding(paymentCards.length),
        }}
        >
            <p
            className='paymentSectionSmallHeader'
            style={{
                color: width < 450 ? "white" : "white"
            }}
            >
                Registered Cards
            </p>
        <CardStack
            numCards={paymentCards.length}
            cardContent={paymentCards}
            handleDeleteClick={handleDeleteClick}
            width={width}
        />
        <button className='addPaymentCardButton'
        onMouseEnter={() => setAddCardButtonIsHovered(true)}
        onMouseLeave={() => setAddCardButtonIsHovered(false)}
        onClick={() => {
            if (paymentCards.length < 4) {
            setAddPaymentCard(true);
            }
        }}
        >
        {paymentCards.length === 4 && addCardButtonIsHovered ? <RxCross2 size={22}/> : "+"}

        
        </button>
        {(paymentCards.length == 4 && addCardButtonIsHovered) &&
        <div className='addPaymentCardButtonPopup'>
            <p style={{margin: "0px"}}>
                4 cards is max
            </p>
        </div>
        }
        </div>
    );
}

export default PaymentCardStack;