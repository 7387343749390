import './ConversationsSectionStyles.css';
import Conversation from '../ConversationsFolder/Conversation';
import { Link } from 'react-router-dom';
function ConversationsSection({  }) {


    return (
        <div className='ConversationsSectionParent'>
            <Link to='/profileHome/explore-primetick'>
            <p className='conversationsSectionBackLinkText'>
                {"< Return"}
            </p>
            </Link>
            <Conversation/>
        </div>
    );
}

export default ConversationsSection;