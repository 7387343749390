import { Link } from "react-router-dom"

const ResetPasswordLink = () => {
    return (
        <Link to="/password-reset" style={resetPasswordLinkStyle}>Forgot your password?</Link>
        )



}

const resetPasswordLinkStyle = {
    marginTop: '20px', 

    color: '#000',
}

export default ResetPasswordLink;