import React, {useState} from 'react';
import './ShippingInfoComponentStyles.css';
import './commonStyles.css';
import SimpleDynamicTextBox from './SimpleDynamicTextBox';
import LightBoldTextBox from './LightBoldTextBox';
import SellerInfoComponent from './SellerInfoComponent';
import { HiCheck, HiX, HiOutlineInformationCircle, HiInformationCircle, HiMiniQuestionMarkCircle } from 'react-icons/hi';
import { BiSolidPaperPlane } from 'react-icons/bi';
import { TbTruckDelivery } from 'react-icons/tb';
import { FaDhl, FaFedex, FaUps } from 'react-icons/fa';

import { color, motion } from "framer-motion";
import { Link } from 'react-router-dom';




const ShippingInfoComponent = ( {marginLeft, marginTop} ) => {

    const [hoveringDeliveryButton, setHoveringDeliveryButton] = useState(false);


    let deliveryDate = new Date();
    deliveryDate.setDate(deliveryDate.getDate() + 3);
    let deliveryDateString = deliveryDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD

    let deliveryInformation = 
    <>
    <LightBoldTextBox lightText={"1-2 Days"} lightTextFontWeight={"500"} boldText={"This Item Is Ready To Ship Within "} boldTextFontWeight={"400"}/>
    <LightBoldTextBox lightText={"3-6 Days."} lightTextFontWeight={"500"} boldText={". Expect Delivery Between "} boldTextFontWeight={"400"}/>

    </>

        const WatchListingData = {
            brand: "Rolex",
            modelName: "Day-Date 36",
            dateAdded: new Date('2023-06-01'),
            popularity: 450,
            sellerComment: "Ref 118388 Men’s 18K Yellow Gold Black MOP Dia Dial, Bezel & Case 2006",
            price: 42995,
            shippingCost: "$99",
            sellerCountry: "US",
            sellerCity: "New York",
            condition: "New (Unworn)",
            productionYear: 2012,
            box: "Original",
            papers: "Original",
            seller: "Professional Dealer",
            inStock: true,
            deliveryTime: 4,
        }

    const shippingInfoParentDivStyle = {
        marginTop: marginTop,
        marginLeft: marginLeft,

    }


    return (
        <div className='shippingComponentUpmostParent'>

            <SimpleDynamicTextBox text={"Seller And Shipping Information"} fontSize={"1.5rem"} fontWeight={"600"} color={"white"} marginLeft={"5px"} marginTop={"0px"}/>
            <div className='shippingUnderline'></div>
            <div className='sellerAndShippingRowDiv'>
            <div className='shippingInfoParentDiv'>

                <SimpleDynamicTextBox text={ 
                    WatchListingData.inStock ? 
                    <>
                    <HiCheck size={"20px"} color='#4ad66d'/>
                    <span className='shippingText'> Item Is In Stock</span>
                    </>
                    :
                    <>
                    <HiX size={"20px"} color='red'/>
                    <LightBoldTextBox boldText={" Item Is Not In Stock"} boldTextFontWeight={"450"} />
                    </>
                    
                    } color={"white"} fontSize="1.1rem" fontWeight="410" marginLeft="10px" marginTop="14px" maxWidth={"90%"}/>
                    <div className='shippingInfoDeliveryDateDiv'>

                        <SimpleDynamicTextBox text={ 
                            <>
                            <TbTruckDelivery size={"20px"}/>
                            <LightBoldTextBox boldText={" Expected Delivery: \n"} boldTextFontWeight={"450"} lightText={deliveryDateString} lightTextFontWeight={"400"} />
                            </>
                            } color={"white"} fontSize="1.1rem" fontWeight="410" marginLeft="10px" marginTop="0px" maxWidth={"90%"}/>
                            {<div className='deliveryInfoButton'
                            onMouseEnter={() => setHoveringDeliveryButton(true)} 
                            onMouseLeave={() => setHoveringDeliveryButton(false)}
                            >
                            {hoveringDeliveryButton ? <HiInformationCircle size={"24px"} /> : <HiOutlineInformationCircle size={"24px"}/>}
                
                        </div>}
                        {hoveringDeliveryButton &&
                            <motion.div className='deliveryInfoHoverDiv'
                            initial={{ scale: 0, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0, opacity: 0 }}
                            transition={{ duration: 0.1 }}
                            >
                            {deliveryInformation}
                            </motion.div>}
                    </div>

                
                    <Link to="/primeTickShipping" className='learnAboutShippingLink' style={{marginTop: "6px"}}>
                        Learn More About Shipping On PrimeTick</Link>
            </div>
            <SellerInfoComponent marginLeft={"25px"} marginTop={"10px"}/>

            </div>
        </div>
        );


}


export default ShippingInfoComponent;




