import React, { useState, useEffect } from 'react';
import './NewNavbarStyles.css';
import { Link } from 'react-router-dom';
import NewSearchBar from './NewSearchBar';
import { colors, alternateColorScheme } from '../commonStyles';
import Altlogo from '../assets/SellWatchPageImages/alternatelogo.png';
import AltlogoWithoutBackground from '../assets/SellWatchPageImages/logowithoutbackground.png';
import { useAuth } from '../contexts/AuthContext'; // Adjust the path as necessary




const logo = '/images/PrimeTickLogo1.png';

const NewNavbar = () => {

    const { isAuthenticated } = useAuth();
    console.log("isAuthenticated:", isAuthenticated); // Add this line

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function decideWidthBasedOnScreenSize(screenSize) {
        switch (true) {
            case (screenSize <= 400):
                return "260px"; // Return an integer for screens smaller than 500px
                case (screenSize > 400 && screenSize < 500):
                    return "330px"; // Return an integer for screens smaller than 500px
            case (screenSize >= 500 && screenSize < 600):
                return "400px"; // Return a different integer for screens between 500px and 600px
                case (screenSize >= 600 && screenSize < 720):
                    return "480px"; // Return a different integer for screens between 500px and 600px

                case (screenSize >= 720 && screenSize <= 820):
                    return "560px"; // Return a different integer for screens between 500px and 600px

            default:
                return "660px"; // Default return value for other sizes
        }
    }

    return (
      <div className="newNavbarParentDiv">
        <div className='newNavBarLogoAndSearchParentDiv'>
            <div className='newNavbarLogoParentDiv'>
            <Link to={"exploreWatches"}>
            <div className='newNavBarLogoDiv'
                style={{
                borderRadius: '48px',
                backgroundImage: `url(${AltlogoWithoutBackground})`,
                backgroundSize: 'contain',  // You can also try 'contain' if you want
                backgroundPosition: 'center', // Adjust this to focus the part of the image you want to show
                transform: 'scale(1)'
            }}
            ></div>
            </Link>
            </div>
            <div className='newNavbarSearchBarParentDiv'>
            <NewSearchBar width={decideWidthBasedOnScreenSize(screenWidth)} height={"43px"}/>

            </div>
        </div>
        <div className='newNavBarLinksParentDiv'>
            <nav style={{display: 'flex', width: '100%', height: "100%"}}>
                <ul style={{display: 'flex',  width: '100%', height: "100%", margin: "0px", padding: "0px"}}>
                    <li className='newNavBarLink'>
                        <Link to='/explorewatches' style={{textDecoration: 'none'}}>
                            <p className='newNavbarLinkTextStyle'
                            
                            >
                                Explore Watches
                            </p>
                        </Link>
                    </li>
                    <li className='newNavBarLink'>
                        <Link to='/sell/watchdetails' style={{textDecoration: 'none'}}>
                            <p className='newNavbarLinkTextStyle'>
                                Sell A Watch
                            </p>
                        </Link>
                    </li>
                    <li className='newNavBarLink'>
                        <Link to='/faq' style={{textDecoration: 'none'}}>
                            <p className='newNavbarLinkTextStyle'>
                                Faq
                            </p>
                        </Link>
                    </li>
                    <li className='newNavBarLink'>
                        {isAuthenticated ? 
                        <Link to='/profileHome/explore-primetick' style={{textDecoration: 'none'}}>
                        <p className='newNavbarLinkTextStyle'>
                            Profile
                        </p>
                        </Link>
                        :
                        <Link to='/loginAndRegister' style={{textDecoration: 'none'}}>
                            <p className='newNavbarLinkTextStyle'>
                                Login/Register
                            </p>
                        </Link>
                        }
                    </li>
                </ul>
            </nav>
        </div>
      </div>
    );
  }
  
  export default NewNavbar;
  