const SimpleDynamicTextBox = ({ text, color, fontFamily, fontSize, fontWeight, marginLeft, marginTop, marginBottom, paddingLeft, width, maxWidth, height, spanHeight, className, backgroundColor, borderRadius }) => {


    const simpleDynamicTextBoxDivStyle = {
        fontFamily: fontFamily,
        fontSize: fontSize,
        fontWeight: fontWeight,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
        marginTop: marginTop,
        paddingLeft: paddingLeft,
        maxWidth: maxWidth,
        width: width,
        height: height,
        borderRadius: borderRadius,
        backgroundColor: backgroundColor,
    }

    const simpleDynamicTextBoxSpanStyle = {

        display: 'flex',
        alignItems: 'center',
        marginLeft: marginLeft,
        color: color,
        fontSize: fontSize,
        fontWeight: fontWeight,
        height: spanHeight,
        
    }

  return (
    <div style={simpleDynamicTextBoxDivStyle}>
        <span 
        className={className}
        style={simpleDynamicTextBoxSpanStyle}>
            {text}
        </span>
    </div>
  );
};

export default SimpleDynamicTextBox;
