import React from 'react';
import './SellerProfileKeyStatsStyles.css';

import SimpleDynamicTextBox from '../components/SimpleDynamicTextBox';

import { commonFont, alternateColorScheme } from '../commonStyles';

import { BsBarChart } from 'react-icons/bs';
import { TbHexagonNumber8 } from 'react-icons/tb';



import { PiWatch, PiMapPinLight, PiGlobeLight, PiCertificate } from 'react-icons/pi';

const sellerProfileKeyStats = [
    {
        header: "Sales",
        childText: "348 Watches Sold",
        icon: <BsBarChart/>,

    },
    {
        header: "Location",
        childText: "Stockholm, Sweden",
        icon: <PiMapPinLight/>,

    },
    {
        header: "Listings",
        childText: "24 Live Listings",
        icon: <PiWatch/>,

    },

    {
        header: "Languages",
        childText: "English, Swedish",
        icon: <PiGlobeLight/>,

    },
    {
        header: "Ranking",
        childText: "Currently 8th biggest seller on PrimeTick",
        icon: <TbHexagonNumber8/>,

    },
    {
        header: "Trusted Seller",
        childText: "Trusted Seller On PrimeTick Since 2023",
        icon: <PiCertificate/>


    },

]

const SellerProfileKeyStats = ( {} ) => {

    return (
        <div className='sellerProfileKeyStatsDiv'>
            {sellerProfileKeyStats.map((stat, index) => (
                <div className='sellerProfileKeyStat' key={index}>
                    <div className='keyStatIcon'>
                        {stat.icon}
                    </div>
                    <div className='keyStatHeaderAndTextDiv'>
                        <p className='keyStatHeader' style={{ fontFamily: commonFont.fontFamily }}>
                            {stat.header}
                        </p>
                        <p className='keyStatChildText' style={{ fontFamily: commonFont.fontFamily }}>
                            {stat.childText}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );



}


export default SellerProfileKeyStats;