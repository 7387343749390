import React, { useState, useEffect } from 'react';
import './ThanksForPurchasePageStyles.css';
import '../components/commonStyles.css';
import { Link } from 'react-router-dom';
import BasicButton from '../components/BasicButton';
import BuyWatchPreview from '../components/BuyWatchPageComponents/BuyWatchPreview';
import ThanksForPurchaseWatchPreview from '../components/ThanksForPurchaseWatchPreview';
function ThanksForPurchasePage({  }) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const underlineStyle = {
        '--underline-width': `92%`,
      };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className='thanksForPurchaseParent'>
            <p className='header thanksForPurchaseHeader'
            style={{
                alignSelf: "center",
                fontSize: windowWidth > 550 ? "2.2rem" : windowWidth > 440 ? "1.9rem" : "1.7rem"
            }}
            >
                Thank you for your purchase
            </p>
            <div className='thanksForPurchaseInfo'>
            {(windowWidth <= 800 && windowWidth > 440) &&
                <ThanksForPurchaseWatchPreview/>
                }
                <div className='thanksForPurchaseTextDiv'>
                    <p className='text thanksForPurchaseText'
                    style={{
                        fontSize: "1.9rem"
                    }}
                    >
                        Congratulations
                    </p>
                    <p className='thanksForPurchaseText'
                    style={{
                        marginTop: "6px"
                    }}
                    >
                        On buying your 2nd watch on PrimeTick.
                    </p>
                    <div className='underLineDiv animatedUnderlineExpand'
                    style={
                        underlineStyle
                    }
                    >

                    </div>

                    <p className='thanksForPurchaseText'
                    style={{
                        marginTop: "20px",
                        fontWeight: 500
                    }}
                    >
                        Contact the seller: 
                    </p>
                    <p className='text thanksForPurchaseText'
                    style={{
                        marginTop: "10px",
                        fontWeight: 400
                    }}
                    >
                         <b>Mail:</b> fkeeblewatches@gmail.com
                    </p>
                    <p className='text thanksForPurchaseText'
                    style={{
                        marginTop: "9px",
                        fontWeight: 400,
                        marginBottom: "25px"
                    }}
                    >
                        <b>Tel:</b> 070-334-12-65
                    </p>

                    <div className='underLineDiv animatedUnderlineExpand'
                    style={
                        underlineStyle
                    }
                    >

                    </div>

                    <p className='text thanksForPurchaseText'
                    style={{
                        marginTop: "35px",
                        fontWeight: 400
                    }}
                    >
                        For any questions reach out to us at: primetick.com/support
                    </p>
                    <p className='text thanksForPurchaseText'
                    style={{
                        marginTop: "12px",
                        fontWeight: 400
                    }}
                    >
                        We will do our very best to resolve any issue or answer any question.
                    </p>
                    <Link to="/profileHome/explore-primetick" className="no-underline"
                    style={{
                        marginTop: "50px",
                        width: "280px",

                    }}
                    >
                        <button className='visitProfileButton'
                        
                        >
                            <p className='standardButtonTextStyle'>
                                Visit Profile

                            </p>
                        </button>
                        </Link>
                </div>
                {windowWidth > 800 &&
                <ThanksForPurchaseWatchPreview/>
                }
            </div>
        </div>
    );
}

export default ThanksForPurchasePage;