import React, { useState, useEffect} from 'react';
import './PersonalInformationPageStyles.css';
import SimpleDynamicTextBox from '../components/SimpleDynamicTextBox';
import SimpleTextInput from '../components/SimpleTextInput';
import FilterDropDown from '../components/FilterDropDown';
import AnimatedButton from '../components/AnimatedButton';
import { Link } from 'react-router-dom';
import { MdHeight } from 'react-icons/md';

const PersonalInformationPage = ( {personalInformationFormData, setPersonalInformationFormData} ) => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const [width, setWidth] = useState(window.innerWidth);

      useEffect(() => {
          const handleResize = () => setWidth(window.innerWidth);
          window.addEventListener('resize', handleResize);
  
          return () => {
              window.removeEventListener('resize', handleResize);
          };
      }, []);

      const handleChange = (field, value) => {
        setPersonalInformationFormData(prevPersonalInformationData => ({
            ...prevPersonalInformationData,
            [field]: value
        }));
    };

    const dayoptions = Array.from({ length: 31 }, (_, index) => ({
        value: index + 1, // Values should start from 1
        label: index + 1 // Labels should start from 1
    }));
    const months = [
        { label: "January", value: "January" },
        { label: "February", value: "February" },
        { label: "March", value: "March" },
        { label: "April", value: "April" },
        { label: "May", value: "May" },
        { label: "June", value: "June" },
        { label: "July", value: "July" },
        { label: "August", value: "August" },
        { label: "September", value: "September" },
        { label: "October", value: "October" },
        { label: "November", value: "November" },
        { label: "December", value: "December" }
      ];
      
      const countries = [
        { label: "Afghanistan", value: "Afghanistan" },
        { label: "Albania", value: "Albania" },
        { label: "Algeria", value: "Algeria" },
        { label: "Andorra", value: "Andorra" },
        { label: "Angola", value: "Angola" },
        { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
        { label: "Argentina", value: "Argentina" },
        { label: "Armenia", value: "Armenia" },
        { label: "Australia", value: "Australia" },
        { label: "Austria", value: "Austria" },
        { label: "Sweden", value: "Sweden" },
        { label: "Yemen", value: "Yemen" },
        { label: "Zambia", value: "Zambia" },
        { label: "Zimbabwe", value: "Zimbabwe" }
      ];

      const countryPhoneExtensions = [
        { label: "Afghanistan +93", value: "Afghanistan +93" },
        { label: "Albania +355", value: "Albania +355" },
        { label: "Algeria +213", value: "Algeria +213" },
        { label: "Andorra +376", value: "Andorra +376" },
        { label: "Angola +244", value: "Angola +244" },
        { label: "Antigua and Barbuda +1-268", value: "Antigua and Barbuda +1-268" },
        { label: "Argentina +54", value: "Argentina +54" },
        { label: "Armenia +374", value: "Armenia +374" },
        { label: "Australia +61", value: "Australia +61" },
        { label: "Austria +43", value: "Austria +43" },
        { label: "Sweden +46", value: "Sweden +46" },
        { label: "Yemen +967", value: "Yemen +967" },
        { label: "Zambia +260", value: "Zambia +260" },
        { label: "Zimbabwe +263", value: "Zimbabwe +263" }
      ];
      
      
    

      let nameAndBirth = [

        {
            category: "First Name",
            clickableOptions: null,
            textInput: true,
            placeholderText: "",

        },
        {
            category: "Last Name",
            dropdownOptions: null,
            clickableOptions: null,
            textInput: true,
            placeholderText: "",

        },
        {
            category: "Date Of Birth",
            dropdownOptions: null,
            clickableOptions: null,
            textInput: false,
            placeholderText: "",

        }
    ]

    let Address = [

        {
            category: "Street",
            dropdownOptions: null,
            clickableOptions: null,
            textInput: true,
            placeholderText: "",

        },
        {
            category: "Street Line 2",
            dropdownOptions: null,
            clickableOptions: null,
            textInput: true,
            placeholderText: "",

        },
        {
            category: "ZIP Code",
            dropdownOptions: null,
            clickableOptions: null,
            textInput: false,
            placeholderText: "",

        },
        {
            category: "Country",
            dropdownOptions: null,
            clickableOptions: null,
            textInput: false,
            placeholderText: "",

        },
        {
            category: "State",
            dropdownOptions: null,
            clickableOptions: null,
            textInput: true,
            placeholderText: "",

        },
        {
            category: "Phone Number",
            dropdownOptions: null,
            clickableOptions: null,
            textInput: false,
            placeholderText: "",
 
        },



    ]


    return (
        <div className='personalInfomationUpmostParent'>
        <div className="personalInformationPageParentDiv">
            <SimpleDynamicTextBox text={"Personal Information"} fontSize={width > 500 ? "2.2rem" : "1.8rem"} fontWeight={500} color={"#003251"}/>
            <SimpleDynamicTextBox text={"Please review your personal information."} fontSize={"1rem"} fontWeight={400} color={"#003251"}
            marginTop={"30px"}/>
            <div style={{
                width: "80%",
                height: "1.5px",
                marginTop: width > 500 ? "50px" : "30px",
                backgroundColor: "black"
            }}>
            </div>

            <div className='personalInformationSectionDiv'>
                <SimpleDynamicTextBox text={"Name And Birth Information"} fontSize={width > 570 ? "2rem" : width > 500 ? "1.6rem" : "1.4rem"} fontWeight={500} color={"white"}/>
                {nameAndBirth.map((item, index) => (
                    <div key={index} className='personalInformationSectionRow'>
                        <div className='personalInformationSectionRowFirstItem'>
                        <SimpleDynamicTextBox text={item.category} fontSize={width > 1165 ? "1.1rem" : width > 1032 ? "1rem" : "1.1rem"} fontWeight={400} color={"white"} />
                        </div>
                        <div className='personalInformationSectionRowSecondItem'>
                        {item.textInput && 
                        <SimpleTextInput width={"520px"} height={"38px"} fontSize={"1rem"}
                        border={"1px solid black"}
                        onChange={(e) => handleChange(item.category, e.target.value)}
                        placeholder={item.placeholderText} 
                        borderRadius={"6px"} paddingLeft={"10px"}/>
                        } 
                        {item.category === "Date Of Birth" && (
                            <>
                            <FilterDropDown options={dayoptions}  
                                handleChange={(selectedOption) => handleChange("Birthday", selectedOption.value)}
                                marginLeft={"0px"} 
                                width={width > 1345 ? "116px" : width > 1250 ? "114px" : width > 1032 ? "84px" : width > 780 ? "116px" : width > 680 ? "108px" : width > 570 ? "78px" : width > 500 ? "106px" : "70px"} height={"40px"} borderRadius={"6px"} border={"1px solid black"}/>
                            <FilterDropDown options={months}  
                                handleChange={(selectedOption) => handleChange("BirthMonth", selectedOption.value)}
                                marginLeft={"23px"} 
                                width={width > 1345 ? "240px" : width > 1165 ? "184px" : width > 1032 ? "170px" : width > 900 ? "240px" : width > 780 ? "200px" : width > 780 ? "150px" : width > 500 ? "140px" : "100px"} height={"40px"} borderRadius={"6px"} border={"1px solid black"}/>
                            <SimpleTextInput width={width > 1345 ? "116px" : width > 1165 ? "112px" : width > 1032 ? "83px" : width > 900 ? "104px" : width > 680 ? "80px" : width > 500 ? "77px" : "65px"} height={"38px"} fontSize={"1rem"}
                                border={"1px solid black"} marginLeft={"23px"}
                                onChange={(e) => handleChange("BirthYear", e.target.value)}
                                placeholder={"Year"} 
                                borderRadius={"6px"} paddingLeft={"10px"}/>
                            </>
                        )}
                        </div>
                    </div>
                ))}
            </div>
            <div style={{width: width > 680 ? "70%" : "86%", height: "1.5px", backgroundColor: "black",
            marginTop: "30px",
            }}>
            </div>
            <div className='personalInformationSectionDiv'>
                <SimpleDynamicTextBox text={"Address"} fontSize={"2rem"} fontWeight={500} color={"white"}/>
                {Address.map((item, index) => (
                    <div key={index} className='personalInformationSectionRow'>
                        <div className='personalInformationSectionRowFirstItem'>
                        <SimpleDynamicTextBox text={item.category} fontSize={width > 1165 ? "1.1rem" : width > 1032 ? "1rem" : (width < 680 && item.category == "Phone Number") ? "1rem" : "1.1rem"} fontWeight={400} color={"white"} />
                        </div>
                        <div className='personalInformationSectionRowSecondItem'>
                        {item.textInput && 
                        <SimpleTextInput width={"520px"} height={"38px"} fontSize={"1rem"}
                        border={"1px solid black"}
                        onChange={(e) => handleChange(item.category, e.target.value)}
                        placeholder={item.placeholderText} 
                        borderRadius={"6px"} paddingLeft={"10px"}/>
                        } 
                        {item.category == "Country" && 
                            <FilterDropDown options={countries}  
                            handleChange={(selectedOption) => handleChange("Country", selectedOption.value)}
                            marginLeft={"0px"} 
                            width={width > 1345 ? "520px" : width > 1250 ? "455px" : width > 1165 ? "428px" : width > 1032 ? "384px" : width > 900 ? "510px" : width > 780 ? "445px" : width > 680 ? "375px" : width > 570 ? "345px" : width > 500 ? "372px" : "285px"} height={"40px"} borderRadius={"6px"} border={"1px solid black"}/>
                        }
                        {item.category === "ZIP Code" && (
                        <>
                        <SimpleTextInput width={width > 680 ? "170px" : "100px"} height={"38px"} fontSize={"1rem"}
                        border={"1px solid black"}
                        onChange={(e) => handleChange("ZIP Code", e.target.value)}
                        placeholder={item.placeholderText} 
                        borderRadius={"6px"} paddingLeft={"10px"}/>
                        <SimpleTextInput width={width > 500 ? "320px" : "155px"} height={"38px"} fontSize={"1rem"}
                        border={"1px solid black"} marginLeft={"30px"}
                        onChange={(e) => handleChange("City", e.target.value)}
                        placeholder={item.placeholderText} 
                        borderRadius={"6px"} paddingLeft={"10px"}/>
                        </>
                        )}
                        {item.category === "Phone Number" && (
                        <>
                        <FilterDropDown options={countryPhoneExtensions}  
                            handleChange={(selectedOption) => handleChange("Phone Extension", selectedOption.value)}
                            marginLeft={"0px"} 
                            width={width > 500 ? "149px" : "100px"} height={"40px"} borderRadius={"6px"} border={"1px solid black"}/>
                        <SimpleTextInput width={width > 680 ? "340px" : width > 570 ? "163px" : width > 500 ? "193px" : "153px"} height={"38px"} fontSize={"1rem"}
                        border={"1px solid black"} marginLeft={"30px"}
                        onChange={(e) => handleChange(item.category, e.target.value)}
                        placeholder={item.placeholderText} 
                        borderRadius={"6px"} paddingLeft={"10px"}/>
                        </>
                        )}
                        </div>
                    </div>
                ))}
            </div>
            <div style={{
                width: "90%",
                height: "1.5px",
                marginTop: "50px",
                backgroundColor: "black",
                marginLeft: "10px",
            }}>
            </div>
            <div style={{display: 'flex',width: "85%", height: "80px", 
            alignItems: 'center', borderRadius: "2px",
            justifyContent: 'flex-start', marginTop: "35px",
            marginLeft: "0px"
            }}>
            <Link to="/sell/watchpictures" className="no-underline">
            <AnimatedButton text={width > 680 ? "Go Back To Step 2" : "Go back"} direction={"left"} width={width > 680 ? "220px" : "132px"} height={"50px"}
            hoverColor={"black"} color={"white"} textcolor={"black"} arrowColor={"black"} arrowAnimatedColor={"white"}
            borderRadius={"3px"} border={"3px solid black"} 
            fontSize={"1rem"} fontWeight={"600"} 
            paddingLeft={"20px"} marginLeft={"0px"}
            arrowMarginLeft={"0px"}
            paragraphMarginLeft={"5px"}
            /> 
            </Link>

            <Link to="/sell/overview" className="no-underline">
            <AnimatedButton text={width > 680 ? "Continue To Overview" : "Continue"} direction={"right"} width={width > 680 ? "260px" : "146px"} height={"50px"}
            hoverColor={"black"} color={"white"} textcolor={"black"} arrowColor={"black"} arrowAnimatedColor={"white"}
            borderRadius={"3px"} border={"3px solid black"} 
            fontSize={"1rem"} fontWeight={"600"} 
            paddingLeft={"15px"} marginLeft={width > 1345 ? "260px" : width > 1250 ? "190px" : width > 1165 ? "150px" : width > 1032 ? "80px" : width > 900 ? "250px" : width > 780 ? "160px" : width > 680 ? "84px" : width > 570 ? "245px" : width > 500 ? "140px" : "50px"}
            paragraphMarginLeft={"15px"}
            arrowMarginLeft={"11px"}
            /> 
            </Link>

            </div>
        </div>
        </div>


    );



}


export default PersonalInformationPage;