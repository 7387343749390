import React, { useState, useEffect } from 'react';
import { colors, commonFont } from '../commonStyles';
import { alternateColorScheme } from '../commonStyles';

const InputField = ({type, placeholder, value, onChange, marginTop, marginBottom}) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const containerDivStyle = {
        height: '40px',
        marginTop: marginTop,
        marginBottom: marginBottom,
    }

    const InputFieldStyle = {
        width: windowWidth > 520 ? '430px' : "336px",
        height: '32px',
        backgroundColor: colors.accentcolor3,
        border: '3px solid black',
        borderRadius: '2px',
        fontFamily: commonFont.fontFamily,
        fontWeight: '500',
        fontSize: '15px',
        
        paddingLeft: '10px',
    }

    return (
        <div style={containerDivStyle}>
        <input type={type} placeholder={placeholder} value={value} onChange={onChange} style={InputFieldStyle}/>
        </div>
    );



}






export default InputField;