import React, { useContext } from 'react';
import './WatchBrandPageStyles.css';
import '../components/commonStyles.css';
import PopularWatchesSlider from '../components/PopularWatchesSlider';
import FilterDropDown from '../components/FilterDropDown';
import { WatchFilterContext } from '../WatchFilterContext';
import WatchListings from '../components/WatchListings';

const WatchBrandPage = ( { brandName } ) => {



    const sortBy = [
        { value: 'New Listings', label: 'New Listings' },
        { value: 'Popular', label: 'Popular' },
        { value: 'Rising Price', label: 'Rising Price' },
        { value: 'Falling Price', label: 'Falling Price' },
    
        // Add more options as needed
      ];


    return (
        <div className='brandPageParentDiv'>
            <div className='brandpageContentDiv'>
            <span className='header watchBrandHeader'
            >Rolex Watches</span>
            
            <WatchListings marginTop={"10px"}/>
            </div>

        </div>

    );
}

export default WatchBrandPage;