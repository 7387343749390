import React, { useState } from 'react';
import './PaymentInformationContentComponentStyles.css';
import { commonFont, alternateColorScheme } from '../../commonStyles';
import PersonalInformationField from '../PersonalInformationField';
import FilterDropDown from '../FilterDropDown';
import DateInputComponent from '../DateInputComponent';
import PersonalInformationUnlockButton from '../PersonalInformationUnlockButton';
import { AiOutlineLock } from 'react-icons/ai';


function PaymentInformationContentComponent({ paymentInformation, setPaymentInformation, index, width }) {



    const updateInformation = (field, value) => {
        setPaymentInformation(prevState => {
            const updatedCards = [...prevState]; // Clone the previous state
            updatedCards[index] = { // Update the specific card
                ...updatedCards[index],
                [field]: value
            };
            return updatedCards;
        });
    };

    const updatecvv = (field, value) => {
        const isNumeric = /^\d+$/.test(value);
        if (value.length < 4 && (isNumeric || value === "")) {
            updateInformation(field, value);
        }
    };

    const handleCardNumberChange = (value) => {
        const isCardNumber = /^[\d\s]+$/.test(value);

        let formattedValue = value.replace(/\s+/g, ""); // Remove all spaces
        if (formattedValue.length > 0) {
          formattedValue = formattedValue.match(/.{1,4}/g).join(" "); // Insert a space after every 4 characters
        }
        if ((isCardNumber || value === "") && value.length < 20) {
            updateInformation('cardNumber', formattedValue);
        }
      };



      const cardIssueMonths = Array.from({ length: 12 }, (_, index) => ({
        value: index + 1, // Values should start from 1
        label: index + 1  // Labels should start from 1
    }));

    const cardIssueYears = Array.from({ length: 21 }, (_, index) => {
        const year = 2023 + index;
        return { value: year, label: year };
    });

      const countries = [
        { label: "Afghanistan", value: "Afghanistan" },
        { label: "Albania", value: "Albania" },
        { label: "Algeria", value: "Algeria" },
        { label: "Andorra", value: "Andorra" },
        { label: "Angola", value: "Angola" },
        { label: "Argentina", value: "Argentina" },
        { label: "Armenia", value: "Armenia" },
        { label: "Australia", value: "Australia" },
        { label: "Austria", value: "Austria" },
        { label: "Azerbaijan", value: "Azerbaijan" },
        { label: "Bahamas", value: "Bahamas" },
        { label: "Bahrain", value: "Bahrain" },
        { label: "Bangladesh", value: "Bangladesh" },
        { label: "Barbados", value: "Barbados" },
        { label: "Belarus", value: "Belarus" },
        { label: "Belgium", value: "Belgium" },
        { label: "Belize", value: "Belize" },
        { label: "Benin", value: "Benin" },
        { label: "Bhutan", value: "Bhutan" },
        { label: "Bolivia", value: "Bolivia" },
        { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
        { label: "Botswana", value: "Botswana" },
        { label: "Brazil", value: "Brazil" },
        { label: "Brunei", value: "Brunei" },
        { label: "Bulgaria", value: "Bulgaria" },
        { label: "Burkina Faso", value: "Burkina Faso" },
        { label: "Burundi", value: "Burundi" },
        { label: "Cambodia", value: "Cambodia" },
        { label: "Cameroon", value: "Cameroon" },
        { label: "Canada", value: "Canada" },
        { label: "Cape Verde", value: "Cape Verde" },
        { label: "Central African Republic", value: "Central African Republic" },
        { label: "Chad", value: "Chad" },
        { label: "Chile", value: "Chile" },
        { label: "China", value: "China" },
        { label: "Colombia", value: "Colombia" },
        { label: "Comoros", value: "Comoros" },
        { label: "Costa Rica", value: "Costa Rica" },
        { label: "Croatia", value: "Croatia" },
        { label: "Cuba", value: "Cuba" },
        { label: "Cyprus", value: "Cyprus" },
        { label: "Czech Republic", value: "Czech Republic" },
        { label: "Denmark", value: "Denmark" },
        { label: "Djibouti", value: "Djibouti" },
        { label: "Dominica", value: "Dominica" },
        { label: "Dominican Republic", value: "Dominican Republic" },
        { label: "Ecuador", value: "Ecuador" },
        { label: "Egypt", value: "Egypt" },
        { label: "El Salvador", value: "El Salvador" },
        { label: "Equatorial Guinea", value: "Equatorial Guinea" },
        { label: "Eritrea", value: "Eritrea" },
        { label: "Estonia", value: "Estonia" },
        { label: "Eswatini", value: "Eswatini" },
        { label: "Ethiopia", value: "Ethiopia" },
        { label: "Fiji", value: "Fiji" },
        { label: "Finland", value: "Finland" },
        { label: "France", value: "France" },
        { label: "Gabon", value: "Gabon" },
        { label: "Gambia", value: "Gambia" },
        { label: "Georgia", value: "Georgia" },
        { label: "Germany", value: "Germany" },
        { label: "Ghana", value: "Ghana" },
        { label: "Greece", value: "Greece" },
        { label: "Grenada", value: "Grenada" },
        { label: "Guatemala", value: "Guatemala" },
        { label: "Guinea", value: "Guinea" },
        { label: "Guinea-Bissau", value: "Guinea-Bissau" },
        { label: "Guyana", value: "Guyana" },
        { label: "Haiti", value: "Haiti" },
        { label: "Honduras", value: "Honduras" },
        { label: "Hungary", value: "Hungary" },
        { label: "Iceland", value: "Iceland" },
        { label: "India", value: "India" },
        { label: "Indonesia", value: "Indonesia" },
        { label: "Iran", value: "Iran" },
        { label: "Iraq", value: "Iraq" },
        { label: "Ireland", value: "Ireland" },
        { label: "Israel", value: "Israel" },
        { label: "Italy", value: "Italy" },
        { label: "Jamaica", value: "Jamaica" },
        { label: "Japan", value: "Japan" },
        { label: "Jordan", value: "Jordan" },
        { label: "Kazakhstan", value: "Kazakhstan" },
        { label: "Kenya", value: "Kenya" },
        { label: "Kiribati", value: "Kiribati" },
        { label: "Kuwait", value: "Kuwait" },
        { label: "Kyrgyzstan", value: "Kyrgyzstan" },
        { label: "Laos", value: "Laos" },
        { label: "Latvia", value: "Latvia" },
        { label: "Lebanon", value: "Lebanon" },
        { label: "Lesotho", value: "Lesotho" },
        { label: "Liberia", value: "Liberia" },
        { label: "Libya", value: "Libya" },
        { label: "Liechtenstein", value: "Liechtenstein" },
        { label: "Lithuania", value: "Lithuania" },
        { label: "Luxembourg", value: "Luxembourg" },
        { label: "Sweden", value: "Sweden" },
        { label: "United States of America", value: "United States of America" },

      ];

    return (
        <div className='paymentInformationInputSpace'>
        <p 
        className='paymentInformationSmallText'
        style={{
            fontFamily: commonFont.fontFamily,
            marginTop: "4px"
            }}>
            Card Name
        </p> 
        <PersonalInformationField 

        value={paymentInformation.cardName}
        onChange={(value) => updateInformation('cardName', value)}

        />

        <p 
        className='paymentInformationSmallText'
        style={{
            fontFamily: commonFont.fontFamily,
            }}>
            Card Number
        </p> 
        <PersonalInformationField 

        value={paymentInformation.cardNumber}
        onChange={(value) => handleCardNumberChange(value)}

        />

        <DateInputComponent 
        dayValue={paymentInformation.cardIssueMonth}
        onDayChange={(value) => updateInformation('cardIssueMonth', value)}
        monthValue={paymentInformation.cardIssueYear}
        onMonthChange={(value) => updateInformation('cardIssueYear', value)}                  
        yearValue={paymentInformation.cvv}
        onYearChange={(value) => updatecvv('cvv', value)} 
        header1={width > 550 ? "Issue Month" : "Month"}  
        header2={"Issue Year"}  
        header3={"CVV"}  
        list1={cardIssueMonths}
        list2={cardIssueYears}
        />

        <p 
        className='paymentInformationSmallText'
        style={{
            fontFamily: commonFont.fontFamily,
            }}>
            Address
        </p>

        <PersonalInformationField 

        value={paymentInformation.address}
        onChange={(value) => updateInformation('address', value)}                  

        />

        <p 
        className='paymentInformationSmallText'
        style={{
            fontFamily: commonFont.fontFamily,
            }}>
            City
        </p> 
        <PersonalInformationField 

        value={paymentInformation.city}
        onChange={(value) => updateInformation('city', value)}

        />

        <p 
        className='paymentInformationSmallText'
        style={{
            fontFamily: commonFont.fontFamily,
            }}>
            Country And Postal Code
        </p>

        <div
        style={{
            display: 'flex',
            width: '100%',
            height: '40px',
            marginTop: '3px',
        }}
        >
        <FilterDropDown options={countries} defaultOption={paymentInformation.country}
        placeHolder={paymentInformation.country}
        handleChange={(selectedOption) => updateInformation('country', selectedOption)}  
        marginLeft={"0px"} 
        marginTop={"0px"}
        width={width > 640 ? "320px" : width > 500 ? "218px" : "138px"} height={"40px"} borderRadius={"6px"} border={"0px solid black"}/>

        <input
        className='paymentInformationSmallTextInputField'
        type='text'
        value={paymentInformation.postalCode}
        onChange={(value) => updateInformation('postalCode', value)} 

        >
        </input>

        </div>




        </div>
    );
}

export default PaymentInformationContentComponent;