import React, { } from 'react';

const CheckBox = ({ isChecked, onCheckBoxChange, size, marginLeft, marginTop, labelMarginLeft, fontSize, fontWeight, color, text }) => {


  const labelStyles = {
    marginLeft: labelMarginLeft,
    fontSize: fontSize,
    fontWeight: fontWeight,
    color: color,
  }

  return (
    <div style={{marginTop: marginTop}}>
      <input 
        type="checkbox" 
        checked={isChecked} 
        onChange={(e) => onCheckBoxChange(text, e.target.checked)}
        style={{ transform: `scale(${size})`, marginLeft: marginLeft }}

      />
      <label style={labelStyles}>{text}</label>
    </div>
  );
}

export default CheckBox;
