import React, { useState, useEffect } from 'react';
import './SellPageStyles.css';
import { Route, Routes, useLocation } from 'react-router-dom'; // import useLocation
import WatchDetailsPage from './WatchDetailsPage'; // Import Component1
import WatchPicturesPage from './WatchPicturesPage'; // Import Component2
import PersonalInformationPage from './PersonalInformationPage';
import OverviewPage from './OverviewPage';
import listingPreviewImage1 from '../assets/SellWatchPageImages/previewListingImage1.png';
import ListingPreviewComponent from '../components/ListingPreviewComponent';
import SimplifiedWatchListingPreview from '../components/SimplifiedWatchListingPreview';
import SimpleDynamicTextBox from '../components/SimpleDynamicTextBox';
import SellPageHeaderSection from '../components/SellPageHeaderSection';


  

const SellPage = () => {

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    const [formData, setFormData] = useState({
        "Currency": "$"
    });

    const location = useLocation(); // get the current location

    const getLastPathSegment = (pathname) => {
        const pathSegments = pathname.split('/');
        return pathSegments[pathSegments.length - 1];
      };

    const lastSegment = getLastPathSegment(location.pathname);


    const isOverviewPath = location.pathname === '/sell/overview';

    const [termsAndConditions, setTermsAndConditions] = useState(false);


    const [personalInformationForm, setPersonalInformationForm] = useState({});

    const [functionsCheckboxData, setFunctionsCheckboxData] = useState({})

    const [images, setImages] = useState([]);

    const [securityImage1, setSecurityImage1] = useState(null);
    const [securityImage2, setSecurityImage2] = useState(null);

    const previewListingInfo = {brand: "Rolex", modelName: "Day-Date 36", dateAdded: new Date('2023-06-01'), popularity: 450,
    sellerComment: "Ref 118388 Men’s 18K Yellow Gold Black MOP Dia Dial , Bezel & Case 2006",
     price: 42995, shippingCost: "$99", sellerCountry: "US",  sellerCity: "New York", 
     images: [listingPreviewImage1]}

     const headerSections = [
        {text: width > 690 ? "Watch Details" : "", number: "1", keepTriangle: true, linkDestination: "watchdetails"},
        {text: width > 690 ? "Watch Pictures" : "", number: "2", keepTriangle: false, linkDestination: "watchpictures"},
        {text: width > 690 ? "Personal Information" : "", number: "3", keepTriangle: false, linkDestination: "personalinformation"},
        {text: width > 690 ? "Overview" : "", number: "4", keepTriangle: false, linkDestination: "overview"},
      ];



    
    

      let sectionsToRender;

      switch (lastSegment) {
        case 'watchdetails':
          sectionsToRender = headerSections.slice(0, 1);
          break;
        case 'watchpictures':
          sectionsToRender = headerSections.slice(0, 2);
          break;
        case 'personalinformation':
          sectionsToRender = headerSections.slice(0, 3);
          break;
        case 'overview':
          sectionsToRender = headerSections.slice(0, 4);
          break;
        default:
          sectionsToRender = [];
      }


    return (
        <div className='sellPageParentDiv'>
            <div className="sellPageHeaderDiv">
                {headerSections.map((section, index) => (
                    <SellPageHeaderSection 
                        key={index}
                        text={section.text}
                        number={section.number}
                        linkDestination={section.linkDestination}
                        keepTriangle={index == sectionsToRender.length - 1 && index < 3} // Changed this line
                        activated={index < sectionsToRender.length}
                        isLastSection={index == sectionsToRender.length - 1}

                    />
                ))}

            </div>



            <div className='sellPageContentParentDiv'>
            {lastSegment == "overview" ? (
                    <OverviewPage formData={formData} previewListingInfo={previewListingInfo}
                    images={images} termsAndConditions={termsAndConditions} setTermsAndConditions={setTermsAndConditions}
                    />
                ) : (
                        <>
                        <div className='sellPageSectionContentParentDiv'>
                        <Routes>
                        <Route path="watchdetails" element={<WatchDetailsPage 
                        formData={formData} setFormData={setFormData}
                        functionsCheckboxData={functionsCheckboxData}
                        setFunctionsCheckboxData={setFunctionsCheckboxData}
                        />} />
                        <Route path="watchpictures" element={<WatchPicturesPage 
                        images={images} setImages={setImages}
                        securityImage1={securityImage1}
                        securityImage2={securityImage2}
                        setSecurityImage1={setSecurityImage1}
                        setSecurityImage2={setSecurityImage2}

                        />} />
                        <Route path="personalinformation" element={<PersonalInformationPage 
                        personalInformationFormData={personalInformationForm}
                        setPersonalInformationFormData={setPersonalInformationForm}
                        />} />
                        <Route path="overview" element={<PersonalInformationPage 
                        personalInformationFormData={personalInformationForm}
                        setPersonalInformationFormData={setPersonalInformationForm}
                        />} />
                    </Routes>
                </div>
                {width > 1032 &&
                <div className='sellPageListingPreviewParentDiv'>
                    <div className='sellPageStickyPreviewParentComponent2'>
                        <div className='sellPagePreviewParentComponent2'>
                        <span className='sellPagePreviewHeader'>Listing Preview</span>
                        <span className='sellPagePreviewText'>This is what your listing will look like: </span>

                        <div className='centerContainer'>
                        <div className='listingPreviewParentDiv2'>
                        <SimplifiedWatchListingPreview title={formData["Brand"] ? `${formData["Brand"].label} ${formData["Model"] || ''}` : ""} shortDescription={formData["Short Description"] ? formData["Short Description"] : ""} price={formData["Price"]} currency={formData["Currency"]} watchListingInfo={previewListingInfo} images={images} to={"/sell/watchdetails"}/>                        

                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                }
                </>
                )}

            </div>

        </div>
    );
}

export default SellPage;
