import React from 'react';
import './WatchKeyStatsSummaryStyles.css';
import { TbDeviceWatchStar, TbDeviceWatchCog } from 'react-icons/tb';
import { RiBox3Line } from 'react-icons/ri';
import { LiaFileContractSolid } from 'react-icons/lia';

function WatchKeyStatsSummary({ width, height, watchKeyStats }) {


    return (
        <div className='WatchKeyStatsSummaryParent'
        style={{
            width: width,
            height: height,
        }}
        >
        <div className='WatchKeyStatsSummaryStat'>
            <TbDeviceWatchStar size={28}/>
            <p className='WatchKeyStatsSummaryStatText'>
                <b>Condition: </b> {watchKeyStats.condition}
            </p>
        </div>
        <div className='WatchKeyStatsSummaryStat'
        style={{
            marginTop: "10px"
        }}
        >
            <TbDeviceWatchCog size={28}/>
            <p className='WatchKeyStatsSummaryStatText'>
                <b>Production Year: </b> {watchKeyStats.productionYear}
            </p>
        </div>
        <div className='WatchKeyStatsSummaryStat'
        style={{
            marginTop: "10px"
        }}
        >
            <RiBox3Line size={28}/>
            <p className='WatchKeyStatsSummaryStatText'>
                <b>Papers: </b> {watchKeyStats.box}
            </p>
        </div>
        <div className='WatchKeyStatsSummaryStat'
        style={{
            marginTop: "10px"
        }}
        >
            <LiaFileContractSolid size={28}/>
            <p className='WatchKeyStatsSummaryStatText'>
                <b>Papers: </b> {watchKeyStats.papers}
            </p>
        </div>
        </div>
    );
}

export default WatchKeyStatsSummary;