import React from 'react';
import './HoverPopupDivStyles.css';


function HoverPopupDiv({ width, height, text, top, left, right }) {


    return (
        <div className='hoverPopupDivParent'
        style={{
            width: width,
            height: height,
            top: top,
            left: left,
            right: right,
        }}
        >
            <p className='hoverPopupDivText'>
                {text}
            </p>

        </div>
    );
}

export default HoverPopupDiv;