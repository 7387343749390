import React, { useEffect, useState } from 'react';
import './SellerProfilePageStyles.css';
import SimpleDynamicTextBox from '../components/SimpleDynamicTextBox';
import SellerProfileKeyStats from '../components/SellerProfileKeyStats';
import UserReview from '../components/UserReview';
import RecommendedSellerListings from '../components/RecommendedSellerListings';
import { commonFont, alternateColorScheme } from '../commonStyles';
import { Link } from 'react-router-dom';
import FilterDropDown from '../components/FilterDropDown';
import boughtWatchImage1 from '../assets/fakeListingImages/watchListing1.png';
import boughtWatchImage2 from '../assets/fakeListingImages/watchListing4.png';
import boughtWatchImage3 from '../assets/fakeListingImages/watchListing5.png';
import boughtWatchImage4 from '../assets/fakeListingImages/watchListing6.png';
import boughtWatchImage5 from '../assets/fakeListingImages/watchListing7.png';
import boughtWatchImage6 from '../assets/fakeListingImages/watchListing8.png';


import RandomSellerProfileImage from '../assets/SellerProfileImages/randomWatchDealerLogo.png';

import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { CiFaceFrown } from 'react-icons/ci';





const SellerProfilePage = ( {} ) => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);



      const [currentStarOption, setCurrentStarOption] = useState('All Reviews');

      const [helpfulButtonHover, setHelpfulButtonHover] = useState(false);
      const [helpfulButtonIsActive, setHelpfulButtonIsActive] = useState(false);
      

      const [helpfulButtonCounter, setHelpfulButtonCounter] = useState(0);
      
      const handleClick = () => {
        if (helpfulButtonCounter) {
            setHelpfulButtonCounter(helpfulButtonCounter - 1);
        } else {
            setHelpfulButtonCounter(helpfulButtonCounter + 1);
        }
        setHelpfulButtonIsActive(!helpfulButtonIsActive);

      };

      const totalAmountOfReviews = 600;

      const fiveStarReviews = 437;
      const fourStarReviews = 122;
      const threeStarReviews = 8;
      const twoStarReviews = 10;
      const oneStarReviews = 25;

      const totalReviewScore = (fiveStarReviews * 5 + fourStarReviews * 4
      + threeStarReviews *3 + twoStarReviews * 2 + oneStarReviews * 1)/totalAmountOfReviews;

      const roundedTotalReviewScore = parseFloat(totalReviewScore.toFixed(1));


      const fiveStarWidth = fiveStarReviews/totalAmountOfReviews;
      const fourStarWidth = fourStarReviews/totalAmountOfReviews;
      const threeStarWidth = threeStarReviews/totalAmountOfReviews;
      const twoStarWidth = twoStarReviews/totalAmountOfReviews;
      const oneStarWidth = oneStarReviews/totalAmountOfReviews;

      const sortBy = [
        { value: 'All Reviews', label: 'All Reviews' },
        { value: 'All 5 Star Reviews', label: '5 Star Reviews' },
        { value: 'All 4 Star Reviews', label: '4 Star Reviews' },
        { value: 'All 3 Star Reviews', label: '3 Star Reviews' },
        { value: 'All 2 Star Reviews', label: '2 Star Reviews' },
        { value: 'All 1 Star Reviews', label: '1 Star Reviews' },

        // Add more options as needed
      ];





      const userReviewsData = [

         {
            userName: "Filip Larsson",
            userScore: 4.2,
            userComment: "Quick shipment. Watch exactly as advertised. Highly recommend!",
            boughtWatchImage: boughtWatchImage6,
            boughtWatchName: "Panerai PAM 51 Luminor Marina Automatic 40mm",
            boughtWatchKeyStats: {
                "Year Of Production": { value: "Year Of Production: 2018", width: "220px" },
                "Box": { value: "Original Box", width: "120px" },
                "Condition": { value: "Pre-owned (Very Good)", width: "200px" },
        
                "Papers": { value: "Original Papers", width: "140px" }, 
            },
            shipment: {
                dateSent: "July 9, 2023",
                dateDelivered: "July 12, 2023",
                shippedFrom: "United States of America",
                shippedTo: "United States of America"
            },
            publishedReview: "19 July, 2023"

        },

         {
            userName: "Stella Larsson",
            userScore: 5.0,
            userComment: "Purchase went smoothly. Reached out first by email, responded quickly. Als questions and information provided by Whatsapp was quick. Watch was received within a week. Would purchase again from seller.",
            boughtWatchImage: boughtWatchImage2,
            boughtWatchName: "Omega Speedmaster Professional Moonwatch Unworn New August 2023 Silver Snoopy Award 50th a",
            boughtWatchKeyStats: {
                "Year Of Production": { value: "Year Of Production: 2014", width: "220px" },
                "Box": { value: "Used Box", width: "110px" },
                "Condition": { value: "Pre-owned (Unworn)", width: "190px" },
                "Papers": { value: "Original Papers", width: "150px" },
            },

            shipment: {
                dateSent: "November 22, 2023",
                dateDelivered: "November 25, 2023",
                shippedFrom: "United States of America",
                shippedTo: "Sweden"
            },
            publishedReview: "27 November, 2023"


        },

        {
            userName: "Alexandré Moch",
            userScore: 4.6,
            userComment: "Aucun souci avec ce professionnel ! Il est à l'écoute et répond favorablement aux demandes du client. Je recommande cet horloger . Bien cordialement. Mr kerebel jean-Paul",
            boughtWatchImage: boughtWatchImage3,
            boughtWatchName: "Audemars Piguet Royal Oak Chronograph Unworn New 2021 26315ST",
            boughtWatchKeyStats: {
                "Year Of Production": { value: "Year Of Production: 2021", width: "220px" },
                "Box": { value: "Original Box", width: "140px" },
                "Condition": { value: "New", width: "100px" },
                "Papers": { value: "Original Papers", width: "150px" },
            },

            shipment: {
                dateSent: "June 5, 2022",
                dateDelivered: "June 9, 2022",
                shippedFrom: "United States of America",
                shippedTo: "France"
            },
            publishedReview: "10 June, 2023"


        },

        {
            userName: "Jacob Schweizer",
            userScore: 3.8,
            userComment: "Der Händler war wirklich hilfsbereit und professionell. Sehr empfehlenswert!",
            boughtWatchImage: boughtWatchImage4,
            boughtWatchName: "Breitling Navitimer 1 B01 Unworn New March 2023 Chronograph 43 Boeing 747 Limited Edition",
            boughtWatchKeyStats: {
                "Year Of Production": { value: "Year Of Production: 2022", width: "220px" },
                "Box": { value: "Original Box", width: "140px" },
                "Condition": { value: "Used (Very Good)", width: "160px" },
                "Papers": { value: "Original Papers", width: "150px" },
            },

            shipment: {
                dateSent: "March 15, 2020",
                dateDelivered: "March 18, 2020",
                shippedFrom: "Sweden",
                shippedTo: "Switzerland"
            },
            publishedReview: "28 March, 2023"


        },
        
        {
            userName: "Mortada Nassér",
            userScore: 4.6,
            userComment: "I have used Chrono Barcelona several times now and every time you receive such an outstanding service. If you are considering a timepiece from here please do not hesitate. The shipping is extremely quick, the communication excellent and I could not recommend highly enough.",
            boughtWatchImage: boughtWatchImage5,
            boughtWatchName: "Rolex Explorer II Unworn New August 2022 White Dial",
            boughtWatchKeyStats: {
                "Year Of Production": { value: "Year Of Production: 2023", width: "220px" },
                "Box": { value: "Original Box", width: "140px" },
                "Condition": { value: "New", width: "100px" },
                "Papers": { value: "Original Papers", width: "150px" },
            },

            shipment: {
                dateSent: "October 2, 2020",
                dateDelivered: "October 6, 2020",
                shippedFrom: "Sweden",
                shippedTo: "Sweden"
            },
            publishedReview: "29 October, 2023"


        },

        {
            userName: "Peo Eneroth",
            userScore: 3.7,
            userComment: "It was the first time I bought from Chrono24, and I was a bit sceptical at first, but everything went perfectly easy! Good communication super fast shipping and the watch is absolutel beautiful and as described! I would definitely buy again!",
            boughtWatchImage: boughtWatchImage1,
            boughtWatchName: "Rolex New Sky-Dweller 326934 42mm Steel Gold Unworn New October 2020",
            boughtWatchKeyStats: {
                "Year Of Production": { value: "Year Of Production: 2020", width: "220px" },
                "Box": { value: "Original Box", width: "140px" },
                "Condition": { value: "Used (Good)", width: "120px" },
                "Papers": { value: "Original Papers", width: "150px" },
            },

            shipment: {
                dateSent: "September 10, 2021",
                dateDelivered: "September 14, 2021",
                shippedFrom: "Sweden",
                shippedTo: "Sweden"
            },
            publishedReview: "17 September, 2023"


        },

      ]

      const [minScore, setMinScore] = useState(0); // Minimum score to filter by



      const [reviews, setReviews] = useState(userReviewsData); // Initial data


      function filterAndSortReviewsByScore(userReviewsData, minScore) {
        console.log('filtering', userReviewsData, minScore);
      
        let filteredData = [];
        
        if (minScore === 0) {
          filteredData = userReviewsData;
        } else {
          filteredData = userReviewsData.filter(review => {
            return Math.floor(review.userScore) === minScore;
          });
        }
      
        // Sort the filtered data
        filteredData.sort((a, b) => b.userScore - a.userScore);
      
        return filteredData;
      }




      


      const handleChange = (selectedOption) => {
      
        console.log("Handling change: ", selectedOption);  // Debugging line

        switch (selectedOption.value) {
          case 'All Reviews':
            setMinScore(0);
            break;
          case 'All 5 Star Reviews':
            setMinScore(5);
            break;
          case 'All 4 Star Reviews':
            setMinScore(4);
            break;
          case 'All 3 Star Reviews':
            setMinScore(3);
            break;
          case 'All 2 Star Reviews':
            setMinScore(2);
            break;
          case 'All 1 Star Reviews':
            setMinScore(1);
            break;
          default:
            setMinScore(0);
        }
      };

      const [sortOrder, setSortOrder] = useState("relevance"); // Initial data




      function compareDates(date1, date2) {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      
        // Remove commas and split the string
        const [day1, monthName1, year1] = date1.replace(',', '').split(" ");
        const [day2, monthName2, year2] = date2.replace(',', '').split(" ");
      
        const month1 = monthNames.indexOf(monthName1);
        const month2 = monthNames.indexOf(monthName2);
      
        const dateObj1 = new Date(year1, month1, day1);
        const dateObj2 = new Date(year2, month2, day2);
      
        console.log(dateObj1, dateObj2);
        
        if (dateObj1 < dateObj2) return -1; // date1 comes first
        if (dateObj1 > dateObj2) return 1;  // date2 comes first
      
        return 0; // the dates are equal
      }
      

      function sortReviewsByDate(reviews, sortOrder) {
        // Clone the reviews array to avoid mutating the original
        const sortedReviews = [...reviews];
      
        if (sortOrder === 'newest') {
          sortedReviews.sort((a, b) => {
            return compareDates(b.publishedReview, a.publishedReview);
          });
        } else if (sortOrder === 'oldest') {
          sortedReviews.sort((a, b) => {
            return compareDates(a.publishedReview, b.publishedReview);
          });
        }
        // If sortOrder is 'none', return the array as is
        return sortedReviews;
      }

      useEffect(() => {
        console.log('minScore changed:', minScore);
        const filteredReviews = filterAndSortReviewsByScore(userReviewsData, minScore);
        const extraFilteredReviews = sortReviewsByDate(filteredReviews, sortOrder)
        console.log('filteredReviews:', extraFilteredReviews);
        setReviews(extraFilteredReviews);
      }, [minScore, sortOrder]);
      

      const handleChange2 = (selectedOption) => {
      
        console.log("Handling change: ", selectedOption);  // Debugging line

        switch (selectedOption.value) {
          case 'Relevance':
            break;
          case 'Newest First':
            setSortOrder("newest");
            break;
          case 'Oldest First':
            setSortOrder("oldest");
            break;

          default:
            setSortOrder("relevance");
        }
      };


      const sortBy2 = [
        { value: 'Relevance', label: 'Relevance' },
        { value: 'Newest First', label: 'Newest First' },
        { value: 'Oldest First', label: 'Oldest First' },

        // Add more options as needed
      ];
    




      const [windowWidth, setWindowWidth] = useState(window.innerWidth);

      useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const sellerName = "G & G Timepieces"


    return (
        <div className="sellerProfilePageParentDiv">
            <div className='sellerProfileParentDiv'>
                <div className='sellerProfileNameAndImageDivAndKeyStatsDiv'>
                <div className='sellerProfileImagePlusInfoParentDiv'>

                <div className='sellerProfileImageDiv'>
                    <img src={RandomSellerProfileImage}
                        className='sellerProfileImage'
                        style={{

                            objectFit: "cover", 
                            objectPosition: "center center",
                            borderRadius: "90px"
                        }}
                    >
                    </img>
                </div>
                <div className='sellerProfileNameAndInfoDiv'>

                    <p style={{
                                fontFamily: commonFont.fontFamily,
                                fontSize: "1rem",
                                fontWeight: 400,
                                color: "#003251",
                                margin: "0px",
                                marginLeft: "0px"
                            }}>
                                Seller
                    </p>
                    <p 
                    className='sellerProfileName'
                    style={{
                                fontFamily: commonFont.fontFamily,
                                color: "#003251",

                            }}>
                                {sellerName}
                    </p>


                    <SimpleDynamicTextBox text={"Professional Dealer"} fontFamily={commonFont.fontFamily}  marginTop={"15px"} fontSize={"1.3rem"} fontWeight={400} color={"#003251"}/>
                    <SimpleDynamicTextBox text={"Active On PrimeTick Since 2023"} fontFamily={commonFont.fontFamily}  marginTop={"15px"} fontSize={"1rem"} fontWeight={400} color={"#003251"}/>
                    <Link style={{color: "#003251", width: "130px"}}>
                    <p>
                        {sellerName}
                    </p>
                    </Link>
                </div>
                </div >

                <SellerProfileKeyStats/>

                </div>
                <div className='sellerProfileReviewsParentDiv'>
                    <SimpleDynamicTextBox text={"Seller Reviews"} fontFamily={commonFont.fontFamily}  marginTop={"0px"} fontSize={"1.5rem"} fontWeight={500} color={"#003251"}/>
                    <div style={{width: "60%", height: "1px", backgroundColor: alternateColorScheme.blue2, marginTop: "10px", marginBottom: "10px"}}>

                    </div>
                    <div className='sellerReviewsSummaryDiv'>
                    <div className=' sellerReviewsSummaryStatsDiv'>
                        <div className='sellerReviewsSummaryDivRow'>
                        <AiFillStar size={23} color={alternateColorScheme.gold3}/>

                            <p style={{
                                fontFamily: commonFont.fontFamily,
                                fontSize: "1.3rem",
                                color: "white",
                                margin: "0px",
                                fontWeight: 700,
                                marginLeft: "3px"
                            }}>
                                {roundedTotalReviewScore}
                            </p>
                            <p style={{
                                fontFamily: commonFont.fontFamily,
                                fontSize: "1rem",
                                color: alternateColorScheme.purple,
                                margin: "0px",
                                marginLeft: "15px"
                            }}>
                                Based on 600 Reviews
                            </p>
                        </div>

                        <div className='sellerReviewsSummaryDivRow' style={{marginTop: "8px", paddingLeft: "3px"}}>
                        <AiOutlineStar size={17} color={alternateColorScheme.gold3}/>

                        <p style={{
                                fontFamily: commonFont.fontFamily,
                                fontSize: "1rem",
                                color: "white",
                                margin: "0px",
                                fontWeight: 400,
                                marginLeft: "9px"
                            }}>
                                4.4
                            </p>
                            <p style={{
                                fontFamily: commonFont.fontFamily,
                                fontSize: "0.9rem",
                                color: "white",
                                margin: "0px",
                                marginLeft: "10px"
                            }}>
                                Shipping
                            </p>
                        </div>
                        <div className='sellerReviewsSummaryDivRow' style={{marginTop: "8px", paddingLeft: "3px"}}>
                        <AiOutlineStar size={17} color={alternateColorScheme.gold3}/>

                        <p style={{
                                fontFamily: commonFont.fontFamily,
                                fontSize: "1rem",
                                color: "white",
                                margin: "0px",
                                fontWeight: 400,
                                marginLeft: "9px"
                            }}>
                                4.7
                            </p>
                            <p style={{
                                fontFamily: commonFont.fontFamily,
                                fontSize: "0.9rem",
                                color: "white",
                                margin: "0px",
                                marginLeft: "10px"
                            }}>
                                Item Description
                            </p>
                        </div>
                        <div className='sellerReviewsSummaryDivRow' style={{marginTop: "8px", paddingLeft: "3px"}}>
                        <AiOutlineStar size={17} color={alternateColorScheme.gold3}/>

                        <p style={{
                                fontFamily: commonFont.fontFamily,
                                fontSize: "1rem",
                                color: "white",
                                margin: "0px",
                                fontWeight: 400,
                                marginLeft: "9px"
                            }}>
                                4.8
                            </p>
                            <p style={{
                                fontFamily: commonFont.fontFamily,
                                fontSize: "0.9rem",
                                color: "white",
                                margin: "0px",
                                marginLeft: "10px"
                            }}>
                                Communication
                            </p>
                        </div>
                        <div className='sellerReviewsSummaryDivRow' style={{marginTop: "30px", paddingLeft: "0px"}}>
                            <p style={{
                                    fontFamily: commonFont.fontFamily,
                                    fontSize: "1rem",
                                    color: "white",
                                    margin: "0px",
                                    fontWeight: 300,
                                    marginLeft: "0px"
                                }}>
                                <b style={{fontWeight: 700, color: alternateColorScheme.purple}}>555 Buyers</b> Recommend This Seller
                            </p>
                        </div>
                    </div>

                    <div className='sellerReviewsChartDiv'>
                        <div className='sellerReviewsSummaryDivRow'>
                        <div className='reviewChartDivTextBox'>

                            <p style={{
                                    fontFamily: commonFont.fontFamily,
                                    fontSize: "0.9rem",
                                    color: "white",
                                    margin: "0px",
                                    marginLeft: "0px"
                                   }}>
                                    5 Stars ({fiveStarReviews})
                            </p>
                            </div>

                            <div className='reviewsChartBar'>
                                <div className='reviewsChartBarFilled'
                                style={{width: `${fiveStarWidth*100}%`}}
                                >

                                </div>
                            </div>
                        </div>
                        <div className='sellerReviewsSummaryDivRow' style={{marginTop: "16px"}}>
                            <div className='reviewChartDivTextBox'>

                            <p style={{
                                    fontFamily: commonFont.fontFamily,
                                    fontSize: "0.9rem",
                                    color: "white",
                                    margin: "0px",
                                    marginLeft: "0px"
                                   }}>
                                    4 Stars ({fourStarReviews})
                            </p>
                            </div>

                            <div className='reviewsChartBar'>
                                <div className='reviewsChartBarFilled'
                                style={{width: `${fourStarWidth*100}%`}}
                                >

                                </div>
                            </div>
                        </div>
                        <div className='sellerReviewsSummaryDivRow' style={{marginTop: "16px"}}>
                        <div className='reviewChartDivTextBox'>
                            <p style={{
                                    fontFamily: commonFont.fontFamily,
                                    fontSize: "0.9rem",
                                    color: "white",
                                    margin: "0px",
                                    marginLeft: "0px"
                                   }}>
                                    3 Stars ({threeStarReviews})
                            </p>
                            </div>

                            <div className='reviewsChartBar'>
                                <div className='reviewsChartBarFilled'
                                style={{width: `${threeStarWidth*100}%`}}
                                >

                                </div>
                            </div>
                        </div>
                        <div className='sellerReviewsSummaryDivRow' style={{marginTop: "16px"}}>
                        <div className='reviewChartDivTextBox'>

                                <p style={{
                                        fontFamily: commonFont.fontFamily,
                                        fontSize: "0.9rem",
                                        color: "white",
                                        margin: "0px",
                                        marginLeft: "0px"
                                    }}>
                                        2 Stars ({twoStarReviews})
                                </p>
                            </div>


                            <div className='reviewsChartBar'>
                                <div className='reviewsChartBarFilled'
                                style={{width: `${twoStarWidth*100}%`}}
                                >

                                </div>
                            </div>
                        </div>
                        <div className='sellerReviewsSummaryDivRow' style={{marginTop: "16px"}}>
                        <div className='reviewChartDivTextBox'>

                            <p style={{
                                    fontFamily: commonFont.fontFamily,
                                    fontSize: "0.9rem",
                                    color: "white",
                                    margin: "0px",
                                    marginLeft: "0px"
                                   }}>
                                    1 Stars ({oneStarReviews})
                            </p>
                            </div>

                            <div className='reviewsChartBar'>
                                <div className='reviewsChartBarFilled'
                                style={{width: `${oneStarWidth*100}%`}}
                                >

                                </div>
                            </div>
                        </div>
                    </div>

                    </div>

                    <div className='sellerTextReviewsParentDiv'>
                        <div className='centerContainer'>
                            <div className='sellerTextReviewsDropDownsParentDiv'>
                                    <FilterDropDown options={sortBy}handleChange={handleChange} borderRadius={"20px"} 
                                            width={windowWidth < 650 ? "315px" : (windowWidth < 725 ? "150px" : (windowWidth < 825 ? "180px" : (windowWidth < 925 ? "230px" : "270px")))}


                                    />
                                <p
                                className='hide-below-1325'
                                style={{
                                    color: "white",
                                    fontWeight: 500,
                                }}
                                >
                                    <b
                                    style={{
                                        fontWeight: 400,
                                    }}
                                    >Currently Showing:</b> {currentStarOption}
                                </p>
                                <div style={{display: "flex", alignItems: "center"}}>

            
                                <p
                                className='hide-below-975'
                                style={{
                                    color: "white",
                                    fontWeight: 500,
                                    marginRight: "15px"
                                }}
                                >
                                    Sort By
                                </p>
                                        <FilterDropDown className='sellerProfileFilterReviewsDropDown' options={sortBy2} handleChange={handleChange2} borderRadius={"20px"}  
                                            width={windowWidth < 650 ? "315px" : (windowWidth < 725 ? "150px" : (windowWidth < 825 ? "180px" : (windowWidth < 925 ? "230px" : "270px")))}
                                            marginLeft={"0px"} />
                                </div>
                            </div>
                        </div>

                        {
                        reviews.length > 0 ? (
                            reviews.map((review, index) => (
                            <>
                                <UserReview 
                                userName={review.userName}
                                userScore={`${review.userScore}`}
                                marginTop={index === 0 ? "30px" : "some other value"}
                                borderTopRightRadius={index === 0 ? "10px" : "some other value"}
                                borderTopLeftRadius={index === 0 ? "10px" : "some other value"}
                                boughtWatchImage={review.boughtWatchImage}
                                boughtWatchName={review.boughtWatchName}
                                boughtWatchKeyStats={review.boughtWatchKeyStats}
                                userComment={review.userComment}
                                shipmentInfo={review.shipment}
                                publishedReview={review.publishedReview}
                                />
                                {index < reviews.length - 1 && (  // Don't render separator after the last element
                                <div className='centerContainer'>
                                    <div className='singleUserReviewSeparatorDiv'></div>
                                </div>
                                )}
                            </>
                            ))
                        ) : (
                            <div className="centerContainer"
                            style={{
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                            >
                            <p style={{
                                    fontFamily: commonFont.fontFamily,
                                    fontSize: "1.5rem",
                                    fontWeight: 500,
                                    color: "black",
                                    margin: "0px",
                                    marginTop: "50px",
                                    marginBottom: "10px",
                                    marginLeft: "0px"
                                   }}>
                                    No Reviews Available
                            </p> 
                            <CiFaceFrown size={36}/>                           
                            </div>
                        )
                        }

                    </div>

                </div>

                <RecommendedSellerListings sellerName={sellerName}/>
                
            </div>
        </div>


    );



}


export default SellerProfilePage;