import React from 'react';
import Select from 'react-select';
import DynamicTextBox from './DynamicTextBox';
import { alternateColorScheme } from '../commonStyles';

const FilterDropDown = ( { options, defaultOption, placeHolder, handleChange, marginLeft, width, height, menuHeight, borderRadius, border, backgroundColor, onFocus, onBlur } ) => {

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: width,
      minWidth: width,
      backgroundColor: backgroundColor ? backgroundColor : "white",
      minHeight: '20px',
      height: height, // Set your desired height here
      zIndex: 10, // Use a sufficiently high value

      border: border,
      borderRadius: borderRadius,
      '&:hover': {
      },
    }),
    option: (provided, state) => ({
      ...provided,
      width: width,
      backgroundColor: state.isSelected ? alternateColorScheme.blue2 : 'white',
      color: state.isSelected ? 'white' : 'black',
      zIndex: 10000, // Use a sufficiently high value

    }),
    menu: (provided) => ({
      ...provided,
      width: width,
      height: menuHeight,
      zIndex: 10000, // Use a sufficiently high value

    }),
  };

 

  return (
    <div style={{display: "flex", alignItems: 'center', marginLeft: marginLeft, width: width, minWidth: width}}>
      <Select 
      onFocus={onFocus}
      onBlur={onBlur}
      options={options} 
      defaultValue={defaultOption || options[0]} // Use defaultOption if provided, otherwise use the first option
      onChange={handleChange} 
      styles={customStyles} 
      placeholder={placeHolder}
      />
      </div>
  );
};

export default FilterDropDown;