import React, { useRef, useState, useEffect } from 'react';
import './ConversationInputAreaStyles.css';
import { FaRegPaperPlane, FaCheck, FaImage } from 'react-icons/fa6';
import ImageInputArea from './ImageInputArea';

function ConversationInputArea({ handleNewMessage }) {

    const [inputValue, setInputValue] = useState("");
    const [inputHeight, setInputHeight] = useState("auto");
    const hiddenDiv = useRef(null);
  
    useEffect(() => {
      if (hiddenDiv.current) {
        setInputHeight(`${hiddenDiv.current.scrollHeight}px`);
      }
    }, [inputValue]);

    const [messageCount, setMessageCount] = useState(0);

    const [pendingImages, setPendingImages] = useState([]);  // New state variable for holding multiple images

    const [imageButtonIsHovered, setImageButtonIsHovered] = useState(false);
    const [imageButtonIsClicked, setImageButtonIsClicked] = useState(false);

    const handleImageButtonClick = () => {
        setImageButtonIsClicked(true);
        setTimeout(() => {
            setImageButtonIsClicked(false);
        }, 100); // 200ms, adjust as needed
      };

    const [buttonIsHovered, setButtonIsHovered] = useState(false);

    const [messageSent, setMessageSent] = useState(false);

    const removePendingImage = (index) => {
        const newImages = [...pendingImages];
        newImages.splice(index, 1);
        setPendingImages(newImages);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setMessageSent(false);
      };

      const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleSendMessage();
        }
      };

      const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = function (e) {
            setPendingImages([...pendingImages, e.target.result]); // Add new image to the array
            if (pendingImages !== null) {
                setMessageSent(false);
            }
          };
          reader.readAsDataURL(file);
        }
      };
      
    
      const handleSendMessage = () => {
        if (inputValue.trim() === "" && pendingImages.length === 0) return; // Prevent empty messages and empty images
    
        const newMessage = {
          message: inputValue.trim() !== "" ? inputValue : null,
          initials: "FL",
          images: pendingImages, // Send the array of images
          leftBubble: messageCount % 2 === 0,
        };  
        setMessageCount(messageCount + 1);
        handleNewMessage(newMessage);

        setInputValue("");
        setPendingImages([]); // Clear the pending images

        setMessageSent(true);
      };

    return (
        <div className='conversationInputAreaParent'>
            <div ref={hiddenDiv} style={{ 
                display: 'none', 
                whiteSpace: 'pre-wrap', 
                wordWrap: 'break-word',
                // Copy other styles from your input
            }}>
                {inputValue}
            </div>
            <input 
                className='conversationTextInput'
                style={{
                    borderTopLeftRadius: pendingImages.length > 0 ? "10px" : "20px",
                    borderTopRightRadius: pendingImages.length > 0 ? "10px" : "20px",

                    borderBottomLeftRadius: pendingImages.length > 0 ? "0px" : "20px",
                    borderBottomRightRadius: pendingImages.length > 0 ? "0px" : "20px",
                    height: inputHeight,
                    borderBottom: pendingImages.length > 0 ? "none" : "1px solid gray"
                }}
                type="text" 
                value={inputValue} 
                onChange={handleInputChange} 
                placeholder="Message..."
                onKeyDown={handleKeyPress}

            />
            

            <input
                className='conversationFileInput'
                type="file"
                id="imageInput"
                onClick={handleImageButtonClick}
                onMouseEnter={() => setImageButtonIsHovered(true)} 
                onMouseLeave={() => setImageButtonIsHovered(false)}
                style={{ 
                    backgroundColor: "red",
                    width: pendingImages.length > 0 ? "32px" : "34px",
                    height: pendingImages.length > 0 ? "32px" : "34px",
                    top: pendingImages.length > 0 ? "13px" : "12px",
                    right: pendingImages.length > 0 ? "52px" : "56px"

                }}
                onChange={handleFileChange}
                accept="image/*"
                
            />
            <label htmlFor="imageInput">
                <button className={`conversationImageButton ${imageButtonIsHovered ? 'conversationImageButtonBGColorChange' : ''}`}

                style={{ 
                    width: pendingImages.length > 0 ? "32px" : "34px",
                    height: pendingImages.length > 0 ? "32px" : "34px",
                    top: pendingImages.length > 0 ? "13px" : "12px",
                    right: pendingImages.length > 0 ? "52px" : "56px"

                }}
                >
                <FaImage size={16} 
                    color={imageButtonIsClicked ? "#023e8a" : (imageButtonIsHovered ? "#3797f0" : "white")}
                    />
                </button>
            </label>

            <button className='conversationSendButton'
            style={{
                top: pendingImages.length > 0 ? "13px" : "12px",
                width: pendingImages.length > 0 ? "32px" : "34px",
                height: pendingImages.length > 0 ? "32px" : "34px",

            }}
            onClick={handleSendMessage}
              onMouseEnter={() => setButtonIsHovered(true)} 
              onMouseLeave={() => setButtonIsHovered(false)}

            >
            <div className='conversationButtonIconContainer'>
                <FaRegPaperPlane 
                className={`convoPlaneIcon ${messageSent ? 'iconOut' : 'iconIn'}`}
                size={16} 
                color={buttonIsHovered ? "#3797f0" : "white"}
                />
                <FaCheck 
                className={`convoCheckIcon ${messageSent ? 'iconIn' : 'iconOut'}`} 
                size={16} 
                color={buttonIsHovered ? "#3797f0" : "white"}
                />
            </div>          
            </button>
            {pendingImages.length > 0 &&
                <ImageInputArea pendingImages={pendingImages} handleRemoveButtonClick={removePendingImage}/>
            }
        </div>
    );
}

export default ConversationInputArea;