import React from 'react';
import './SignOutSectionStyles.css';
import '../commonStyles.css';
import { useAuth } from '../../contexts/AuthContext'; // Import useAuth hook
import { useNavigate } from 'react-router-dom';

const SignOutSection = () => {

    const { setIsAuthenticated } = useAuth(); // Get the setIsAuthenticated function from context
    const navigate = useNavigate();

    const handleSignOut = () => {
        // Clear the session from local storage or cookies
        localStorage.removeItem('isLoggedIn');
        
        // Update the authentication state
        setIsAuthenticated(false);
        console.log("User signed out"); // Add this line

        navigate('/loginAndRegister'); // Change the path as needed
    };
    
    return (
        <div className='signOutSectionParent'>
            <button className='signOutSectionButton' onClick={handleSignOut}>
                <span className='smallThickText signOutText'>Sign Out</span>
            </button>
        </div>
    );
};

export default SignOutSection;