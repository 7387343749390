import React from 'react';
import './InputClickableBoxStyles.css';
import { alternateColorScheme } from '../commonStyles';
import SimpleDynamicTextBox from './SimpleDynamicTextBox';
import { AiOutlineCheckCircle, AiFillCheckCircle } from 'react-icons/ai';

const InputClickableBox = ( {width, height, image, imageWidth, imageHeight, imageMarginTop, text, isSelected, onSelect, fontSize} ) => {

    const increasedWidth = `${parseInt(width) * 0.95}%`;
    const increasedHeight = `${parseInt(height) * 0.95}px`;

    return (
        <div className="inputClickableBoxParentDiv" style={{
            width: isSelected ? increasedWidth : width, 
            height: isSelected ? increasedHeight : height, 
            backgroundColor: 'white', // <-- Apply different background color based on isSelected
            border: isSelected ? `3px solid ${alternateColorScheme.blue2}` : "1px solid black"
            }}
            onClick={onSelect} // <-- Attach onClick handler

            >
            <div className='inputClickableBoxImageDiv'>
                <img src={image} style={{width: imageWidth, height: imageHeight, marginTop: imageMarginTop}} className='inputClickableBoxImage' alt='Wrist Watch Image'></img>

            </div>
            <div className='inputClickableBoxTextAndCheckParentDiv'>
                <SimpleDynamicTextBox 
                text={text} 
                fontSize={fontSize}  // Change fontSize based on isSelected
                fontWeight={450} 
                color={"black"} 
                marginTop={"0px"} />
                {isSelected ? <AiFillCheckCircle size={20} color="black" style={{marginTop: "7px"}} /> : <AiOutlineCheckCircle size={22} color="black" style={{marginTop: "7px"}} />}

            </div>
  
        </div>


    );



}


export default InputClickableBox;