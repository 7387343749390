import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Explore from './pages/ExploreWatchesPage';
import Sell from './pages/SellPage';
import FAQ from './pages/FaqPage';
import Login from './pages/LoginPage';
import ProfileHome from './pages/ProfileHome';
import SellerProfilePage from './pages/SellerProfilePage';
import RolexPage from './pages/RolexPage';
import WatchListingPage3 from './pages/WatchListingPage3';
import BuyWatchPage from './pages/BuyWatchPage';
import ThanksForPurchasePage from './pages/ThanksForPurchasePage';
import TestPage from './pages/TestPage';
import NewNavbar from './components/NewNavbar';
import FooterComponent from './components/FooterComponent';

const AppRouter = () => {
  return (

    <Router>
    <NewNavbar/>
      <Routes>
        <Route path="/" element={<Explore />} />
        <Route path="/rolex" element={<RolexPage />} />
        <Route path="/watchListing3" element={<WatchListingPage3 />} />
        <Route path="/buyWatch" element={<BuyWatchPage />} />
        <Route path="/thanksForPurchase" element={<ThanksForPurchasePage />} />
        <Route path="/testPage" element={<TestPage />} />

        
        <Route path="/faq" element={<FAQ/>} />

        <Route path="/exploreWatches" element={<Explore />} />
        <Route path="/sell/*" element={<Sell />} />
        <Route path="/loginAndRegister" element={<Login />} />
        <Route path="/profileHome/*" element={<ProfileHome />} />
        <Route path="/sellerProfile/*" element={<SellerProfilePage />} />

      </Routes>
    <FooterComponent/>

    </Router>
  );
};

export default AppRouter;
