import React, { useRef } from 'react';
import Slider from 'react-slick';
import './PurchasedWatchThumbnailStyles.css';
import purchasedWatchImage1 from '../assets/fakeListingImages/watchListing1.png';
import purchasedWatchImage2 from '../assets/fakeListingImages/watchListing2.png';
import purchasedWatchImage3 from '../assets/fakeListingImages/watchListing3.png';
import SliderArrow from './SliderArrow';

function PurchasedWatchThumbnail({ watchData }) {

    const listingSliderRef = useRef();

    const handleArrowClick = (direction) => {

        if (direction === 'left') {
            listingSliderRef.current.slickPrev(); // this changes the slide to the previous one
        } else if (direction === 'right') {
            listingSliderRef.current.slickNext(); // this changes the slide to the next one
        }
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // set this to false to remove the default arrows
      };

      const watchImages= [purchasedWatchImage1, purchasedWatchImage2, purchasedWatchImage3];



    return (
        <div className='purchasedWatchThumbnailParent'>
            <SliderArrow direction={'left'} handleClick={() => handleArrowClick('left')} width={"35px"} 
            height={"190px"} arrowMargin={"10px"} paddingLeft={"3px"} borderRadius={"8px"} 
            additionalStyles={{backgroundColor: '#ced4da', top: '105px', left: "0px"}} disableHover={true} invisible={true}/>
            <Slider ref={listingSliderRef} {...settings} className='sliderStyles'>
            {watchImages.map((image, index) => (
                        <img
                        className='purchasedWatchImage'
                        src={image}
                        >
                        </img>
                    ))}
            </Slider>
            <SliderArrow direction={'right'} handleClick={() => handleArrowClick('right')} width={"35px"} 
            height={"190px"} arrowMargin={"10px"} paddingLeft={"16px"} borderRadius={"8px"} 
            additionalStyles={{backgroundColor: '#ced4da', top: '105px'}} disableHover={true} invisible={true}/>
            <div className='purchasedWatchTextsParent'>
            <p className='purchasedWatchtext purchasedWatchBrandName'>
                {watchData.brand}
            </p>
            <p className='purchasedWatchtext purchasedWatchModelName'>
                {watchData.model}
            </p>
            <p className='purchasedWatchtext purchasedWatchPrice'>
                {`- $${watchData.price}`}
            </p>
            <p className='purchasedWatchtext purchasedWatchDate'>
                Purchased: <b>{watchData.date}</b>
            </p>
            </div>
        </div>
    );
}

export default PurchasedWatchThumbnail;