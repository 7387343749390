import React, { useState, useEffect } from 'react';
import { alternateColorScheme } from '../../commonStyles';
import './ProfileSettingsSectionStyles.css';
import InformationWithLockDiv from '../InformationWithLockDiv';
import PersonalInformationContentComponent from '../PersonalInformationContentComponent';
import { FaWindowMinimize } from 'react-icons/fa';

const ProfileSettingsSection = () => {

    const [active, setActive] = useState(false);

    useEffect(() => {
      // Activate the animation when the component mounts
      setActive(true);
    }, []);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
        <div className={`slide-up-fade-in ${active ? 'active' : ''}`}>
          <div className='settingsParentDiv'>
        <InformationWithLockDiv
          header={"Personal Information"}
          backgroundcolor={alternateColorScheme.silver4}
          width={windowWidth > 640 ? "584px" : windowWidth > 500  ? "450px" : "350px"}
          height={"500px"}
          windowWidth={windowWidth}
          type={"personal"}
          headerAndUnlockButtonWidth={windowWidth > 640 ? "510px" : windowWidth > 500  ? "400px" : "320px"}
          content={
            <PersonalInformationContentComponent


            />
          }
        />
        </div>
        </div>
    );
};

export default ProfileSettingsSection;