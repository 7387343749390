import React, { useState } from 'react';
import './AnimatedButtonStyles.css';
import AnimatedArrow from './AnimatedArrow';

const AnimatedButton = ({
  text,
  direction,
  width,
  height,
  color,
  hoverColor,
  textcolor,
  arrowColor,
  arrowAnimatedColor,
  arrowMarginLeft,
  paragraphMarginLeft,
  borderRadius,
  border,
  fontSize,
  fontWeight,
  paddingLeft,
  marginLeft,

}) => {

 
    
  const initialArrows = direction === 'left'
    ? [{ position: 18 }, { position: 0 }]
    : [{ position: -18 }, { position: 0 }];

  const [arrows, setArrows] = useState(initialArrows);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);

    setArrows(prevArrows => {
      const newArrows = [...prevArrows];
      if (direction === 'left') {
        newArrows[0].position = 0;
        newArrows[1].position = -18;
      } else {
        newArrows[0].position = 0;
        newArrows[1].position = 18;
      }
      return newArrows;
    });
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setArrows(initialArrows);
  };

  const paragraphStyle = {
    fontSize: fontSize,
    fontWeight: fontWeight,
    fontFamily: 'Arial',
    marginLeft: paragraphMarginLeft,
    color: isHovered ? color : textcolor,
    textDecoration: isHovered ? "underline" : "none",
  }

  return (
    <button
      className="animated-button"
      style={{
        width: width,
        height: height,
        backgroundColor: isHovered ? hoverColor : color,
        borderRadius: borderRadius,
        border: border,
        paddingLeft: paddingLeft,
        marginLeft: marginLeft,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {direction === 'right' && (
        <p
          style={paragraphStyle}
        >
          {text}
        </p>
      )}
      <AnimatedArrow
        arrows={arrows}
        animate={isHovered}
        direction={direction}
        arrowMarginLeft={arrowMarginLeft}
        animatedColor={arrowAnimatedColor}
        regularColor={arrowColor}
      />
      {direction === 'left' && (
        <p
          style={paragraphStyle}
        >
          {text}
        </p>
      )}
    </button>
  );
};

export default AnimatedButton;
