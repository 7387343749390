import React, { useState, useEffect } from 'react';
import { commonFont } from '../commonStyles';


const HeaderComponent = ({ text, marginRight, marginBottom, marginLeft, marginTop }) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const h1Styling = {
        textAlign: 'center',
        fontFamily: commonFont.fontFamily,
        fontSize: windowWidth > 520 ? '32px' : "25px",
        marginRight: marginRight,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
        marginTop: marginTop,
    
    }
    
    return (
        <header style={{display: 'flex', alignItems: 'center'}}>
            <h1 style={h1Styling}>{text}</h1>
        </header>
    )
}



export default HeaderComponent;