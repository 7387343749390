import React, { useEffect, useState } from 'react';
import './ImageAdderComponentStyles.css';
import SimpleDynamicTextBox from '../components/SimpleDynamicTextBox';
import { BiBorderRadius } from 'react-icons/bi';
import { PiWarningDiamondBold } from 'react-icons/pi';
import { RxCross2 } from 'react-icons/rx';
import AddImagesComponent from '../components/AddImagesComponent';
import NumberOfImagesComponent from '../components/NumberOfImagesComponent';

const ImageAdderComponent = ({
    images,
    setImages,
    computeGridHeight,
    marginTop,
    width,
  }) => {

    const [draggingIndex, setDraggingIndex] = useState(null); // New state to keep track of dragging index

    const [removeImageHoveredButtonIndex, setRemoveImageHoveredButtonIndex] = useState(null);


    const handleAddImage = (image) => {
        if (images.length < 10) {
          setImages([...images, image]);
        }
      };

      const handleRemoveImage = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
      };

        // New function to handle drag start
  const handleDragStart = (event, index) => {
    setDraggingIndex(index);
  };

  // New function to handle drop
  const handleDrop = (event, index) => {
    event.preventDefault();

    // Re-order images
    const newImages = [...images];
    const [draggedImage] = newImages.splice(draggingIndex, 1);
    newImages.splice(index, 0, draggedImage);

    setImages(newImages);
    setDraggingIndex(null); // Reset dragging index
  };

    const computeGridWidth = () => {
          if (width > 1300) {
          switch (images.length) {
            case 0:
              return 350;
            case 1:
              return 535;
            default:
              return 1000;
          }
        } else if (width > 1240) {
          switch (images.length) {
            case 0:
              return 309;
            case 1:
              return 472;
            default:
              return 640;
          }
        } else if (width > 1032) {
          switch (images.length) {
            case 0:
              return 268;
            case 1:
              return 408;
            default:
              return 640;
          }
        } else if (width > 900) {
          switch (images.length) {
            case 0:
              return 350;
            case 1:
              return 535;
            default:
              return 1000;
          }
        } else if (width > 820) {
          switch (images.length) {
            case 0:
              return 309;
            case 1:
              return 472;
            default:
              return 640;
          }
        } else if (width > 700) {
          switch (images.length) {
            case 0:
              return 268;
            case 1:
              return 408;
            default:
              return 539;
          }
        } else if (width > 570) {
          switch (images.length) {
            case 0:
              return 260;
            default:
              return 539;
          }
        }
      };




    return (
      <div className='addWatchImagesParentDiv'
        style={{ width: `${computeGridWidth() + 90}px`, height: `${computeGridHeight()}px`,
        marginTop: marginTop,
    }}
      >
        <SimpleDynamicTextBox text={"Upload Your Watch Pictures:"}
          fontSize={"1.4rem"}
          fontWeight={500} color={"white"} marginLeft={"5px"}
          marginTop={"10px"}
        />
        <div className='watchImagesParentDiv'
          style={{ width: `${computeGridWidth()}px`, height: `${computeGridHeight()}px` }}
        >
          <AddImagesComponent onAddImage={handleAddImage} marginTop={"28px"}
          text1={"Select files or Drag and drop"}
          text1Margin={"5px"}
          text2={"Drag images to reorder them"} 
          width={width}
          />
          {images.slice(0, 10).map((image, index) => (
                        <div className='watchImageBoxStyling'
                        draggable="true"
                        onDragStart={(e) => handleDragStart(e, index)} // New event handler
                        onDrop={(e) => handleDrop(e, index)} // New event handler
                        onDragOver={(e) => e.preventDefault()} // New event handler
            
                        >
                        <SimpleDynamicTextBox text={index+1} fontSize={"1.2rem"} 
                        fontWeight={500} color={"white"} marginLeft={width > 1300 ? "70px" : width > 1240 ? "60px" : width > 1032 ? "50px" : width > 900 ? "70px" : width > 820 ? "60px" : "50px"}
                        marginTop={"0px"}
                        />
                        <div className='centerContainer' style={{marginTop: "5px", position: 'relative'}}>
                        <img src={image} alt={`thumbnail-${index}`} key={index} 
                        className='watchImageStyling'
                        />
                            <button className='watchImageButtonRotateIcon' 
                            onMouseEnter={() => setRemoveImageHoveredButtonIndex(index)}
                            onMouseLeave={() => setRemoveImageHoveredButtonIndex(false)}
                            onClick={() => handleRemoveImage(index)}
                            style={{
                                transition: 'transform 0.5s ease',
                                backgroundColor: removeImageHoveredButtonIndex === index ? '#d81939' : 'white'

                            }}
                            >   {index == 0 &&
                                <div className='mainPhotoIconDiv'>
                                    <p style={{ fontSize: "0.6rem", fontWeight: "400px" }}>
                                        Main Photo
                                    </p>
                                </div>
                                }
                                <div 
                                        style={{
                                            transform: removeImageHoveredButtonIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.5s ease'
                                          }}
                                className='watchImageButtonIconWrapper'>
                                <RxCross2 
                                    size={removeImageHoveredButtonIndex === index ? 16 : 20}
                                    color={removeImageHoveredButtonIndex === index ? 'white' : 'black'}
                                    />
                                </div>
                            </button>
                        </div>
                        </div>
                    ))}
          <NumberOfImagesComponent currentNumOfPics={images.length} width={width} />
        </div>
      </div>
    );
  };
  
  export default ImageAdderComponent;