import React, { useState, useEffect } from 'react';
import './SellPageHeaderSectionStyles.css';
import SimpleDynamicTextBox from './SimpleDynamicTextBox';
import { Link } from 'react-router-dom';
import { GrFormCheckmark } from 'react-icons/gr';
import { HiOutlineLocationMarker } from 'react-icons/hi';

const SellPageHeaderSection = ({ activated, text, number, keepTriangle, linkDestination, isLastSection }) => {
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    const [shouldAnimate, setShouldAnimate] = useState(false);
  
    useEffect(() => {
      
        if (isLastSection) {
            setShouldAnimate(true);
        } else {
            setShouldAnimate(false);
        }
    }, [isLastSection]);

    const content = (
    <div className= {activated ? 'sellPageHeaderDivSectionDiv' : 'sellPageHeaderDivSectionDivNotActivated'}>
      <div className='sellPageHeaderDivSectionRectangle'
      style={{
        backgroundColor: activated && isLastSection && shouldAnimate ? "#48cae4" : 
        activated && !isLastSection ? "#48cae4" : 
        "black",
    }}
      > 

        <p style={{
          marginLeft: "5px",
          fontWeight: "600",
          color: activated ? "#003251" : "white",
        }}>
          {number}
        </p>
        <SimpleDynamicTextBox text={text} fontSize={windowWidth > 900 ? "1.05rem" : "0.75rem"} fontWeight={500} color={ activated ? "#003251" : "white"} marginLeft={"6px"} marginTop={"0px"} />
        {((activated && !isLastSection) || linkDestination == "overview") &&
        <GrFormCheckmark color='"black' size={20}/>
        }
      </div>
      {keepTriangle ? (
        <div className='sellPageHeaderDivSectionTriangle'
        style={{

            borderLeft: activated && isLastSection && shouldAnimate ? "34px solid #48cae4" : 
        activated && !isLastSection ? "34px solid #48cae4" : 
        "34px solid black",
        }}
        ></div>
      ) : (
        <div className='headerSectionLastDiv' 
        style={{
          width: "34px",
          height: "34px",
          backgroundColor: activated && isLastSection && shouldAnimate ? "#48cae4" : 
          activated && !isLastSection ? "#48cae4" : 
          "black",
        }}
        ></div>
      )}
    </div>
  );

  return activated ? (
    <Link to={linkDestination} className='SellPageHeaderSectionResetLinkStyles'>
      {content}
    </Link>
  ) : (
    <>
      {content}
    </>
  );
};

export default SellPageHeaderSection;
