import React, { createContext, useState } from 'react';

// Step 1: Create a Context
export const WatchFilterContext = createContext();

export const WatchFilterProvider = ({ children }) => {
  // Step 2: Provide the Context
  const [filter, setFilter] = useState(null);

  return (
    <WatchFilterContext.Provider value={{ filter, setFilter }}>
      {children}
    </WatchFilterContext.Provider>
  );
};
