import React from 'react';
import TextBox from './TextBox';
import './ThumbWidgetStyles.css';
import { Link } from 'react-router-dom'; // Import Link


const ThumbWidget = ( { text, imgSrc, to } ) => {

    return (
        <Link to={to} className="thumb-widget-link">

        <div className="thumb-widget-container">
            <div className="image-container">
                <img className="thumb-widget-image" src={imgSrc} alt="Explore PrimeTick" />
            </div>
            <TextBox children={text} color="black" size='16px' marginTop={'127px'} marginLeft={'17px'}/>
        </div>
        </Link>
    );



}



export default ThumbWidget;