import React from 'react';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

const ResizableInput = ({
    initialWidth,
    initialHeight,
    borderRadius,
    fontSize,
    marginLeft,
    marginTop,
    placeholderText,
    onInputChange = () => {},
    minConstraints = [100, 30], // Default values in case the props aren't provided
    maxConstraints = [500, 200], // Default values in case the props aren't provided
    minLength,  // New prop for minimum length
    maxLength   // New prop for maximum length
}) => {
    return (
        <ResizableBox 
            width={initialWidth} 
            height={initialHeight} 
            minConstraints={minConstraints} 
            maxConstraints={maxConstraints}
            style={{ marginLeft: marginLeft, marginTop: marginTop, justifySelf: 'flex-start' }}
        >
            <textarea 
                style={{ 
                    width: '100%', 
                    height: '100%', 
                    boxSizing: 'border-box',
                    borderRadius: borderRadius,
                    resize: 'none',
                    overflow: 'auto',
                    padding: '10px',
                    fontFamily: 'Arial',
                    fontSize: fontSize,
                }} 
                placeholder={placeholderText}
                onChange={e => onInputChange(e.target.value)}
                minLength={minLength}  // Assigning the prop value to textarea's minLength attribute
                maxLength={maxLength}  // Assigning the prop value to textarea's maxLength attribute
            />
        </ResizableBox>
    );
}

export default ResizableInput;
