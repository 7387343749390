import React, { useState, useRef } from 'react';
import './SimplifiedWatchListingStyles.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import CustomSliderArrow from './CustomSliderArrow';
import DynamicTextBox from './DynamicTextBox';
import { BiInfoSquare, BiSolidInfoSquare } from 'react-icons/bi';
import { BsFillInfoSquareFill } from 'react-icons/bs';
import HoverPopupDiv  from './HoverPopupDiv';
import { color, motion } from "framer-motion";
import { HiOutlineLocationMarker, HiLocationMarker } from 'react-icons/hi';

function SimplifiedWatchListing({ watchListingInfo, images, to }) {

    const [showHoverArrows, setShowHoverArrows] = useState(false);
    const [infoIconHovered, setInfoIconHovered] = useState(false);
    const [locationIconHovered, setLocationIconHovered] = useState(false);

    const listingSliderRef = useRef();

    const settings = {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // set this to false to remove the default arrows
      };

      const isSellerCommentLong = (sellerComment) => {
        return sellerComment.length > 33;

    };


    const handleArrowClick = (event, direction) => {
        event.preventDefault(); // Prevent the default anchor link behavior
        event.stopPropagation(); // Prevent the link navigation

        if (direction === 'left') {

            listingSliderRef.current.slickPrev(); // this changes the slide to the previous one
        } else if (direction === 'right') {
            listingSliderRef.current.slickNext(); // this changes the slide to the next one
        }
    };

    const handleMouseEnter = () => {
        setShowHoverArrows(true);
      };
    
      const handleMouseLeave = () => {
        setShowHoverArrows(false);
      };

    return (
        <Link to={to} 
        className='resetLinkStyle simplifiedWatchListingLink'>
        
        <div className='simplifiedWatchListingParent'>
                <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className='simpleWatchListingSliderParentStyles'
                >
                <CustomSliderArrow direction={"left"} onClick={(event) => handleArrowClick(event, 'left')}
                        showHoverArrows={showHoverArrows} 
                        top={"41%"}
                        sidePosition={"6px"} />
                <Slider ref={listingSliderRef} {...settings} 
                className='simpleWatchListingsliderStyles'
                >
                {images.map((image, index) => (
                            <img
                            className='simpleWatchListingPreviewImage'
                            src={image}
                            >
                            </img>
                        ))}
                </Slider>
                <CustomSliderArrow direction={"right"} onClick={(event) => handleArrowClick(event, 'right')}
                        showHoverArrows={showHoverArrows} 
                        top={"41%"}
                        sidePosition={"6px"} />
                </div>
                <p className='simpleWatchListingText smallThickText'

                >
                {`${watchListingInfo.brand} ${watchListingInfo.modelName}`}
                </p>
                <div className='simpleWatchListingSellerCommentDiv'>
                    <DynamicTextBox text={`${watchListingInfo.sellerComment}`} color="black" 
                    fontSize="0.9rem" fontWeight="400"  marginLeft="0px" marginTop="0px" 
                    paddingLeft={'5px'} paddingTop={'4px'} divHeight={"25px"}maxWidth={'88%'} 
                    borderRadius={'5px'} textOverflow={true}/>
                    {isSellerCommentLong(watchListingInfo.sellerComment) &&
                    <div className='simpleWatchListingInfoIconDiv'
                        onMouseEnter={() => setInfoIconHovered(true)}
                        onMouseLeave={() => setInfoIconHovered(false)}
                    >
                        {infoIconHovered ?
                        <>
                        <BiSolidInfoSquare color='black' size={24}/>

                        <motion.div className='sellerInfoHoverDiv'
                        style={{
                            width: "230px"
                        }}
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ duration: 0.1 }}
                        >
                        {watchListingInfo.sellerComment}
                        </motion.div>
                        </>
                        : 
                        <BiInfoSquare color='black' size={24}/>
                        
                        }
                    </div>
                    }
                </div>

                <div className='simpleWatchListingPriceAndLocationDiv'>
                        <div className='simpleWatchListingPriceDiv'>
                            <p className='smallText'
                            style={{
                                fontSize: "1.1rem",
                                marginTop: "3px"
                            }}
                            >
                                {`$${watchListingInfo.price}`}
                            </p>
                            <DynamicTextBox text={`+${watchListingInfo.shippingCost} Shipping`} color="gray" fontSize="0.8rem" fontWeight="400" marginLeft="0px" marginTop="2px"  />

                        </div>
                        <Link 
                        to='/sellerProfile'
                        className='resetLinkStyle'
                        >
                        <div className='simpleWatchListingLocationDiv'
                            onMouseEnter={() => setLocationIconHovered(true)}
                            onMouseLeave={() => setLocationIconHovered(false)}
                        >
                            {locationIconHovered ? 
                            <>
                            <HiLocationMarker color='black' size={18}/>
                            <HoverPopupDiv width={"250px"} height={"40px"} 
                            text={`This seller is from ${watchListingInfo.sellerCity}, ${watchListingInfo.sellerCountry}.`}
                            top={"-52px"}
                            right={"2px"}/>
                            </>
                            
                            :
                            <HiOutlineLocationMarker color='black' size={18}/>
                            
                            }
                            <p className='text smallThickText'
                            style={{
                                fontSize: "0.7rem"
                            }}
                            >
                                US
                            </p>

                        </div>
                        </Link>
                </div>

        </div>
        
        </Link>
        
    );
}

export default SimplifiedWatchListing;