import React, { useState, useEffect } from 'react';
import './PersonalInformationContentComponentStyles.css';
import { commonFont, alternateColorScheme } from '../commonStyles';
import PersonalInformationField from './PersonalInformationField';
import FilterDropDown from './FilterDropDown';
import DateInputComponent from './DateInputComponent';
import PersonalInformationUnlockButton from './PersonalInformationUnlockButton';
import { AiOutlineLock } from 'react-icons/ai';

function PersonalInformationContentComponent({  }) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const [personalInformation, setPersonalInformation] = useState({
        name: "Kent Larsson",
        address: "Hälsingegatan 41",
        birthDay: 7,
        birthMonth: "September",
        birthYear: 2002,
        country: "Sweden",
        postalCode: "11363",
        phoneNumber: "070-334 25 21",
    });

    const updateInformation = (field, value) => {
        setPersonalInformation({
            ...personalInformation,
            [field]: value
        });
    };

    const dayoptions = Array.from({ length: 31 }, (_, index) => ({
        value: index + 1, // Values should start from 1
        label: index + 1 // Labels should start from 1
    }));
    const months = [
        { label: "January", value: "January" },
        { label: "February", value: "February" },
        { label: "March", value: "March" },
        { label: "April", value: "April" },
        { label: "May", value: "May" },
        { label: "June", value: "June" },
        { label: "July", value: "July" },
        { label: "August", value: "August" },
        { label: "September", value: "September" },
        { label: "October", value: "October" },
        { label: "November", value: "November" },
        { label: "December", value: "December" }
      ];

      const countries = [
        { label: "Afghanistan", value: "Afghanistan" },
        { label: "Albania", value: "Albania" },
        { label: "Algeria", value: "Algeria" },
        { label: "Andorra", value: "Andorra" },
        { label: "Angola", value: "Angola" },
        { label: "Argentina", value: "Argentina" },
        { label: "Armenia", value: "Armenia" },
        { label: "Australia", value: "Australia" },
        { label: "Austria", value: "Austria" },
        { label: "Azerbaijan", value: "Azerbaijan" },
        { label: "Bahamas", value: "Bahamas" },
        { label: "Bahrain", value: "Bahrain" },
        { label: "Bangladesh", value: "Bangladesh" },
        { label: "Barbados", value: "Barbados" },
        { label: "Belarus", value: "Belarus" },
        { label: "Belgium", value: "Belgium" },
        { label: "Belize", value: "Belize" },
        { label: "Benin", value: "Benin" },
        { label: "Bhutan", value: "Bhutan" },
        { label: "Bolivia", value: "Bolivia" },
        { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
        { label: "Botswana", value: "Botswana" },
        { label: "Brazil", value: "Brazil" },
        { label: "Brunei", value: "Brunei" },
        { label: "Bulgaria", value: "Bulgaria" },
        { label: "Burkina Faso", value: "Burkina Faso" },
        { label: "Burundi", value: "Burundi" },
        { label: "Cambodia", value: "Cambodia" },
        { label: "Cameroon", value: "Cameroon" },
        { label: "Canada", value: "Canada" },
        { label: "Cape Verde", value: "Cape Verde" },
        { label: "Central African Republic", value: "Central African Republic" },
        { label: "Chad", value: "Chad" },
        { label: "Chile", value: "Chile" },
        { label: "China", value: "China" },
        { label: "Colombia", value: "Colombia" },
        { label: "Comoros", value: "Comoros" },
        { label: "Costa Rica", value: "Costa Rica" },
        { label: "Croatia", value: "Croatia" },
        { label: "Cuba", value: "Cuba" },
        { label: "Cyprus", value: "Cyprus" },
        { label: "Czech Republic", value: "Czech Republic" },
        { label: "Denmark", value: "Denmark" },
        { label: "Djibouti", value: "Djibouti" },
        { label: "Dominica", value: "Dominica" },
        { label: "Dominican Republic", value: "Dominican Republic" },
        { label: "Ecuador", value: "Ecuador" },
        { label: "Egypt", value: "Egypt" },
        { label: "El Salvador", value: "El Salvador" },
        { label: "Equatorial Guinea", value: "Equatorial Guinea" },
        { label: "Eritrea", value: "Eritrea" },
        { label: "Estonia", value: "Estonia" },
        { label: "Eswatini", value: "Eswatini" },
        { label: "Ethiopia", value: "Ethiopia" },
        { label: "Fiji", value: "Fiji" },
        { label: "Finland", value: "Finland" },
        { label: "France", value: "France" },
        { label: "Gabon", value: "Gabon" },
        { label: "Gambia", value: "Gambia" },
        { label: "Georgia", value: "Georgia" },
        { label: "Germany", value: "Germany" },
        { label: "Ghana", value: "Ghana" },
        { label: "Greece", value: "Greece" },
        { label: "Grenada", value: "Grenada" },
        { label: "Guatemala", value: "Guatemala" },
        { label: "Guinea", value: "Guinea" },
        { label: "Guinea-Bissau", value: "Guinea-Bissau" },
        { label: "Guyana", value: "Guyana" },
        { label: "Haiti", value: "Haiti" },
        { label: "Honduras", value: "Honduras" },
        { label: "Hungary", value: "Hungary" },
        { label: "Iceland", value: "Iceland" },
        { label: "India", value: "India" },
        { label: "Indonesia", value: "Indonesia" },
        { label: "Iran", value: "Iran" },
        { label: "Iraq", value: "Iraq" },
        { label: "Ireland", value: "Ireland" },
        { label: "Israel", value: "Israel" },
        { label: "Italy", value: "Italy" },
        { label: "Jamaica", value: "Jamaica" },
        { label: "Japan", value: "Japan" },
        { label: "Jordan", value: "Jordan" },
        { label: "Kazakhstan", value: "Kazakhstan" },
        { label: "Kenya", value: "Kenya" },
        { label: "Kiribati", value: "Kiribati" },
        { label: "Kuwait", value: "Kuwait" },
        { label: "Kyrgyzstan", value: "Kyrgyzstan" },
        { label: "Laos", value: "Laos" },
        { label: "Latvia", value: "Latvia" },
        { label: "Lebanon", value: "Lebanon" },
        { label: "Lesotho", value: "Lesotho" },
        { label: "Liberia", value: "Liberia" },
        { label: "Libya", value: "Libya" },
        { label: "Liechtenstein", value: "Liechtenstein" },
        { label: "Lithuania", value: "Lithuania" },
        { label: "Luxembourg", value: "Luxembourg" },
        { label: "Sweden", value: "Sweden" },
        { label: "United States of America", value: "United States of America" },

      ];

    return (
        <div className='personalInformationInputSpace'>
        <p 
        className='personalInformationSmallText'
        style={{
            fontFamily: commonFont.fontFamily,
            marginTop: "4px"
            }}>
            Name
        </p> 
        <PersonalInformationField 

        value={personalInformation.name}
        onChange={(value) => updateInformation('name', value)}

        />

        <p 
        className='personalInformationSmallText'
        style={{
            fontFamily: commonFont.fontFamily,
            }}>
            Address
        </p> 
        <PersonalInformationField 

        value={personalInformation.address}
        onChange={(value) => updateInformation('address', value)}

        />

        <DateInputComponent 
        dayValue={personalInformation.birthDay}
        onDayChange={(value) => updateInformation('birthDay', value)}
        monthValue={personalInformation.birthMonth}
        onMonthChange={(value) => updateInformation('birthMonth', value)}                 
        yearValue={personalInformation.birthYear}
        onYearChange={(value) => updateInformation('birthYear', value)} 
        header1={"Day"}
        header2={"Month"}
        header3={"Year"}  
        list1={dayoptions}
        list2={months}
        type={"personal"}
        />

        <p 
        className='personalInformationSmallText'
        style={{
            fontFamily: commonFont.fontFamily,
            }}>
            Country And Postal Code
        </p>

        <div
        style={{
            display: 'flex',
            width: '100%',
            height: '40px',
            marginTop: '3px',
        }}
        >
        <FilterDropDown options={countries} defaultOption={personalInformation.country}
        placeHolder={personalInformation.country}
        handleChange={(selectedOption) => updateInformation('country', selectedOption)}  
        marginLeft={"0px"} 
        marginTop={"0px"}
        width={windowWidth > 640 ? "320px" : windowWidth > 500 ? "220px" : "138px"} height={"40px"} borderRadius={"6px"} border={"0px solid black"}/>

        <input
        className='personalInformationSmallTextInputField'
        type='text'
        value={personalInformation.postalCode}
        onChange={(value) => updateInformation('postalCode', value)} 

        >
        </input>

        </div>

        <p 
        className='personalInformationSmallText'
        style={{
            fontFamily: commonFont.fontFamily,
            }}>
            Phone Number
        </p> 
        <PersonalInformationField 

        value={personalInformation.phoneNumber}
        onChange={(value) => updateInformation('phoneNumber', value)}

        />


        </div>
    );
}

export default PersonalInformationContentComponent;

