import React from 'react';
import { commonFont, alternateColorScheme } from '../commonStyles';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const getWeeksSinceDate = (inputDate) => {
  const today = new Date();
  const startDate = new Date(inputDate);
  const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
  const millisecondsSinceStart = today - startDate;
  const weeks = Math.floor(millisecondsSinceStart / millisecondsPerWeek);
  return weeks;
};

const formatDate = (date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

const VisitorChart = ({ inputDate, inData }) => {
  const today = new Date();
  const formattedToday = formatDate(today);
  const formattedInputDate = formatDate(new Date(inputDate));  // Ensure input date is formatted
  const weeksPassed = getWeeksSinceDate(inputDate);
  
  let labels = [formattedInputDate];  // Use formatted input date
  for (let i = 1; i <= weeksPassed; i++) {
    labels.push(`Week ${i}`);
  }
  labels.push(formattedToday);  // Use formatted today's date

  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          color: 'white', // Change this to the color you want
        },
        type: 'category',
        ticks: {
          color: 'white',  // Text color for x-axis
          font: {
            size: 12,
            family: commonFont.fontFamily,
            weight: 500  // Your preferred font family for x-axis labels
          },
          callback: function(_, index, values) {
            if (index === 0) {
              return [formattedInputDate, '(Start Date)'];  // Each array element will be on a new line
            }
            if (index === values.length - 1) {
              return [formattedToday, '(Today)'];  // Each array element will be on a new line
            }
            return null;  // No labels in between
          },
        },
      },
      y: {
        // ... (your existing y-axis options)
        grid: {
          color: 'white', // Change this to the color you want
        },
        ticks: {
          color: 'white',  // Text color for y-axis
          font: {
            size: 12,
            family: commonFont.fontFamily,  // Your preferred font family for x-axis labels
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,  // Add this line to hide the legend
      },
      title: {
        display: true,
        text: 'Watch Listing Visitors',
        font: {
          size: 14,
          family: commonFont.fontFamily,  // Your preferred font family for x-axis labels
        },
        color: 'white',  // Text color for y-axis
        align: 'start',  // Aligns the title to the top left corner

      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Listing Visitors',
        data: inData,
        borderColor: alternateColorScheme.purple2,  // Change the color here
        backgroundColor: "white",
        pointRadius: 4,  // Add this line to set the radius of the data points
        pointHitRadius: 10,

      },
    ],
  };

  return (
    <div style={{ height: '400px', width: '380px' }}>
      <Line options={options} data={data} />
    </div>
  );
};

export default VisitorChart;
