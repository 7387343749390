import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import SliderArrow from './SliderArrow';
import './PopularWatchBrandsStyles.css';
import './commonStyles.css';
import TextBox from './TextBox';
import rolexImage from '../assets/brandImages/rolex8.png';
import omegaImage from '../assets/brandImages/omega2.png';
import cartierImage from '../assets/brandImages/cartier1.png';
import patekphilippeImage from '../assets/brandImages/patekphilippe2.png';
import audemarspiguetImage from '../assets/brandImages/audemarspiguet5.png';
import hublotImage from '../assets/brandImages/hublot2.png';
import longinesImage from '../assets/brandImages/longines2.png';
import tagheuerImage from '../assets/brandImages/tagheuer3.png';
import iwcschaffhausenImage from '../assets/brandImages/iwcschaffhausen7.png';
import zenithImage from '../assets/brandImages/zenith2.png';
import breitlingImage from '../assets/brandImages/breitling2.webp';
import hamiltonImage from '../assets/brandImages/hamilton3.webp';
import paneraiImage from '../assets/brandImages/panerai1.webp';
import baumeandmercierImage from '../assets/brandImages/baumemercier.webp';
import blancpainImage from '../assets/brandImages/blancpain.webp';
import chopardImage from '../assets/brandImages/chopard1.webp';
import alangesohneImage from '../assets/brandImages/alangesohne.webp';
import glashutteImage from '../assets/brandImages/glashutte.webp';
import tudorImage from '../assets/brandImages/tudor1.webp';
import franckmullerImage from '../assets/brandImages/franckmuller1.webp';


const PopularWatchBrands = () => {

    const sliderRef = useRef();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleArrowClick = (direction) => {
        if (direction === 'left') {
            sliderRef.current.slickPrev(); // this changes the slide to the previous one
        } else if (direction === 'right') {
            sliderRef.current.slickNext(); // this changes the slide to the next one
        }
    };

    const brands = [
        { name: "Rolex", image: rolexImage },
        { name: "Omega", image: omegaImage },
        { name: "Cartier", image: cartierImage},
        { name: "Patek Philippe", image: patekphilippeImage},
        { name: "Audemars Piguet", image: audemarspiguetImage},
        { name: "Hublot", image: hublotImage},
        { name: "TAG Heuer", image: tagheuerImage},
        { name: "IWC Schaffhausen", image: iwcschaffhausenImage},
        { name: "Longines", image: longinesImage},
        { name: "Zenith", image: zenithImage},
        { name: "Breitling", image: breitlingImage},
        { name: "Hamilton", image: hamiltonImage},
        { name: "Panerai", image: paneraiImage},
        { name: "Baume & Mercier", image: baumeandmercierImage},
        { name: "Blancpain", image: blancpainImage},
        { name: "Chopard", image: chopardImage},
        { name: "A. Lange & Söhne", image: alangesohneImage},
        { name: "Glashütte Original", image: glashutteImage},
        { name: "Tudor", image: tudorImage},
        { name: "Franck Muller", image: franckmullerImage},

        // ... Add more brands here
    ];

    const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
    );

    const decideChunkAmount = (screenWidth) => {
        switch (true) {
            case (screenWidth <= 680): // Case for small screens like mobile devices
                return 4; // Return 1 for small screens
            case (screenWidth > 680 && screenWidth <= 900): // Case for medium screens like tablets
                return 6; // Return 2 for medium screens
            case (screenWidth > 1024 && screenWidth <= 1400): // Case for large screens like small laptops
                return 8; // Return 3 for large screens
            default: // Default case for extra large screens or any other size
                return 10; // You can choose the default return value
        }
    };
    
    const chunkSize = decideChunkAmount(screenWidth);
    const chunkedBrands = chunk(brands, chunkSize);

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
    };

    return (
        <div className='popularBrandsParentDiv'>
                        <SliderArrow direction={'left'} handleClick={() => handleArrowClick('left')} width={"47px"} 
            height={"130px"} top={"50%"} arrowMargin={"7px"} paddingLeft={"13px"} borderRadius={"3px"} border={"2px solid black"}/>
            <Slider ref={sliderRef} {...settings}>
                {chunkedBrands.map((brandChunk, index) => (
                    <div key={index} className="slide">
                        <div className="row">
                            {brandChunk.slice(0, chunkedBrands/2).map((brand, i) => (
                                <Link to={`/rolex`} key={i}>
                                    <div className="popularBrandsChildDiv">
                                        <div className='brandImageDiv'>
                                            <img className="brandImage" src={brand.image} alt={brand.name} />
                                        </div>
                                        <TextBox children={brand.name} fontSize={"0.4rem"}/>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <div className="row">
                            {brandChunk.slice(chunkedBrands/2).map((brand, i) => (
                                <Link to={`/rolex`} key={i}>
                                    <div className="popularBrandsChildDiv">
                                        <div className='brandImageDiv'>
                                            <img className="brandImage" src={brand.image} alt={brand.name} />
                                        </div>
                                        <TextBox children={brand.name} fontSize={screenWidth > 540 ? "1rem" : "0.8rem"}/>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                ))}
            </Slider>
            <SliderArrow direction={'right'} handleClick={() => handleArrowClick('right')} width={"47px"} 
            height={"130px"} top={"50%"} arrowMargin={"7px"} paddingLeft={"13px"} borderRadius={"3px"} border={"2px solid black"}/>
        </div>
    );



}


export default PopularWatchBrands;