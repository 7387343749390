import React, { useState, useEffect } from 'react';
import './ListingStatisticsStyles.css';
import SmallWatchListing from './SmallWatchListing';
import { commonFont, alternateColorScheme } from '../commonStyles';
import { PiPerson, PiPersonArmsSpreadBold, PiPersonArmsSpreadFill } from 'react-icons/pi';
import { BsInfoCircle, BsInfoCircleFill, BsHourglassSplit } from 'react-icons/bs';
import { BiConversation } from 'react-icons/bi';
import { CgArrowsExpandRight, CgCompressRight, CgEnter } from 'react-icons/cg';
import { AiOutlineExpandAlt } from 'react-icons/ai';
import LargeListingStatistics from './LargeListingStatistics';

import { color, motion } from "framer-motion";
import { LuTimer } from 'react-icons/lu'



const ListingStatistics = ( {watchListingInfo, marginLeft, index, rowIndex, handleListingSizeChange, isExpanded, width} ) => {

    const [showVisitorsInfo, setShowVisitorsInfo] = useState(false);
    const [showTimePerVisitInfo, setShowTimePerVisitInfo] = useState(false);

    const [size, setSize] = useState('small');
    const [showContent, setShowContent] = useState(true);

    const toggleSize = () => {
      const newSize = isExpanded ? 'small' : 'large';
      setSize(newSize);
      handleListingSizeChange(index, newSize, rowIndex); // Send the new size
      setShowContent(false);
    };

      useEffect(() => {
        if (!showContent) {
          const timer = setTimeout(() => {
            setShowContent(true);
          }, 150); // 200ms delay to show the new content
          return () => clearTimeout(timer);
        }
      }, [showContent]);

    return (
        <motion.div className={`listingStatisticsDiv ${size}`}

        style={{
          marginLeft: marginLeft,
          flexDirection: isExpanded ? "row" : "column",
          alignItems: isExpanded ? 'center' : 'none',
          justifyContent: isExpanded ? 'center' : 'none',

        }}
        initial={{ width: 270, height: 470 }}
        animate={{ width: isExpanded ? 500 : 270, height: isExpanded ? 720 : 530 }}
        transition={{ duration: 0.2 }}
      >
        {width > 660 &&
          <button className='listingStatisticsDivEnlargebutton'
            onClick={toggleSize}
          >
            {isExpanded ? <CgCompressRight size={16}/> : <CgArrowsExpandRight size={16}/>}
          </button>
        } 

          
            {showContent && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              {!isExpanded ?
                <>
                <SmallWatchListing
                images={watchListingInfo.images}
                watchListingInfo={watchListingInfo}
    
                />
                <div className='listingStatisticsStat'
                style={{
                    marginTop: "5px"
                }}
                >
                <PiPerson size={23} color="black"/>
                <p 
                className='listingStatisticsStatHeader'
                style={{
                    fontFamily: commonFont.fontFamily,
                    }}>
                    Total Visitors: 
                </p>
                <p 
                className='listingStatisticsStatText'
                style={{
                    fontFamily: commonFont.fontFamily,
                    }}>
                    754 
                </p>
                <button
                className='listingStatisticInfoButton'
                style={{
                    marginLeft: "18px"
                }}
                onMouseEnter={() => setShowVisitorsInfo(true)} 
                onMouseLeave={() => setShowVisitorsInfo(false)}
                >
                    {showVisitorsInfo ? 
                    <BsInfoCircleFill size={20}/>
                    :
                    <BsInfoCircle size={20}/>
                    }
    
                </button>
                {showVisitorsInfo &&
                        <motion.div className='listingStatisticPopupDiv'
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ duration: 0.1 }}
                        >
                        Visitors Last Week: 118
                        </motion.div>
                }
    
                </div>
                <div className='listingStatisticsStat'>
                <LuTimer size={23} color="black"/>
                <p 
                className='listingStatisticsStatHeader'
                style={{
                    fontFamily: commonFont.fontFamily,
                    }}>
                    Time Per Visit: 
                </p>
                <p 
                className='listingStatisticsStatText'
                style={{
                    fontFamily: commonFont.fontFamily,
                    }}>
                    32 s
                </p>
                <button
                className='listingStatisticInfoButton'
                style={{
                    marginLeft: "8px",
                }}
                onMouseEnter={() => setShowTimePerVisitInfo(true)} 
                onMouseLeave={() => setShowTimePerVisitInfo(false)}
                >
                    {showTimePerVisitInfo ? 
                    <BsInfoCircleFill size={20} />
                    :
                    <BsInfoCircle size={20} />
                    }
    
                </button>
                {showTimePerVisitInfo &&
                        <motion.div className='listingStatisticPopupDiv'
    
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ duration: 0.1 } }
                        >
                        Below Average
                        </motion.div>
                }
    
                </div>
                <div className='listingStatisticsStat'>
                <BiConversation size={23} color="black"/>
                <p 
                className='listingStatisticsStatHeader'
                style={{
                    fontFamily: commonFont.fontFamily,
                    }}>
                    Conversations:
                </p>
                <p 
                className='listingStatisticsStatText'
                style={{
                    fontFamily: commonFont.fontFamily,
                    }}>
                    14
                </p>
    
    
                </div>
                <div className='listingStatisticsStat'>
                <BsHourglassSplit size={23} color="black"/>
                <p 
                className='listingStatisticsStatHeader'
                style={{
                    fontFamily: commonFont.fontFamily,
                    }}>
                    Expires In:
                </p>
                <p 
                className='listingStatisticsStatText'
                style={{
                    fontFamily: commonFont.fontFamily,
                    }}>
                    16 Days
                </p>
    
    
                </div>
                </>
               
               : 
            
               <LargeListingStatistics/>
                }
               
            </motion.div>
          )}

    </motion.div>
        
        
            );



}


export default ListingStatistics;