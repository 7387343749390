import React, { useState, useEffect } from 'react';
import './FaqPageStyles.css';
import '../components/commonStyles.css';
import DynamicDropDown from '../components/DynamicDropDown';




const FAQ = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const howToBuyWatchDropDownContent = 
    <div className='standardFaqDropdownContent'>
                <div className='standardFlexDiv'
        style={{
            alignItems: "center",
        }}
        >
        <p className='smallHeader'>
            1.
        </p>
        <p className='smallText'
        style={{
            marginLeft: "10px"
        }}
        >
            Click "Explore Watches" in the navigation bar.
        </p>
        </div>
        <div className='standardFlexDiv'
        style={{
            alignItems: "center",
            marginTop: "10px"

        }}
        >
        <p className='smallHeader'>
            2.
        </p>
        <p className='smallText'
        style={{
            marginLeft: "10px"
        }}
        >
            Click on a brand to see watch listings.
        </p>
        </div>
        <div className='standardFlexDiv'
        style={{
            alignItems: "center",
            marginTop: "10px"

        }}
        >
        <p className='smallHeader'>
            3.
        </p>
        <p className='smallText'
        style={{
            marginLeft: "10px"
        }}
        >
            Inside a listing click on the "Buy Watch" button.
        </p>
        </div>
        <div className='standardFlexDiv'
        style={{
            alignItems: "center",
            marginTop: "10px"
        }}
        >
        <p className='smallHeader'>
            4.
        </p>
        <p className='smallText'
        style={{
            marginLeft: "10px"
        }}
        >
            Fill in all necessary address information.
        </p>
        </div>
        <div className='standardFlexDiv'
        style={{
            alignItems: "center",
            marginTop: "10px"
        }}
        >
        <p className='smallHeader'>
            5.
        </p>
        <p className='smallText'
        style={{
            marginLeft: "10px"
        }}
        >
            Done!
        </p>
        </div>
    </div>

const howToSellWatchDropDownContent = 
<div className='standardFaqDropdownContent'>
            <div className='standardFlexDiv'
    style={{
        alignItems: "center",
    }}
    >
    <p className='smallHeader'>
        1.
    </p>
    <p className='smallText'
    style={{
        marginLeft: "10px"
    }}
    >
        Click "Sell A Watch" in the navigation bar.
    </p>
    </div>
    <div className='standardFlexDiv'
    style={{
        alignItems: "center",
        marginTop: "10px"

    }}
    >
    <p className='smallHeader'>
        2.
    </p>
    <p className='smallText'
    style={{
        marginLeft: "10px"
    }}
    >
        Fill in all the necessary watch information on the first page.
    </p>
    </div>
    <div className='standardFlexDiv'
    style={{
        alignItems: "center",
        marginTop: "10px"
    }}
    >
    <p className='smallHeader'>
        3.
    </p>
    <p className='smallText'
    style={{
        marginLeft: "10px"
    }}
    >
        Upload pictures of your watch on the second page.
    </p>
    </div>
    <div className='standardFlexDiv'
    style={{
        alignItems: "center",
        marginTop: "10px"
    }}
    >
    <p className='smallHeader'>
        4.
    </p>
    <p className='smallText'
    style={{
        marginLeft: "10px"
    }}
    >
        Fill in personal information on the third page.
    </p>
    </div>
    <div className='standardFlexDiv'
    style={{
        alignItems: "center",
        marginTop: "10px"
    }}
    >
    <p className='smallHeader'>
        5.
    </p>
    <p className='smallText'
    style={{
        marginLeft: "10px"
    }}
    >
        Done!
    </p>
    </div>
</div>

const howToMessageSellerWatchDropDownContent = 
<div className='standardFaqDropdownContent'>
            <div className='standardFlexDiv'
    style={{
        alignItems: "center",
    }}
    >
    <p className='smallHeader'>
        1.
    </p>
    <p className='smallText'
    style={{
        marginLeft: "10px"
    }}
    >
        Click "Explore Watches" in the navigation bar.
    </p>
    </div>
    <div className='standardFlexDiv'
    style={{
        alignItems: "center",
        marginTop: "10px"

    }}
    >
    <p className='smallHeader'>
        2.
    </p>
    <p className='smallText'
    style={{
        marginLeft: "10px"
    }}
    >
        Click on a brand to see watch listings.
    </p>
    </div>
    <div className='standardFlexDiv'
    style={{
        alignItems: "center",
        marginTop: "10px"
    }}
    >
    <p className='smallHeader'>
        3.
    </p>
    <p className='smallText'
    style={{
        marginLeft: "10px"
    }}
    >
        Inside a listing click on the "Chat With Seller" button.
    </p>
    </div>
    <div className='standardFlexDiv'
    style={{
        alignItems: "center",
        marginTop: "10px"
    }}
    >
    <p className='smallHeader'>
        4.
    </p>
    <p className='smallText'
    style={{
        marginLeft: "10px"
    }}
    >
        Done!
    </p>
    </div>
</div>

const whySearchBarIsntWorking = 
<div className='standardFaqDropdownContent'>
            <div className='standardFlexDiv'
    style={{
        alignItems: "center",
    }}
    >
    <p className='smallText'
    style={{
        marginLeft: "0px"
    }}
    >
        If I could make copies of myself the searchbar would work. Unfortunately I can't.
    </p>
    </div>
</div>

    return (
        <div className='standardFlexDiv faqPageParent'>
            <div className='faqPageContentDiv'>
                <p
                className='header faqPageHeader'
                >
                    Questions and answers
                </p>
                <DynamicDropDown width={windowWidth > 750 ? "700px" : windowWidth > 540 ? "500px" : "340px"} height={"50px"} 
                headerText={"How to buy a watch"} 
                marginTop={"40px"}
                content={howToBuyWatchDropDownContent}
                contentHeight={windowWidth > 540 ? "180px" : "220px"}
                iconSize={"28px"}
                iconPaddingRight={"10px"}
                borderRadius={"35px"}
                headerPaddingLeft={"10px"}
                />
                <DynamicDropDown width={windowWidth > 750 ? "700px" : windowWidth > 540 ? "500px" : "340px"} height={"50px"} 
                headerText={"How to sell a watch"} 
                marginTop={"20px"}
                content={howToSellWatchDropDownContent}
                contentHeight={windowWidth > 540 ? "180px" : "234px"}
                iconSize={"28px"}
                iconPaddingRight={"10px"}
                borderRadius={"35px"}
                headerPaddingLeft={"10px"}
                />
                <DynamicDropDown width={windowWidth > 750 ? "700px" : windowWidth > 540 ? "500px" : "340px"} height={"50px"} 
                headerText={"How to message a seller"} 
                marginTop={"20px"}
                content={howToMessageSellerWatchDropDownContent}
                contentHeight={windowWidth > 540 ? "180px" : "170px"}
                iconSize={"28px"}
                iconPaddingRight={"10px"}
                borderRadius={"35px"}
                headerPaddingLeft={"10px"}
                />
                <DynamicDropDown width={windowWidth > 750 ? "700px" : windowWidth > 540 ? "500px" : "340px"} height={"50px"} 
                headerText={"Why isn't the searchbar working?"} 
                marginTop={"20px"}
                content={whySearchBarIsntWorking}
                contentHeight={"90px"}
                iconSize={"28px"}
                iconPaddingRight={"10px"}
                borderRadius={"35px"}
                headerPaddingLeft={"10px"}
                />
            </div>
        </div>
    );
}

export default FAQ;