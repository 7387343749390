import React from 'react';
import './PersonalInformationUnlockButtonStyles.css';
import { commonFont, alternateColorScheme } from '../commonStyles';

const PersonalInformationUnlockButton = ( {onClick, isActive, isParentHovered} ) => {
    const animationStyle = isActive ? { animation: 'none' } : {};
    const backgroundColorStyle = isActive ? { backgroundColor: alternateColorScheme.blue1 } : {}; // Choose the color you want


    let buttonClass = "unlockButtonParentDiv";

    if (isActive) {
        buttonClass += " buttonIsActive";
      } else if (isParentHovered) {
        buttonClass += " parentIsHovered";
      }

    return (
        <button className={buttonClass}
        onClick={onClick}
        style={backgroundColorStyle}

        >
            <p 
            className={`unlockButtonText`}
            style={{
                    fontFamily: commonFont.fontFamily,
                    fontSize: "1.2rem",
                    fontWeight: 500,
                    margin: "0px",
                    ...animationStyle,
                    color: isActive ? "white" : "",
                    }}>
                    {
                        isActive ? "Save" : "Edit"
                    }
                     
            </p>
        </button>


    );



}


export default PersonalInformationUnlockButton;