import React, { useState } from 'react';

import { FiMenu } from 'react-icons/fi';
import { RxCross2 } from 'react-icons/rx';
import IconLink from './IconLink';

import { PiWatch, PiWatchFill } from 'react-icons/pi';
import { AiOutlineCreditCard, AiFillCreditCard, AiOutlineStock, AiOutlineShop, AiFillShop } from 'react-icons/ai';
import { commonFont, alternateColorScheme } from '../commonStyles';


import { BiCog, BiSolidCog, BiConversation, BiSolidConversation } from 'react-icons/bi';
import { MdOutlineLogout } from 'react-icons/md';
import './ProfileHomeHamburgerStyles.css';

const ProfileHomeHamburger = ( { isActive } ) => {
    const [showMenu, setShowMenu] = useState(false);
  
    const toggleMenu = () => {
      setShowMenu(!showMenu);
    };
  
    const closeMenu = () => {
        setShowMenu(false);
      };
      
    return (
      <div style={{position: 'relative'}}>
        <button 
          className='profileHomeHamburgerButton'
          onClick={toggleMenu}
          aria-expanded={showMenu}
        >
          <FiMenu size={32} />
        </button>
        <div className={`profileHomeHamburgerLinksParentDiv ${showMenu ? 'open' : ''}`}>


            <div className='hamburgerLinksDiv'>
            <IconLink to="explore-primetick" className={`sidebar-link ${isActive('explore-primetick')}`} icon={<PiWatch size={'23px'} />} 
            selectedIcon={<PiWatchFill size={'23px'} />} text={`Explore PrimeTick`} 
            marginTop={'40px'} marginBottom={'0px'} leftPadding={'30px'}
            height={"30px"} width={"100%"}
            onClick={closeMenu}

            />
            <IconLink to="seller-dashboard" className={`sidebar-link ${isActive('seller-dashboard')}`} 
            icon={<AiOutlineStock size={'23px'} />} selectedIcon={<AiOutlineStock 
            size={'23px'} />} text={`Seller Dashboard`} marginTop={'15px'} 
            marginBottom={'0px'} leftPadding={'30px'}
            height={"30px"} width={"100%"}
            onClick={closeMenu}

            />

            <IconLink to="conversations" className={`sidebar-link ${isActive('conversations')}`} icon={<BiConversation size={'23px'} />} 
            selectedIcon={<BiSolidConversation size={'23px'} />} text={`Conversations`} 
            marginTop={'15px'} marginBottom={'0px'} leftPadding={'30px'}
            height={"30px"} width={"100%"}
            onClick={closeMenu}

            />

            <IconLink to="payment" className={`sidebar-link ${isActive('payment')}`} 
            icon={<AiOutlineCreditCard size={'23px'} />} selectedIcon={<AiFillCreditCard 
            size={'23px'} />} text={`Payment`} marginTop={'15px'} marginBottom={'0px'} 
            leftPadding={'30px'}
            height={"30px"} width={"100%"}
            onClick={closeMenu}

            />

            <IconLink to="section-two" className={`sidebar-link ${isActive('section-two')}`} 
            icon={<BiCog size={'23px'} />} selectedIcon={<BiSolidCog size={'23px'} />} 
            text={`Profile Settings`} marginTop={'15px'} marginBottom={'0px'} 
            leftPadding={'30px'}
            height={"30px"} width={"100%"}
            onClick={closeMenu}

            />

            <IconLink to="sign-out" className={`sidebar-link ${isActive('sign-out')}`} 
            icon={<MdOutlineLogout size={'23px'} />} selectedIcon={<MdOutlineLogout 
            size={'23px'} />} text={`Sign out`} marginTop={'15px'} marginBottom={'0px'} 
            leftPadding={'32px'} 
            height={"30px"} width={"100%"}
            onClick={closeMenu}

            />
            </div>

            <button 
          className='hamburgerPopupCloseButton'
          onClick={toggleMenu}>
            <RxCross2 size={30} color='black'/>
            </button>

        </div>
      </div>
    );
  }
  


export default ProfileHomeHamburger;