import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import './PurchasedWatchesStyles.css';
import PurchasedWatchThumbnail from './PurchasedWatchThumbnail';
import CustomSliderArrow from './CustomSliderArrow';

function PurchasedWatches({  }) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    function getSlidesToShow(width) {
        if (width <= 550) return 1; // For mobile devices
        else if (width > 550 && width <= 772) return 2; // For tablets
        else if (width > 772 && width <= 1200) return 3; // For small desktops
        else return 4; // For larger desktops
    }

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setWindowWidth(newWidth);
            setSlidesToShow(getSlidesToShow(newWidth));
          };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const [slidesToShow, setSlidesToShow] = useState(4);
  
    const [showHoverArrows, setShowHoverArrows] = useState(false);


    const handleMouseEnter = () => {
      setShowHoverArrows(true);
    };
  
    const handleMouseLeave = () => {
      setShowHoverArrows(false);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow,
        arrows: false,


      };

      const sliderRef = useRef();


      const handleArrowClick = (direction) => {
        if (direction === 'left') {
            sliderRef.current.slickPrev(); // this changes the slide to the previous one
        } else if (direction === 'right') {
            sliderRef.current.slickNext(); // this changes the slide to the next one
        }
    };

    const purchasedWatchesData = [
        {
            brand: "Audemars Piguet",
            model: "Royal Oak Offshore",
            price: "8567",
            date: "2022-08-07",
        },
        {
            brand: "Rolex",
            model: "Submariner",
            price: "10500",
            date: "2023-01-15",
        },
        {
            brand: "Omega",
            model: "Seamaster",
            price: "4800",
            date: "2022-11-20",
        },
        {
            brand: "Patek Philippe",
            model: "Nautilus",
            price: "33000",
            date: "2023-02-28",
        },
        {
            brand: "Breitling",
            model: "Navitimer",
            price: "6600",
            date: "2023-03-10",
        },
        {
            brand: "Tag Heuer",
            model: "Carrera",
            price: "5500",
            date: "2023-04-26",
        },
        {
            brand: "Panerai",
            model: "Luminor",
            price: "7200",
            date: "2023-05-08",
        },
        {
            brand: "IWC",
            model: "Portugieser",
            price: "12300",
            date: "2022-12-05",
        },
        {
            brand: "Hublot",
            model: "Big Bang",
            price: "20000",
            date: "2023-06-18",
        },
        {
            brand: "Cartier",
            model: "Tank",
            price: "3450",
            date: "2022-07-21",
        },
        {
            brand: "Jaeger-LeCoultre",
            model: "Reverso",
            price: "9900",
            date: "2023-07-19",
        },
        {
            brand: "Richard Mille",
            model: "RM 011",
            price: "170000",
            date: "2022-06-30",
        },
        {
            brand: "Vacheron Constantin",
            model: "Overseas",
            price: "24500",
            date: "2023-08-12",
        },
        {
            brand: "Breguet",
            model: "Classique",
            price: "18300",
            date: "2023-03-21",
        },
        {
            brand: "Girard-Perregaux",
            model: "Laureato",
            price: "14200",
            date: "2023-09-05",
        },
        {
            brand: "Bell & Ross",
            model: "BR 03-94",
            price: "5700",
            date: "2023-10-15",
        }
        
    ]

    return (

            <div className='purchasedWatchesSliderDiv'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            >
            <p className='purchasedWatchesHeader'>
                Previous Purchases
            </p>
            <CustomSliderArrow direction={"left"} onClick={() => handleArrowClick('left')}
                    sidePosition={"-18px"} top={"40%"}
                    showHoverArrows={showHoverArrows}/>
                    <div className='purchasedWatchesOnlySliderDiv'>
                    <Slider {...settings}
                    ref={sliderRef}
                    >
                    {purchasedWatchesData.map((watchInfo, index) => (
                        <div key={index}>
                        <PurchasedWatchThumbnail watchData={watchInfo}/>
                        </div>
                    ))}
                    </Slider>
                    </div>
                    <CustomSliderArrow direction={"right"} onClick={() => handleArrowClick('right')}
                    sidePosition={"-18px"} top={"40%"}
                    showHoverArrows={showHoverArrows}/>

            </div>

    );
}

export default PurchasedWatches;